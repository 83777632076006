// Contain the reducer of Dashboard
import { dasboard_type, B2BFooter_type } from '../helpers/types';

let {
  SUBSCRIBER_HIERARCHY_SUCCESS,
  SUBSCRIBER_HIERARCHY_ERROR,
  SUBSCRIBER_HIERARCHY_SUCCESS_B2B,
  SUBSCRIBER_HIERARCHY_ERROR_B2B,
  CUSTOMER_INFO_SUCCESS,
  CUSTOMER_INFO_ERROR,
  CUSTOMER_INFO_RESET
} = dasboard_type;


const {
  FOOTER_B2B_PRODUCT_BUSSINESS_SUCCESS,
  FOOTER_B2B_PRODUCT_BUSSINESS_ERROR,
  FOOTER_B2B_WHY_AIRTEL_ERROR,
  FOOTER_B2B_WHY_AIRTEL_SUCCESS,
  FOOTER_B2B_CONTACT_US_SUCCESS,
  FOOTER_B2B_CONTACT_US_ERROR,
  FOOTER_B2B_ABOUT_STORE_LOCATION_SUCCESS,
  FOOTER_B2B_ABOUT_STORE_LOCATION_ERROR,
  FOOTER_B2B_ABOUT_AIRTEL_ERROR,
  FOOTER_B2B_ABOUT_AIRTEL_SUCCESS,

  //menu
  FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_SUCCESS,
  FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_ERROR,
  FOOTER_B2B_WHY_AIRTEL_TITLE_ERROR,
  FOOTER_B2B_WHY_AIRTEL_TITLE_SUCCESS,
  FOOTER_B2B_CONTACT_US_TITLE_SUCCESS,
  FOOTER_B2B_CONTACT_US_TITLE_ERROR,
  FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_SUCCESS,
  FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_ERROR,
  FOOTER_B2B_ABOUT_AIRTEL_TITLE_ERROR,
  FOOTER_B2B_ABOUT_AIRTEL_TITLE_SUCCESS
} = B2BFooter_type

export const initState = {
  customerInfo: [],
  customerInfoError: "",
  subscriberHierarchy: {},
  subscriberHierarchyError: "",
  subscriberHierarchyB2B: {},
  subscriberHierarchyErrorB2B: "",
  product_solution_business: [],
  product_solution_business_error: "",
  why_airtel_vodafone: [],
  why_airtel_vodafone_error: "",
  contact_us: [],
  contact_us_error: "",
  store_locations: [],
  store_locations_error: "",
  about_airtel_vodafone: [],
  about_airtel_vodafone_error: "",

  // menu
  product_solution_business_title: {},
  product_solution_business_error_title: "",
  why_airtel_vodafone_title: {},
  why_airtel_vodafone_error_title: "",
  contact_us_title: {},
  contact_us_error_title: "",
  store_locations_title: {},
  store_locations_error_title: "",
  about_airtel_vodafone_title: {},
  about_airtel_vodafone_error_title: ""
};

const dashboard = (state = initState, action = {}) => {
  const { type, data, error } = action;
  switch (type) {
    case CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        customerInfo: data,
        customerInfoError: ""

      };
    case CUSTOMER_INFO_ERROR:
      return {
        ...state,
        customerInfo: [],
        customerInfoError: error

      };
    case CUSTOMER_INFO_RESET:
      return {
        ...state,
        customerInfo: [],
        customerInfoError: ""
      };
    case SUBSCRIBER_HIERARCHY_SUCCESS:
      return {
        ...state,
        subscriberHierarchy: data,

      };
    case SUBSCRIBER_HIERARCHY_ERROR:
      return {
        ...state,
        subscriberHierarchy: {},
        subscriberHierarchyError: error

      };
    case SUBSCRIBER_HIERARCHY_SUCCESS_B2B:
      return {
        ...state,
        subscriberHierarchyB2B: data,
        subscriberHierarchyErrorB2B: ""

      };
    case SUBSCRIBER_HIERARCHY_ERROR_B2B:
      return {
        ...state,
        subscriberHierarchyB2B: {},
        subscriberHierarchyErrorB2B: error

      };
    case FOOTER_B2B_PRODUCT_BUSSINESS_SUCCESS:
      return {
        ...state,
        product_solution_business: data,
        product_solution_business_error: ""
      }
    case FOOTER_B2B_PRODUCT_BUSSINESS_ERROR:
      return {
        ...state,
        product_solution_business: [],
        product_solution_business_error: error
      }
    case FOOTER_B2B_WHY_AIRTEL_SUCCESS:
      return {
        ...state,
        why_airtel_vodafone: data,
        why_airtel_vodafone_error: ""
      }
    case FOOTER_B2B_WHY_AIRTEL_ERROR:
      return {
        ...state,
        why_airtel_vodafone: [],
        why_airtel_vodafone_error: error
      }
    case FOOTER_B2B_CONTACT_US_SUCCESS:
      return {
        ...state,
        contact_us: data,
        contact_us_error: ""
      }
    case FOOTER_B2B_CONTACT_US_ERROR:
      return {
        ...state,
        contact_us: [],
        contact_us_error: error
      }
    case FOOTER_B2B_ABOUT_STORE_LOCATION_SUCCESS:
      return {
        ...state,
        store_locations: data,
        store_locations_error: "",
      }
    case FOOTER_B2B_ABOUT_STORE_LOCATION_ERROR:
      return {
        ...state,
        store_locations: [],
        store_locations_error: error,
      }
    case FOOTER_B2B_ABOUT_AIRTEL_SUCCESS:
      return {
        ...state,
        about_airtel_vodafone: data,
        about_airtel_vodafone_error: ""
      }
    case FOOTER_B2B_ABOUT_AIRTEL_ERROR:
      return {
        ...state,
        about_airtel_vodafone: [],
        about_airtel_vodafone_error: error
      }
    //menu
    case FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_SUCCESS:
      return {
        ...state,
        product_solution_business_title: data,
        product_solution_business_error_title: ""
      }
    case FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_ERROR:
      return {
        ...state,
        product_solution_business_title: {},
        product_solution_business_error_title: error
      }
    case FOOTER_B2B_WHY_AIRTEL_TITLE_SUCCESS:
      return {
        ...state,
        why_airtel_vodafone_title: data,
        why_airtel_vodafone_error_title: ""
      }
    case FOOTER_B2B_WHY_AIRTEL_TITLE_ERROR:
      return {
        ...state,
        why_airtel_vodafone_title: {},
        why_airtel_vodafone_error_title: error
      }
    case FOOTER_B2B_CONTACT_US_TITLE_SUCCESS:
      return {
        ...state,
        contact_us_title: data,
        contact_us_error_title: ""
      }
    case FOOTER_B2B_CONTACT_US_TITLE_ERROR:
      return {
        ...state,
        contact_us_title: {},
        contact_us_error_title: error
      }
    case FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_SUCCESS:
      return {
        ...state,
        store_locations_title: data,
        store_locations_error_title: "",
      }
    case FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_ERROR:
      return {
        ...state,
        store_locations_title: {},
        store_locations_error_title: error,
      }
    case FOOTER_B2B_ABOUT_AIRTEL_TITLE_SUCCESS:
      return {
        ...state,
        about_airtel_vodafone_title: data,
        about_airtel_vodafone_error_title: ""
      }
    case FOOTER_B2B_ABOUT_AIRTEL_TITLE_ERROR:
      return {
        ...state,
        about_airtel_vodafone_title: {},
        about_airtel_vodafone_error_title: error
      }
    default:
      return state;
  }
};

export default dashboard;
