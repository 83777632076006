import React from 'react'
import { SimpleButton } from '../../common'
import { getCurrency, getMobileNumber } from '../../common/utils'

function PaymentResponse({ icon, message, heading, closePaymentModal, paymentValidationSuccess }) {
    return (
        <div className="payment">
            <div className="succes_icon">
                <img src={icon} alt="success" />
            </div>
            <div class="message">
                {heading && <h3 class="m-0">{heading}</h3>}
                {message && <p>{message}</p>}
            </div>
            <ul>
                <li>
                    <span>Invoice number</span>
                    <span className="f-b">{paymentValidationSuccess.invoiceNumber}</span>
                </li>
                <li>
                    <span>Mobile number</span>
                    <span className="f-b">{getMobileNumber(paymentValidationSuccess.siNumber)}</span>
                </li>
                <li className="paid">
                    <span>Amount</span>
                    <span className="amt">{getCurrency(paymentValidationSuccess.amount)}</span>
                </li>
                <li className="transaction">
                    <span>Transaction id</span>
                    <span className="f-b">{paymentValidationSuccess.transactionId}</span>
                </li>
            </ul>
            <SimpleButton onClick={closePaymentModal}>Close</SimpleButton>
        </div>
    )
}

export default PaymentResponse