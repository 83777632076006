import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { b2b_prefix, postpaid_prefix, idle_time_config } from './components/envConfig'
import "./Assets/sass/App.scss";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Login from "./components/login/Login";
import Layout from './components/layout'
import ProtectedRoute from "./routes/ProtectedRoute";
import PostpaidRoute from "./routes/postpaidRoute";
import { useDispatch, useSelector } from "react-redux";
import B2bRoute from "./routes/b2bRoute";
import { b64_to_string, ResponseModal, ResponseMessage } from "./components/common";
import IdelTimer from "./components/services/IdelTimer";
import { logout } from "./redux/actions/loginAction";
import './components/services/firebase'
import Home from "./components/login/Home";

function App() {

  const [showResponseModal, setShowResponseModal] = useState(false)
  const { logoutError, optimusDetails } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { domain, si, emailAddress } = optimusDetails

  useEffect(() => {
    const timer = new IdelTimer({
      timeout: idle_time_config || 500000, //expire after 10 minute
      onTimeout: () => {
        if ((domain || si) && (domain || emailAddress)) {
          dispatch(logout(domain === 'B2C' ? si : "", domain === 'B2B' ? b64_to_string(emailAddress) : "", domain))
          console.log("session expire")
        }
      },
    });

    return () => timer.cleanUp();
  }, []);


  useEffect(() => {
    // if (auth?.userType === b2b_prefix) {
    //   dispatch(footerData("B2B"))
    // }

    // if (auth?.userType === postpaid_prefix || auth?.userType === prepaid_prefix) {
    //   dispatch(footerData("B2C"))
    // }

    if (logoutError && logoutError !== "") {
      setShowResponseModal(true)
    }

  }, [logoutError])

  return (
    <div className="App">
      <ResponseModal id="rs-22" show={showResponseModal} handleClose={() => { dispatch({ type: "LOGOUT_RESET" }); setShowResponseModal(false) }} data-testid="response-modal">
        <ResponseMessage
          id="rs-22s32"
          error
          heading={"Warning"}
          message={logoutError}
          button={{ label: "Close", onClick: () => setShowResponseModal(false) }}
        />
      </ResponseModal>
      <Router>
        <Layout>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/login" component={Login} />
            <Route path={`/${postpaid_prefix}`}>
              <ProtectedRoute url={postpaid_prefix} component={PostpaidRoute} />
            </Route>
            <Route path={`/${b2b_prefix}`}>
              <ProtectedRoute url={b2b_prefix} component={B2bRoute} />
            </Route>
            <Redirect to="/" />
          </Switch>
        </Layout>
      </Router>
    </div >
  );
}

export default App;
