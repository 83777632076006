import { Container, Row, Col } from 'react-bootstrap';
import { GooglePlayIcon, AppStoreIcon, facebookIcon, instagramIcon, twitterIcon, airtelVodaLogoIcon, LinkedinIcon } from '../../Icons'
import { useState } from 'react';
import AcoordianFooter from '../../common/components/AcoordianFotter';
import { social, applicationStore } from './socialLinks';
import { Link } from 'react-router-dom';
import prodFooterjsy from '../../json/prodWebFooterJsy.json'
import prodFootergsy from '../../json/prodWebFootergsy.json'
import SITfooter from '../../json/SITfooter.json'
import { ENV } from '../../envConfig'
import { useSelector } from 'react-redux';
let Footer = () => {
    const [active, setActive] = useState("")
    const footerDatajsy = ENV === 'prod' ? prodFooterjsy : SITfooter
    const footerDatagsy = ENV === 'prod' ? prodFootergsy : SITfooter

    const circle = {
        circle_jsy: "JERSEY",
        circle_Gsy: "GUERNSEY"
    }

    const { optimusDetails } = useSelector(state => state.auth);

    const openChatBot = () => {

        if (document.getElementById("chatButton") && document.getElementById("chatButton").getElementsByClassName('assistant-bot-icon')[0]) {
            document.getElementById("chatButton").getElementsByClassName('assistant-bot-icon')[0].click();
        }
    }


    // these object are store both the value for b2b footer and b2c footer
    // product_solution_business or devices
    // contact_us  or help_support
    // const {
    //     product_solution_business,
    //     //product_solution_business_error,
    //     why_airtel_vodafone,
    //     //why_airtel_vodafone_error,
    //     contact_us,
    //     //contact_us_error,
    //     store_locations,
    //     //store_locations_error,
    //     about_airtel_vodafone,
    //     //about_airtel_vodafone_error

    //     product_solution_business_title,
    //     // product_solution_business_error_title,
    //     why_airtel_vodafone_title,
    //     // why_airtel_vodafone_error_title,
    //     contact_us_title,
    //     // contact_us_error_title,
    //     store_locations_title,
    //     // store_locations_error_title,
    //     about_airtel_vodafone_title,
    //     // about_airtel_vodafone_error_title

    // } = useSelector(state => state.dashboard)


    return (
        <>

            <div id="footer" className="footer">
                <Row id="ft-12">
                    <Col sm={9} className="d-none d-xl-block" id="ft-1222">
                        Manage your Airtel-Vodafone products and services in the easiest way
                    </Col>
                    <Col id="ft-122w">
                        <Row id="ft-12sw2">
                            <Col xs={6} sm={6} className="text-center" id="ft-122">
                            <a href={applicationStore.AppStore} target="_blank" title="Link to apple app store" rel="noopener nofollow noreferrer">
                                <img
                                    id="ft-1112"
                                    src={AppStoreIcon}
                                    alt="app store"
                                    className="d-inline-block align-top"
                                />
                                </a>
                                </Col>
                            <Col xs={6} sm={6} className="text-center" id="ft-12w12">
                            <a href={applicationStore.GooglePlay} target="_blank" title="Link to google play store" rel="noopener nofollow noreferrer">
                                <img
                                    id="ft-wsse12"
                                    src={GooglePlayIcon}
                                    alt="google play"
                                    className="d-inline-block align-top"
                                />
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Container className="footer-social" id="ft-1hsc2">
                <div id="ft-social" className="footer-social-font pb-3">Social</div>
                <div className="d-flex pb-3 justify-content-between footer-social-border" id="ft-wsws12">
                    <div id="ft-w-12">
                        <a href={social.fb} target='blank' id="uy7yuy" >
                            <span className="m-r-4" id="ft-skk112">
                                <img
                                    src={facebookIcon}
                                    alt="facebook"
                                    className="d-inline-block align-top"
                                    id="ft-12ww32"
                                />
                            </span>
                        </a>
                        <a href={social.insta} target='blank' id="uy7yuiy" >
                            <span className="m-r-4" id="ft-1de42">
                                <img
                                    src={instagramIcon}
                                    alt="instagram"
                                    className="d-inline-block align-top"
                                    id="ft-332w12"
                                />
                            </span>
                        </a>
                        <a href={social.twitter} target='blank' id="uy7yiuiy" >
                            <span className="m-r-4" id="ft-1332s2">
                                <img
                                    src={twitterIcon}
                                    alt="twitter"
                                    className="d-inline-block align-top"
                                    id="ft-ww2212"
                                />
                            </span>
                        </a>
                        <a href={social.linkedin} target='blank' id="uy7yunuiy" >
                            <span id="ft-1233ww">
                                <img
                                    src={LinkedinIcon}
                                    alt="linkedin"
                                    className="d-inline-block align-top"
                                    id="ft-1www2"
                                    width={32}
                                />
                            </span>
                        </a>
                    </div>
                    <Link to="/">
                        <img
                            src={airtelVodaLogoIcon}
                            id="ft-1wwwi2"
                            alt="Airtel/vodaphone"
                            className="d-inline-block align-top navBar-img"
                        />
                    </Link>
                </div>
                {/* hardcord fotter for old website */}
                <div className="footer-links footer-social-border" id="ft-133frfdr2">
                    <Row className="my-4 m-0" id="ft-1htt2">
                        {String(optimusDetails.circle).toUpperCase() === circle.circle_jsy ? <>{footerDatajsy.map((item, i) => (
                            <Col className="px-0" id="ft-14rrtt2" key={i}>
                                <h5 className="footer-social-font" id="fth-112">{item.title ? item.title : ""}</h5>
                                <div className="footer-nav">
                                    {item.items && item.items.length > 0 && item.items.map((nav, index) => (
                                        <div key={index}> {nav.title === "Chat with us" ? <a onClick={openChatBot}  href={nav.link}>{nav.title}</a> : <a href={nav.link} target='blank'>{nav.title}</a>}</div>
                                    ))}
                                </div>
                            </Col>
                        ))}</> : <>{footerDatagsy.map((item, i) => (
                            <Col className="px-0" id="ft-14rrtt2" key={i}>
                                <h5 className="footer-social-font" id="fth-112">{item.title ? item.title : ""}</h5>
                                <div className="footer-nav">
                                    {item.items && item.items.length > 0 && item.items.map((nav, index) => (
                                        <div key={index}> <a  href={nav.link} target='blank'>{nav.title}</a></div>
                                    ))}
                                </div>
                            </Col>
                        ))} </>}
                        
                    </Row>
                </div>
                { }
                <div className="footer-links-acoordian" id="ftac-12">
                    {String(optimusDetails.circle).toUpperCase() === circle.circle_jsy ? <>{footerDatajsy.map((item, i) => (
                        <AcoordianFooter key={i} name={item.title ? item.title : ""} id="ftac-sss12" title={item.title ? item.title : ""} active={active} setActive={setActive} >
                            <div className="footer-nav">
                                {item.items && item.items.length > 0 && item.items.map((nav, index) => (
                                    <div key={index}> <a href={nav.link} target='blank'>{nav.title}</a></div>
                                ))}

                            </div>
                        </AcoordianFooter>
                    ))}</> : <>{footerDatagsy.map((item, i) => (
                        <AcoordianFooter key={i} name={item.title ? item.title : ""} id="ftac-sss12" title={item.title ? item.title : ""} active={active} setActive={setActive} >
                            <div className="footer-nav">
                                {item.items && item.items.length > 0 && item.items.map((nav, index) => (
                                    <div key={index}> <a href={nav.link} target='blank'>{nav.title}</a></div>
                                ))}

                            </div>
                        </AcoordianFooter>
                    ))}</>}
                    
                </div>
                {/* <div className="footer-links footer-social-border" id="ft-133frfdr2">
                    <Row className="my-4 m-0" id="ft-1htt2">
                        <Col className="px-0" id="ft-14rrtt2">
                            <h5 className="footer-social-font" id="fth-112">{!isEmpty(product_solution_business_title) ? product_solution_business_title.label : ""}</h5>
                            <div className="footer-nav">
                                {product_solution_business.map((item, i) => (
                                    <div key={i}> <a href={item.absolute} target='blank'>{item.title}</a></div>
                                ))}

                            </div>
                        </Col>
                        <Col className="px-0">
                            <h5 className="footer-social-font" id="fts-1222">{!isEmpty(why_airtel_vodafone_title) ? why_airtel_vodafone_title.label : ""}</h5>
                            <div className="footer-nav">
                                {why_airtel_vodafone.map((item, i) => (
                                    <div key={i}> <a href={item.absolute} target='blank'>{item.title}</a></div>
                                ))}
                            </div>
                        </Col>
                        <Col className="px-0">
                            <h5 className="footer-social-font" id="ftp-123ww">{!isEmpty(contact_us_title) ? contact_us_title.label : ""}</h5>
                            <div className="footer-nav">
                                {contact_us.map((item, i) => (
                                    <div key={i}> <a href={item.absolute} target='blank'>{item.title}</a></div>
                                ))}
                            </div>
                        </Col>
                        <Col className="px-0">
                            <h5 className="footer-social-font" id="ftp-123ww">{!isEmpty(about_airtel_vodafone_title) ? about_airtel_vodafone_title.label : ""}</h5>
                            <div className="footer-nav">
                                {about_airtel_vodafone.map((item, i) => (
                                    <div key={i}> <a href={item.absolute} target='blank'>{item.title}</a></div>
                                ))}
                            </div>
                        </Col>
                        <Col className="px-0">
                            <h5 className="footer-social-font" id="fta-11122">{!isEmpty(store_locations_title) ? store_locations_title.label : ""}</h5>
                            <div className="footer-nav">
                                {store_locations.map((item, i) => (
                                    <div key={i} > <a href={item.absolute} target='blank'>{item.title}</a></div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </div>

                { }
                <div className="footer-links-acoordian" id="ftac-12">
                    <AcoordianFooter name={!isEmpty(product_solution_business_title) ? product_solution_business_title.label : ""} id="ftac-sss12" title={!isEmpty(product_solution_business_title) ? product_solution_business_title.label : ""} active={active} setActive={setActive} >
                        <div className="footer-nav">
                            {product_solution_business.map((item, i) => (
                                <div key={i}> <a href={item.absolute} target='blank'>{item.title}</a></div>
                            ))}

                        </div>
                    </AcoordianFooter>
                    <AcoordianFooter id="ftac-12ww2" name={!isEmpty(why_airtel_vodafone_title) ? why_airtel_vodafone_title.label : ""} title={!isEmpty(why_airtel_vodafone_title) ? why_airtel_vodafone_title.label : ""} active={active} setActive={setActive} >
                        <div className="footer-nav">
                            {why_airtel_vodafone.map((item, i) => (
                                <div key={i}> <a href={item.absolute} target='blank'>{item.title}</a></div>
                            ))}
                        </div>
                    </AcoordianFooter>
                    <AcoordianFooter id="ftac-12w2" name={!isEmpty(contact_us_title) ? contact_us_title.label : ""} title={!isEmpty(contact_us_title) ? contact_us_title.label : ""} active={active} setActive={setActive} >
                        <div className="footer-nav">
                            {contact_us.map((item, i) => (
                                <div key={i} > <a href={item.absolute} target='blank'>{item.title}</a></div>
                            ))}
                        </div>
                    </AcoordianFooter>
                    <AcoordianFooter id="ftac-12232" name={!isEmpty(about_airtel_vodafone_title) ? about_airtel_vodafone_title.label : ""} title={!isEmpty(about_airtel_vodafone_title) ? about_airtel_vodafone_title.label : ""} active={active} setActive={setActive} >
                        <div className="footer-nav">
                            {about_airtel_vodafone.map((item, i) => (
                                <div key={i} > <a href={item.absolute} target='blank'>{item.title}</a></div>
                            ))}
                        </div>
                    </AcoordianFooter>
                    <AcoordianFooter id="ftac-12233" name={!isEmpty(store_locations_title) ? store_locations_title.label : ""} title={!isEmpty(store_locations_title) ? store_locations_title.label : ""} active={active} setActive={setActive} >
                        <div className="footer-nav">
                            {store_locations.map((item, i) => (
                                <div key={i}> <a href={item.absolute} target='blank'>{item.title}</a></div>
                            ))}
                        </div>
                    </AcoordianFooter>
                </div> */}
                <div className="text-center text-light pt-4" id="ftac-133w2">
                    &copy; 2024 Jersey Airtel Limited. Registered office: 1 Castle Street, St Helier, Jersey, JE2 3BT.
                </div>
                <div className="text-center text-light pb-1" id="ftac-133w2">
                    Registered in Channel Island No 92186.
                </div>
            </Container>
        </>
    )
}

export default Footer;