// MY BILLS TYPES
export const myBills_Type = {
    DUBLICATE_INVOICE_SUCCESS: "DUBLICATE_INVOICE_SUCCESS",
    DUBLICATE_INVOICE_ERROR: "DUBLICATE_INVOICE_ERROR",
    GET_USER_PAYMENT_HISTORY: "GET_USER_PAYMENT_HISTORY",
    FAIL_USER_PAYMENT_HISTORY: "FAIL_USER_PAYMENT_HISTORY",
    GET_CUSTOMER_INVOICE_SUMMARY: "GET_CUSTOMER_INVOICE_SUMMARY",
    FAIL_CUSTOMER_INVOICE_SUMMARY: "FAIL_CUSTOMER_INVOICE_SUMMARY",
    RESET_CUSTOMER_INVOICE_SUMMARY: "RESET_CUSTOMER_INVOICE_SUMMARY",
    GET_BREAKDOWN_DATA: "GET_BREAKDOWN_DATA",
    ERROR_BREAKDOWN_DATA: "ERROR_BREAKDOWN_DATA",
    RESET_BREAKDOWN_DATA: "RESET_BREAKDOWN_DATA",
    BILL_PAYMENT_SUCCESS: "BILL_PAYMENT_SUCCESS",
    BILL_PAYMENT_ERROR: "BILL_PAYMENT_ERROR",
    BILL_PAYMENT_RESET: "BILL_PAYMENT_RESET",
    CURRENT_MONTH_BILL_AMOUNT_SUCCESS: "CURRENT_MONTH_BILL_AMOUNT",
    CURRENT_MONTH_BILL_AMOUNT_ERROR: "CURRENT_MONTH_BILL_AMOUNT_ERROR"
}

export const common_types = {
    GET_NEXT_BILL: "GET_NEXT_BILL",
    GET_OPTIMUS_DETAILS: "GET_OPTIMUS_DETAILS",
    GET_PROFILE_DATA_SUCCESS: "GET_PROFILE_DATA_SUCCESS",
    GET_PROFILE_DATA_ERROR: "GET_PROFILE_DATA_ERROR",
    UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
    UPDATE_PROFILE_ERROR: "UPDATE_PROFILE_ERROR",
    RESET_UPDATE_PROFILE: "RESET_UPDATE_PROFILE",
    PROFILE_DATA_RESET: "PROFILE_DATA_RESET",
    SR_HISTORY_SUCCESS: "SR_HISTORY_SUCCESS",
    SR_HISTORY_ERROR: "SR_HISTORY_ERROR",
}

export const login_types = {
    GENERATE_OTP_SUCCESS: "GENERATE_OTP_SUCCESS",
    GENERATE_OTP_ERROR: "GENERATE_OTP_ERROR",
    VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
    VERIFY_OTP_ERROR: "VERIFY_OTP_ERROR",
    RESET_LOGIN_META: "RESET_LOGIN_META",
    SAVE_AUTH: "SAVE_AUTH",
    LOGOUT: "LOGOUT",
    LOGOUT_ERROR: "LOGOUT_ERROR",
    LOGOUT_RESET: "LOGOUT_RESET",
    AUTH_DATA: "uiuijkjkjiuiu%Auijkjkjvnbnbcbvbcf%Uhjhjjj%T&jkj&H",
    TOKEN_INFO: "jkjhjkjhut768877%Tguyghggiyhhjkj%Oititii%K&yuuyuy&Ejkjkjkjk%N",
    REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
    REFRESH_TOKEN_ERROR: "REFRESH_TOKEN_ERROR"
}

export const dasboard_type = {
    SUBSCRIBER_HIERARCHY_SUCCESS: "SUBSCRIBER_HIERARCHY_SUCCESS",
    SUBSCRIBER_HIERARCHY_ERROR: "SUBSCRIBER_HIERARCHY_ERROR",
    SUBSCRIBER_HIERARCHY_SUCCESS_B2B: "SUBSCRIBER_HIERARCHY_SUCCESS_B2B",
    SUBSCRIBER_HIERARCHY_ERROR_B2B: "SUBSCRIBER_HIERARCHY_ERROR_B2B",
    CUSTOMER_INFO_SUCCESS: "CUSTOMER_INFO_SUCCESS",
    CUSTOMER_INFO_ERROR: "CUSTOMER_INFO_ERROR",
    CUSTOMER_INFO_RESET: "CUSTOMER_INFO_RESET"

}

export const helSupport = {
    RAISE_SERVICE_MASTER_SUCCESS: "RAISE_SERVICE_MASTER_SUCCESS",
    RAISE_SERVICE_MASTER_ERROR: "RAISE_SERVICE_MASTER_ERROR",
    RAISE_SERVICE_SUBMIT_SUCCESS: "RAISE_SERVICE_SUBMIT_SUCCESS",
    RAISE_SERVICE_SUBMIT_ERROR: "RAISE_SERVICE_SUBMIT_ERROR",
    RAISE_SERVICE_RESET: "RAISE_SERVICE_RESET",
    B2B_RAISE_SERVICE_SUBMIT_SUCCESS: "B2B_RAISE_SERVICE_SUBMIT_SUCCESS",
    B2B_RAISE_SERVICE_SUBMIT_ERROR: "B2B_RAISE_SERVICE_SUBMIT_ERROR",
    B2B_RAISE_SERVICE_RESET: "B2B_RAISE_SERVICE_RESET",
    B2B_CALLBACK_SUCCESS: "B2B_CALLBACK_SUCCESS",
    B2B_CALLBACK_ERROR: "B2B_CALLBACK_ERROR",
    B2B_CALLBACK_RESET: "B2B_CALLBACK_RESET"
}

export const myplanType = {
    GET_ACTIVE_PLAN_SUCCESS: "GET_ACTIVE_PLAN_SUCCESS",
    GET_ACTIVE_PLAN_ERROR: "GET_ACTIVE_PLAN_ERROR",
    GET_LOCAL_BOLTON_SUCCESS: "LOCAL_BOLTON_SUCCESS",
    GET_LOCAL_BOLTON_ERROR: "GET_LOCAL_BOLTON_ERROR",
    GET_DATA_BOLTON_SUCCESS: "GET_DATA_BOLTON_SUCCESS",
    GET_ACC_LEVEL_BOLTON: "GET_ACC_LEVEL_BOLTON",
    GET_ACC_LEVEL_BOLTON_ERROR: "GET_ACC_LEVEL_BOLTON_ERROR",
    GET_DATA_BOLTON_ERROR: "GET_DATA_BOLTON_ERROR",
    GET_ROAMING_BOLTON_SUCCESS: "GET_ROAMING_BOLTON_SUCCESS",
    GET_ROAMING_BOLTON_ERROR: "GET_ROAMING_BOLTON_ERROR",
    GET_PROMOLIST_DATA: "GET_PROMOLIST_DATA",
    ERROR_PROMOLIST_DATA: "ERROR_PROMOLIST_DATA",
    MINUTES_SMS_USAGE_SUCCESS: "MINUTES_SMS_USAGE_SUCCESS",
    MINUTES_SMS_USAGE_ERROR: "MINUTES_SMS_USAGE_ERROR",
    DATA_USAGE_SUCCESS: "DATA_USAGE_SUCCESS",
    DATA_USAGE_ERROR: "DATA_USAGE_ERROR",
    CHANGE_ORDER_SUCCESS: "CHANGE_ORDER_SUCCESS",
    CHANGE_ORDER_ERROR: "CHANGE_ORDER_ERROR",
    CHANGE_ORDER_RESET: "CHANGE_ORDER_RESET",
    GET_MSISDN_DETAILS: "GET_MSISDN_DETAILS",
    ERROR_MSISDN: "ERROR_MSISDN"
}

export const B2BFooter_type = {
    FOOTER_B2B_PRODUCT_BUSSINESS_SUCCESS: "FOOTER_B2B_PRODUCT_BUSSINESS_SUCCESS",
    FOOTER_B2B_PRODUCT_BUSSINESS_ERROR: "FOOTER_B2B_PRODUCT_BUSSINESS_ERROR",
    FOOTER_B2B_WHY_AIRTEL_SUCCESS: "FOOTER_B2B_WHY_AIRTEL_SUCCESS",
    FOOTER_B2B_WHY_AIRTEL_ERROR: "FOOTER_B2B_WHY_AIRTEL_ERROR",
    FOOTER_B2B_CONTACT_US_SUCCESS: "FOOTER_B2B_CONTACT_US_SUCCESS",
    FOOTER_B2B_CONTACT_US_ERROR: "FOOTER_B2B_CONTACT_US_ERROR",
    FOOTER_B2B_ABOUT_AIRTEL_SUCCESS: "FOOTER_B2B_ABOUT_AIRTEL_SUCCESS",
    FOOTER_B2B_ABOUT_AIRTEL_ERROR: "FOOTER_B2B_ABOUT_AIRTEL_ERROR",
    FOOTER_B2B_ABOUT_STORE_LOCATION_SUCCESS: "FOOTER_B2B_ABOUT_STORE_LOCATION_SUCCESS",
    FOOTER_B2B_ABOUT_STORE_LOCATION_ERROR: "FOOTER_B2B_ABOUT_STORE_LOCATION_ERROR",

    //menue
    FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_SUCCESS: "FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_SUCCESS",
    FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_ERROR: "FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_ERROR",
    FOOTER_B2B_WHY_AIRTEL_TITLE_SUCCESS: "FOOTER_B2B_WHY_AIRTEL_TITLE_SUCCESS",
    FOOTER_B2B_WHY_AIRTEL_TITLE_ERROR: "FOOTER_B2B_WHY_AIRTEL_TITLE_ERROR",
    FOOTER_B2B_CONTACT_US_TITLE_SUCCESS: "FOOTER_B2B_CONTACT_US_TITLE_SUCCESS",
    FOOTER_B2B_CONTACT_US_TITLE_ERROR: "FOOTER_B2B_CONTACT_US_TITLE_ERROR",
    FOOTER_B2B_ABOUT_AIRTEL_TITLE_SUCCESS: "FOOTER_B2B_ABOUT_AIRTEL_TITLE_SUCCESS",
    FOOTER_B2B_ABOUT_AIRTEL_TITLE_ERROR: "FOOTER_B2B_ABOUT_AIRTEL_TITLE_ERROR",
    FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_SUCCESS: "FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_SUCCESS",
    FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_ERROR: "FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_ERROR"
}