import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllUserPaymentHistory } from '../../../../redux/actions/myBillsAction';
import { PreLoader, ResponseMessage } from '../../../common';
import BorderedCard from '../../../common/components/BorderedCard';
import { dateFormatter, getCurrency, divideByCurrencey } from '../../../common/utils';
import isEmpty from '../../../common/utils/isEmpty';
import TableComp from '../../common/TableComp'

const TransactionsB2b = () => {

    const dispatch = useDispatch()
    const { optimusDetails } = useSelector(state => state.auth);
    const { userPaymentHistory, paymentHistoryError } = useSelector(state => state.myBillsAllReducers);

    const { si_lob, domain, cust_ac_no } = optimusDetails

    const columns = [{
        dataField: 'id',
        text: 'Transaction id'
    },
    {
        dataField: 'paidOn',
        text: 'Paid on'
    },
    {
        dataField: "paymentMethod",
        text: "Payment method"
    },
    {
        dataField: "paidAmount",
        text: "Paid amount",
        formatter: (cell, row) => getCurrency(divideByCurrencey(cell))
    }
    ];

    useEffect(() => {
        //it will call the action to get user payment history before render

        dispatch(getAllUserPaymentHistory(domain, si_lob, cust_ac_no));
    }, []);

    let newTransaction = []

    if (userPaymentHistory && userPaymentHistory.data && !isEmpty(userPaymentHistory.data)) {
        userPaymentHistory.data.forEach((data) => {
            newTransaction.push({
                id: data.trackingRecord.identification.id,
                paidOn: dateFormatter(data.partyPayment.paymentDate),
                paymentMethod: data.partyPayment.remarks,
                paidAmount: data.partyPayment.amount
            })
        })
    }

    return (
        <div className='main_component_container' id="transaction_table_b2b" >
            {
                !isEmpty(userPaymentHistory) && !isEmpty(userPaymentHistory.data) && paymentHistoryError === "" &&
                <TableComp keyField="id" heading="Total payment history" tableData={newTransaction} columns={columns} searchBar check={true} />
            }
            {
                !isEmpty(userPaymentHistory) && isEmpty(userPaymentHistory.data) && userPaymentHistory.data.length <= 0 &&

                <BorderedCard>
                    <ResponseMessage error heading={"Data not found"} />
                </BorderedCard>
            }
            {
                isEmpty(userPaymentHistory) && paymentHistoryError === "" &&
                <BorderedCard>
                    <PreLoader id="pro-13" />
                </BorderedCard>
            }
            {
                isEmpty(userPaymentHistory) && paymentHistoryError !== "" &&
                <BorderedCard>
                    <ResponseMessage error heading={paymentHistoryError} />
                </BorderedCard>
            }
        </div>
    )
}

export default TransactionsB2b
