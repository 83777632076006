import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from 'react-redux'
import store from './redux/store'
import ErrorBoundary from "./components/services/ErrorBoundary";


ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>
  ,
  document.getElementById("root")
);
