// Component of My account and bolt on
import BorderedCard from '../../common/components/BorderedCard';

const MyAccountCard = (props) => {
    let id = props.id ? props.id : `myAccountCard-${Math.random()}`;

    return (
        <BorderedCard id={`dashboard-109-${id}`} className="dashboard-account-card cursor" onClick={props.onClick}>
            <div id={`dashboard-110-${id}`} className="dasboard-bill">{props.title}</div>
            <div id={`dashboard-111-${id}`} className="font-style dasboard-spend">{props.content}</div>
            {props.subContent ? <div id={`dashboard-sub-111-${id}`} >{props.subContent}</div> : ""}
            <div id={`dashboard-112-${id}`} className="d-flex justify-content-end">
                <img
                    src={props.icone}
                    alt="ima"
                    id={`dashboard-113-${id}`}
                    className={`d-inline-block align-top image ${props.subContent ? "accountImagePos" : ""}`}
                />
            </div>
        </BorderedCard>
    )
}
export default MyAccountCard