// Component of plan card
import React from 'react'
import BorderedCard from '../../common/components/BorderedCard';
import { Row, Col } from 'react-bootstrap';
import { PreLoader, ResponseMessage, SimpleButton } from '../../common'
import { DataIcon, CallIcon, SMSIcon } from '../../Icons'
import { useSelector } from 'react-redux';
import isEmpty from '../../common/utils/isEmpty';
import useUsage from '../../dashboard/hooks/useUsage';
import { PROD_TYPE_DATACARD } from "../../dashboard/dashboardConstant";


const PlanCard = (props) => {
    const { activePlanData } = useSelector(state => state.planReducer);
    const { minutes, sms, minutesAndSmsUsageError, minutesAndSmsUsage, sumOfQuotaAllocated, sumOfQuotaAllocatedUnit, quotaLeft, quotaLeftUnit } = useUsage()

    const { optimusDetails } = useSelector((state) => state.auth);
    const {  domain, prod_type } = optimusDetails;

    return (
        <div id="usage-Card" className="usageCard">
            <BorderedCard>
                <Row id="plan-card" >
                    <Col sm={12} className="planCard">
                        <h4 className="planCard-act planCard-act-color m-b-4">{activePlanData && activePlanData.logicalResource && activePlanData.logicalResource[0].lrStatus}</h4>
                        <h2 className="planCard-title">{activePlanData && activePlanData.billPlanRental && activePlanData.billPlanRental.productSpecification && activePlanData.billPlanRental.productSpecification.name}</h2>
                        <div id="dashboard-27" className="usageCard" >

                            {
                                !isEmpty(minutesAndSmsUsage) && Object.keys(minutesAndSmsUsage).length > 0 && minutesAndSmsUsageError === "" &&
                                <>
                                    <Row id="dashboard-35" className="mt-5 px-5" style={{ minHeight: 222 }}>
                                        <Col id="dashboard-36" sm={8} md={12} lg={10}>
                                            <Row id="dashboard-37" className="mb-4">
                                                <Col sm={1} lg={1}>
                                                    <img
                                                        src={DataIcon}
                                                        id="dashboard-39"
                                                        alt="data icone"
                                                        className="d-inline-block align-top"
                                                    />
                                                </Col>
                                                <Col id="dashboard-40" sm={11} lg={11} className="p-0">
                                                    <div id="dashboard-41" className="dasboard-bill-details"><span>Data</span><span className="data-unit-used ms-2">{`${quotaLeft} ${quotaLeftUnit} `}</span> <span className='light-font'> consumed
                                                        {/* {`${sumOfQuotaAllocated} ${sumOfQuotaAllocatedUnit}`} */}
                                                    </span>
                                                        {/* {(quotaLeft <= 5 && quotaLeftUnit === 'MB') || (quotaLeft <= 100 && quotaLeftUnit === 'KB') ? */}
                                                        <SimpleButton
                                                            lrStatusactive={activePlanData && activePlanData.logicalResource && activePlanData.logicalResource[0].lrStatus}
                                                            onClick={props.OpenDataDrawer}
                                                            className="ms-4"
                                                            size="sm"
                                                            // onClick={(e) => e.stopPropagation()}
                                                            variant="primary">
                                                            Add data
                                                        </SimpleButton>
                                                        {/* : ""} */}
                                                    </div>
                                                    <hr />
                                                </Col>
                                            </Row>
                                            {
                                                minutes !== undefined && minutes !== null &&
                                                <Row id="dashboard-43" className="mb-4">
                                                    <Col sm={1} lg={1}>
                                                        <img
                                                            src={CallIcon}
                                                            id="dashboard-45"
                                                            alt="call icone"
                                                            className="d-inline-block align-top"
                                                        />
                                                    </Col>
                                                    <Col id="dashboard-40" sm={11} lg={11} className="p-0">
                                                        <div id="dashboard-47" className="dasboard-bill-details"><span>Calls</span>
                                                        {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? <span className="ms-2 bold-font">NA</span>
                                                         : 
                                                         <>
                                                         <span className="ms-2 data-unit-used">{`${minutes ? minutes : 0} Minutes`}</span><span className='light-font'> consumed</span> 
                                                         </>
                                                         }
                                                        </div> 
                                                            {/* <span><span className="dashboard-usage-text-color">Unlimited</span></span> */}
                                                        <hr />
                                                    </Col>

                                                </Row>
                                            }
                                            {
                                                sms !== undefined && sms !== null &&
                                                <Row id="dashboard-49" className="mb-4">
                                                    <Col sm={1} lg={1}>
                                                        <img
                                                            src={SMSIcon}
                                                            id="dashboard-50"
                                                            alt="message icone"
                                                            className="d-inline-block align-top"
                                                        />
                                                    </Col>
                                                    <Col id="dashboard-40" sm={11} lg={11} className="p-0">
                                                        <div id="dashboard-52" className="dasboard-bill-details"><span>Texts</span>
                                                        {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? <span className="ms-2 bold-font">NA</span>
                                                         :
                                                         <>
                                                          <span className="ms-2 data-unit-used">{`${sms ? sms : 0} SMS`}</span><span className='light-font'> consumed</span>
                                                         </>
                                                          }
                                                        </div>
                                                        {/* <ProgressBar id="dashboard-53" now={sms} /> */}
                                                        <hr />
                                                    </Col>

                                                </Row>
                                            }

                                        </Col>
                                    </Row>
                                </>
                            }
                            {
                                minutesAndSmsUsageError !== "" && isEmpty(minutesAndSmsUsage) &&
                                <div id="pro-14" style={{ minHeight: 100 }} className="d-flex flex-column justify-content-between mt-5">
                                    <ResponseMessage
                                        error
                                        heading={minutesAndSmsUsageError}
                                        id="pro-15"
                                    />
                                </div>
                            }
                            {
                                minutesAndSmsUsageError === "" && isEmpty(minutesAndSmsUsage) &&
                                <div id="pro-12" style={{ minHeight: 100, marginTop: 50 }} data-testid="profile_preloader">
                                    <PreLoader id="pro-13" />
                                </div>
                            }
                        </div >
                    </Col>
                </Row>
                {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? "" :
                <Row className="p-3 d-flex justify-content-center">
                    <SimpleButton id="change-plan-button" variant="primary" className="mt-4" width="392px"
                        onClick={props.handleShow}  //not in scope so disabling it
                    >
                        Change plan
                    </SimpleButton>
                </Row>
                }
            </BorderedCard >
        </div >
    )
}
export default PlanCard