
// Carousel component for usage showm in mobile
import BorderedCard from '../../common/components/BorderedCard';
import { Carousel } from "react-bootstrap";
import { useState } from 'react';
import { DataIcon, CallIcon, SMSIcon } from '../../Icons/'
import { SimpleButton } from '../../common';
import useUsage from '../hooks/useUsage';
import isEmpty from '../../common/utils/isEmpty';

const MyUsageCarousel = ({ OpenDataDrawer, gotoMyplan }) => { //reset day is required from backend currently hard coded

    const [indicators, setIndicator] = useState(0);
    const { minutes, sms, minutesAndSmsUsage, sumOfQuotaAllocated, sumOfQuotaAllocatedUnit, quotaLeft, quotaLeftUnit, } = useUsage()

    const CarouselonSelect = (event) => {
        setIndicator(event)
    }

    const createIndicator = () => {
        let indicatorCount = 3
        let arrayOfElement = [];
        if (indicatorCount > 1) {
            for (let i = 0; i < indicatorCount; i++) {
                arrayOfElement.push(<div key={`indicator-${i}`} onClick={() => setIndicator(i)} className={`indicators-button ${indicators === i ? "active" : ""}`}></div>)
            }
        }
        return arrayOfElement
    }

    return (
        <div id="dashboard-74" className="usageCard" onClick={gotoMyplan}>
            <Carousel interval={7000} controls={false} activeIndex={indicators} indicators={false} onSelect={CarouselonSelect}>
                {
                    !isEmpty(minutesAndSmsUsage) &&
                    < Carousel.Item >
                        <BorderedCard className="dashboard-carousel">
                            <div id="dashboard-72" className=" d-flex justify-content-between mb-4">
                                <div className="d-flex flex-row">
                                    <img
                                        src={DataIcon}
                                        id="dashboard-39"
                                        alt="data icone"
                                        width={25}
                                        className="d-inline-block align-top"
                                    />
                                    <span id="dashboard-73" className="dasboard-bill m-l-2">Data</span>
                                </div>
                                <div className="d-flex flex-row slide-indicators pt-2">
                                    {createIndicator()}
                                </div>
                            </div>
                            <div id="dashboard-41" className="dasboard-bill-details"><span className="data-unit-used ms-2">{`${quotaLeft} ${quotaLeftUnit} `}</span> <span className='light-font'> consumed
                                {/* {`${sumOfQuotaAllocated} ${sumOfQuotaAllocatedUnit}`} */}
                            </span>
                                {/* {
                                    (quotaLeft <= 5 && quotaLeftUnit === 'MB') || (quotaLeft <= 100 && quotaLeftUnit === 'KB') ? */}
                                < SimpleButton
                                    onClick={OpenDataDrawer} //removing because not in scope
                                    className="ms-4"
                                    size="sm"
                                    variant="primary">
                                    Add data
                                </SimpleButton>
                                {/* : ""
                                } */}
                            </div>
                        </BorderedCard>
                    </Carousel.Item>
                }
                {
                    minutes !== undefined && minutes !== null &&
                    <Carousel.Item>
                        <BorderedCard className="dashboard-carousel">
                            <div id="dashboard-72" className=" d-flex justify-content-between mb-4">
                                <div className="d-flex flex-row">
                                    <img
                                        src={CallIcon}
                                        id="dashboard-45"
                                        alt="call icone"
                                        className="d-inline-block align-top"
                                    />
                                    <span id="dashboard-73" className="dasboard-bill m-l-2">Voice</span>
                                </div>
                                <div className="d-flex flex-row slide-indicators pt-2">
                                    {createIndicator()}
                                </div>
                            </div>
                            <div id="dashboard-47" className="dasboard-bill-details"><span></span><span className="ms-2 data-unit-used">{`${minutes ? minutes : 0} Minutes`}</span><span className='light-font'> consumed</span> </div>     {/* <span><span className="dashboard-usage-text-color">Unlimited</span></span> */}
                        </BorderedCard>
                    </Carousel.Item>
                }
                {
                    sms !== undefined && sms !== null &&
                    <Carousel.Item>
                        <BorderedCard className="dashboard-carousel">
                            <div id="dashboard-72" className=" d-flex justify-content-between mb-4">
                                <div className="d-flex flex-row">
                                    <img
                                        src={SMSIcon}
                                        id="dashboard-73"
                                        alt="report icone"
                                        className="d-inline-block align-top"
                                    />
                                    <span id="dashboard-73" className="dasboard-bill m-l-2">SMS</span>
                                </div>
                                <div className="d-flex flex-row slide-indicators pt-2">
                                    {createIndicator()}
                                </div>
                            </div>
                            <div id="dashboard-52" className="dasboard-bill-details"><span className="ms-2 data-unit-used">{`${sms ? sms : 0} SMS`}</span><span className='light-font'> consumed</span></div>
                        </BorderedCard>
                    </Carousel.Item>
                }
            </Carousel>
        </div >

    )
}

export default MyUsageCarousel;