import React, { useEffect, useState } from "react";
import TableComp from '../common/TableComp'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import { getSrHistory } from "../../../redux/actions/commonAction";
import isEmpty from "../../common/utils/isEmpty";
import { PreLoader, ResponseMessage } from "../../common";
import { dateFormatter } from "../../common/utils";
import BorderedCard from "../../common/components/BorderedCard";
import { useHistory } from "react-router-dom";
import HeaderBar from "../../common/components/HeaderBar";

const ServiceHistory = (props) => {
    const dispatch = useDispatch();
    const { srHistory, errorSrHistory } = useSelector(state => state.common);
    const { optimusDetails } = useSelector(state => state.auth);
    const { si, si_lob, domain } = optimusDetails
    const [mobileNumber, setMobileNumber] = useState("")
    let { location } = useHistory()
    let history = useHistory()

    useEffect(() => {

        if (location && location.state && location.state.mobileNumber) {
            const { mobileNumber } = location.state
            setMobileNumber(mobileNumber)
            dispatch(getSrHistory(si_lob, domain, mobileNumber));
        }
        else {
            history.goBack()
        }
    }, [])

    const aftectedServiceFormat = (cell, row) => {
        let str = cell
        if (String(str).length > 30) {
            str = String(cell).substring(0, 30);
        }

        return (
            <>
                {cell ?
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 200, hide: 400 }}
                        overlay={<Tooltip>{cell}</Tooltip>
                        }
                    >
                        <span className="cursor">{cell.length > 30 ? str + '...' : str}</span>
                    </OverlayTrigger>
                    : "-"}
            </>
        )
    }

    const formatter = (cell, row) => dateFormatter(cell)

    // const serialFormatter = (cell, row, rowIndex,) => <>{rowIndex + 1}</>

    const columns = [
        // {
        //     dataField: "srno",
        //     text: "Sr No.",
        //     isDummyField: true,
        //     formatter: serialFormatter,
        // },
        {
            dataField: 'problem.problemId',
            text: 'SR number'
        }, {
            dataField: 'problem.status',
            text: 'Status'
        }, {
            dataField: 'problem.createdDate',
            text: 'Raised on',
            formatter: formatter
        },
        {
            dataField: "problem.actualClosureDate",
            text: "SR closed",
            formatter: formatter
        },
        {
            dataField: "problem.type",
            text: "Issue"
        },
        {
            dataField: "problem.subType",
            text: "Issue type"
        },
        {
            dataField: "problem.subSubType",
            text: "Affected service",
            formatter: aftectedServiceFormat

        },
    ];


    return (
        <div className="serviceHistory">
            <HeaderBar>
                <div id="mssidnb2b_1" className="row p-4 container-fluid">
                    <div id="mssidnb2b_2" className="col-6">
                        <h1 id="mssidnb2b_3" className="mediumText">Mobile Number</h1>
                    </div>
                    <div id="mssidnb2b_5" className="col-6">
                        <h1 id="mssidnb2b_6" className="mediumText float-end">+{mobileNumber}</h1>
                    </div>
                </div>
            </HeaderBar>
            <div className="main_component_container">
                <div onClick={() => history.goBack()} className="back-button back-button-sr " >
                    &larr;{" "} Back

                </div>
                {

                    !isEmpty(srHistory) && !isEmpty(srHistory.serviceProblems) && errorSrHistory === "" &&


                    <TableComp columns={columns} keyField="problem.problemId" tableData={srHistory && srHistory.serviceProblems ? srHistory.serviceProblems : []} heading={`Service request history`} paggination check searchBar />

                }
                {
                    srHistory
                    &&
                    srHistory.serviceProblems
                    &&
                    isEmpty(srHistory.serviceProblems)
                    &&
                    errorSrHistory === ""
                    &&

                    <BorderedCard>
                        <ResponseMessage error heading={"No service history found"} />
                    </BorderedCard>
                }
                {
                    isEmpty(srHistory) && errorSrHistory !== "" &&
                    < BorderedCard >
                        <ResponseMessage info heading={errorSrHistory} />
                    </BorderedCard>
                }

                {
                    isEmpty(srHistory) && errorSrHistory === "" &&
                    <BorderedCard>
                        <PreLoader />
                    </BorderedCard>
                }
            </div>
        </div >

    )
}

export default ServiceHistory
