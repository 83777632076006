import React from "react";
import NavTab from "../common/components/NavTab";
import { Redirect, Route } from "react-router-dom";
import BillingSection from "./views/BillingSection";
import Transactions from "./views/Transactions";
import PrepaidTopUp from "./views/PrepaidTopUp";
import { postpaid_prefix, prepaid_prefix } from "../envConfig";
import Payment from "./views/Payment";

const MyBillsMain = ({ type }) => {
  let billRoute = ''

  const postpaidPrefix = `/${postpaid_prefix}`
  const prepaidPrefix = `/${prepaid_prefix}`


  if (type === "postpaid") {
    billRoute = [
      { path: `${postpaidPrefix}/myBill`, eventKey: "link-1", name: "My bill" },
      { path: `${postpaidPrefix}/myBill/transactions`, eventKey: "link-2", name: "Transactions" },
    ];

    //array for routes for prepaid user login

    if (type === "prepaid") {
      billRoute = [
        { path: `${prepaidPrefix}/topUp`, eventKey: "link-5", name: "Top-up" },
        { path: `${prepaidPrefix}/topUp/transactions`, eventKey: "link-6", name: "Transactions" },
      ]
    }
  }

  return (
    <React.Fragment>

      {
        type === "postpaid"
          ?
          //POSTPIAD ALL ROUTES CODE
          <React.Fragment>
            <div id="bill_routing_1" data-test="bill-routing">
              <NavTab tabRoutes={billRoute} />
              {/* chart component for my bill postpaid */}

              <Route
                path={`${postpaidPrefix}/myBill`}
                render={({ match: { url } }) => (
                  <>
                    <Route path={url + "/"} component={BillingSection} exact />
                    <Route path={url + "/transactions"} component={Transactions} />
                    <Route path={url + "/payment"} component={Payment} />
                    <Redirect to={url} />
                  </>
                )}
              />
            </div>

          </React.Fragment>

          :
          //PREPAID ROUTES FOR TOPUP

          type === "prepaid"

            ?

            <div id="bill_routing_1" data-test="bill-routing">
              <NavTab tabRoutes={billRoute} />
              {/* chart component for my bill postpaid */}

              <Route
                path={`${prepaidPrefix}/topUp`}
                render={({ match: { url } }) => (
                  <>
                    <Route path={url + "/"} component={PrepaidTopUp} exact />
                    <Route
                      path={url + "/transactions"}
                      component={Transactions}
                      exact
                    />
                  </>
                )}
              />
            </div>

            :

            ""
      }

    </React.Fragment>

  );
};

export default MyBillsMain;
