import Offcanvas from "react-bootstrap/Offcanvas";

export function Drawer({ body, isOpen, closeDrawer }) {
  return (
    <>
      <Offcanvas
        show={isOpen}
        keyboard={false}
        onHide={closeDrawer}
        data-testid="drawer"
        className="Offcanvas-custome"
      >
        <div className="Offcanvas_custom_header">
          <button data-testid="drawer-close-button" onClick={closeDrawer} className="btn-close" />
        </div>
        <Offcanvas.Body>{body}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
