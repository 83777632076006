import { useSelector } from "react-redux";
import { fixed_brodband_url, fiber_broadband_url } from "../../envConfig";
import {DiscoveryJSYDesktop, DiscoveryJSYMobile, DiscoveryGSYDesktop, DiscoveryGSYMobile} from '../../../Assets/imageData';
export const Discovery = ({ screen }) => {

    const { optimusDetails } = useSelector(state => state.auth);
    return (
        <>
            <div id="dashboard-post-114" className="dashboard-account row">
                <div id="dashboard-post-115" className="col-sm-12">
                    <h2 id="dashboard-post-116" className="dashboard-typo font-style">Discover</h2>
                </div>
            </div>
            <div id="dashboard-post-117" className="dashboard-Discover-section m-b-10">
                <a href={String(optimusDetails.circle).toUpperCase() === "GUERNSEY" ? fixed_brodband_url : fiber_broadband_url} target="blank">
                    <img src={
                        screen >= 769
                            ? String(optimusDetails.circle).toUpperCase() === "GUERNSEY" ? DiscoveryGSYDesktop : DiscoveryJSYDesktop
                            : String(optimusDetails.circle).toUpperCase() === "GUERNSEY" ? DiscoveryGSYMobile : DiscoveryJSYMobile
                    }
                        alt="discover" />
                </a>
            </div>
        </>
    )
}