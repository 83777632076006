
import { common_types, helSupport } from '../helpers/types';

let {
  GET_NEXT_BILL,
  GET_PROFILE_DATA_SUCCESS,
  GET_PROFILE_DATA_ERROR,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
  PROFILE_DATA_RESET,
  RESET_UPDATE_PROFILE,
  SR_HISTORY_ERROR,
  SR_HISTORY_SUCCESS
} = common_types;


const {
  RAISE_SERVICE_MASTER_SUCCESS,
  RAISE_SERVICE_MASTER_ERROR,
  RAISE_SERVICE_SUBMIT_ERROR,
  RAISE_SERVICE_SUBMIT_SUCCESS,
  RAISE_SERVICE_RESET,
  B2B_RAISE_SERVICE_SUBMIT_SUCCESS,
  B2B_RAISE_SERVICE_SUBMIT_ERROR,
  B2B_CALLBACK_ERROR,
  B2B_CALLBACK_SUCCESS,
  B2B_CALLBACK_RESET
} = helSupport
export const commonInitState = {
  nextBill: { loader: true, msg: "" },
  raiseServiceMasterDetail: {},
  raiseServiceMasterError: "",
  raiseServiceSubmitSuccess: "",
  raiseServiceSubmitError: "",
  b2bCallSuccess: "",
  b2bCallError: "",
  b2bRaiseServiceSubmitSuccess: [],
  b2bRaiseServiceSubmitError: "",
  profileData: {},
  getProfileError: "",
  updateProfileMessage: "",
  updateProfileError: "",
  srHistory: {},
  errorSrHistory: ""
};

const common = (state = commonInitState, action = {}) => {
  const { type, data, error } = action;
  switch (type) {
    case GET_NEXT_BILL:
      return {
        ...state,
        nextBill: data,

      };
    case RAISE_SERVICE_MASTER_SUCCESS:
      return {
        ...state,
        raiseServiceMasterDetail: data,
        raiseServiceMasterError: "",
        raiseServiceSubmitSuccess: "",
        raiseServiceSubmitError: ""

      };
    case RAISE_SERVICE_MASTER_ERROR:
      return {
        ...state,
        raiseServiceMasterError: error,
      };
    case RAISE_SERVICE_SUBMIT_SUCCESS:
      return {
        ...state,
        raiseServiceSubmitSuccess: data,
        // srHistory: {},
        errorSrHistory: ""
      };
    case RAISE_SERVICE_SUBMIT_ERROR:
      return {
        ...state,
        raiseServiceSubmitError: error,
      };
    case RAISE_SERVICE_RESET:
      return {
        ...state,
        // raiseServiceMasterDetail: {}, why
        raiseServiceMasterError: "",
        raiseServiceSubmitSuccess: "",
        raiseServiceSubmitError: "",
        b2bRaiseServiceSubmitError: "",
        b2bRaiseServiceSubmitSuccess: []

      };
    case B2B_RAISE_SERVICE_SUBMIT_SUCCESS:
      return {
        ...state,
        b2bRaiseServiceSubmitSuccess: data,
        b2bRaiseServiceSubmitError: ""
      };
    case B2B_RAISE_SERVICE_SUBMIT_ERROR:
      return {
        ...state,
        b2bRaiseServiceSubmitError: error,
        b2bRaiseServiceSubmitSuccess: []
      };
    case B2B_CALLBACK_SUCCESS:
      return {
        ...state,
        b2bCallSuccess: data,
        b2bCallError: ""
      };
    case B2B_CALLBACK_ERROR:
      return {
        ...state,
        b2bCallSuccess: "",
        b2bCallError: error
      };
    case B2B_CALLBACK_RESET:
      return {
        ...state,
        b2bCallSuccess: "",
        b2bCallError: ""
      };
    case GET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        profileData: data,
        updateProfileMessage: "",
        updateProfileError: "",
        getProfileError: "",
      }
    case GET_PROFILE_DATA_ERROR:
      return {
        ...state,
        getProfileError: error
      }
    case UPDATE_PROFILE_SUCCESS:
      return {
        updateProfileMessage: data,
        updateProfileError: "",
        getProfileError: "",

      }
    case UPDATE_PROFILE_ERROR:
      return {
        updateProfileMessage: "",
        updateProfileError: error,
        getProfileError: "",
      }
    case RESET_UPDATE_PROFILE:
      return {
        updateProfileMessage: "",
        updateProfileError: "",
        getProfileError: "",
        profileData: {}
      }
    case PROFILE_DATA_RESET:
      return {
        updateProfileMessage: "",
        updateProfileError: "",
        profileData: {},
        getProfileError: ""
      }
    case SR_HISTORY_SUCCESS:
      return {
        ...state,
        srHistory: data,
        errorSrHistory: ""
      };
    case SR_HISTORY_ERROR:
      return {
        ...state,
        errorSrHistory: error,
        srHistory: {}
      };
    default:
      return state;
  }
};

export default common;
