import React, { useEffect } from 'react'
import HeaderBar from '../../../../components/common/components/HeaderBar'
import redCircle from '../../../../Assets/images/b2b/redCircle.svg';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import isEmpty from '../../../common/utils/isEmpty';
import { getMsisdnDetails, fetchActivePlan } from '../../../../redux/actions/myPlanAction';
import { PreLoader, ResponseMessage } from '../../../common';
import { BackIcon } from '../../../Icons'
import { b2b_prefix } from '../../../envConfig';

const MSSIDN = () => {

    let { location } = useHistory()

    const history = useHistory()

    const prefix = `/${b2b_prefix}`

    const { detailsMsisdn, errorMsisdn, activePlanData, activePlanError } = useSelector(state => state.planReducer);

    const dispatch = useDispatch()



    useEffect(() => {

        if (location && location.state && location.state.data) {
            const { si_lob, domain, number, cust_class } = location.state.data

            dispatch(fetchActivePlan(si_lob, domain, number, cust_class))
        }
        if (location && location.state && location.state.data) {
            dispatch(getMsisdnDetails(location.state.data))
            return null

        } else {
            history.push(`${prefix}/MSISDN`)
        }

    }, [dispatch, history, location, prefix])

    return (
        <React.Fragment>
            {
                !isEmpty(detailsMsisdn)
                    &&
                    errorMsisdn === ""
                    ?
                    <React.Fragment>
                        <HeaderBar>
                            <div id="mssidnb2b_1" className="row p-4 container-fluid">
                                <div id="mssidnb2b_2" className="col-6">
                                    <h1 id="mssidnb2b_3" className="mediumText">Mobile Number</h1>
                                    <p id="mssidnb2b_4" className="lightText">Billing Account {location.state && location.state.data && location.state.data.cust_ac_no}</p>
                                </div>
                                <div id="mssidnb2b_5" className="col-6">
                                    <h1 id="mssidnb2b_6" className="mediumText float-end">+{location && location.state && location.state.data.number ? location.state.data.number : ""}</h1>
                                </div>
                            </div>
                        </HeaderBar>

                        <div id="mssidnb2b_7" className="billing_details_b2b mt-5 cardContainer">
                            <HeaderBar>
                                <div id="mssidnb2b_8" className="container-fluid p-4">
                                    <div onClick={() => history.goBack()} className="cursor" >
                                        <img src={BackIcon} alt="back" />

                                    </div>
                                    <h1 id="mssidnb2b_9" style={{ color: "#e60000" }} className="semiMediumText mb-4">Billing details</h1>
                                    <div id="mssidnb2b_10" className="row">
                                        <div id="mssidnb2b_11" className="col-6">
                                            <h5 id="mssidnb2b_12" className="smallText">Parent billing account</h5>
                                            <p id="mssidnb2b_13" className="lightText mb-4" >{detailsMsisdn.parentBillingAccount}</p>
                                            <h5 id="mssidnb2b_14" className="smallText">Bill type</h5>
                                            <p id="mssidnb2b_15" className="lightText mb-4">{detailsMsisdn.billType}</p>
                                            <h5 id="mssidnb2b_16" className="smallText">Service state</h5>
                                            <p id="mssidnb2b_17" className="lightText mb-4">{detailsMsisdn.serviceState}</p>
                                            <h5 id="mssidnb2b_20" className="smallText">Sim number</h5>
                                            <p id="mssidnb2b_21" className="lightText mb-4">{detailsMsisdn.simNumber}</p>
                                            <h5 id="mssidnb2b_22" className="smallText">Bill payment mode</h5>
                                            <p id="mssidnb2b_23" className="lightText">{detailsMsisdn.billPaymentMode}</p>
                                        </div>
                                        <div id="mssidnb2b_24" className="col-6">
                                            <div id="mssidnb2b_25" className="float-end">
                                                <h5 id="mssidnb2b_26" className="smallText">Customer account ID</h5>
                                                <p id="mssidnb2b_27" className="lightText mb-4">{detailsMsisdn.customerAccountId}</p>
                                                <h5 id="mssidnb2b_28" className="smallText">Bill cycle</h5>
                                                <p id="mssidnb2b_29" className="lightText mb-4">{detailsMsisdn.billCycle}</p>
                                                <h5 id="mssidnb2b_30" className="smallText">Bill mode</h5>
                                                <p id="mssidnb2b_31" className="lightText mb-4">{detailsMsisdn.billMode}</p>
                                                <h5 id="mssidnb2b_32" className="smallText">PUK number</h5>
                                                <p id="mssidnb2b_33" className="lightText mb-4">{detailsMsisdn.puk1Number}</p>
                                                <h5 id="mssidnb2b_36" className="smallText">Billing state</h5>
                                                <p id="mssidnb2b_37" className="lightText">{detailsMsisdn.billingState}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </HeaderBar>
                        </div>
                    </React.Fragment>
                    :

                    isEmpty(detailsMsisdn)
                        &&
                        errorMsisdn === ""

                        ?
                        <div id="mssidnb2b_7" className="billing_details_b2b mt-5 layout-container">
                            <HeaderBar>
                                <div className='p-5 cardContainer'>
                                    <PreLoader />
                                </div>
                            </HeaderBar>
                        </div>
                        :

                        isEmpty(detailsMsisdn)
                            &&
                            errorMsisdn !== ""
                            ?
                            <div id="mssidnb2b_7" className="billing_details_b2b mt-5 layout-container">
                                <HeaderBar>
                                    <ResponseMessage error heading={errorMsisdn} />
                                </HeaderBar>
                            </div>
                            :
                            ""
            }


            <div id="mssidnb2b_38" className="mssidn_plan_details_b2b cardContainer mt-5 mb-5">

                {
                    !isEmpty(activePlanData)
                        &&
                        activePlanError === ""

                        ?

                        <HeaderBar>
                            <div id="mssidnb2b_39" className="p-4 billing_details_b2b">
                                <h1 id="mssidnb2b_40" style={{ color: "#e60000" }} className="semiMediumText mb-4">Plan</h1>
                                <div id="mssidnb2b_41" className="mb-2">
                                    <img id="mssidnb2b_42" src={redCircle} alt="img" /> <span id="mssidnb2b_43" className="smallText">{activePlanData && activePlanData.billPlanRental && activePlanData.billPlanRental.productSpecification ? activePlanData.billPlanRental.productSpecification.name : ""}</span>
                                </div>
                                {/* <div id="mssidnb2b_44" className="mb-2">
                                    <img id="mssidnb2b_45" src={redCircle} alt="img" /> <span id="mssidnb2b_46" className="smallText">15 GB CL data every 30 days</span>
                                </div>
                                <div id="mssidnb2b_47" className="mb-2">
                                    <img id="mssidnb2b_48" src={redCircle} alt="img" /> <span id="mssidnb2b_49" className="smallText">Unlimited calls and text to CI & UK number</span>
                                </div>
                                <div id="mssidnb2b_50" className="mb-2 mt-4">
                                    <img id="mssidnb2b_51" src={redCircle} alt="img" /> <span id="mssidnb2b_52" className="smallText">Voice mail</span>
                                </div>
                                <p id="mssidnb2b_53" className="mt-4 smallLightText">Extra added service</p> */}
                            </div>
                        </HeaderBar>
                        :
                        isEmpty(activePlanData)
                            &&
                            activePlanError === ""
                            ?
                            <HeaderBar>
                                <PreLoader />
                            </HeaderBar>
                            :
                            isEmpty(activePlanData)
                                &&
                                activePlanError !== ""
                                ?
                                <HeaderBar>
                                    <ResponseMessage error heading={activePlanError} />
                                </HeaderBar>
                                :
                                ""
                }

            </div>
        </React.Fragment>
    )
}

export default MSSIDN
