import React from 'react'
import { isEmpty } from '../../common/utils'

function useAllownces(promData) {
    let name = ''
    let price = 0
    let sms = 0
    let minutes = 0
    let data = 0

    if (promData && !isEmpty(promData)) {
        name = promData.name
        price = promData.bundlePriceDetails
            && promData.bundlePriceDetails.summaryOfCharges
            && promData.bundlePriceDetails.summaryOfCharges.attribute
            && promData.bundlePriceDetails.summaryOfCharges.attribute[0].attributeName
            && promData.bundlePriceDetails.summaryOfCharges.attribute[0].attributeName === "RC" ? promData.bundlePriceDetails.summaryOfCharges.attribute[0].attributeValue : 0

        promData.epcProductSpec && promData.epcProductSpec.length > 0 && promData.epcProductSpec.map((item, index) => {
            //sms
            if (item.isMandatory === "Y" && item.discountAttribute && item.discountAttribute.summaryOfDiscount && item.discountAttribute.summaryOfDiscount.attribute && item.discountAttribute.summaryOfDiscount.attribute[1] && (String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue).toUpperCase() === "SMS" || String(item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue).toUpperCase() === "SMS")) {
                if ((String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue).toUpperCase() === "SMS" && String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeName).toUpperCase() === "DISCOUNT UOM") && String(item.discountAttribute.summaryOfDiscount.attribute[1].attributeName).toUpperCase() === "DISCOUNT UNIT" && item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue) {
                    sms = sms + parseInt(item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue)
                }
                else {
                    sms = sms + parseInt(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue)
                }
            }
            // minutes
            if (item.isMandatory === "Y" && item.discountAttribute && item.discountAttribute.summaryOfDiscount && item.discountAttribute.summaryOfDiscount.attribute && item.discountAttribute.summaryOfDiscount.attribute[1] && (String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue).toUpperCase() === "MINUTE" || String(item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue).toUpperCase() === "MINUTE")) {
                if ((String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue).toUpperCase() === "MINUTE" && String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeName).toUpperCase() === "DISCOUNT UOM") && String(item.discountAttribute.summaryOfDiscount.attribute[1].attributeName).toUpperCase() === "DISCOUNT UNIT" && item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue) {
                    minutes = minutes + parseInt(item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue)
                }
                else {
                    minutes = minutes + parseInt(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue)
                }
            }

            // data
            if (item.isMandatory === "Y" && item.discountAttribute && item.discountAttribute.summaryOfDiscount && item.discountAttribute.summaryOfDiscount.attribute && item.discountAttribute.summaryOfDiscount.attribute[1] && ((String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue).toUpperCase() === 'MB' || String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue).toUpperCase() === 'GB') || (String(item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue).toUpperCase() === "MB" || String(item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue).toUpperCase() === "GB"))) {
                if ((String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue).toUpperCase() === 'MB' || String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue).toUpperCase() === 'GB') && String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeName).toUpperCase() === "DATA QUOTA" && String(item.discountAttribute.summaryOfDiscount.attribute[1].attributeName).toUpperCase() === "DATA UNIT" && item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue) {
                    if (String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeName).toUpperCase() === "DATA UNIT" && String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue).toUpperCase() === "MB") {
                        //IF DATA UNIT IS IN MB THEN CONVER IT INTO GB FIRST
                        data = data + parseInt(item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue) / 1024
                    }
                    if (String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeName).toUpperCase() === "DATA UNIT" && String(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue).toUpperCase() === "GB") {
                        //IF DATA UNIT IS IN MB THEN CONVER IT INTO GB FIRST
                        data = data + parseInt(item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue)
                    }
                }
                else {
                    if (String(item.discountAttribute.summaryOfDiscount.attribute[1].attributeName).toUpperCase() === "DATA UNIT" && String(item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue).toUpperCase() === "MB") {
                        //IF DATA UNIT IS IN MB THEN CONVER IT INTO GB FIRST
                        data = data + parseInt(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue) / 1024
                    }
                    if (String(item.discountAttribute.summaryOfDiscount.attribute[1].attributeName).toUpperCase() === "DATA UNIT" && String(item.discountAttribute.summaryOfDiscount.attribute[1].attributeValue).toUpperCase() === "GB") {
                        //IF DATA UNIT IS IN MB THEN CONVER IT INTO GB FIRST
                        data = data + parseInt(item.discountAttribute.summaryOfDiscount.attribute[0].attributeValue)
                    }
                }
            }
        })
    }

    return { name, price, sms, minutes, data }
}

export default useAllownces