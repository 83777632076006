import React from 'react'

function useActivePlanId(activePlanData) {
    let planId = ""

    if (activePlanData && activePlanData.billPlanRental
        &&
        activePlanData.billPlanRental.productSpecification
        &&
        activePlanData.billPlanRental.productSpecification.productNumber
    ) {
        planId = activePlanData.billPlanRental.productSpecification.productNumber
    }
    return planId
}

export default useActivePlanId