// Nav components
import { Navbar, Container } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import CloseIcon from '../../../Assets/images/close.svg'
import MenuIcon from '../../../Assets/images/layout/menu.svg'
import airtelVodaLogo from "../../../Assets/images/airtel-voda-logo.png";
import { useState } from 'react';
import { Drawer, SimpleButton, b64_to_string } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import { PowerIcon } from '../../Icons'
import { getMobileNumber } from '../../common/utils';
import { logout } from '../../../redux/actions/loginAction';
import { b2b_prefix, postpaid_prefix, prepaid_prefix } from '../../envConfig';
import { PROD_TYPE_BROADBAND } from '../../dashboard/dashboardConstant';

let NavBar = () => {
    const { auth, optimusDetails } = useSelector(state => state.auth)
    const { isAuthenticate, userType } = auth
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [isOpenNavDrawer, setIsOpenNavDrawer] = useState(false)
    const { domain, si, emailAddress, fname, lname, frst_nm, lst_nm, cnt, prod_type} = optimusDetails
    const toggleDrawer = () => setIsOpenNavDrawer(!isOpenNavDrawer)
    const postpaidPrefix = postpaid_prefix
    const prepaidPrefix = prepaid_prefix
    const b2bPrefix = b2b_prefix
    const prefixURL = userType === postpaidPrefix ? `/${postpaidPrefix}` : userType === b2bPrefix ? `/${b2bPrefix}` : `/${prepaidPrefix}`;

    let DrawerList = (
        <div className="nav-drawer-list">
            <NavLink className="nav-style nav-link" onClick={toggleDrawer} to={`${prefixURL}`} >Dashboard</NavLink>
            <NavLink className="nav-style nav-link" onClick={toggleDrawer} to={`${prefixURL}/myBill`}>Billing</NavLink>
            {(userType === postpaidPrefix || userType === prepaidPrefix) && <>
            {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_BROADBAND ? "" : <NavLink className="nav-style nav-link" onClick={toggleDrawer} to={`${prefixURL}/myPlan`}>My plan</NavLink>}
            </>
            }
            {/* {userType === b2bPrefix && <NavLink className="nav-style nav-link" onClick={toggleDrawer} to={`${prefixURL}/serviceHistory`}>SR History</NavLink>} */}
        </div>
    )


    return (
        <Navbar id="navigation-bar" collapseOnSelect expand="lg" className={`${isAuthenticate ? 'navBarContainer_login' : ""} fixed-top bg-white`}>
            {!isAuthenticate && <Navbar.Brand className="pt-0 pb-0" to="/">
                <Link to={`${prefixURL}`}>
                    <img
                        src={airtelVodaLogo}
                        className="d-inline-block align-top navBar-img"
                        alt="Airtel/vodaphone"
                    />
                </Link>
            </Navbar.Brand>}
            {isAuthenticate && <Container>
                <Navbar.Brand className="pt-0 pb-0" to="/">
                    <Link to={`${prefixURL}`}>
                        <img
                            src={airtelVodaLogo}
                            className="d-inline-block align-top navBar-img"
                            alt="Airtel/vodaphone"
                        />
                    </Link>
                </Navbar.Brand>
                {isAuthenticate ?
                    <div className="nav-items">
                        <div className="nav-toggle-icon cursor" onClick={toggleDrawer}>
                            <img src={MenuIcon} alt="menu" />
                        </div>

                        <div className="nav-tems-links">
                            <NavLink className="nav-style nav-link" to={`${prefixURL}`}>Dashboard</NavLink>
                            <NavLink className="nav-style nav-link" to={`${prefixURL}/myBill`}>Billing</NavLink>
                            {(userType === postpaidPrefix || userType === prepaidPrefix) &&  
                            <>
                                {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_BROADBAND ? "" : <NavLink className="nav-style nav-link" to={`${prefixURL}/myPlan`}>My plan</NavLink>}
                            </>
                            }
                            {/* {userType === b2bPrefix && <NavLink className="nav-style nav-link" to={`${prefixURL}/serviceHistory`}>SR History</NavLink>} */}
                        </div>

                        <div className="nav-Myaccount">
                            <OverlayTrigger
                                trigger="click"
                                placement='bottom'
                                onToggle={() => setShow(!show)}
                                show={show}
                                overlay={
                                    <Popover id={`popover-positioned-bottom`}>
                                        <Popover.Header as="h3">
                                            <div className="myAccountPopover-header" >
                                                <h4>My account</h4>
                                                <img src={CloseIcon} alt="close" className="cursor" onClick={() => setShow(!show)} />
                                            </div>
                                        </Popover.Header>
                                        <Popover.Body>
                                            {domain === "B2C" &&
                                                <div className="myAccountPopover-container mt-2 mb-4">
                                                    {
                                                        fname || frst_nm
                                                            ?
                                                            domain === "B2B" ? <></>
                                                                // <div className="avatar">{`${frst_nm ? frst_nm.charAt(0) : ""} ${lst_nm ? lst_nm.charAt(0) : ""}`}</div>
                                                                : <div className="avatar">{`${fname ? fname.charAt(0) : ""} ${lname ? lname.charAt(0) : ""}`}</div>


                                                            :
                                                            ""
                                                    }
                                                    <div className="info-container">
                                                        {domain === "B2B" ?
                                                            <>
                                                                {/* <p className="name">{`${frst_nm ? frst_nm : ""} ${lst_nm ? lst_nm : ""}`}</p> */}
                                                                {/* <p className={cnt ? "mobile" : ""}>{`+44(0)${getMobileNumber(cnt)}`}</p> */}
                                                            </>
                                                            :
                                                            <>
                                                                <p className="name">{`${fname ? fname : ""} ${lname ? lname : ""}`}</p>
                                                                <p className={si ? "mobile" : ""}>{`+44(0)${getMobileNumber(si)}`}</p>
                                                            </>}

                                                    </div>
                                                </div>
                                            }
                                            {domain === "B2B" &&
                                                // <p className={cnt ? "mobile" : ""}>{`+44(0)${getMobileNumber(cnt)}`}</p>
                                                <p>{optimusDetails.org_name}</p>
                                            }
                                            <SimpleButton
                                                variant="primary"
                                                maxWidth
                                                className="mb-2"
                                                data-testid="submit-mobile"
                                                onClick={() => dispatch(logout(domain === 'B2C' ? si : "", domain === 'B2B' ? b64_to_string(emailAddress) : "", domain))}
                                            >
                                                Log out
                                            </SimpleButton>

                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                {/* <div className="avatar">{`${optimusDetails.fname ? optimusDetails.fname.charAt(0) : ""} ${optimusDetails.lname ? optimusDetails.lname.charAt(0) : ""}`}</div> */}
                                <img src={PowerIcon} alt="account" className="cursor logout-icon" />
                            </OverlayTrigger>
                        </div>

                    </div>
                    : <div style={{ padding: '22px 0px' }}></div>}


                <Drawer
                    body={DrawerList}
                    isOpen={isOpenNavDrawer}
                    closeDrawer={toggleDrawer}
                />
            </Container>
            }
        </Navbar>
    )
}

export default NavBar;
