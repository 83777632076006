import { Redirect, Route } from 'react-router-dom'
import useAuth from '../components/login/useAuth'

function ProtectedRoute({ component: Component, url, ...rest }) {
    const { isAuth, userType } = useAuth()

    return <Route
        {...rest}
        render={(props) =>
            isAuth && userType === url ? <Component {...props} /> :
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: props.location }
                    }}
                />
        }
    />

}

export default ProtectedRoute
