import React, { useEffect, useState } from "react";
import BorderedCard from "../../common/components/BorderedCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserPaymentHistory } from "../../../redux/actions/myBillsAction";
import isEmpty from "../../common/utils/isEmpty";
import { dateFormatter, getCurrency, divideByCurrencey } from "../../common/utils";
import PreLoader from "../../common/components/PreLoader";
import ResponseMessage from "../../common/components/ResponseMessage";
import { Container } from "react-bootstrap";

const Transactions = () => {
  const dispatch = useDispatch();
  const { optimusDetails } = useSelector(state => state.auth)
  const [initialData, setInitialData] = useState(3);
  const { userPaymentHistory, paymentHistoryError } = useSelector(state => state.myBillsAllReducers);
  const { si_lob, domain, cust_ac_no } = optimusDetails

  useEffect(() => {
    //it will call the action to get user payment history before render
    if (isEmpty(userPaymentHistory) && paymentHistoryError === "") {
      dispatch(getAllUserPaymentHistory(domain, si_lob, cust_ac_no));
    }

  }, []);

  const showMoreTransacton = () => {
    setInitialData(userPaymentHistory.data.length);
  }

  return (
    <Container className="my-4">

      {!isEmpty(userPaymentHistory)
        &&
        !isEmpty(userPaymentHistory.data)
        &&
        paymentHistoryError === ""
        ?
        <div
          id="ransaction_comp_1"
          data-testid="transaction-comp"
          className="transaction_history_main_div cardContainer"
        >
          <h1 id="ransaction_comp_2" className="mb-4 semiMediumText">Transaction history</h1>

          {userPaymentHistory.data.slice(0, initialData).map((data) => {
            return (
              <BorderedCard key={data.partyPayment.description} className="blueCard mb-2">
                <div id="ransaction_comp_3" data-testid="transaction_card">
                  <div id="ransaction_comp_4" className="d-flex justify-content-between">
                    <p id="ransaction_comp_5" className="lightText">{data.partyPayment.remarks}</p>
                    <p id="ransaction_comp_6" className="lightText">{getCurrency(divideByCurrencey(data.partyPayment.amount))}</p>
                  </div>
                  <p className="smallLightText">
                    {dateFormatter(data.partyPayment.paymentDate)}
                  </p>
                  <p className="smallLightText">
                    Transaction Id : {data.trackingRecord.identification.id}
                  </p>
                  <div>
                  </div>
                </div>
              </BorderedCard>
            )
          })
          }
          {
            userPaymentHistory.data.length > 3
              &&
              initialData === 3
              ?
              <p data-testid="load_more_button" id="billing_section_main_9" onClick={showMoreTransacton} className="lightText mb-5 cursor" style={{ color: "#E60000", textAlign: "center" }}>Show more</p>
              : ""
          }

        </div>

        :

        !isEmpty(userPaymentHistory)
          &&
          isEmpty(userPaymentHistory.data)
          &&
          userPaymentHistory.data.length <= 0

          ?
          <BorderedCard>
            <ResponseMessage error heading={"Data not found"} />
          </BorderedCard>

          :

          paymentHistoryError !== ""

            ?
            <BorderedCard>
              <ResponseMessage error heading={paymentHistoryError} />
            </BorderedCard>

            :

            <BorderedCard>
              <PreLoader />
            </BorderedCard>

      }
    </Container>
  );
};

export default Transactions;
