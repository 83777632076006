import { useSelector } from "react-redux"

export const GSTNote = () => {
    const { optimusDetails } = useSelector(state => state.auth)

    return (
        optimusDetails.circle === "Jersey" ?
            <h6 id="emailHelp" className="text-danger">*Note: Prices exclude GST which will be added at time of billing.</h6>
            : ""
    )
}