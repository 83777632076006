import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBillBreakdown } from '../../../../redux/actions/myBillsAction'
import { getCurrency, PreLoader, ResponseMessage, SimpleButton } from '../../../common';
import isEmpty from '../../../common/utils/isEmpty';

const BillBreakdownB2b = ({ invoice, closeDrawer }) => {

    const dispatch = useDispatch()

    const { optimusDetails } = useSelector(state => state.auth);
    const accountNumber = optimusDetails.cust_ac_no ? optimusDetails.cust_ac_no : ""
    const { breakDownData, errorBreakdown } = useSelector(state => state.myBillsAllReducers);
    useEffect(() => {
        // if (isEmpty(breakDownData) && errorBreakdown === "") {
        dispatch(getBillBreakdown(accountNumber, invoice))
        // }


    }, [])

    return (
        <>
            {breakDownData && breakDownData.data && !isEmpty(breakDownData.data) &&
                <>
                    <h1 id="bill_breakdown_b2b_1" className="text26px mb-2">Total Outstanding</h1>
                    <h1 id="bill_breakdown_b2b_2" className="largeText">{breakDownData.data && breakDownData.data.BREAKUP_ACCOUNT_DETAILS && breakDownData.data.BREAKUP_ACCOUNT_DETAILS.DUE_AMOUNT ? getCurrency(breakDownData.data.BREAKUP_ACCOUNT_DETAILS.DUE_AMOUNT) : ""}</h1>
                    <h5 id="bill_breakdown_b2b_3" className="smallText20 mb-4">Bill breakdown</h5>
                    <div id="bill_breakdown_b2b_4" className="d-flex justify-content-between">
                        <p id="bill_breakdown_main_5" className="lightText">This month's charges</p>
                        <p id="bill_breakdown_main_6" className="lightText">{breakDownData.data && breakDownData.data.accountSummary && breakDownData.data.accountSummary.thisMonthCharges && breakDownData.data.accountSummary.thisMonthCharges.monthlyRental && getCurrency(breakDownData.data.accountSummary.thisMonthCharges.monthlyRental)}</p>
                    </div>
                    <hr id="bill_breakdown_b2b_7" />
                    <div id="bill_breakdown_b2b_8" className="d-flex justify-content-between">
                        <p id="bill_breakdown_b2b_9" className="lightText">Home usage</p>
                        <p id="bill_breakdown_b2b_10" className="lightText">{breakDownData.data && breakDownData.data.accountSummary && breakDownData.data.accountSummary.thisMonthCharges && breakDownData.data.accountSummary.thisMonthCharges.homeUsage && getCurrency(breakDownData.data.accountSummary.thisMonthCharges.homeUsage)}</p>
                    </div>
                    <hr id="bill_breakdown_b2b_11" />
                    <div id="bill_breakdown_b2b_12" className="d-flex justify-content-between">
                        <p id="bill_breakdown_b2b_13" className="lightText">Roaming usage</p>
                        <p id="bill_breakdown_b2b_14" className="lightText">{breakDownData.data && breakDownData.data.accountSummary && breakDownData.data.accountSummary.thisMonthCharges && breakDownData.data.accountSummary.thisMonthCharges.roamingUsage && getCurrency(breakDownData.data.accountSummary.thisMonthCharges.roamingUsage)}</p>
                    </div>
                    <hr id="bill_breakdown_b2b_15" />
                    <div id="bill_breakdown_b2b_16" className="d-flex justify-content-between">
                        <p id="bill_breakdown_b2b_17" className="lightText">One time charges</p>
                        <p id="bill_breakdown_b2b_18" className="lightText">{breakDownData.data && breakDownData.data.accountSummary && breakDownData.data.accountSummary.thisMonthCharges && breakDownData.data.accountSummary.thisMonthCharges.oneTimeCharges && getCurrency(breakDownData.data.accountSummary.thisMonthCharges.oneTimeCharges)}</p>
                    </div>
                    <hr id="bill_breakdown_b2b_2323" />
                    <div className="d-flex justify-content-between">
                        <p id="bill_breakdown_b2b_19" className="lightText">Discount</p>
                        <p id="bill_breakdown_b2b_20" className="lightText">{breakDownData.data && breakDownData.data.accountSummary && breakDownData.data.accountSummary.thisMonthCharges && breakDownData.data.accountSummary.thisMonthCharges.discounts && getCurrency(breakDownData.data.accountSummary.thisMonthCharges.discounts)}</p>
                    </div>
                    <hr id="bill_breakdown_b2b_1676" />
                    <div id="bill_breakdown_b2b_44" className="d-flex justify-content-between">
                        <p id="bill_breakdown_b2b_21" className="lightText">Taxes</p>
                        <p id="bill_breakdown_b2b_22" className="lightText">{breakDownData.data && breakDownData.data.accountSummary && breakDownData.data.accountSummary.thisMonthCharges && breakDownData.data.accountSummary.thisMonthCharges.taxes && getCurrency(breakDownData.data.accountSummary.thisMonthCharges.taxes)}</p>
                    </div>
                    <hr id="bill_breakdown_b2b_120" />
                </>
            }
            {
                breakDownData && breakDownData.data && isEmpty(breakDownData.data) && errorBreakdown === "" &&
                <div className="d-flex flex-column justify-content-between mt-5" id="pro-119" >
                    <ResponseMessage error heading={"Bill not found"} />
                    <div className="success-close-button" id="pro-1993" >
                        <SimpleButton variant="primary" maxWidth onClick={closeDrawer} id="pro-1u83" >Close</SimpleButton>
                    </div>
                </div>
            }
            {
                isEmpty(breakDownData) && errorBreakdown === "" &&
                <div id="pro-12676" className="loading-screen" data-testid="break_preloader">
                    <PreLoader id="pro-13" />
                </div>}
            {
                isEmpty(breakDownData) && errorBreakdown !== "" &&
                <div className="d-flex flex-column justify-content-between mt-5" id="pro-119" >
                    <ResponseMessage error heading={errorBreakdown} />
                    <div className="success-close-button" id="pro-1993" >
                        <SimpleButton variant="primary" maxWidth onClick={closeDrawer} id="pro-1u83" >Close</SimpleButton>
                    </div>
                </div>
            }
        </>
    )
}

export default BillBreakdownB2b
