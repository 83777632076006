import { account_manager_url, auth_base_url, bill_base_url, footer_base, footer_base_menu, paln_manager_url } from "../../components/envConfig"

const boot_auth_base_url = auth_base_url // auth service
const boot_bill_base_url = bill_base_url  // billiManagement service
const boot_account_manager_url = account_manager_url
const boot_paln_manager_url = paln_manager_url
const footer_base_url = `${footer_base}/api/menu_items`
const footer_base_url_menu = `${footer_base_menu}/entity/menu`



export const commonApi = {
    optimus: '/v1/services/447839745411?lineOfBusiness=mobility',
    getProfileDetail: `${boot_account_manager_url}/services/customer/manage-customer-data/customer-profile`,
    updateProfile: `${boot_account_manager_url}/services/customer/manage-customer-data/customer-profile/update`
}


export const apiConfig = {
    commonAuth: 'Basic Q2hhbm5lbElzbGFuZDpDM0c1VE8zRw=='
}

export const apiLogin = {
    logoutAPI: `${boot_auth_base_url}/logout`,
    generateOtp: `${boot_auth_base_url}/otp/generate`,
    verifyOtp: `${boot_auth_base_url}/otp/verify`,
    refreshToken: `${boot_auth_base_url}/token/refresh`,
    newConnection: `${boot_account_manager_url}/services/operations/customer-relationship-management/customer/new-connection`
}


export const apiMyBill = {
    paymentHistory: `${boot_bill_base_url}/services/customer/manage-customer-billing/payment-history`,
    customerInvoices: `${boot_bill_base_url}/services/customer/invoice-summary`,
    ebillPassword: `${boot_bill_base_url}/services/customer/deliver-electronic-invoice/e-bill-password`,
    downloadPreviousBill: `${boot_bill_base_url}/customer/invoices`,
    breakdownBill: `${boot_bill_base_url}/services/customer/manage-customer-billing/break-up-summary-charges`,
    dublicateInvoice: `${boot_bill_base_url}/services/customer/generate-duplicate-invoice/duplicate-invoice`,
    payBillAPI: `${boot_bill_base_url}/self-care/payment/initiate`,
    validatePaymentApi: `${boot_bill_base_url}/self-care/payment`,
    downloadExcel: `${boot_bill_base_url}/services/customer/generate-invoice-excel`,
    currentmonthBill: `${boot_bill_base_url}/services/customer/invoice-summary`,
    currentInvoiceAmmountApi: `${boot_bill_base_url}/services/customer/customer-information-management/customer-account-summary`
}

export const apiMyPlan = {
    getActivePlan: `${boot_paln_manager_url}/services/customer/manage-customer-data/plan-details`,
    localBoltOnAPI: `${boot_paln_manager_url}/services/customer/manager-customer-data/local/product-add-on`,
    roamingBoltOnAPI: `${boot_paln_manager_url}/services/customer/manager-customer-data/IR/product-add-on`,
    accountLevelBoltonApi: `${boot_paln_manager_url}/services/customer/manager-customer-data/account-level/product-add-on`,

    fetchPromoChangePlan: `${boot_paln_manager_url}/services/customer/manager-customer-data/promotions-list`,
    minutesAndSmsUsageApi: `${boot_paln_manager_url}/services/customer/manage-customer-data/active-plan/usage-details/sms-voice`,
    dataUsageApi: `${boot_paln_manager_url}/services/customer/manage-customer-data/active-plan/usage-details/data`,
    changeOrderAPI: `${boot_paln_manager_url}/services/customer/manager-customer-data/plans`,
    msisdnDetails: `${boot_account_manager_url}/services/customer/manage-customer-data/fetch-connection-details`,
}

export const apiErrorCode = {
    NO_INTERNET: "Something went wrong",
    SOMETHING_WRONG: "Something went wrong"
}



export const apiDashboard = {
    subscriberHierarchy: `${boot_account_manager_url}/services/customer/manage-customer-data/customer-hierarchy-list`,
    subscriberHierarchyB2B: `${boot_account_manager_url}/services/customer/manage-customer-data/customer-hierarchy-list-B2B`,
    customerInfoApi: `${boot_account_manager_url}/services/customer-info`

}

export const apiSrHistory = {
    srHistory: `${boot_account_manager_url}/services/customer/problem-handling/track-and-manage-customer-problem/customer`,
}



//use these params in your API request

export const params = {
    lob: "CIMobility",
    consumerTransactionId: "12345",
    consumerName: "CI",
    programmeName: "Butterfly",
    customerMigrated: "true",
    domain: "B2C",
    subLob: "Postpaid"
}

// Use for mapping lob and sublob
export const lobSubLob = {
    POSTPAID: "Postpaid",
    PREPAID: "Prepaid"
}




export const apiHelpSupport = {
    raiseServiceMasterData: `${boot_account_manager_url}/services/operations/customer-relationship-management/problem-handling/customer/problem-report/master-data`,
    apiSubmitServiceRequest: `${boot_account_manager_url}/services/operations/customer-relationship-management/problem-handling/customer/problem-report`,
    b2bApiSubmitServiceRequest: `${boot_account_manager_url}/services/operations/customer-relationship-management/bulk-problem-handling/customer/problem-bulk-report`,
    b2bCallbackApi: `${boot_account_manager_url}/services/operations/customer-relationship-management/customer/request-a-callback`
}

export const b2bFooter = {
    product_solution_business: `${footer_base_url}/product-solution-business`,
    why_airtel_vodafone_footer: `${footer_base_url}/why-airtel-vodafone-footer`,
    contact_us: `${footer_base_url}/contact-us`,
    about_airtel_vodafone: `${footer_base_url}/about-airtel-vodafone`,
    store_locations: `${footer_base_url}/store-locations`,
    //    B2C
    devices: `${footer_base_url}/devices`,
    pay_monthly_plans: `${footer_base_url}/pay-monthly-plans`,
    help_and_support: `${footer_base_url}/help-and-support`,

    // b2B menu
    product_solution_business_title: `${footer_base_url_menu}/product-solution-business`,
    why_airtel_vodafone_footer_title: `${footer_base_url_menu}/why-airtel-vodafone-footer`,
    contact_us_title: `${footer_base_url_menu}/contact-us`,
    about_airtel_vodafone_title: `${footer_base_url_menu}/about-airtel-vodafone`,
    store_locations_title: `${footer_base_url_menu}/store-locations`,

    //    B2C menu
    devices_title: `${footer_base_url_menu}/devices`,
    pay_monthly_plans_title: `${footer_base_url_menu}/pay-monthly-plans`,
    help_and_support_title: `${footer_base_url_menu}/help-and-support`,
}
