import React from 'react'
import Radio from '../../common/form/Radio'
import { DataIcon, SMSIcon, CallIcon } from '../../Icons'
import { getCurrency } from '../../common'
import { isEmpty } from '../../common/utils'
import useAllownces from '../hooks/useAllownces'

const AllPromoLists = ({ promData, callBack, index = 0 }) => {
    const { name, price, sms, minutes, data, value } = useAllownces(promData)
    return (
        <label for={`local_${index}`} className='planDataSection'>
        <div>
            <div id="p12" className="position-relative p-2 mb-3 d-flex data-planCard justify-content-between align-items-center">
                <ul id="p123">
                    <li id="p124" ><h5 className='m-0' id="p125"><b>{name ? name : ""}</b></h5></li>
                    {minutes && minutes !== 0 ?
                        <li id="p124">< img src={CallIcon} alt="data" />
                            <span id="p125">{` ${minutes} Minutes`}</span>
                        </li>
                        : ""
                    }
                    {
                        sms && sms !== 0 ?
                            <li id="p124">< img src={SMSIcon} alt="data" />
                                <span id="p125">{` ${sms} SMS`} </span>
                            </li>
                            : ""
                    }
                    {
                        data && data !== 0 ?
                            <li id="p124">< img src={DataIcon} alt="data" />
                                {
                                    data >= 1 && <span id="p125">{` ${parseFloat(data).toFixed(2)} GB`}</span>
                                }
                                {
                                    data < 1 && <span id="p125">{` ${(data * 1024)} MB`}</span>
                                }

                            </li>
                            : ""
                    }
                </ul>
                <div id="p1772" className="d-flex align-items-center">
                    <span id="p1276">{getCurrency(price)}</span>
                    <Radio id={`local_${index}`} name="local" className="m-0" value={value} onChange={() => callBack(promData)} />
                </div>
            </div>
        </div >
        </label>
    )
}

export default AllPromoLists
