import React from "react";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import HeaderBar from "./HeaderBar";



const NavTab = ({ tabRoutes }) => {
  //send the link to props and you can navigate them 
  return (
    <HeaderBar>
      <Nav data-test="nav_tab" id="nav_tab_1" className="justify-content-center">
        {tabRoutes.map((data, i) => {
          return (
            <NavLink
              className="main-nav px-3"
              activeClassName="activeClass"
              exact
              to={data.path}
              key={i}
              id={i}
            >
              {data.name}
            </NavLink>
          );
        })}
      </Nav>
    </HeaderBar>
  );
};

export default NavTab;
