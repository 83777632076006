import { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator'

const useValidator = (customValidator = {}, customMessage = {}) => {
  const [show, setShow] = useState(false)
  const validator = new SimpleReactValidator({
    messages: {
      required: ':attribute is required.',
      max: 'Maximum :max character allowed.',
      min: "Minimum :min character allowed.",
      alpha_space: ":attribute should only contain letters and spaces.",
      alpha: ":attribute should only contain letters.",
      ...customMessage
    },
    validators: customValidator
  })

  if (show) {
    validator.showMessages()
  }

  return [validator, setShow]
}

export default useValidator