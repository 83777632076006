/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Acoordian from '../../common/components/Acoordian'
import { DataIcon, CallIcon, SMSIcon, ReportIcon } from '../../Icons';
import { fetchMinutesSmsUsage } from "../../../redux/actions/myPlanAction";
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap';
import { AcoordianBreak, PreLoader, ResponseMessage } from '../../common';
import isEmpty from '../../common/utils/isEmpty';
import useUsage from '../../dashboard/hooks/useUsage';
import { PROD_TYPE_DATACARD } from '../../dashboard/dashboardConstant';

const UsagePostpaid = () => {
    const dispatch = useDispatch();
    const { quotaLeft, quotaLeftUnit, quotaLeftRoaming, quotaLeftUnitRoaming } = useUsage()
    const [active, setActive] = useState("Title1");
    const [activeBreakRoaming, setActiveBreakRoaming] = useState("");
    const { optimusDetails } = useSelector(state => state.auth);
    const { minutesAndSmsUsage, minutesAndSmsUsageError, childNumber } = useSelector(state => state.planReducer);
    const { domain, si, si_lob, prod_type } = optimusDetails
    useEffect(() => {
        if (childNumber) {
            dispatch(fetchMinutesSmsUsage(domain, childNumber, optimusDetails.imsi, optimusDetails.circle, si_lob));
        }
        else {
            dispatch(fetchMinutesSmsUsage(domain, si, optimusDetails.imsi, optimusDetails.circle, si_lob));
        }
    }, [childNumber, dispatch, domain, si])


    // local
    const totaLocalMinutes = minutesAndSmsUsage && minutesAndSmsUsage.localUsage && minutesAndSmsUsage.localUsage.voiceUsage && minutesAndSmsUsage.localUsage.voiceUsage.totalUnits
    const localMinutesBreak = minutesAndSmsUsage && minutesAndSmsUsage.localUsage && minutesAndSmsUsage.localUsage.voiceUsage && minutesAndSmsUsage.localUsage.voiceUsage.breakups
    const totaLocalSms = minutesAndSmsUsage && minutesAndSmsUsage.localUsage && minutesAndSmsUsage.localUsage.smsUsage && minutesAndSmsUsage.localUsage.smsUsage.totalUnits
    const localSmsBreak = minutesAndSmsUsage && minutesAndSmsUsage.localUsage && minutesAndSmsUsage.localUsage.smsUsage && minutesAndSmsUsage.localUsage.smsUsage.breakups

    //roamoing
    const totaRoamingMinutes = minutesAndSmsUsage && minutesAndSmsUsage.roamingUsage && minutesAndSmsUsage.roamingUsage.voiceUsage && minutesAndSmsUsage.roamingUsage.voiceUsage.totalUnits
    const roamingMinutesBreak = minutesAndSmsUsage && minutesAndSmsUsage.roamingUsage && minutesAndSmsUsage.roamingUsage.voiceUsage && minutesAndSmsUsage.roamingUsage.voiceUsage.breakups
    const totalRoamingSms = minutesAndSmsUsage && minutesAndSmsUsage.roamingUsage && minutesAndSmsUsage.roamingUsage.smsUsage && minutesAndSmsUsage.roamingUsage.smsUsage.totalUnits
    const roamingSmsBreak = minutesAndSmsUsage && minutesAndSmsUsage.roamingUsage && minutesAndSmsUsage.roamingUsage.smsUsage && minutesAndSmsUsage.roamingUsage.smsUsage.breakups


    return (
        <div id="usage_comp_main_1" className="container usage_comp_main_css" data-testid="usage_comp">
            <div id="usage_comp_main_2" className="usage_padding">
                <h1 id="usage_comp_main_3" className="semiMediumText dashboard-account">Usage</h1>
                {
                    !isEmpty(minutesAndSmsUsage) && Object.keys(minutesAndSmsUsage).length > 0 && minutesAndSmsUsageError === "" &&
                    <>
                        <Acoordian name={"Local Usage"} img={ReportIcon} title="Title1" active={active} setActive={setActive}>
                            <>
                                < Row id="dashboard-37" className="mb-3">
                                    <Col sm={2} lg={2}>
                                        <img
                                            src={DataIcon}
                                            id="dashboard-39"
                                            alt="data icone"
                                            className="d-inline-block align-top"
                                        />
                                    </Col>
                                    <Col id="dashboard-40" className="p-0">
                                        <div id="dashboard-41" className="dasboard-bill-details"><span>Data</span><span className="data-unit-used ms-2">{`${quotaLeft} ${quotaLeftUnit} `}</span> <span className='light-font'> consumed
                                            {/* {`${sumOfQuotaAllocated} ${sumOfQuotaAllocatedUnit}`} */}
                                        </span>
                                        </div>
                                        <hr />
                                    </Col>
                                </Row>
                                {
                                    totaLocalMinutes !== undefined && totaLocalMinutes !== null &&
                                    <AcoordianBreak
                                        child={localMinutesBreak}
                                        parent={
                                            <Row id="dashboard-43" className="mb-3">
                                                <Col sm={2} lg={2}>
                                                    <img
                                                        src={CallIcon}
                                                        id="dashboard-45"
                                                        alt="call icone"
                                                        className="d-inline-block align-top"
                                                    />
                                                </Col>
                                                <Col id="dashboard-40" className="p-2">
                                                    <div id="dashboard-47" className="dasboard-bill-details dasboard-bill-details-voice"><span>Calls</span>
                                                    {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? <span className="ms-2 bold-font">NA</span>
                                                    :
                                                    <>
                                                     <span className="ms-2 data-unit-used">{`${totaLocalMinutes} Minutes`}</span><span className='light-font'> consumed</span>
                                                    </>
                                                     }
                                                     </div>
                                                    {/* <span><span className="dashboard-usage-text-color">Unlimited</span></span> */}
                                                    <hr />
                                                </Col>
                                            </Row>
                                        }
                                        title={"call"}
                                        active={activeBreakRoaming}
                                        setActive={setActiveBreakRoaming}
                                    >
                                        {
                                            localMinutesBreak && Array.isArray(localMinutesBreak) && localMinutesBreak.length > 0 && localMinutesBreak.map((item, index) => (
                                                <Row id="dashboard-43" className='mb-2' key={index} >
                                                    <Col sm={1} lg={2}>
                                                    </Col>
                                                    <Col id="dashboard-40" className="p-0">
                                                        <div id="dashboard-47" className="dasboard-bill-details"><span className="ms-2 data-unit-used-break">{item.totalUnits ? item.totalUnits : ""} {item.description ? String(item.description).includes("Local") && "Local" || String(item.description).includes("ISD") && "International" : ""} minutes</span><span className='light-font'> consumed</span> </div>     {/* <span><span className="dashboard-usage-text-color">Unlimited</span></span> */}
                                                        <hr />
                                                    </Col>
                                                </Row>
                                            ))
                                        }
                                    </AcoordianBreak>
                                }
                                {
                                    totaLocalSms !== undefined && totaLocalSms !== null &&
                                    // <AcoordianBreak
                                    //     child={localSmsBreak}
                                    //     parent={
                                    <Row id="dashboard-43" className="mb-3">
                                        <Col sm={2} lg={2}>
                                            <img
                                                src={SMSIcon}
                                                id="dashboard-45"
                                                alt="call icone"
                                                className="d-inline-block align-top"
                                            />
                                        </Col>
                                        <Col id="dashboard-40" className="p-0">
                                            <div id="dashboard-47" className="dasboard-bill-details"><span>Texts</span>
                                            {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? <span className="ms-2 bold-font">NA</span> 
                                            : 
                                            <>
                                            <span className="ms-2 data-unit-used">{`${totaLocalSms} SMS`}</span><span className='light-font'> consumed</span>
                                            </>
                                            }
                                            </div>
                                            {/* <span><span className="dashboard-usage-text-color">Unlimited</span></span> */}
                                            <hr />
                                        </Col>

                                    </Row>
                                    //     }
                                    //     title={"SMS"}
                                    //     active={activeBreakRoaming}
                                    //     setActive={setActiveBreakRoaming}
                                    // >
                                    //     {
                                    //         localSmsBreak && Array.isArray(localSmsBreak) && localSmsBreak.length > 0 && localSmsBreak.map((item, index) => (
                                    //             <Row id="dashboard-43" className='mb-2' key={index} >
                                    //                 <Col sm={1} lg={2}>
                                    //                 </Col>
                                    //                 <Col id="dashboard-40" className="p-0">
                                    //                     <div id="dashboard-47" className="dasboard-bill-details"><span className="ms-2 data-unit-used-break">{item.totalUnits ? item.totalUnits : ""} {item.description ? String(item.description).includes("Local") && "Local" || String(item.description).includes("ISD") && "International" : ""} SMS</span><span className='light-font'> consumed</span> </div>     {/* <span><span className="dashboard-usage-text-color">Unlimited</span></span> */}
                                    //                     <hr />
                                    //                 </Col>
                                    //             </Row>
                                    //         ))
                                    //     }
                                    // </AcoordianBreak>
                                }
                            </>
                        </Acoordian>

                        {/* roaming */}
                        <Acoordian name={"Roaming Usage"} img={ReportIcon} title={"Titel2"} active={active} setActive={setActive}>

                            <Row id="dashboard-37" className="mb-3">
                                <Col sm={2} lg={2}>
                                    <img
                                        src={DataIcon}
                                        id="dashboard-39"
                                        alt="data icone"
                                        className="d-inline-block align-top"
                                    />
                                </Col>
                                <Col id="dashboard-40" className="p-0">
                                    <div id="dashboard-41" className="dasboard-bill-details"><span>Data</span><span className="data-unit-used ms-2">{`${quotaLeftRoaming} ${quotaLeftUnitRoaming} `}</span> <span className='light-font'> consumed
                                        {/* {`${sumOfQuotaAllocatedRoaming} ${sumOfQuotaAllocatedUnitRoaming}`} */}
                                    </span>
                                    </div>
                                    <hr />
                                </Col>
                            </Row>
                            {
                                totaRoamingMinutes !== undefined && totaRoamingMinutes !== null &&
                                <AcoordianBreak
                                    child={roamingMinutesBreak}
                                    parent={
                                        <Row id="dashboard-43" className="mb-3">
                                            <Col sm={2} lg={2}>
                                                <img
                                                    src={CallIcon}
                                                    id="dashboard-45"
                                                    alt="call icone"
                                                    className="d-inline-block align-top"
                                                />
                                            </Col>
                                            <Col id="dashboard-40" className="p-2">
                                                <div id="dashboard-47" className="dasboard-bill-details dasboard-bill-details-voice"><span>Calls</span>
                                                {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? <span className="ms-2 bold-font">NA</span> 
                                                : 
                                                <>
                                                <span className="ms-2 data-unit-used">{`${totaRoamingMinutes ? totaRoamingMinutes : 0} Minutes`}</span><span className='light-font'> consumed</span>
                                                </>
                                                }
                                                </div>
                                                {/* <span><span className="dashboard-usage-text-color">Unlimited</span></span> */}
                                                <hr />
                                            </Col>

                                        </Row>
                                    }
                                    title={"call"}
                                    active={activeBreakRoaming}
                                    setActive={setActiveBreakRoaming}
                                >
                                    {
                                        roamingMinutesBreak && Array.isArray(roamingMinutesBreak) && roamingMinutesBreak.length > 0 && roamingMinutesBreak.map((item, index) => (
                                            <Row id="dashboard-43" className='mb-2' key={index} >
                                                <Col sm={1} lg={2}>
                                                </Col>
                                                <Col id="dashboard-40" className="p-0">
                                                    <div id="dashboard-47" className="dasboard-bill-details"><span className="ms-2 data-unit-used-break">{item.totalUnits ? item.totalUnits : ""} {item.description ? String(item.description).includes("National") && "National roaming" || String(item.description).includes("International") && "International roaming" : ""} minutes</span><span className='light-font'> consumed</span> </div>     {/* <span><span className="dashboard-usage-text-color">Unlimited</span></span> */}
                                                    <hr />
                                                </Col>
                                            </Row>
                                        ))
                                    }
                                </AcoordianBreak>

                            }
                            {
                                totalRoamingSms !== undefined && totalRoamingSms !== null &&
                                // <AcoordianBreak
                                //     child={roamingSmsBreak}
                                //     parent={
                                <Row id="dashboard-43" className="mb-3">
                                    <Col sm={2} lg={2}>
                                        <img
                                            src={SMSIcon}
                                            id="dashboard-45"
                                            alt="call icone"
                                            className="d-inline-block align-top"
                                        />
                                    </Col>
                                    <Col id="dashboard-40" className="p-0">
                                        <div id="dashboard-47" className="dasboard-bill-details"><span>Texts</span>
                                        {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? <span className="ms-2 bold-font">NA</span>
                                         : 
                                         <>
                                         <span className="ms-2 data-unit-used">{`${totalRoamingSms ? totalRoamingSms : 0} SMS`}</span><span className='light-font'> consumed</span>
                                         </>
                                         }
                                        </div>
                                        {/* <span><span className="dashboard-usage-text-color">Unlimited</span></span> */}
                                        <hr />
                                    </Col>

                                </Row>
                                //  ßß
                            }
                        </Acoordian>
                    </>
                }
                {
                    minutesAndSmsUsageError !== "" && isEmpty(minutesAndSmsUsage) &&
                    <div style={{ backgroundColor: "#fff", padding: '20px' }} className=" mb-5">
                        <ResponseMessage error heading={minutesAndSmsUsageError} />
                    </div>
                }
                {
                    minutesAndSmsUsageError === "" && isEmpty(minutesAndSmsUsage) &&
                    <div style={{ backgroundColor: "#fff", padding: '20px' }} className=" mb-5">
                        <PreLoader />
                    </div>
                }
            </div >
        </div >
    )
}

export default UsagePostpaid
