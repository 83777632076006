import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
// import Dashboard from '../components/dashboard';
import ServiceHistory from '../components/B2B/service history/ServiceHistory'
import BillingMainB2b from '../components/B2B/billing/BillingMainB2b';
import MSSIDN from '../components/B2B/mssidn/components/MSSIDN';
import { ListMsisdn } from '../components/B2B/mssidn/views/ListMsisdn';
import B2bProfile from '../components/B2B/my profile/B2bProfile';
import B2bDashboard from '../components/B2B/dashboard/b2bDashboard';
import ResponseDetail from '../components/B2B/mssidn/views/ResponseDetail';
import { b2b_prefix } from '../components/envConfig';
import TermAndCondition from '../components/services/TermAndCondition';
import Payment from '../components/mybills/views/Payment';


function B2bRoute() {

    useEffect(() => {
        document.title = "Airtel-Vodafone Selfcare | Bussiness"
    }, [])
    const prefix = `/${b2b_prefix}`
    return (
        <>
            <TermAndCondition />
            <Switch>
                {/* <Route path={prefix} exact >
                    <Dashboard />
                </Route> */}
                <Route exact path={`${prefix}`} component={B2bDashboard} />
                <Route path={`${prefix}/myBill`}> <BillingMainB2b /> </Route>
                <Route path={`${prefix}/MSISDN`}> <ListMsisdn /> </Route>
                <Route path={`${prefix}/service_response`}> <ResponseDetail /> </Route>
                <Route path={`${prefix}/MSISDN_details`}> <MSSIDN /> </Route>
                <Route path={`${prefix}/serviceHistory`} component={ServiceHistory} />
                <Route path={`${prefix}/profile`} component={B2bProfile} />
                <Route path={`${prefix}/payment`} component={Payment}/>
                <Redirect to={`${prefix}/`} />
            </Switch>
        </>
    )
}

export default B2bRoute
