import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ResponseModal, SimpleButton } from '../common'


function TermAndCondition() {
    const [termAndConditions, setTermAndConditions] = useState(false)
    const { auth } = useSelector(s => s.auth)
    const [agree, setAgree] = useState(true)
    const [showNewsLetter, setShowNewsLetter] = useState(false)

    useEffect(() => {
        let visited = localStorage["alreadyVisited"];
        let firstTimeLogin = auth.firstTimeLogin
        if (!visited && firstTimeLogin) {
            setTermAndConditions(true)
            //do not view Popup
        } else {
            setTermAndConditions(false)
        }
    }, [])

    const closeTermAndConditionModal = () => {
        //this is the first time
        localStorage["alreadyVisited"] = true;
        setTermAndConditions(false)
    }
    return (
        <>
            <ResponseModal
                isClose={false}
                size="sm"
                backdrop="static"
                keyboard={false}
                show={termAndConditions}
            >
                <p>Terms and conditions and privacy policy </p>
                <p>By using the app/platform you agree to our <a className='text-danger' target="_blank" rel="noopener noreferrer" href="https://airtel-vodafone.com/JE/About-Airtel-Vodafone/Terms-and-Conditions">terms and conditions</a> and confirm you have read and understood our <a className='text-danger' href='https://airtel-vodafone.com/sites/default/files/inline-files/Privacy_Policy_effective_from_25.05_.18__0.pdf' target="_blank" rel="noopener noreferrer">privacy policy</a></p>
                <Form.Check
                    type="checkbox"
                    label="I agree"
                    className='mb-2'
                    checked={agree}
                    onChange={() => setAgree(!agree)}
                />
                <Form.Label className='text-danger mb-3 cursor' onClick={() => setShowNewsLetter(true)}>Sign up for latest news and promotions</Form.Label>
                <SimpleButton maxWidth onClick={() => agree ? closeTermAndConditionModal() : null}>Continue</SimpleButton>
            </ResponseModal>
            <ResponseModal
                handleClose={() => setShowNewsLetter(false)}
                show={showNewsLetter}
            >
                <iframe allowfullscreen="" frameborder="0" height="780" id="cm-popup-iframe" margin="0" marginwidth="0" onclick="cmGetIframeHeight(this)" scrolling="no" src="https://confirmsubscription.com/h/j/E51AA97AD9CDA801/forms/4EC4EE59284A01C6" width="100%"></iframe>
            </ResponseModal>
        </>
    )
}

export default TermAndCondition