import { useSelector } from 'react-redux';

function useUsage() {

    const { minutesAndSmsUsage, minutesAndSmsUsageError, dataUsage } = useSelector(state => state.planReducer);

    // local usage 
    const minutes = minutesAndSmsUsage && minutesAndSmsUsage.localUsage && minutesAndSmsUsage.localUsage.voiceUsage && minutesAndSmsUsage.localUsage.voiceUsage.totalUnits
    const sms = minutesAndSmsUsage && minutesAndSmsUsage.localUsage && minutesAndSmsUsage.localUsage.smsUsage && minutesAndSmsUsage.localUsage.smsUsage.totalUnits

    const dataUsageBreak = dataUsage && dataUsage.localUsage && dataUsage.localUsage.dataUsage && dataUsage.localUsage.dataUsage.usage ? dataUsage.localUsage.dataUsage.usage : []
    const sumOfQuotaAllocated = dataUsage && dataUsage.localUsage && dataUsage.localUsage.dataUsage && dataUsage.localUsage.dataUsage && dataUsage.localUsage.dataUsage.quota && dataUsage.localUsage.dataUsage.quota.quota ? dataUsage.localUsage.dataUsage.quota.quota : 0
    const sumOfQuotaAllocatedUnit = dataUsage && dataUsage.localUsage && dataUsage.localUsage.dataUsage && dataUsage.localUsage.dataUsage && dataUsage.localUsage.dataUsage.quota && dataUsage.localUsage.dataUsage.quota.unit ? dataUsage.localUsage.dataUsage.quota.unit : 'GB'
    const quotaLeft = dataUsage && dataUsage.localUsage && dataUsage.localUsage.dataUsage && dataUsage.localUsage.dataUsage && dataUsage.localUsage.dataUsage.quotaConsumed && dataUsage.localUsage.dataUsage.quotaConsumed.quota ? dataUsage.localUsage.dataUsage.quotaConsumed.quota : 0
    const quotaLeftUnit = dataUsage && dataUsage.localUsage && dataUsage.localUsage.dataUsage && dataUsage.localUsage.dataUsage && dataUsage.localUsage.dataUsage.quotaConsumed && dataUsage.localUsage.dataUsage.quotaConsumed.unit ? dataUsage.localUsage.dataUsage.quotaConsumed.unit : 'GB'

    // roaming data
    const dataUsageBreakRoaming = dataUsage && dataUsage.roamingUsage && dataUsage.roamingUsage.dataUsage && dataUsage.roamingUsage.dataUsage && dataUsage.roamingUsage.dataUsage.usage ? dataUsage.roamingUsage.dataUsage.usage : []
    const sumOfQuotaAllocatedRoaming = dataUsage && dataUsage.roamingUsage && dataUsage.roamingUsage.dataUsage && dataUsage.roamingUsage.dataUsage && dataUsage.roamingUsage.dataUsage.quota && dataUsage.roamingUsage.dataUsage.quota.quota ? dataUsage.roamingUsage.dataUsage.quota.quota : 0
    const sumOfQuotaAllocatedUnitRoaming = dataUsage && dataUsage.roamingUsage && dataUsage.roamingUsage.dataUsage && dataUsage.roamingUsage.dataUsage && dataUsage.roamingUsage.dataUsage.quota && dataUsage.roamingUsage.dataUsage.quota.unit ? dataUsage.roamingUsage.dataUsage.quota.unit : 'GB'
    const quotaLeftRoaming = dataUsage && dataUsage.roamingUsage && dataUsage.roamingUsage.dataUsage && dataUsage.roamingUsage.dataUsage && dataUsage.roamingUsage.dataUsage.quotaConsumed && dataUsage.roamingUsage.dataUsage.quotaConsumed.quota ? dataUsage.roamingUsage.dataUsage.quotaConsumed.quota : 0
    const quotaLeftUnitRoaming = dataUsage && dataUsage.roamingUsage && dataUsage.roamingUsage.dataUsage && dataUsage.roamingUsage.dataUsage && dataUsage.roamingUsage.dataUsage.quotaConsumed && dataUsage.roamingUsage.dataUsage.quotaConsumed.unit ? dataUsage.roamingUsage.dataUsage.quotaConsumed.unit : 'GB'

    // change after, API Change 

    return {
        // local usage
        dataUsageBreak, sumOfQuotaAllocated, sumOfQuotaAllocatedUnit, quotaLeft, quotaLeftUnit,
        // minute 
        minutes, sms, minutesAndSmsUsageError, minutesAndSmsUsage,
        // roaming
        dataUsageBreakRoaming, sumOfQuotaAllocatedRoaming, sumOfQuotaAllocatedUnitRoaming, quotaLeftRoaming, quotaLeftUnitRoaming
    }
}

export default useUsage