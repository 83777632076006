import React from 'react'
import { Form } from 'react-bootstrap'
import PropTypes from 'prop-types'

function Select({ label, placeholder, options = [], value, onChange, keyName, keyValue, className, error, ...rest }) {

    return (
        <Form.Group className={`form-group ${className ? className : ""}`} data-testid="form-select" >
            {label && <Form.Label data-testid="select-label">{label}</Form.Label>}
            <Form.Control as="select" value={value} {...rest} onChange={onChange} required data-test="select" className="form-select">
                {placeholder && <option data-testid="select-placeholder" value="">--{placeholder}--</option>}
                {options.map((item, index) => (
                    <option key={index} value={item[keyValue]} role="select-options">{item[keyName]}</option>
                ))}
            </Form.Control>
            {error && <Form.Control.Feedback type="invalid" data-testid="select-error" >
                {error}
            </Form.Control.Feedback>
            }
        </Form.Group>
    )
}

Select.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    keyName: PropTypes.string,
    keyValue: PropTypes.string,
    error: PropTypes.string,
}


export default Select
