//Main dashbord component
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import NextBill from "./components/nextBill";
import UsageCard from "./components/usageCard";
import HelpSupport from "./components/helpSupport";
import Addons from "./components/addons";
import profileIcone from "../../Assets/images/dashboard/profileIcone.png";
import addContactIcone from "../../Assets/images/dashboard/addContact.svg";
import MyProductCard from "./components/myProductCard";
import MyAccountCard from "./components/accountCard";
import {
  Drawer,
  PreLoader,
  ResponseMessage,
  getCurrency,
  dateFormatter,
  breakDownDateFormatter,
} from "../common";
import UpdateProfile from "../common/myProfile/UpdateProfile";
import BorderedCard from "../common/components/BorderedCard";
import reportIcone from "../../Assets/images/dashboard/report.svg";
import { getScreenResolution, divideByCurrencey } from "../common/utils";
import MyUsageCarousel from "./components/myUsageCarousel";
import {
  getCurrentInovieAmount,
  getCurrentMonthBill,
} from "../../redux/actions/myBillsAction";
import { batch, useDispatch, useSelector } from "react-redux";
import BoltOn from "../common/boltOn/BoltOn";
import { Discovery } from "../common/dashboard/Discovery";
import {
  getCutomerInfo,
  getSIDetail,
  getSubscriberHierarchy,
} from "../../redux/actions/dashboardAction";
import AddData from "../MyPlan&Usage/components/AddData";
import {
  fetchActivePlan,
  getLocalBoltOn,
  fetchMinutesSmsUsage,
  getRomaingBoltOn,
  getDataBoltOn,
  getAccLevelBolton,
} from "../../redux/actions/myPlanAction";
import isEmpty from "../common/utils/isEmpty";
import NoBillScreen from "../mybills/components/NoBillScreen";
import { postpaid_prefix } from "../envConfig";
import useActivePlanId from "../MyPlan&Usage/hooks/useActivePlanId";
import { MOBILE_NUMBER_SERIES, PROD_TYPE_DATACARD, PROD_TYPE_BROADBAND } from "../dashboard/dashboardConstant";

const Dashboard = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { activePlanData } = useSelector((state) => state.planReducer);
  const { optimusDetails } = useSelector((state) => state.auth);
  const { subscriberHierarchy, subscriberHierarchyError } = useSelector(
    (state) => state.dashboard
  );
  const authData = useSelector((state) => state.auth);
  const [isOpenProfileDrawer, setIsOpenProfileDrawer] = useState(false);
  const [isOpenBoltOnDrawer, setIsOpenBoltOnDrawer] = useState(false);
  const [isAccLevel, setIsAccLevel] = useState(false);
  const openProfileDrawer = () => setIsOpenProfileDrawer(true);
  const closeProfileDrawer = () => setIsOpenProfileDrawer(false);
  const [isOpenDataDrawer, setIsOpenDataDrawer] = useState(false);
  const closeDataDrawer = () => setIsOpenDataDrawer(false);
  const [screenResolution, setResolution] = useState({
    height: "768",
    width: "1366",
  });
  const { breakDownData, errorBreakdown, currentBillAmmount } = useSelector(
    (state) => state.myBillsAllReducers
  );
  const prefix = `/${postpaid_prefix}`;
  const planId = useActivePlanId(activePlanData);

  const closeBoltOnDrawer = () => {
    dispatch({ type: "BOLTON_PLAN_RESET" });
    dispatch({ type: "CHANGE_ORDER_RESET" });
    setIsOpenBoltOnDrawer(false);
    setIsAccLevel(false);
  };
  const openBoltOnDrawer = (isAccLevelB = false) => {
    dispatch(fetchActivePlan(si_lob, domain, si, cust_class));
    if (isAccLevelB) {
      setIsAccLevel(true);
    }
    setIsOpenBoltOnDrawer(true);
    if (planId !== "" && !isEmpty(planId)) {
      if (isAccLevelB) {
        dispatch(
          getAccLevelBolton(
            planId,
            msisdn,
            domain,
            sublob,
            circle_id,
            cust_class
          )
        );
      } else {
        dispatch(
          getLocalBoltOn(planId, msisdn, domain, sublob, circle_id, cust_class)
        );
        dispatch(
          getRomaingBoltOn(
            planId,
            msisdn,
            domain,
            sublob,
            circle_id,
            cust_class
          )
        );
      }
    }
  };

  const OpenDataDrawer = (e) => {
    dispatch(fetchActivePlan(si_lob, domain, si, cust_class));
    e.stopPropagation();
    setIsOpenDataDrawer(true);
    if (planId !== "" || planId !== undefined) {
      dispatch(
        getDataBoltOn(planId, msisdn, domain, sublob, circle_id, cust_class)
      );
    }
  };

  const myAccount = [
    {
      title: "My profile",
      content: "Update profile",
      icone: profileIcone,
      onClick: openProfileDrawer,
    },
    {
      title: "My bolt on",
      content: "Manage bolt on at account level",
      icone: addContactIcone,
      subContent: "(To be shared across all users)",
      onClick: () => openBoltOnDrawer(true),
    },
  ];

  const { si, si_lob, domain, cust_class, circle_id, prod_type } = optimusDetails;
  const accountNumber = optimusDetails.cust_ac_no
    ? optimusDetails.cust_ac_no
    : "";
  const msisdn = optimusDetails.si ? optimusDetails.si : "";
  const sublob = optimusDetails.si_lob ? optimusDetails.si_lob : "";

  useEffect(() => {
    //initial Data
    dispatch(getCurrentMonthBill(domain, msisdn, sublob, accountNumber));
    if(prod_type.toLowerCase() !==  PROD_TYPE_BROADBAND){
      dispatch(
        fetchMinutesSmsUsage(
          domain,
          msisdn,
          optimusDetails.imsi,
          optimusDetails.circle,
          si_lob
        )
      );
    }
    else{
      console.log("no data");
    }
    dispatch(fetchActivePlan(si_lob, domain, si, cust_class));
    dispatch(getSubscriberHierarchy(sublob, domain, msisdn));
    dispatch(getCurrentInovieAmount(domain, si_lob, si));
  }, []);

  let getResolution = () => {
    setResolution(getScreenResolution());
  };

  useEffect(() => {
    //get screen resolution
    getResolution();
    window.addEventListener("resize", getResolution);

    return () => {
      window.removeEventListener("resize", getResolution);
    };
  }, []);

  const changeChildNumber = (number) => {
    if (number) {
      dispatch(getCutomerInfo(number));
      dispatch({ type: "SET_CHILD_NUMBER", data: number });
      history.push(`${prefix}/myPlan`);
    }
  };

  let gotToMyBills = () => {
    history.push(`${prefix}/myBill`);
  };

  let gotoMyplan = () => {
    history.push(`${prefix}/myPlan/usage`);
  };

  let gotoUsage = (e) => {
    e.stopPropagation();
    history.push(`${prefix}/myPlan/usage`);
  };

  let matchedUserNumbers = [];
  let unmatchedUserNumbers = [];
  
  let productHierarchyList = subscriberHierarchy.subscriberHierarchyList;

  if (productHierarchyList != null && productHierarchyList !== undefined) {
     var getProductNumber = productHierarchyList.forEach(number => {
      let userNumber = number.logicalResource.identification.id;
        let isNumberMatched = MOBILE_NUMBER_SERIES.some(prefix => userNumber.startsWith(prefix));
        isNumberMatched ? matchedUserNumbers.push(number) : unmatchedUserNumbers.push(number);
      });
      getProductNumber = [...matchedUserNumbers, ...unmatchedUserNumbers];
  }


  let getProductsOfUser = () => {
    let allProducts = [];
    getProductNumber.forEach((product, index) => {
      const number =
        product &&
        product.logicalResource &&
        product.logicalResource.identification &&
        product.logicalResource.identification.id
          ? product.logicalResource.identification.id
          : "";
      const isMatched = MOBILE_NUMBER_SERIES.some((prefix) =>
        number.startsWith(prefix)
      );

      if (
        product.customer &&
        product.customer.customerAccount &&
        product.customer.customerAccount.billableFlag &&
        product.customer.customerAccount.billableFlag === "Y"
      ) {
        allProducts.unshift(
          <div
            id={`dashboard-my-product-104-${index}`}
            className={!isMatched ? 
              `item ${
                    number &&
                    product.siStatus &&
                    product.siStatus === "Suspended"
                      ? ""
                      : number
                  }`
                   : `item ${
              number && product.siStatus && product.siStatus === "Suspended"
                ? ""
                : number
                ? "cursor"
                : ""
            }`}
            key={`dashboard-my-product-${index}`}
            onClick={() =>
              !isMatched ? "" :
              changeChildNumber(
                product.siStatus && product.siStatus === "Suspended"
                  ? ""
                  : number
              )
            }
          >
            {!isMatched ? <MyProductCard
              id={`dashboard-myProduct-${index}`}
              index
              {...product}
              title="Broadband"
              f-s1-4
            /> :
            <MyProductCard
              id={`dashboard-myProduct-${index}`}
              index
              {...product}
              title="Number"
            />
      }
          </div>
        );
      } else {
        allProducts.push(
          <div
            id={`dashboard-my-product-104-${index}`}
            className={
              !isMatched
                ? `item ${
                    number &&
                    product.siStatus &&
                    product.siStatus === "Suspended"
                      ? ""
                      : number
                  }`
                : `item ${
                    number &&
                    product.siStatus &&
                    product.siStatus === "Suspended"
                      ? ""
                      : number
                      ? "cursor"
                      : ""
                  }`
            }
            key={`dashboard-my-product-${index}`}
            onClick={() =>
              !isMatched
                ? ""
                : changeChildNumber(
                    product.siStatus && product.siStatus === "Suspended"
                      ? ""
                      : number
                  )
            }
          >
            {!isMatched ? <MyProductCard
              id={`dashboard-myProduct-${index}`}
              index
              {...product}
              title="Broadband"
              f-s1-4
            /> : <MyProductCard
            id={`dashboard-myProduct-${index}`}
            index
            {...product}
            title="Number"
            f-s-3
          />}
            
          </div>
        );
      }
    });
    return allProducts;
  };

  var prepOrPost = authData.auth.userType;

  /**********************************PREPAID COMPONENT**************************************************/
  const prepaid = (
    <>
      {/*       <Container id="dashboard-pre" className="pt-4">
        <Row id="dashboard-pre-1" className="mb-4 mt-5">
          <Col id="dashboard-pre-2" sm={12}>
            <h2 id="dashboard-pre-3" className="dashboard-typo font-style">Hello</h2>
          </Col>
        </Row>
        {screenResolution.width >= 769 ?
          <Row id="dashboard-pre-4">
            <Col id="dashboard-pre-5" sm={6} onClick={gotToMyBills}>
              <NextBill type={prepOrPost} nextBill={nextBill && nextBill.getCustomerAccountSummaryResMsg && nextBill.getCustomerAccountSummaryResMsg.dataArea ? { ...nextBill.getCustomerAccountSummaryResMsg.dataArea.getCustomerAccountSummaryResponse, ...nextBillData } : { ...nextBillData }} />
            </Col>
            <Col id="dashboard-pre-26" sm={6}>
              <UsageCard type={prepOrPost} {...minutesAndSmsUsage} gotoMyplan={gotoMyplan} gotoUsage={gotoUsage} optimusDetails={optimusDetails ? optimusDetails : {}} />
              <Row id="dashboard-pre-58" className="mt-21">
                <Col id="dashboard-pre-59" sm={6}>
                  <HelpSupport />
                </Col>
                <Col id="dashboard-pre-66" sm={6}>
                  <Addons type={prepOrPost} onClick={openBoltOnDrawer} />
                </Col>
              </Row>
            </Col>
          </Row>
          :
          <>
            <Row id="dashboard-pre-71" className="mb-3">
              <MyUsageCarousel />
            </Row>
            <Row id="dashboard-pre-86">
              <Col id="dashboard-pre-87" xs={6}>
                <BorderedCard className="dashboard-mobile-bill">
                  <div id="dashboard-pre-88" className="d-flex flex-row mb-2">
                    <img
                      src={reportIcone}
                      id="dashboard-pre-89"
                      alt="report icone"
                      className="d-inline-block align-top"
                    />
                    <span id="dashboard-pre-90" className="dasboard-bill">My bill</span>
                  </div>
                  <div id="dashboard-pre-91" className="dashboard-mobile-bill-text mb-3">Your current spend is:</div>
                  <div id="dashboard-pre-92" className="dashboard-mobile-bill-amount mb-3">£52.9</div>
                  <div id="dashboard-pre-93">To be paid the 25th Jan</div>
                </BorderedCard>
              </Col>
              <Col id="dashboard-pre-94" xs={6}>
                <Row id="dashboard-pre-95">
                  <Col id="dashboard-pre-96" sm={12}>
                    <HelpSupport />
                  </Col>
                </Row>
                <Row id="dashboard-pre-97">
                  <Col id="dashboard-pre-98" sm={12}>
                    <Addons type="prepaid" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
        {subscriberHierarchy && subscriberHierarchy.getSubscriberHierarchyListResMsg && subscriberHierarchy.getSubscriberHierarchyListResMsg.dataArea && subscriberHierarchy.getSubscriberHierarchyListResMsg.dataArea.getSubscriberHierarchyListResponse && subscriberHierarchy.getSubscriberHierarchyListResMsg.dataArea.getSubscriberHierarchyListResponse.subscriberHierarchyList ?
          <>
            <Row id="dashboard-pre-99" className="m-b-10 m-t-15">
              <Col id="dashboard-pre-100" sm={12}>
                <h3 id="dashboard-pre-101" className="dashboard-typo font-style">
                  My products
                </h3>
              </Col>

            </Row>
            <Row id="dashboard-pre-102">
              <Col id="dashboard-pre-103" sm={6}>
                <Row>
                  {subscriberHierarchy.getSubscriberHierarchyListResMsg.dataArea.getSubscriberHierarchyListResponse.subscriberHierarchyList.map((data, index) => {
                    return (
                      <Col id={`dashboard-my-product-104-${index}`} key={index} xs={6} sm={6} md={4} onClick={() => changeChildNumber(data)}>
                        <MyProductCard id={`dashboard-myProduct-${index}`} index {...data} title="Group data plan" />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </>
          : ""}
      </Container>
      */}
    </>
  );

  /**********************************POSTPAID COMPONENT**************************************************/
  /**********************************POSTPAID COMPONENT**************************************************/
  /**********************************POSTPAID COMPONENT**************************************************/

  const postpaid = (
    <>
      <div
        id="dashboard-post-2"
        className="d-flex justify-content-between align-items-center"
        sm={12}
      >
        <h1 id="dashboard-post-3" className="dashboard-typo">{`Hello ${
          optimusDetails && optimusDetails.fname ? optimusDetails.fname : ""
        }`}</h1>
        {optimusDetails && optimusDetails.si ? (
          <h1
            id="dashboard-post-3"
            className="dashboard-typo"
          >{`+${optimusDetails.si}`}</h1>
        ) : (
          ""
        )}
      </div>
      {screenResolution.width >= 769 ? (
        <Row id="dashboard-post-4">
          <Col id="dashboard-post-5" sm={6} onClick={gotToMyBills}>
            <NextBill
              type={prepOrPost}
              nextBill={breakDownData}
              errorBreakdown={errorBreakdown}
            />
          </Col>
          <Col id="dashboard-post-26" sm={6}>
            <UsageCard
              type={prepOrPost}
              gotoMyplan={gotoMyplan}
              OpenDataDrawer={OpenDataDrawer}
              gotoUsage={gotoUsage}
              optimusDetails={optimusDetails ? optimusDetails : {}}
            />
            <Row id="dashboard-post-58" className="mt-21">
              <Col id="dashboard-post-59" sm={6}>
                <HelpSupport />
              </Col>
              {domain === 'B2C' && (prod_type.toLowerCase() === PROD_TYPE_DATACARD || prod_type.toLowerCase() === PROD_TYPE_BROADBAND) ? "" : 
              <Col id="dashboard-post-66" sm={6}>
                <Addons type={prepOrPost} onClick={() => openBoltOnDrawer()} />
              </Col>
              }
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          <Row id="dashboard-post-71" className="mb-3">
            <MyUsageCarousel
              gotoMyplan={gotoMyplan}
              OpenDataDrawer={OpenDataDrawer}
            />
          </Row>
          <Row id="dashboard-post-86">
            <Col id="dashboard-post-87" xs={6}>
              <BorderedCard
                onClick={gotToMyBills}
                className="dashboard-mobile-bill"
              >
                {isEmpty(breakDownData) && errorBreakdown === "" ? (
                  <div className="loader-position">
                    <PreLoader />
                  </div>
                ) : errorBreakdown === "Bill not found" ? (
                  <div>
                    <NoBillScreen />
                  </div>
                ) : errorBreakdown ? (
                  <div>
                    <ResponseMessage
                      id="Bill-section-rs-55t534"
                      error
                      heading={errorBreakdown}
                    />
                  </div>
                ) : breakDownData.data.BREAKUP_ACCOUNT_DETAILS ? (
                  <>
                    <div
                      id="dashboard-post-88"
                      className="d-flex flex-row mb-2"
                    >
                      <img
                        src={reportIcone}
                        id="dashboard-post-89"
                        alt="report icone"
                        className="d-inline-block align-top"
                      />
                      <span id="dashboard-post-90" className="dasboard-bill">
                        My bill
                      </span>
                    </div>
                    <div
                      id="dashboard-post-91"
                      className="dashboard-mobile-bill-text mb-3"
                    >
                      Your current bill is
                    </div>
                    <div
                      id="dashboard-post-92"
                      className="dashboard-mobile-bill-amount mb-4"
                    >
                      {breakDownData &&
                      breakDownData.data &&
                      breakDownData.data.BREAKUP_ACCOUNT_DETAILS
                        ? getCurrency(!isEmpty(currentBillAmmount) && currentBillAmmount.customerBill && currentBillAmmount.customerBill.partyBill && divideByCurrencey(currentBillAmmount.customerBill.partyBill.dueAmount))
                        : 0}
                    </div>
                    <div id="dashboard-post-93">{` ${
                      breakDownData &&
                      breakDownData.data &&
                      breakDownData.data.BREAKUP_ACCOUNT_DETAILS &&
                      breakDownData.data.BREAKUP_ACCOUNT_DETAILS.DUE_DATE
                        ? `To be paid ${dateFormatter(breakDownDateFormatter(breakDownData.data.BREAKUP_ACCOUNT_DETAILS.DUE_DATE))}`
                        : ""
                    }`}</div>
                  </>
                ) : (
                  <div>
                    <ResponseMessage
                      id="Bill-section-rs-55t534"
                      error
                      heading={errorBreakdown}
                    />
                  </div>
                )}
              </BorderedCard>
            </Col>
            <Col
              id="dashboard-post-94"
              className={screenResolution.width >= 769 ? "" : "ps-0"}
              xs={6}
            >
              <Row id="dashboard-post-95">
                <Col id="dashboard-post-96" sm={12}>
                  <HelpSupport />
                </Col>
              </Row>
              <Row id="dashboard-post-97">
                <Col id="dashboard-post-98" sm={12}>
                  <Addons type="postpaid" onClick={() => openBoltOnDrawer()} />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
      <Row id="dashboard-post-99" className="dashboard-account">
        <Col id="dashboard-post-100" sm={12}>
          <h3 id="dashboard-post-101" className="dashboard-typo font-style">
            My products
          </h3>
        </Col>
      </Row>
      {subscriberHierarchy &&
        subscriberHierarchy.subscriberHierarchyList &&
        subscriberHierarchy.subscriberHierarchyList.length > 0 && (
          <div id="dashboard-post-102" className="scroll-gride">
            <div
              id="dashboard-post-103"
              className="hs full"
              style={{
                "--total": `${subscriberHierarchy.subscriberHierarchyList.length}`,
              }}
            >
              {getProductsOfUser()}
            </div>
          </div>
        )}
      {subscriberHierarchy &&
        subscriberHierarchy.subscriberHierarchyList &&
        subscriberHierarchy.subscriberHierarchyList.length === 0 &&
        subscriberHierarchyError === "" && (
          <BorderedCard className="productLoaderErrorCard">
            <ResponseMessage
              error
              heading={"Warning"}
              imageClass="productErrorImage"
              headingClass="productErrorHeading"
              messageClass="productErrorMessage"
              message="Data not found"
            />
          </BorderedCard>
        )}
      {Object.keys(subscriberHierarchy).length === 0 &&
        subscriberHierarchyError === "" && (
          <BorderedCard className="productLoaderErrorCard">
            <div className="m-t-13">
              <PreLoader />
            </div>
          </BorderedCard>
        )}
      {Object.keys(subscriberHierarchy).length === 0 &&
        subscriberHierarchyError !== "" && (
          <BorderedCard className="productLoaderErrorCard">
            <ResponseMessage
              error
              heading={"Warning"}
              imageClass="productErrorImage"
              headingClass="productErrorHeading"
              messageClass="productErrorMessage"
              message={subscriberHierarchyError}
            />
          </BorderedCard>
        )}
    </>
  );

  /****************************MY ACCOUNT SECTION**************************************************************/
  const myAccountSection = (
    <>
      <Row id="dashboard-post-105" className="dashboard-account">
        <Col id="dashboard-post-106" sm={12}>
          <h2 id="dashboard-post-107" className="dashboard-typo font-style">
            My account
          </h2>
        </Col>
      </Row>
      <Row>
        <Col id="dashboard-post-108" xs={12} sm={12} md={6} className="mb-3">
          <MyAccountCard id="myProfile" {...myAccount[0]} />
        </Col>
        {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_BROADBAND ? "" : 
        <Col xs={12} sm={12} md={6}>
          <MyAccountCard
            id="bolt-on"
            {...myAccount[1]}
            //onClick={openBoltOnDrawer}
          />
        </Col>
}
      </Row>
    </>
  );

  /*********************DISCOVER SECTION************************************************************/

  return (
    <>
      <Container id="dashboard-post" className="pt-3">
        {props.type === "postpaid" ? postpaid : ""}
        {props.type === "prepaid" ? prepaid : ""}
        {myAccountSection}
        {<Discovery screen={screenResolution.width} />}
      </Container>
      <Drawer
        body={<UpdateProfile closeDrawer={closeProfileDrawer} />}
        isOpen={isOpenProfileDrawer}
        closeDrawer={closeProfileDrawer}
      />
      <Drawer
        body={
          <BoltOn isAccLevel={isAccLevel} handleClose={closeBoltOnDrawer} />
        }
        isOpen={isOpenBoltOnDrawer}
        closeDrawer={closeBoltOnDrawer}
      />
      <Drawer
        body={<AddData handleClose={closeDataDrawer} />}
        isOpen={isOpenDataDrawer}
        closeDrawer={closeDataDrawer}
      />
    </>
  );
};

export default Dashboard;
