import React, { useEffect, useState } from "react";
import BorderedCard from "../../common/components/BorderedCard";
import HeaderBar from "../../common/components/HeaderBar";
import { AlertMessage, dateFormatter, breakDownDateFormatter, getCurrency, PreLoader, ResponseMessage, SimpleButton, ResponseModal } from "../../common";
import phone from "../../../Assets/images/billing/phone.svg";
import { useDispatch, useSelector } from "react-redux"
import { getCurrentInovieAmount, getCurrentMonthBill, payBill } from "../../../redux/actions/myBillsAction";
import isEmpty from "../../common/utils/isEmpty";
import { divideByCurrencey, getMobileNumber } from "../../common/utils";


const BillBreakDown = () => {
  const dispatch = useDispatch();
  const { optimusDetails } = useSelector(state => state.auth);
  const [payButtonDisabeld, setPayButtonDisabeld] = useState(false)
  const { billPaymentSuccess, billPaymentError, currentBillAmmount, currentBillAmmountError } = useSelector(state => state.myBillsAllReducers)
  const { breakDownData, errorBreakdown } = useSelector(state => state.myBillsAllReducers);
  const [showbillPayModal, setShowbillPayModal] = useState(false)
  const { si, domain, si_lob, cust_ac_no } = optimusDetails

  const payBillAmount = (invoiceNo) => {
    if (!isEmpty(currentBillAmmount) && currentBillAmmount.customerBill && currentBillAmmount.customerBill.partyBill && currentBillAmmount.customerBill.partyBill.dueAmount > 0 && currentBillAmmountError === "") {
      setPayButtonDisabeld(true)
      dispatch(payBill(si, domain, cust_ac_no, invoiceNo, si_lob))
    }
  }


  useEffect(() => {
    if (isEmpty(breakDownData) && errorBreakdown === "") {
      dispatch(getCurrentMonthBill(domain, si, si_lob, cust_ac_no))
    }
    if (isEmpty(currentBillAmmount) && currentBillAmmountError === "") {
      dispatch(getCurrentInovieAmount(domain, si_lob, si))
    }
  }, [currentBillAmmount]);

  useEffect(() => {
    if (billPaymentSuccess && billPaymentSuccess.url) {
      window.location.replace(billPaymentSuccess.url)
    }
    if (billPaymentError) {
      setPayButtonDisabeld(false)
      setShowbillPayModal(true)
    }

  }, [billPaymentSuccess, billPaymentError, dispatch]);


  let thisMonthCharges = {}
  let accountSummary = {}
  let account_Detail = {}
  if (
    !isEmpty(breakDownData && breakDownData.data && breakDownData.data.BREAKUP_ACCOUNT_DETAILS)
    &&
    !isEmpty(breakDownData.data.accountSummary)
  ) {
    thisMonthCharges = breakDownData && breakDownData.data && breakDownData.data.accountSummary && breakDownData.data.accountSummary.thisMonthCharges
    accountSummary = breakDownData && breakDownData.data && breakDownData.data.accountSummary && breakDownData.data.accountSummary
    account_Detail = breakDownData && breakDownData.data && breakDownData.data.BREAKUP_ACCOUNT_DETAILS
  }



  return (

    <div data-testid="bill_breakdown_prep" id="bill_breakdown_main_1" className="bill_breakdown_main_div">
      {!isEmpty(thisMonthCharges) && !isEmpty(accountSummary) && !isEmpty(account_Detail) && errorBreakdown === "" &&
        <>
          <HeaderBar>
            <div id="bill_breakdown_main_2" className="bill_breakdown_header d-flex justify-content-between align-items-center">
              <div id="bill_breakdown_main_3" className="bill_date">
                <h3 id="bill_breakdown_main_4" className="mediumText font-weight-bold">
                  Bill summary
                </h3>
                {/* BILL DATE */}
                <p id="bill_breakdown_main_5" className="mb-1 lightText" style={{ color: "#666666" }}>
                  {dateFormatter(breakDownDateFormatter(account_Detail.BILL_FROM_DT))}
                  <b> - </b>
                  {dateFormatter(breakDownDateFormatter(account_Detail.BILL_TO_DT))}
                </p>
                {/* DUE DATE */}
                <p className="lightText" style={{ color: "#666666" }}>
                  {account_Detail.DUE_DATE ? 'Due date on' : ""}
                  {" "}{dateFormatter(breakDownDateFormatter(account_Detail.DUE_DATE))}
                </p>
              </div>
              <div id="bill_breakdown_main_6" className="bill_breakdown_pay">
                <h3
                  id="bill_breakdown_main_8"
                  className="mediumText font-weight-bold"
                >
                  {getCurrency(account_Detail.DUE_AMOUNT)}
                </h3>
                {account_Detail.DUE_AMOUNT && !isEmpty(currentBillAmmount) &&
                  <SimpleButton
                    maxWidth
                    variant="primary"
                    data-testid="open-drawer"
                    disabled={payButtonDisabeld}
                    onClick={() => payBillAmount(account_Detail.INVOICE_NO)}
                  >
                    {!isEmpty(currentBillAmmount) && currentBillAmmount.customerBill && currentBillAmmount.customerBill.partyBill && divideByCurrencey(currentBillAmmount.customerBill.partyBill.dueAmount) > 0 && currentBillAmmountError === "" ? 'Pay now' : 'Paid'}
                  </SimpleButton>
                }
              </div>
            </div>
          </HeaderBar>
          <div id="bill_breakdown_main_10" className="breakdown_card">
            <div>
              <div id="bill_breakdown_main_11" className="d-flex justify-content-between mb-4 mt-5 mid_header_part">

                <div id="bill_breakdown_main_15">
                  <p id="bill_breakdown_main_16" className="smallText">Bill number</p>
                  <p id="bill_breakdown_main_17" className="lightText">{account_Detail.INVOICE_NO ? account_Detail.INVOICE_NO : ""}</p>
                </div>
              </div>
            </div>

            <BorderedCard>
              <div id="bill_breakdown_main_25" className="breakdown_card_phone">
                <img id="bill_breakdown_main_26" src={phone} alt="phnimg" />
                <label id="bill_breakdown_main_27" className="card-name smallText">{account_Detail.NAME ? account_Detail.NAME : ""}</label>
                <label id="bill_breakdown_main_28" className="card-number smallLightText">{account_Detail.MOBILE_NO ? getMobileNumber(account_Detail.MOBILE_NO) : ""}</label>
              </div>
              <hr id="bill_breakdown_main_29" />
              <div id="bill_breakdown_main_30" className="d-flex justify-content-between">
                <p id="bill_breakdown_main_31" className="lightText">Monthly rental</p>
                <p id="bill_breakdown_main_32" className="lightText">{getCurrency(thisMonthCharges.monthlyRental)}</p>
              </div>
              <hr id="bill_breakdown_main_301" />
              <div id="bill_breakdown_main_501" className="d-flex justify-content-between">
                <p id="bill_breakdown_main_502" className="lightText">*Home usage</p>
                <p id="bill_breakdown_main_503" className="lightText">{getCurrency(thisMonthCharges.homeUsage)}</p>
              </div>
              <hr id="bill_breakdown_main_302" />
              <div id="bill_breakdown_main_504" className="d-flex justify-content-between">
                <p id="bill_breakdown_main_505" className="lightText">*Roaming Usage</p>
                <p id="bill_breakdown_main_506" className="lightText">{getCurrency(thisMonthCharges.roamingUsage)}</p>
              </div>
              <hr id="bill_breakdown_main_303" />
              <div id="bill_breakdown_main_34" className="d-flex justify-content-between">
                <p id="bill_breakdown_main_35" className="lightText">Previous balance</p>
                <p id="bill_breakdown_main_36" className="lightText">{getCurrency(accountSummary.previousBalance)}</p>
              </div>
              <hr id="bill_breakdown_main_37" />
              <div id="bill_breakdown_main_38" className="d-flex justify-content-between">
                <p id="bill_breakdown_main_39" className="lightText">Payments</p>
                <p id="bill_breakdown_main_40" className="lightText">{getCurrency(accountSummary.payments)}</p>
              </div>
              <hr id="bill_breakdown_main_41" />
              <div id="bill_breakdown_main_42" className="d-flex justify-content-between">
                <p id="bill_breakdown_main_43" className="lightText">Adjustments</p>
                <p id="bill_breakdown_main_44" className="lightText">{getCurrency(accountSummary.adjustments)}</p>
              </div>
              <hr id="bill_breakdown_main_45" />
              <div id="bill_breakdown_main_46" className="d-flex justify-content-between">
                <p id="bill_breakdown_main_47" className="lightText">Taxes</p>
                <p id="bill_breakdown_main_48" className="lightText">{getCurrency(thisMonthCharges.taxes)}</p>
              </div>
              <hr id="bill_breakdown_main_49" />
              <div id="bill_breakdown_main_50" className="d-flex justify-content-between">
                <p id="bill_breakdown_main_51" className="lightText">One time charges</p>
                <p id="bill_breakdown_main_52" className="lightText">{getCurrency(thisMonthCharges.oneTimeCharges)}</p>
              </div>
              <hr id="bill_breakdown_main_530" />
              <div id="bill_breakdown_main_511" className="d-flex justify-content-between">
                <p id="bill_breakdown_main_512" className="lightText">Discount</p>
                <p id="bill_breakdown_main_513" className="lightText">{getCurrency(thisMonthCharges.discounts)}</p>
              </div>
              <hr id="bill_breakdown_main_53" />
              <div id="bill_breakdown_main_58" className="d-flex justify-content-between">
                <p id="bill_breakdown_main_59" className="smallText">Total</p>
                <p id="bill_breakdown_main_60" className="smallText">{getCurrency(account_Detail.DUE_AMOUNT)}</p>
              </div>
            </BorderedCard>
            <p id="bill_breakdown_main_502" className="text-muted mt-2">* Out of bundle charges</p>
          </div>
        </>
      }
      {
        isEmpty(breakDownData)
        &&
        errorBreakdown === ""
        &&
        <div className="breakdown_card p-5 response-bill-break">
          <BorderedCard >
            <PreLoader />
          </BorderedCard>
        </div>
      }
      {
        isEmpty(breakDownData)
        &&
        errorBreakdown !== ""
        &&
        <div className="breakdown_card response-bill-break">
          <BorderedCard >
            <ResponseMessage
              error
              heading={errorBreakdown}
            />
          </BorderedCard>
        </div>
      }
      <ResponseModal show={showbillPayModal}
        handleClose={() => {
          setPayButtonDisabeld(false)
          dispatch({ type: "BILL_PAYMENT_RESET" })
          setShowbillPayModal(false)
        }}>
        <ResponseMessage
          error
          heading={billPaymentError}
          button={{ label: "Try Again", onClick: () => payBillAmount(account_Detail.INVOICE_NO) }}
        />
      </ResponseModal>
    </div>
  );
};

export default BillBreakDown;
