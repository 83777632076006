import React from 'react'
import arrow_up from '../../../Assets/images/billing/arrow_up.svg'
import arrow_down from '../../../Assets/images/billing/arrow_down.svg'

const Acoordian = ({ children, title, active, setActive, img, name }) => {

    //this function will open/close the drawer
    const openAccordian = (data) => {

        if (active === data) {
            setActive("")
        } else {
            setActive(data)
        }
    }

    return (
        <div data-testid="custom_accordian_test" className="custom_accordian_myPlan_Usage mb-4">
            <div className="accordingHeading">
                <div className="container d-flex justify-content-between p-0">
                    <div>
                        <img src={img} alt="doxlogo" /><span className="smallText">{name}</span>
                    </div>
                    <img onClick={() => openAccordian(title)}
                        data-testid="accordian_button"
                        className="accordian_img cursor"
                        //arrow up and down image change after closing/opening the drawer
                        src={active === title ? arrow_up : arrow_down}
                        alt="arrow" />
                </div>
            </div>
            <div className={(active === title ? "accordian_content_show" : "accordian_content")}>
                <div className="content_padding">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default Acoordian
