import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '../../common'
import { postpaid_prefix, b2b_prefix } from '../../envConfig'
import { useSelector } from 'react-redux';

function Payment() {
    const { optimusDetails } = useSelector(state => state.auth);
    const { domain } = optimusDetails;
    const query = useQuery()
    const h = useHistory()
    const paymentQuery = query.get('token')
    console.log(domain, "domain")
    useEffect(() => {
        if (paymentQuery) {
           if(domain === "B2C"){
            return h.push({
                pathname: `/${postpaid_prefix}/myBill`,
                state: { token: paymentQuery }
            })
           }
           else{
            return h.push({
                pathname: `/${b2b_prefix}`,
                state: { token: paymentQuery }
            })
           }
            
        }
        else {
            if(domain === "B2C"){
            return h.push(`/${postpaid_prefix}/myBill`)
            }
            else{
                return h.push(`/${b2b_prefix}`)
            }
        }
    }, [])
    return (
        <div>Payment</div>
    )
}

export default Payment