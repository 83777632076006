import React, { useState, useEffect } from 'react'
import { Drawer, ResponseModal, PreLoader, ResponseMessage } from '../../../../components/common';
import SimpleButton from '../../../../components/common/form/SimpleButton';
import { downloadIcon } from '../../../Icons';
import TableComp from '../../common/TableComp'
import BillBreakdownB2b from '../components/BillBreakdownB2b';
import { apiMyBill, apiErrorCode, lobSubLob } from '../../../../redux/helpers/ApiConstant'
import { axios } from '../../../services/axiosService';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerInvoiceSummary } from '../../../../redux/actions/myBillsAction';
import isEmpty from '../../../common/utils/isEmpty';
import { dateFormatter, divideByCurrencey, getCurrency } from '../../../common/utils';
import BorderedCard from '../../../common/components/BorderedCard';
const { downloadExcel, downloadPreviousBill } = apiMyBill
const { NO_INTERNET, SOMETHING_WRONG } = apiErrorCode

const BillingSectionB2b = () => {
    const { userInvoiceSummary, errorInvoiceSummary } = useSelector(state => state.myBillsAllReducers);
    const { optimusDetails } = useSelector(state => state.auth);
    const [isOpenDrawer, setIsOpenDrawer] = useState({ state: false, data: "" })
    const [downloadExcelError, setDownloadExcelError] = useState("")
    const [showResponseModal, setShowResponseModal] = useState(false)
    const [rowsData, setRowsData] = useState([])
    const dispatch = useDispatch()
    const [showDownloadErrorMoadal, setShowDownloadErrorMoadal] = useState(false)

    const downloadPdf = (referenceNo, accountNumber) => {
        setShowDownloadErrorMoadal(false)
        axios({
            url: `${downloadPreviousBill}/${referenceNo}?accountNumber=${accountNumber}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${referenceNo}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
            .catch(_err => {
                setShowDownloadErrorMoadal(true)
            })
    }

    const columns = [{
        dataField: 'invoice',
        text: 'Invoice no'
    },
    {
        dataField: "billingDate",
        text: "Billing date"
    },
    {
        dataField: "dueData",
        text: "Due date"
    },
    {
        dataField: "previous",
        text: "Previous amount",
        formatter: (cell) => getCurrency(divideByCurrencey(cell))
    },
    {
        dataField: "dueAmount",
        text: "Total amount",
        formatter: (cell) => getCurrency(divideByCurrencey(cell))
    },

    {
        dataField: "action",
        text: "View",
        isDummyField: true,
        formatter: (cell, row) => <SimpleButton onClick={(e) => { openBreakdown(row.invoice, e) }} variant="outline-secondary">View</SimpleButton>,
    },
    {
        dataField: "pdf",
        text: "Pdf",
        isDummyField: true,
        formatter: (cell, row) => <img title={`${row.invoice} download pdf`} onClick={(e) => downloadPdf(row.invoice, optimusDetails.payto_bill_ac)} className="cursor" src={downloadIcon} alt="download" />,
    }
    ];

    const isCloseDrawer = () => setIsOpenDrawer({ state: false, data: "" })
    const handleCloseResponseModal = () => {
        setShowResponseModal(false)
        setDownloadExcelError("")
    }

    const openBreakdown = (invoice, e) => {
        setIsOpenDrawer({ state: true, data: invoice })
        e.stopPropagation();
    }
    const { si, si_lob, domain } = optimusDetails
    const downloadBillExcellB2b = () => {
        let invoices = rowsData.map(item => item.invoice)
        // eslint-disable-next-line no-unused-vars
        invoices = invoices.join()
        const config = {
            url: downloadExcel,
            method: 'GET',
            responseType: 'blob', // important
            params: {
                msisdn: si,
                domain,
                subLob: lobSubLob[si_lob],
                invoices
            }
        }
        axios(config)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${'bill'}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                setShowResponseModal(true)
                if (!err.response) {
                    setDownloadExcelError(NO_INTERNET)
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    setDownloadExcelError(err.response.data.errors[0].description);
                }
                else {
                    setDownloadExcelError(SOMETHING_WRONG)
                }
            })

    }

    let newTableArr = []
    if (userInvoiceSummary && userInvoiceSummary.data && !isEmpty(userInvoiceSummary.data.invoiceDetails)) {
        userInvoiceSummary.data.invoiceDetails.map((data) => {
            newTableArr.push({
                billingDate: dateFormatter(data.customerBillingCycle.billingDate),
                dueData: dateFormatter(data.customerBillingCycle.paymentDueDate),
                dueAmount: data.customerBillingCycle.partyBill.dueAmount,
                invoice: data.customerPayment.partyPayment.referenceNumber,
                previous: data.customerBillingCycle.partyBill.carriedForward
            })
        })
    }


    useEffect(() => {
        dispatch(getCustomerInvoiceSummary(si, domain, si_lob))
    }, [si, domain, si_lob, dispatch]);



    const download = (

        <h3
            onClick={rowsData.length === 0 ? null : downloadBillExcellB2b}
            style={{ color: "#e60000" }}
            className={`tableHeader ${rowsData.length === 0 ? 'disabled-text' : ""} cursor`}
        >Download excel
            <img className="downldImg" src={downloadIcon} alt="img" />
        </h3>
    )

    return (
        <div id="billing_section_b2b" className="main_component_container">
            {newTableArr && newTableArr.length > 0 && errorInvoiceSummary === ""
                ?
                <>
                    <TableComp keyField="invoice" columns={columns} tableData={newTableArr} heading="Pay & download" download={download} check={false} rowsData={rowsData} handeleSelection={setRowsData} />
                    <Drawer
                        body={<BillBreakdownB2b invoice={isOpenDrawer.data} closeDrawer={isCloseDrawer} />}
                        isOpen={isOpenDrawer.state}
                        closeDrawer={isCloseDrawer}
                    />
                    <ResponseModal id="rs-22" show={showResponseModal} handleClose={handleCloseResponseModal} data-testid="response-modal">
                        {downloadExcelError ?
                            <ResponseMessage
                                id="rs-22s32"
                                error
                                heading={"Warning"}
                                message={downloadExcelError}
                                button={{ label: "Close", onClick: handleCloseResponseModal }}
                            />
                            :
                            <PreLoader />
                        }
                    </ResponseModal>
                </>
                :
                errorInvoiceSummary !== ""
                    ?
                    <BorderedCard id="rsponsmsg">
                        <ResponseMessage
                            id="rs-22s32"
                            error
                            heading={errorInvoiceSummary}
                        />
                    </BorderedCard>

                    :

                    userInvoiceSummary && userInvoiceSummary.data && isEmpty(userInvoiceSummary.data.invoiceDetails)
                        ?
                        <BorderedCard>
                            <ResponseMessage
                                id="rs-22s32"
                                error
                                heading="Data not found"
                            />
                        </BorderedCard>

                        :
                        <BorderedCard>
                            <PreLoader />
                        </BorderedCard>
            }

            <ResponseModal show={showDownloadErrorMoadal} handleClose={() => setShowDownloadErrorMoadal(false)}>
                <ResponseMessage
                    error
                    heading="Bill is not generated"
                    button={{ label: "Try Again", onClick: downloadPdf }}
                />
            </ResponseModal>

        </div >
    )
}

export default BillingSectionB2b
