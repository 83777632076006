import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import TotalOutstanding from "./components/totalOutstanding";
import HelpSupport from "../../dashboard/components/helpSupport";
import Addons from "../../dashboard/components/addons";
import TotalMobileNumber from "./components/totalMobileNumber";
import MyProductCard from "../../dashboard/components/myProductCard";
import BorderedCard from "../../common/components/BorderedCard";
import { PreLoader, ResponseMessage, Drawer, } from "../../common";
import { getScreenResolution } from "../../common/utils";
import { Discovery } from '../../common/dashboard/Discovery'
import BoltOn from "../../common/boltOn/BoltOn";
import B2bProfile from "../my profile/B2bProfile";
import profileIcone from "../../../Assets/images/dashboard/profileIcone.png";
import addContactIcone from "../../../Assets/images/dashboard/addContact.svg";
import MyAccountCard from "../../dashboard/components/accountCard";
import { batch, useDispatch, useSelector } from 'react-redux'
import { getSrHistory } from "../../../redux/actions/commonAction";
import { fetchActivePlan, getAccLevelBolton } from "../../../redux/actions/myPlanAction";
import { getCurrentMonthBill } from "../../../redux/actions/myBillsAction";
import { getSubscriberHierarchyB2B } from "../../../redux/actions/dashboardAction";
import { useHistory } from "react-router-dom";
import isEmpty from "../../common/utils/isEmpty";
import { b2b_prefix } from "../../envConfig";
import useActivePlanId from "../../MyPlan&Usage/hooks/useActivePlanId";


const B2bDashboard = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const prefix = `/${b2b_prefix}`
    const { activePlanData } = useSelector(state => state.planReducer);
    const { optimusDetails } = useSelector(state => state.auth);
    const { si, si_lob, domain, cust_ac_no, payto_bill_ac, cust_class, circle_id } = optimusDetails
    const msisdn = optimusDetails.si ? optimusDetails.si : ""
    const sublob = optimusDetails.si_lob ? optimusDetails.si_lob : ""
    const { localBoltOnPlans } = useSelector(state => state.planReducer);
    const {
        breakDownData,
        errorBreakdown
    } = useSelector(state => state.myBillsAllReducers);
    const { subscriberHierarchyB2B, subscriberHierarchyErrorB2B } = useSelector(state => state.dashboard);
    const [isOpenProfileDrawer, setIsOpenProfileDrawer] = useState(false)
    const [isOpenBoltOnDrawer, setIsOpenBoltOnDrawer] = useState(false)
    const openProfileDrawer = () => setIsOpenProfileDrawer(true)
    const closeProfileDrawer = () => setIsOpenProfileDrawer(false)
    //const closeBoltOnDrawer = () => setIsOpenBoltOnDrawer(false)
    const [screenResolution, setResolution] = useState({ height: "768", width: "1366" })
    const planId = useActivePlanId(activePlanData)
    const [isAccLevel, setIsAccLevel] = useState(false)

    const closeBoltOnDrawer = () => {
        dispatch({ type: "BOLTON_PLAN_RESET" });
        dispatch({ type: "CHANGE_ORDER_RESET" });
        setIsOpenBoltOnDrawer(false);
        setIsAccLevel(false);
      };

    const openAcclevelBoltonDrawer = (isAccLevelB = false) => {
        dispatch(fetchActivePlan(si_lob, domain, si, cust_class))
        if (isAccLevelB) {
            setIsAccLevel(true)
        }
        setIsOpenBoltOnDrawer(true)
        if (planId !== "" && !isEmpty(planId)) {
            if (isAccLevelB) {
                dispatch(getAccLevelBolton(planId, msisdn, domain, sublob, circle_id, cust_class));
            }
        }
    }
    const myAccount = [
        {
            title: "My profile",
            content: "Update profile",
            icone: profileIcone,
            onClick: openProfileDrawer
        },
        {
            title: "My bolt on",
            content: "Manage bolt on at account level",
            icone: addContactIcone,
            onClick: openAcclevelBoltonDrawer
        },
    ];

    useEffect(() => {
        batch(() => {
            dispatch(fetchActivePlan(si_lob, domain, si, cust_class));
            dispatch(getCurrentMonthBill(domain, si, si_lob, cust_ac_no));
            if (isEmpty(subscriberHierarchyB2B) && subscriberHierarchyErrorB2B === "") {
                dispatch(getSubscriberHierarchyB2B(payto_bill_ac));
            }

            dispatch(getSrHistory(si_lob, domain, si));
        })
    }, [dispatch, domain, cust_ac_no, si, si_lob]);

    let getResolution = () => {
        setResolution(getScreenResolution());
    }

    useEffect(() => {
        //get screen resolution
        getResolution();
        window.addEventListener('resize', getResolution);

        return () => {
            window.removeEventListener('resize', getResolution);
        };
    }, []);


    return (
        <>
            <Container id="dashboard-B2b" className="pt-3">
                {screenResolution.width >= 769 ?
                    <>
                        <Row id="dashboard-B2b-4">
                            <Col id="dashboard-B2b-5" className="mb-3" sm={6}>
                                <TotalOutstanding breakDownData={breakDownData} erorBreak={errorBreakdown} />
                            </Col>
                            <Col id="dashboard-pre-26" className={screenResolution.width >= 769 ? "ps-1" : ""} sm={6}>
                                <TotalMobileNumber />
                                <Row id="dashboard-pre-58" >
                                    <Col id="dashboard-pre-59" sm={6}>
                                        <HelpSupport />
                                    </Col>
                                    <Col id="dashboard-pre-66" className={screenResolution.width >= 769 ? "ps-1" : ""} sm={6}>
                                        <Addons onClick={() => history.push(`${prefix}/MSISDN`)} type="postpaid" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                    :
                    <>
                        <Row id="dashboard-post-71">
                            <Col sm={12} md={12}> <TotalMobileNumber /></Col>
                        </Row>
                        <Row id="dashboard-post-86">
                            <Col id="dashboard-B2b-5" className="mb-3" xs={6}>
                                <TotalOutstanding breakDownData={breakDownData} erorBreak={errorBreakdown} />
                            </Col>
                            <Col id="dashboard-post-94" className={screenResolution.width >= 769 ? "" : "ps-0"} xs={6}>
                                <Row id="dashboard-post-95">
                                    <Col id="dashboard-post-96" sm={12}>
                                        <HelpSupport />
                                    </Col>
                                </Row>
                                <Row id="dashboard-post-97">
                                    <Col id="dashboard-post-98" sm={12}>
                                        <Addons onClick={() => history.push(`${prefix}/MSISDN`)} type="postpaid" />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                }
                <Row id="dashboard-pre-99" className="dashboard-account" >
                    <Col id="dashboard-pre-100" sm={12}>
                        <h3 id="dashboard-pre-101" className="dashboard-typo font-style">
                            My products
                        </h3>
                    </Col>
                </Row>
                <>
                    {subscriberHierarchyB2B &&
                        <Row id="dashboard-pre-102">

                            {subscriberHierarchyB2B.data && subscriberHierarchyB2B.data.listOfHierarchyListB2BResponse && subscriberHierarchyB2B.data.listOfHierarchyListB2BResponse.length > 0 &&
                                <div id="dashboard-post-102" className="scroll-gride">
                                    <div id="dashboard-post-103" className="hs full" style={{ "--total": `${subscriberHierarchyB2B.data.listOfHierarchyListB2BResponse.length}` }}>
                                        {subscriberHierarchyB2B.data.listOfHierarchyListB2BResponse.map((data, index) => {
                                            return (
                                                <div id={`dashboard-my-product-104-${index}`} className="item cursor" key={index} onClick={() => history.push(`${prefix}/MSISDN`)}>
                                                    <MyProductCard id={`dashboard-myProduct-${index}`} number={data.msisdn} title="Number" />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            }
                            {
                                subscriberHierarchyB2B.data && subscriberHierarchyB2B.data.listOfHierarchyListB2BResponse && subscriberHierarchyB2B.data.listOfHierarchyListB2BResponse.length === 0 && subscriberHierarchyErrorB2B === "" &&
                                <BorderedCard className="productLoaderErrorCard">
                                    <ResponseMessage error heading={"Warning"} imageClass="productErrorImage" headingClass="productErrorHeading" messageClass="productErrorMessage" message="Data not found" />
                                </BorderedCard>
                            }
                            {
                                Object.keys(subscriberHierarchyB2B).length === 0 && subscriberHierarchyErrorB2B === "" &&
                                <BorderedCard className="productLoaderErrorCard">
                                    <div className="m-t-13">
                                        <PreLoader />
                                    </div>
                                </BorderedCard>
                            }
                            {
                                Object.keys(subscriberHierarchyB2B).length === 0 && subscriberHierarchyErrorB2B !== "" &&
                                <BorderedCard className="productLoaderErrorCard">
                                    <ResponseMessage error heading={subscriberHierarchyErrorB2B} imageClass="productErrorImage" headingClass="productErrorHeading" messageClass="productErrorMessage" />
                                </BorderedCard>
                            }
                        </Row>
                    }
                </>
                {/* <Row className="sr_history">
                    <SrHistory srData={srHistory} errorSr={errorSrHistory} />
                </Row> */}
                <Row id="dashboard-post-105" className="dashboard-account">
                    <Col id="dashboard-post-106" sm={12}>
                        <h2 id="dashboard-post-107" className="dashboard-typo font-style">My account</h2>
                    </Col>
                </Row>
                <Row>
                    <Col id="dashboard-post-108" xs={12} sm={12} md={6} className="mb-3">
                        <MyAccountCard id="myProfile" {...myAccount[0]} />
                    </Col>
                    <Col xs={12} sm={12} md={6}  style={{display: "none"}}>
                        <MyAccountCard
                            id="bolt-on"
                            {...myAccount[1]}
                        />
                    </Col>
                </Row>
                <Discovery screen={screenResolution.width} />
            </Container>

            <Drawer
                body={<B2bProfile closeDrawer={closeProfileDrawer} />}
                isOpen={isOpenProfileDrawer}
                closeDrawer={closeProfileDrawer}
            />
            <Drawer
                body={<BoltOn isAccLevel handleClose={closeBoltOnDrawer} />}
                isOpen={isOpenBoltOnDrawer}
                closeDrawer={closeBoltOnDrawer}
            />
        </>
    )
}

export default B2bDashboard;