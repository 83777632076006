import MainLogin from './MainLogin'
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useAuth from './useAuth';
import axios from 'axios';

const Login = () => {
    const history = useHistory()
    const { isAuth, userType } = useAuth()
    let location = useLocation();
    const [country, setcountry] = useState("Jersey")
    const [countryCode, setCountryCode] = useState("JE")

    let { from } = location.state || { from: { pathname: "/" } };

    const redirectedUrl = `${from.pathname}${from.search ? from.search : ""}`
    const getLocation = () => {
        axios.get('https://ipwho.is')
            .then((res) => {
                setcountry(res.data.country)
                if(res.data.country_code === "GG"){
                   setCountryCode("GG")
                }
                else{
                    setCountryCode("JE")
                }
            })
            .catch(err => {
            })
    }

    useEffect(() => {
        getLocation()
    }, [])

    useEffect(() => {
        if (!isAuth) {
            document.title = "Airtel-Vodafone Selfcare | Login"
        }
        if (isAuth) {
            if (redirectedUrl && redirectedUrl.length > 3 && redirectedUrl.includes(userType)) {
                history.push(redirectedUrl)
            }
            else {
                history.push(`/${userType}`)
            }

        }
    }, [history, isAuth, userType])
    return (
        <div >
            {!isAuth &&
                <>
                    <div className="login-drawer-backdrop" />
                    <div className="bg-picture" />

                    <div className="login-drawer" >
                        {/* <div className="login-drawer-header" >
                            <img className="login-drawer-close-button" src={CloseButton} alt="button" />
                        </div> */}
                        <div className="login-drawer-body">
                            <MainLogin country={country} countryCode={countryCode} />
                        </div>
                    </div>
                </>

            }
        </div>
    )
}

export default Login

