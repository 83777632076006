import React, { useEffect, useState } from "react";
import CurrentBillCard from "../components/CurrentBillCard";
import PreviousBills from "../components/PreviousBills";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerInvoiceSummary, dublicateInvoiceStatus, getCurrentInovieAmount, getCurrentMonthBill, validatePayment } from "../../../redux/actions/myBillsAction";
import UpdateProfile from "../../common/myProfile/UpdateProfile";
import { ResponseModal, PreLoader, ResponseMessage, Drawer } from "../../common";
import isEmpty from "../../common/utils/isEmpty";
import RequestEbillPassword from "../components/RequestEbillPassword";
import BillingChartCard from "../components/BillingChartCard";
import { useHistory } from "react-router-dom";
import { fetchUserProfileDetail } from "../../../redux/actions/commonAction";
import PaymentResponse from "../components/PaymentResponse";
import { payment } from "../helpers/constant";
import { SuccessIcon, ErrorIcon } from "../../Icons";

const BillingSection = () => {
  const dispatch = useDispatch();
  const { optimusDetails } = useSelector(state => state.auth)
  const [ebillSuccessMessage, setEbillSuccessMessage] = useState("")
  const [ebillErrorMessage, setEbillErrorMessage] = useState(false)
  const [ebillInfoMessage, setEbillInfoMessage] = useState(false);
  const [isShowProfileDrawer, setIsShowProfileDrawer] = useState(false)
  const [size, setSize] = useState(3);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const { si, si_lob, domain, email, cust_ac_no } = optimusDetails
  const [paymentValidationSuccess, setPaymentValidationSuccess] = useState({})
  const [paymentValidationError, setPaymentValidationError] = useState("")
  const [customerEmail, setCustomerEmail] = useState("")
  const { location } = useHistory()

  const { profileData } = useSelector(state => state.common)
  const {
    userInvoiceSummary,
    dublicateInvoiceSuccess,
    dublicateInvoiceError,
    errorInvoiceSummary,
    currentBillAmmount,
    currentBillAmmountError
  } = useSelector(state => state.myBillsAllReducers);

  const {
    breakDownData,
    errorBreakdown
  } = useSelector(state => state.myBillsAllReducers);


  const handleCloseResponseModal = () => {
    dispatch(dublicateInvoiceStatus({ success: "", msg: "" }));
    setShowResponseModal(false)
    setEbillInfoMessage(false);
    setEbillSuccessMessage("")
    setEbillErrorMessage("")
  }
  const closeProfileDrawer = () => {
    if (si && si_lob && domain) {
      dispatch(fetchUserProfileDetail(si_lob, domain, si))
    }
    setIsShowProfileDrawer(false)
    setTimeout(() => {
      document.body.removeAttribute('style')
    },
      1000)
  }

  const showInfoMessage = () => {
    setShowResponseModal(true);
    setEbillInfoMessage(true);
  }

  const openProfileDrawer = () => {
    handleCloseResponseModal()
    setIsShowProfileDrawer(true)
  }

  const showMore = () => {
    setSize(userInvoiceSummary.data.invoiceDetails.length);
  }

  useEffect(() => {
    if (si && si_lob && domain) {
      dispatch(fetchUserProfileDetail(si_lob, domain, si))
    }
  }, [])

  useEffect(() => {
    if (dublicateInvoiceSuccess.data) {
      setShowResponseModal(dublicateInvoiceSuccess.data ? true : false);
      setEbillSuccessMessage(dublicateInvoiceSuccess.data ? dublicateInvoiceSuccess.data : "");
    }
    if (dublicateInvoiceError) {
      setShowResponseModal(dublicateInvoiceError ? true : false);
      setEbillErrorMessage(dublicateInvoiceError ? dublicateInvoiceError : "");
    }

    if (isEmpty(currentBillAmmount) && currentBillAmmountError === "") {
      dispatch(getCurrentInovieAmount(domain, si_lob, si))
    }

    if (isEmpty(userInvoiceSummary) && errorInvoiceSummary === "") {
      dispatch(getCustomerInvoiceSummary(si, domain, si_lob))
    }

    if (isEmpty(breakDownData) && errorBreakdown === "") {
      dispatch(getCurrentMonthBill(domain, si, si_lob, cust_ac_no))
    }
    if (profileData && !isEmpty(profileData)) {
      setCustomerEmail(profileData.email)
    }
  }, [dublicateInvoiceSuccess, dublicateInvoiceError, profileData]);

  ///get the token for payment validation//

  const paymentValidate = () => {
    setShowPaymentModal(true)
    validatePayment(location.state.token)
      .then(res => {
        setPaymentValidationError("")
        if (res && res.data && res.data.data) {
          setPaymentValidationSuccess(res.data.data)
        }
      })
      .catch(err => {
        setPaymentValidationSuccess({})
        if (!err.response) {
          setPaymentValidationError("Something went wrong")
        }
        else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
          setPaymentValidationError(err.response.data.errors[0].description);
        }
        else {
          setPaymentValidationError("Something went wrong")
        }
      })
  }

  useEffect(() => {
    if (location && location.state && location.state.token) {
      paymentValidate()
    }

  }, [location])


  // unmout and clean all the object if there is an error
  useEffect(() => {
    return () => {
      dispatch({ type: 'PROFILE_DATA_RESET' })
      if (errorBreakdown !== "") {
        dispatch({ type: "RESET_BREAKDOWN_DATA" })
      }
      if (errorInvoiceSummary !== "") {
        dispatch({ type: "RESET_CUSTOMER_INVOICE_SUMMARY" })
      }
    }
  }, [])

  const closePaymentModal = () => {
    setShowPaymentModal(false)
    setPaymentValidationSuccess({})
    setPaymentValidationError("")
    dispatch(getCurrentInovieAmount(domain, si_lob, si))
    dispatch(getCurrentMonthBill(domain, si, si_lob, cust_ac_no))
  }
  return (
    <>
      <BillingChartCard />
      <div id="billing_section_main_1" className="container">
        <h1 id="billing_section_main_2" className="semiMediumText dashboard-account cardContainer">Latest bill</h1>
        <div id="billing_section_main_3" className="cureent_bill_card">
          <CurrentBillCard currentMonthBill={breakDownData} errorBreakdown={errorBreakdown} />
        </div>
        <div id="billing_section_main_4" className="d-flex justify-content-between cardContainer dashboard-account">
          <h2 id="billing_section_main_5" className="semiMediumText ">Previous bills</h2>

          {!isEmpty(userInvoiceSummary)
            &&
            userInvoiceSummary.data
            &&
            userInvoiceSummary.data.invoiceDetails
            &&
            userInvoiceSummary.data.invoiceDetails.length > 0
            &&
            errorInvoiceSummary === ""
            ?
            <RequestEbillPassword customerEmail={customerEmail} />
            :
            ""
          }

        </div>

        {userInvoiceSummary
          &&
          userInvoiceSummary.data
          &&
          !isEmpty(userInvoiceSummary.data.invoiceDetails)
          &&
          errorInvoiceSummary === ""
          ?
          userInvoiceSummary.data.invoiceDetails.slice(0, size).map((data, i) => {
            return (
              <div id="billing_section_main_7" data-testid={`previous_bill_component-${i}`} key={data.customerPayment.partyPayment.referenceNumber} >
                <PreviousBills
                  showInfoMessage={showInfoMessage}
                  amaount={data && data.customerBillingCycle && data.customerBillingCycle.partyBill && data.customerBillingCycle.partyBill.dueAmount}
                  date={data.customerBillingCycle.billingDate}
                  invoiceNumber={data.customerPayment.partyPayment.referenceNumber}
                  billingDate={data.customerBillingCycle && data.customerBillingCycle.billingDate ? data.customerBillingCycle.billingDate : ""}
                  id={i}
                  customerEmail={customerEmail}
                />
              </div>

            )
          })
          :

          userInvoiceSummary.length <= 0
            &&
            errorInvoiceSummary !== ""

            ?
            <div style={{ backgroundColor: "#fff", padding: '80px' }} className="cardContainer mb-5">
              <ResponseMessage error heading={"Data not found"} />
            </div>

            :

            // isEmpty(userInvoiceSummary) //below we are checking userInvoiceSummary
            //   &&
            userInvoiceSummary
              &&
              userInvoiceSummary.data
              &&
              isEmpty(userInvoiceSummary.data.invoiceDetails)
              &&
              errorInvoiceSummary === ""
              ?
              <div style={{ backgroundColor: "#fff", padding: '20px' }} className="cardContainer mb-5">
                <ResponseMessage error heading={"Data not found"} />
              </div>

              :

              errorInvoiceSummary !== ""

                ?

                <div style={{ backgroundColor: "#fff", borderRadius: "10px" }} className="cardContainer mb-5">
                  <ResponseMessage error heading={errorInvoiceSummary} />
                </div>

                :

                <div style={{ backgroundColor: "#fff", padding: '80px' }} className="cardContainer mb-5">
                  <PreLoader data-testid="userInvoiceSummary-loader" />
                </div>
        }


        {!isEmpty(userInvoiceSummary)
          &&
          userInvoiceSummary.data
          &&
          userInvoiceSummary.data.invoiceDetails
          &&
          errorInvoiceSummary === ""
          &&
          userInvoiceSummary.data.invoiceDetails.length > 3
          &&
          size === 3
          ?
          <p id="billing_section_main_9" onClick={showMore} className="lightText mb-5 cursor" style={{ color: "#E60000", textAlign: "center" }}>Show more</p>
          :
          ""
        }


        <ResponseModal id="Bill-section-998899" show={showResponseModal} handleClose={handleCloseResponseModal} data-testid="response-modal">
          {ebillSuccessMessage ?
            <ResponseMessage
              id="Bill-section-2see"
              success heading={"Success"}
              message={<>Email sent successfully on <strong>{customerEmail}</strong>. Please check your email box</>}
              button={{ label: "Close", onClick: handleCloseResponseModal }}
            />
            : ""}
          {ebillErrorMessage ?
            <ResponseMessage
              id="Bill-section-rs-2se"
              error
              heading={ebillErrorMessage}
              button={{ label: "Close", onClick: handleCloseResponseModal }}
            /> : ""}

          {ebillInfoMessage ?
            <ResponseMessage
              id="Bill-section-rs-32r"
              error
              heading={"Warning"}
              message={<>Please add email address to your profile</>}
              button={{ label: "Update profile", onClick: openProfileDrawer }}
            /> : ""}
        </ResponseModal>

        {/* profile side bar code */}
        <Drawer
          id="Bill-section-9s99s"
          body={<UpdateProfile closeDrawer={closeProfileDrawer} />}
          isOpen={isShowProfileDrawer}
          closeDrawer={closeProfileDrawer}
        />
        {/* payment Drwaer */}
        <ResponseModal id="Bill-section-9988999" show={showPaymentModal} handleClose={closePaymentModal} data-testid="response-modal">
          {
            // payment success
            !isEmpty(paymentValidationSuccess) &&
            paymentValidationError === "" &&
            paymentValidationSuccess.status === payment.TRANSACTION_COMPLETED &&
            < PaymentResponse
              icon={SuccessIcon}
              paymentValidationSuccess={paymentValidationSuccess}
              closePaymentModal={closePaymentModal}
              heading="Payment successfull!"
              message="Transaction may take 24 hours to be reflected"
            />
          }
          {
            // payment failed
            !isEmpty(paymentValidationSuccess) &&
            paymentValidationError === "" &&
            (paymentValidationSuccess.status === payment.TOKEN_GENERATED ||
              paymentValidationSuccess.status === payment.TRANSACTION_FAILURE ||
              paymentValidationSuccess.status === payment.TOKEN_GENERATION_FAILED
            ) &&
            < PaymentResponse
              icon={ErrorIcon}
              paymentValidationSuccess={paymentValidationSuccess}
              closePaymentModal={closePaymentModal}
              heading="Failed!"
              message="Your transaction has been failed"
            />
          }
          {
            // payment failed at posting
            !isEmpty(paymentValidationSuccess) && paymentValidationError === "" &&
            paymentValidationSuccess.status !== payment.TOKEN_GENERATED &&
            paymentValidationSuccess.status !== payment.TRANSACTION_FAILURE &&
            paymentValidationSuccess.status !== payment.TOKEN_GENERATION_FAILED &&
            paymentValidationSuccess.status !== payment.TRANSACTION_COMPLETED &&

            < PaymentResponse
              icon={SuccessIcon}
              paymentValidationSuccess={paymentValidationSuccess}
              closePaymentModal={closePaymentModal}
              heading="Payment successfull!"
              message="Transaction may take 24 hours to be reflected"
            />
          }
          {
            isEmpty(paymentValidationSuccess) && paymentValidationError === "" &&
            <PreLoader />
          }
        </ResponseModal>
      </div>
    </>
  );
};

export default BillingSection;
