import React from 'react'
import warningIcon from '../../../Assets/images/warning.svg'
import successIcon from '../../../Assets/images/success.svg'
import { SimpleButton } from '../';

const ResponseMessage = ({ button, heading, message, error, success, info, imageClass, headingClass, messageClass, ...rest }) => {
    return (
        <div className="d-flex flex-column align-items-center text-center px-1 pb-1 response-message" data-test="response-message" {...rest}>
            {error &&
                <div data-testid="response-error">
                    <img src={warningIcon} data-testid="response-img" className={imageClass ? imageClass : ""} alt="Warning" />
                    {/* {heading && <h4 data-testid="response-h" className={headingClass ? headingClass : ""} >{heading}</h4>}
                    {message && <p data-testid="response-p" className={messageClass ? messageClass : ""}>{message}</p>} */}
                    <h4 data-testid="response-h" className={headingClass ? headingClass : ""} >Something went wrong</h4>
                    <p data-testid="response-p" className={messageClass ? messageClass : ""}>Apologies, we are unable to complete your request right now.
                        Please call our customer care team on <a href="tel:121">121</a> or email <a href="mailto:121@airtel-vodafone.com">121@airtel-vodafone.com</a> for further assistance.
                    </p>
                </div>
            }
            {success &&
                <div data-testid="response-success">
                    <img src={successIcon} data-testid="response-img" className={imageClass ? imageClass : ""} alt="Success" />
                    {heading && <h4 data-testid="response-h" className={headingClass ? headingClass : ""}>{heading}</h4>}
                    {message && <p data-testid="response-p" className={messageClass ? messageClass : ""}>{message}</p>}
                </div>
            }
            {info &&
                <div data-testid="response-success">
                    <img src={warningIcon} data-testid="response-img" className={imageClass ? imageClass : ""} alt="Warning" />
                    {heading && <h4 data-testid="response-h" className={headingClass ? headingClass : ""}>{heading}</h4>}
                    {message && <p data-testid="response-p" className={messageClass ? messageClass : ""}>{message}</p>}
                </div>
            }

            {button && button.label && button.onClick && <SimpleButton variant={button.variant || "primary"} onClick={button.onClick}>{button.label}</SimpleButton>}

        </div>
    )
}

export default ResponseMessage
