//help and support component
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SimpleButton } from "../../common";
import { b2b_prefix, faq_url_guernsey, faq_url_jersey } from "../../envConfig";
import { AVAIcon } from '../../Icons'

const HelpSupport = (props) => {
    const { optimusDetails } = useSelector(state => state.auth)
    const prefix = `/${b2b_prefix}`
    const history = useHistory()
    const openChatBot = () => {

        if (document.getElementById("chatButton") && document.getElementById("chatButton").getElementsByClassName('assistant-bot-icon')[0]) {
            document.getElementById("chatButton").getElementsByClassName('assistant-bot-icon')[0].click();
        }
        setTimeout(() => props.closeDrawer(), 1000)

    }

    return (
        <div className="mt-3 help_Support_layout">
            <div className="help_Support_tobi">
                <img
                    src={AVAIcon}
                    alt="AVA"
                    className="d-inline-block align-top ava"
                />
            </div>
            <h2 className="help_Support_title">Hello I’m AVA</h2>
            <h3 className="help_Support_text mb-3">How can I help you today?</h3>
            <SimpleButton onClick={openChatBot} variant="primary" width="182px">
                Ask me a question
            </SimpleButton>
            <div className="mt-5">
                <div title="you can raise service request on msisdn list page" className="help_Support_anchor" onClick={() => optimusDetails.domain && optimusDetails.domain === "B2B" ? history.push(`${prefix}/MSISDN`) : props.onStepChange("serviceRequest")}>Raise a service request</div>
                <div className="help_Support_anchor" onClick={() => props.onStepChange("callBack")}>Request a call back</div>
                <div className="help_Support_anchor"><a className="faq" href={String(optimusDetails.circle).toUpperCase() === "GUERNSEY" ? faq_url_guernsey : faq_url_jersey} target="blank">FAQs</a></div>
                {optimusDetails.domain !== "B2B" ? <div className="help_Support_anchor" onClick={() => props.onStepChange("NEWCON")}>Request a new connection</div> : ""}
            </div>
        </div >
    )
}

export default HelpSupport;