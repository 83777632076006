import React, { useEffect, useState } from 'react'
import TableComp from '../../common/TableComp';
import { useHistory } from "react-router-dom";
import HeaderBar from '../../../../components/common/components/HeaderBar';
import { getSubscriberHierarchyB2B } from "../../../../redux/actions/dashboardAction";
import { useDispatch, useSelector } from 'react-redux';
import { fetchActivePlan, getDataBoltOn, getLocalBoltOn, getMsisdnDetails, getRomaingBoltOn } from '../../../../redux/actions/myPlanAction';
import isEmpty from '../../../common/utils/isEmpty';
import { PreLoader, ResponseMessage, Drawer, SimpleButton, } from '../../../common';
import HelpSupportMain from "../../../help&support/helpSupportMain";
import BoltOn from '../../../common/boltOn/BoltOn'
import { b2b_prefix } from '../../../envConfig';

export const ListMsisdn = () => {
    const [showHelpSupport, setShowHelpSupport] = useState(false);
    const [isShowBolton, setShowBolton] = useState(false)
    const [activeMobileNumber, setActiveMobileNumber] = useState();
    const [selcetdRowBolton, setSelcetdRowBolton] = useState({})
    const [view, setView] = useState("");
    const { activePlanData, activePlanError } = useSelector(state => state.planReducer);

    const OpenHelpSupport = (v = "") => {
        setShowHelpSupport(!showHelpSupport);
        setView(v)
    }
    const fetchBoltOn = (number) => {
        if (number) {
            dispatch(fetchActivePlan(si_lob, domain, number, cust_class))
        }
    }

    const openBolton = (r = {}) => {
        setShowBolton(true);
        // setBoltonView(v)
        setSelcetdRowBolton(r)
        fetchBoltOn(r?.msisdn)
    }
    const closeBolton = () => {
        dispatch({ type: "CHANGE_ORDER_RESET" });
        dispatch({ type: "BOLTON_PLAN_RESET" })
        setSelcetdRowBolton({})
        setShowBolton(false)

    }


    const dispatch = useDispatch()
    const [selectedRows, setSelectedRows] = useState([])

    const { optimusDetails } = useSelector(state => state.auth);
    const { subscriberHierarchyB2B, subscriberHierarchyErrorB2B } = useSelector(state => state.dashboard);
    const { si, si_lob, domain, cust_class, parent_ac_no, circle_id, org_name, cust_ac_no, payto_bill_ac } = optimusDetails
    let planId = ""

    if (activePlanData && activePlanData.billPlanRental
        &&
        activePlanData.billPlanRental.productSpecification
        && activePlanData.billPlanRental.productSpecification.productNumber
    ) {

        planId = activePlanData.billPlanRental.productSpecification.productNumber

    }
    useEffect(() => {
        if (isEmpty(subscriberHierarchyB2B) && subscriberHierarchyErrorB2B === "")
            dispatch(getSubscriberHierarchyB2B(payto_bill_ac));

        if (!isEmpty(activePlanData) && activePlanError === "" && !isEmpty(selcetdRowBolton) && planId) {
            dispatch(getLocalBoltOn(planId, selcetdRowBolton?.msisdn, domain, si_lob, circle_id, cust_class));
            dispatch(getRomaingBoltOn(planId, selcetdRowBolton?.msisdn, domain, si_lob, circle_id, cust_class));
           dispatch(getDataBoltOn(planId, selcetdRowBolton?.msisdn, domain, si_lob, circle_id, cust_class));
        }
        setActiveMobileNumber(selcetdRowBolton?.msisdn);

    }, [dispatch, domain, si, si_lob, planId, subscriberHierarchyB2B, subscriberHierarchyErrorB2B, cust_ac_no, payto_bill_ac, activePlanData, activePlanError, selcetdRowBolton, circle_id]);


    const history = useHistory();
    const prefix = `/${b2b_prefix}`


    const showMsisdnDetails = (tabData) => {
        const msdinDATA = {
            number: tabData.msisdn,
            domain,
            subLob: si_lob,
            customerId: parent_ac_no,
            circleId: circle_id,
            si_lob,
            cust_class,
            cust_ac_no
        }
        dispatch(getMsisdnDetails(msdinDATA))
        history.push({
            pathname: `${prefix}/MSISDN_details`,
            state: { data: { ...msdinDATA }, }
        })
    }

    const showSrHistory = (row) => {
        history.push({
            pathname: `${prefix}/serviceHistory`,
            state: { mobileNumber: row.msisdn }
        })
    }
    const columns = [
        {
            dataField: 'msisdn',
            text: 'Mobile number'
        },
        //  {
        //     dataField: 'name',
        //     text: 'Name'
        // },
        {
            dataField: "status",
            text: "Status"
        },
        // {
        //     dataField: "segment",
        //     text: "Segment"
        // },
        {
            dataField: "viewButton",
            text: "Connection detail",
            isDummyField: true,
            formatter: (_cell, row) => <SimpleButton onClick={() => showMsisdnDetails(row)} variant="outline-secondary">View</SimpleButton>
        },
        {
            dataField: "srHistory",
            text: "SR history",
            isDummyField: true,
            formatter: (_cell, row) => <SimpleButton onClick={() => showSrHistory(row)} variant="outline-secondary">View</SimpleButton>
        },
        {
            dataField: "BoltOn",
            text: "Bolt on",
            isDummyField: true,
            formatter: (_cell, row) => <SimpleButton rowstatus = {row.status} size="sm" onClick={() => openBolton(row)} >Bolt on</SimpleButton>
        },

    ];

    const actions = (
        // <DropdownButton title="Select an option" className='w-100' disabled={selectedRows.length === 0 ? true : false}>
        //     <>
        //         <Dropdown.Item disabled={selectedRows.length === 0 ? true : false} onClick={() => OpenHelpSupport("serviceRequest")}>Raise service request</Dropdown.Item>
        //         {/* <Dropdown.Item disabled={selectedRows.length === 0 ? true : false} onClick={() => OpenHelpSupport("callBack")}>Raise a call back</Dropdown.Item> */}
        //         {/* <Dropdown.Item disabled={selectedRows.length === 0 ? true : false} onClick={() => openBolton("LOCAL")} >Local bolt on</Dropdown.Item>
        //         <Dropdown.Item disabled={selectedRows.length === 0 ? true : false} onClick={() => openBolton("ROMING")}>Roaming bolt on</Dropdown.Item> */}
        //     </>
        // </DropdownButton>
        <SimpleButton title="Raise service request" disabled={selectedRows.length === 0 ? true : false} onClick={() => OpenHelpSupport("serviceRequest")}>Raise service request</SimpleButton>
    )

    return (
        <React.Fragment>

            {subscriberHierarchyB2B.data && subscriberHierarchyB2B.data.listOfHierarchyListB2BResponse && subscriberHierarchyB2B.data.listOfHierarchyListB2BResponse.length > 0 && subscriberHierarchyErrorB2B === "" &&
                <>
                    <HeaderBar>
                        <div data-testid="lsit_misidn_main" id="mssidnb2b_1" className="py-4 container d-flex align-items-center justify-content-between">
                            <h1 id="mssidnb2b_3" className="mediumText">{org_name ? org_name : ""}</h1>

                            <div>
                                {/* <h1 id="mssidnb2b_6" className="mediumText"></h1> */}
                                <h1 id="mssidnb2b_7" className="mediumText"><span className='font-weight-bold'>{subscriberHierarchyB2B && subscriberHierarchyB2B.data ? subscriberHierarchyB2B.data.length : ""}{" "}</span>{ }{subscriberHierarchyB2B.data.length === 1 && subscriberHierarchyB2B.data.length > 0 ? "Mobile number" : "Mobile numbers"}</h1>

                            </div>
                        </div>
                    </HeaderBar>
                    <div id="mssidnb2b_8" className="table_padding">
                        <TableComp
                            keyField="msisdn"
                            heading={`${subscriberHierarchyB2B && subscriberHierarchyB2B.data && subscriberHierarchyB2B.data.listOfHierarchyListB2BResponse.length} Total connections`}
                            searchBar={true}
                            tableData={subscriberHierarchyB2B && subscriberHierarchyB2B.data && subscriberHierarchyB2B.data.listOfHierarchyListB2BResponse ? subscriberHierarchyB2B.data.listOfHierarchyListB2BResponse : []}
                            columns={columns}
                            check={false}
                            actions={actions}
                            rowsData={selectedRows}
                            handeleSelection={(rows) => setSelectedRows(rows)}
                        />
                    </div>
                </>
            }
            {subscriberHierarchyErrorB2B !== "" && isEmpty(subscriberHierarchyB2B) &&
                <HeaderBar>
                    < div data-testid="transaction_loading_component" style={{ padding: "5%" }}>
                        <ResponseMessage error heading={subscriberHierarchyErrorB2B} />
                    </div>
                </HeaderBar>
            }
            {
                subscriberHierarchyB2B && subscriberHierarchyB2B.data && subscriberHierarchyB2B.data.length === 0 && subscriberHierarchyErrorB2B === "" &&
                <HeaderBar>
                    <div data-testid="transaction_loading_component" style={{ padding: "5%" }}>
                        <ResponseMessage error heading={'Data not found'} />
                    </div>
                </HeaderBar>
            }
            {
                subscriberHierarchyErrorB2B === "" && isEmpty(subscriberHierarchyB2B) &&
                <HeaderBar>
                    <div data-testid="transaction_loading_component" style={{ padding: "5%" }}>
                        <PreLoader />
                    </div>
                </HeaderBar>
            }
            {/* bolt on */}
            <Drawer data-testid="drawerw223" isOpen={isShowBolton}
                closeDrawer={closeBolton}
                body={<BoltOn
                    activeMobileNumber = {activeMobileNumber}
                    //  boltView={boltonView} 
                    selectedRow={selcetdRowBolton}
                    closeDrawer={closeBolton} />} />
            {/* SR */}
            <Drawer data-testid="drawerw22" isOpen={showHelpSupport} closeDrawer={OpenHelpSupport} body={<HelpSupportMain msisdnList={selectedRows} view={view} closeDrawer={OpenHelpSupport} />} />
        </React.Fragment >
    )
}