import React from 'react'
import noBillHhite from '../../../Assets/images/billing/noBillHhite.png'


const NoBillScreen = ({ date }) => {
  return (
    <div className="nobill_screen_main">
      <img className="mb-4 mb-sm-3" src={noBillHhite} alt="no-n=bill-img" />
      <h1 className="semiMediumText">Your bill isn't ready yet</h1>
      <p className='text-muted'>Please call our customer care team on <a href="tel:121">121</a> or email <a href="mailto:121@airtel-vodafone.com">121@airtel-vodafone.com</a> for further assistance.</p>
    </div>
  )
}

export default NoBillScreen
