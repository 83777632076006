// Store that contain all data of application
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import myBillsAllReducers from './reducers/myBillsReducer'
import common from './reducers/commonReducer';
import { auth, authInitState } from './reducers/loginReducer'
import { decryptData } from '../components/common/utils/crypto';
import { login_types } from './helpers/types'
import dashboard from './reducers/dashboardReducer';
import planReducer from './reducers/myPlanReducer';

const { AUTH_DATA, LOGOUT, TOKEN_INFO } = login_types

const portalApp = combineReducers({
  myBillsAllReducers,
  common,
  auth,
  dashboard,
  planReducer
});

const loadFromLocalStorage = () => {
  let initialState = {}
  try {
    const authData = localStorage.getItem(AUTH_DATA) ? decryptData(localStorage.getItem(AUTH_DATA)) : undefined

    if (authData) {
      authInitState.auth = authData.authDetails
      if (authData.domain === "B2B") {
        authData.userInfo.b2bServices[0].domain = authData.domain
        authData.userInfo.b2bServices[0].emailAddress = authData.emailAddress
        authInitState.optimusDetails = { ...authData.userInfo.b2bServices[0], ...authData.userInfo.contactDetails }
      }
      else {
        authData.userInfo.services[0].domain = authData.domain
        authInitState.optimusDetails = authData.userInfo.services[0]
      }
      return initialState
    }
    else {
      emptyLocalstorage();
      return undefined
    }



  }
  catch {
    emptyLocalstorage();
    return undefined
  }

}

const emptyLocalstorage = () => {
  localStorage.removeItem(AUTH_DATA)
  localStorage.removeItem(TOKEN_INFO)
  localStorage.clear();
  return true;
}

//state presistence
const persistState = loadFromLocalStorage()

window.addEventListener("onbeforeunload", (ev) => {
  ev.preventDefault();
  emptyLocalstorage();
  return ev;
});

//logout => clear the local storage and the rootreducer
const rootReducer = (state, action) => {

  if (action.type === LOGOUT) {
    emptyLocalstorage();
    state = undefined;
    window.location.reload()
  }

  return portalApp(state, action);
};

const store = createStore(
  rootReducer,
  persistState,
  // composeEnhancer(applyMiddleware(thunk))   //DEV
  applyMiddleware(thunk)                       // PROD
);

export default store
