import React from 'react'
import Radio from '../form/Radio.js'
import { DataIcon } from '../../Icons'
import { getCurrency } from '../index.js'

function PlanCard({ planData, callBack, index = 0}) {

    let attribute = planData && planData.discountAttribute && planData.discountAttribute.summaryOfDiscount && planData.discountAttribute.summaryOfDiscount.attribute;
    let unit = attribute && attribute.length ? `${attribute[0].attributeValue}${attribute[1].attributeValue}` : "";
    let priceDetail = planData && planData.productPriceDetails && planData.productPriceDetails.summaryOfCharges && planData.productPriceDetails.summaryOfCharges.attribute;
    let price = priceDetail && priceDetail.length ? `${priceDetail[0].attributeValue}` : "";



    return (
        <label for={`local_${index}`} className='planDataSection'>
            <div id="p12" className="position-relative p-3 mb-3 d-flex data-planCard justify-content-between align-items-center" >
            {planData && planData.name && <p className='font-weight-bold mb-0 mr-2'>{planData.name}</p>}
            <div id="p1772" className="d-flex align-items-center ps-2">
                {price && <span id="p1276">{getCurrency(price)}</span>}
                <Radio id={`local_${index}`} name="local" className="m-0" onChange={() => callBack(planData)} />
            </div>
        </div>
        </label>
        

    )
}

export default PlanCard
