import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { PROD_TYPE_DATACARD } from "../../dashboard/dashboardConstant";
import { useSelector } from "react-redux";

function SimpleButton({rowstatus, lrStatusactive,  variant, children, maxWidth, width, rounded, ...rest }) {
  variant = variant || "primary"
  const { optimusDetails } = useSelector((state) => state.auth);
  const { domain, prod_type} = optimusDetails
  const isSuspended = domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD 
  ? lrStatusactive === "Suspended" 
  : rowstatus === "Suspended";
  return (
    <>
      <Button
  disabled={isSuspended}
  style={{ width: width || (maxWidth && "100%") }}
  data-testid="form-button"
  variant={variant}
  {...rest}
>
  {children}
</Button>
    </>
  );
  
}


SimpleButton.propTypes = {
  variant: PropTypes.string,
  rounded: PropTypes.bool,
};

export default SimpleButton;
