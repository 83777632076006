
import Modal from 'react-bootstrap/Modal'
import backArrow from "../../../Assets/images/helpSupport/backArrow.svg"
export default function ResponseModal({ isClose = true, children, handleClose, show, isBack, back, ...rest }) {

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                animation={true}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                {...rest}
            >
                <div className="modal-header">
                    {isBack ? back : ""}
                    <button onClick={isClose ? handleClose : null} type="button" className="btn-close" aria-label="Close"></button>
                </div>
                {/* <Modal.Header bsPrefix='modal-header' closeButton={isClose ? true : false}> 
            </Modal.Header> */}
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </>
    );
}
