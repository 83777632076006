import React, { useState } from "react";


const OTPField = ({ onChange, ...rest }) => {
    const [otp, setOtp] = useState(new Array(4).fill(""));

    const handleChange = (element, index) => {
        //it will only accept the numbers
        if (isNaN(element.value)) return false;

        // set the value to matching index else on change
        let varb = otp
        varb = [...varb.map((d, idx) => (idx === index ? element.value : d))]
        setOtp(varb)
        onChange(varb.join(""))

        //Focus next input after adding the new element
        if (element.value !== "") {
            if (element.nextSibling) {
                element.nextSibling.focus();
            }
        }

        //focus to the previous sibling after deleteing the element
        else {
            if (element.previousSibling)
                element.previousSibling.focus()
        }
    };

    return (
        <>
            <div className="row" {...rest} id="otpp-1">
                <div className="col text-center" id="otpp-21">
                    {otp.map((data, index) => {
                        return (
                            <input
                                role="otp-field"
                                id={`otpp-112${index}`}
                                className="otp-field"
                                data-testid={`otp000-${index}`}
                                type="text"
                                name="otp"
                                maxLength="1"
                                key={index}
                                value={data}
                                onChange={e => handleChange(e.target, index)}
                                onFocus={e => e.target.select()}
                            />
                        );
                    })}

                </div>
            </div>
        </>
    );
};

export default OTPField;