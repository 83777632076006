import { BackIcon, RoamingIcon, GroupIcon, LocalLocationIcon, internetweb } from '../../Icons'
import { SimpleButton, ResponseMessage, PreLoader, ResponseModal} from '../../common'
import { useEffect, useState } from "react"
import PlanCard from "../components/PlanCard"
import { useDispatch, useSelector } from "react-redux"
import isEmpty from "../utils/isEmpty"
import IRCardPostpaid from "./boltonComponetns/IRCardPostpaid"
import { changeOrder} from '../../../redux/actions/myPlanAction'
import { globalErrorMessage } from '../../envConfig'
import useActivePlaId from '../../MyPlan&Usage/hooks/useActivePlanId'
import { GSTNote } from './boltonComponetns/GSTtext'
const BoltOn = ({ handleClose, boltView, selectedRow, isAccLevel = false, activeMobileNumber, closeDrawer}) => {
    const dispatch = useDispatch()
    const { localBoltOnPlans, localBoltOnPlansError, romaingBoltOnPlans, romaingBoltOnPlansError, changeOrderSuccess, changeOrderError, activePlanData,
        accLevelBolton,
        accLevelBoltonError,
        childNumber,
        dataBoltOnPlans,
        dataBoltOnPlansError
    } = useSelector(state => state.planReducer);
    const [view, changeView] = useState(boltView || "BOLTON")
    const [flag, setFlag] = useState("")
    const [selectedPlan, setSelectedPlan] = useState({})
    const [isBoltonConfirm, setIsBoltonConfirm] = useState(false)
    const { optimusDetails } = useSelector(state => state.auth)
    const { si, si_lob, domain, cust_class, circle_id } = optimusDetails
    const [isDisabledChangeOrder, setIsDisabledChangeOrder] = useState(false)
    const planId = useActivePlaId(activePlanData)

    const submitBoltOn = (f) => {
        setFlag(f)
        setIsDisabledChangeOrder(true)
        if (!isEmpty(selectedPlan)) {
            if (!isEmpty(optimusDetails) && localBoltOnPlansError === "") {
                //write submission code here
                if(domain === "B2B"){
                dispatch(changeOrder(planId, childNumber ? childNumber : si, si_lob, domain, cust_class, selectedPlan.productOfferingId, selectedPlan.name, circle_id))
                }
                else{
                dispatch(changeOrder(planId, childNumber ? childNumber : si, si_lob, domain, cust_class, selectedPlan.productOfferingId, selectedPlan.name, circle_id))
                }
            }
        }
    }
    const switchPage = (v) => {
        dispatch({ type: "CHANGE_ORDER_RESET" })
        setIsBoltonConfirm(false)
        setIsDisabledChangeOrder(false)
        changeView(v)
        setSelectedPlan({})
    }

    useEffect(() => {
        if (changeOrderError) {
            changeView("ERROR")
        }
        if (changeOrderSuccess) {
            changeView("SUCCESS")
        }

    }, [changeOrderError, changeOrderSuccess])

    // sorting the list price wise
    function compare(a, b) {
        try {
            return (a.productPriceDetails && a.productPriceDetails.summaryOfCharges && a.productPriceDetails.summaryOfCharges.attribute.length ? `${a.productPriceDetails.summaryOfCharges.attribute[0].attributeValue}` : "") - (b.productPriceDetails && b.productPriceDetails.summaryOfCharges && b.productPriceDetails.summaryOfCharges.attribute.length ? `${b.productPriceDetails.summaryOfCharges.attribute[0].attributeValue}` : "")
        }
        catch (e) {
            return 0
        }
    }

    let localBoltOnPlansList = {}
    if (localBoltOnPlans && !isEmpty(localBoltOnPlans) && localBoltOnPlans.epcProductSpec && localBoltOnPlans.epcProductSpec.epcAddOns) {
        localBoltOnPlans.epcProductSpec.epcAddOns.sort(compare)
        localBoltOnPlansList = localBoltOnPlans

    }

    let romaingBoltOnPlansList = {}
    if (romaingBoltOnPlans && !isEmpty(romaingBoltOnPlans) && romaingBoltOnPlans.epcProductSpec && romaingBoltOnPlans.epcProductSpec.epcAddOns) {
        romaingBoltOnPlans.epcProductSpec.epcAddOns.sort(compare)
        romaingBoltOnPlansList = romaingBoltOnPlans

    }

    let accLevelBoltonList = {}
    if (accLevelBolton && !isEmpty(accLevelBolton) && accLevelBolton.epcProductSpec && accLevelBolton.epcProductSpec.epcAddOns) {
        accLevelBolton.epcProductSpec.epcAddOns.sort(compare)
        accLevelBoltonList = accLevelBolton

    }


    let addDataPlanList = {}
    if (dataBoltOnPlans && !isEmpty(dataBoltOnPlans) && dataBoltOnPlans.epcProductSpec && dataBoltOnPlans.epcProductSpec.epcAddOns){
        dataBoltOnPlans.epcProductSpec.epcAddOns.sort(compare)
        addDataPlanList = dataBoltOnPlans
    }

    const openRoamingBolotOnConfirmation = (f) => {
        if (!isEmpty(selectedPlan)) {
            //at some product need to show confirmation message
            // if (selectedPlan.productOfferingId === "S1-YW7P4NL" || selectedPlan.productOfferingId === "S1-YW7P4NQ" || selectedPlan.productOfferingId === "S1-YW7P4NV" || selectedPlan.productOfferingId === "S1-LLMDXF2") {
            //     setIsBoltonConfirm(true)
            // }
            // else {
            //     submitBoltOn(f)
            // }
           setIsBoltonConfirm(true)
        }

    }

    const openBoltOnConfirmation = (f) => {
        if (!isEmpty(selectedPlan)) {
            //at some product need to show confirmation message
                setIsBoltonConfirm(true)
        }

    }
    
    const closeAddonConfirm = () => {
        setIsBoltonConfirm(false)
    }

    return (
        <>
            {{
                "BOLTON":
                    isAccLevel ?
                        <div className="upgrage_plan_main">
                            <h1 className="semiMediumText change_plan_header my-2">Account level Bolton</h1>
                            {
                                isEmpty(accLevelBoltonList) && accLevelBoltonError === "" &&

                                <div id="pro-12" className="loading-screen" data-testid="profile_preloader">
                                    <PreLoader id="pro-13" />
                                </div>
                            }

                            {accLevelBoltonError !== "" && isEmpty(accLevelBoltonList) &&
                                <>
                                    <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                                        <ResponseMessage
                                            error
                                            heading={localBoltOnPlansError}
                                            id="pro-15"
                                        />
                                        <div className="d-grid gap-3 success-close-button" id="pro-16" >
                                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                accLevelBoltonList && accLevelBoltonList.epcProductSpec && accLevelBoltonList.epcProductSpec.epcAddOns.length === 0 && localBoltOnPlansError === "" &&
                                <>
                                    <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                                        <ResponseMessage
                                            info
                                            heading="Product not found"
                                            message={globalErrorMessage()}
                                            id="pro-15"
                                        />
                                        <div className="d-grid gap-3 success-close-button" id="pro-16" >
                                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                                        </div>
                                    </div>

                                </>
                            }

                            {
                                accLevelBoltonList && accLevelBoltonList.epcProductSpec && accLevelBoltonList.epcProductSpec.epcAddOns.length > 0 && accLevelBoltonError === "" &&
                                <>
                                    <div className="upgrade_plan_body">
                                        {
                                            accLevelBoltonList.epcProductSpec.epcAddOns.map((planData, i) => {
                                                return (
                                                    <PlanCard key={`localBoltOnPlansList${i}`} planData={planData} callBack={(data) => setSelectedPlan(data)} index={i} />
                                                )
                                            })
                                        }

                                    </div>
                                    <GSTNote />
                                    <SimpleButton variant="primary" disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} className="mb-4" width="100%" onClick={() => openBoltOnConfirmation("A")} >
                                        Confirm
                                    </SimpleButton>
                                </>
                            }

                        <ResponseModal
                            show={isBoltonConfirm}
                            handleClose={() => closeAddonConfirm()}
                            size="md"
                        >
                            <>
                                <h3 className='text-danger'>IMPORTANT NOTICE</h3>
                              <p className='changeplan_confirm_p mb-4 text-justify'>
                              Please be advised the product you are selecting will be added to your account immediately. 
                              Please contact us 121 should you wish for this product to be removed at the end of the current billing period.
                              Alternatively, the product will continue to be charged in following bill periods.   sdfvcxz      
                              </p>
                               
                                <SimpleButton maxWidth disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} onClick={() => submitBoltOn("A")}>Continue</SimpleButton>                          
                            </>
                        </ResponseModal>

                        </div>
                        :
                        <div data-testid="first-screen" className="main-bolts">
                            <>
                                <div className="bolts-bolton m-b-10"><h2>Bolt on</h2></div>
                                <div className="partent-bolt">
                                    <div className="bolts-local cursor" onClick={() => switchPage("LOCAL")} >
                                        <img
                                            src={LocalLocationIcon}
                                            alt="roaming"
                                            className="bolts-roamings"
                                        />
                                        <div className="local-text" >
                                            <h5>Anywhere minutes</h5>
                                        </div>
                                        <img
                                            src={GroupIcon}
                                            alt="group"
                                            className="bolts-group"
                                        />
                                    </div >
                                    <div className="bolts-local cursor" onClick={() => switchPage("ROMING")}>
                                        <img
                                            src={RoamingIcon}
                                            alt="roaming"
                                            className="bolts-roamings"
                                        />
                                        <div className="local-text">
                                            <h5> Roaming bolt on</h5>
                                        </div>
                                        <img
                                            src={GroupIcon}
                                            alt="group"
                                            className="bolts-group"
                                        />
                                    </div>
                                    {/* Add Data Button */}
                                    {domain === "B2B" ? <div className="bolts-addData cursor" onClick={() => switchPage("ADDDATA")}>
                                        <img
                                            src={internetweb}
                                            alt="roaming"
                                            className="bolts-roamings"
                                        />
                                        <div className="local-text">
                                            <h5>Add data</h5>
                                        </div>
                                        <img
                                            src={GroupIcon}
                                            alt="group"
                                            className="bolts-group"
                                        />
                                    </div> : ""}
                                </div>

                            </>
                        </div>,
                "LOCAL":
                    <div className="upgrage_plan_main">
                        <img src={BackIcon} alt="back" data-testid="Back_to_init" onClick={() => switchPage("BOLTON")} className="cursor" />
                        <h1 className="semiMediumText change_plan_header my-2">Anywhere minutes</h1>
                        {
                            isEmpty(localBoltOnPlansList) && localBoltOnPlansError === "" &&

                            <div id="pro-12" className="loading-screen" data-testid="profile_preloader">
                                <PreLoader id="pro-13" />
                            </div>
                        }

                        {localBoltOnPlansError !== "" && isEmpty(localBoltOnPlansList) &&
                            <>
                                <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                                    <ResponseMessage
                                        error
                                        heading={localBoltOnPlansError}
                                        id="pro-15"
                                    />
                                    <div className="d-grid gap-3 success-close-button" id="pro-16" >
                                        <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            localBoltOnPlansList && localBoltOnPlansList.epcProductSpec && localBoltOnPlansList.epcProductSpec.epcAddOns.length === 0 && localBoltOnPlansError === "" &&
                            <>
                                <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                                    <ResponseMessage
                                        error
                                        heading="Data not found"
                                        id="pro-15"
                                    />
                                    <div className="d-grid gap-3 success-close-button" id="pro-16" >
                                        <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                                    </div>
                                </div>

                            </>
                        }

                        {
                            localBoltOnPlansList && localBoltOnPlansList.epcProductSpec && localBoltOnPlansList.epcProductSpec.epcAddOns.length > 0 && localBoltOnPlansError === "" &&
                            <>
                                <div className="upgrade_plan_body">
                                    {
                                        localBoltOnPlansList.epcProductSpec.epcAddOns.map((planData, i) => {
                                            return (
                                                <PlanCard key={`localBoltOnPlansList${i}`} planData={planData} callBack={(data) => setSelectedPlan(data)} index={i}/>
                                            )
                                        })
                                    }
                                </div>
                                <GSTNote />
                                <SimpleButton variant="primary" disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} className=" mb-4" width="100%" onClick={() => openBoltOnConfirmation("L")} >
                                    Confirm
                                </SimpleButton>
                            </>
                        }

                        <ResponseModal
                            show={isBoltonConfirm}
                            handleClose={() => closeAddonConfirm()}
                            size="md"
                        >
                            <>
                                <h3 className='text-danger'>IMPORTANT NOTICE</h3>
                              <p className='changeplan_confirm_p mb-4 text-justify'>
                              Please be advised the product you are selecting will be added to your account immediately. 
                              Please contact us 121 should you wish for this product to be removed at the end of the current billing period.
                              Alternatively, the product will continue to be charged in following bill periods.         
                              </p>
                               
                                <SimpleButton maxWidth disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} onClick={() => submitBoltOn("L")}>Continue</SimpleButton>
                            </>
                        </ResponseModal>

                    </div>,

"ADDDATA":
<div className="upgrage_plan_main">
    <img src={BackIcon} alt="back" data-testid="Back_to_init" onClick={() => switchPage("BOLTON")} className="cursor" />
    <h1 className="semiMediumText change_plan_header my-2">Add data</h1>
    {
        isEmpty(addDataPlanList) && dataBoltOnPlansError === "" &&

        <div id="pro-12" className="loading-screen" data-testid="profile_preloader">
            <PreLoader id="pro-13" />
        </div>
    }

    {dataBoltOnPlansError !== "" && isEmpty(addDataPlanList) &&
        <>
            <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                <ResponseMessage
                    error
                    heading={dataBoltOnPlansError}
                    id="pro-15"
                />
                <div className="d-grid gap-3 success-close-button" id="pro-16" >
                    <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                </div>
            </div>
        </>
    }
    {
        addDataPlanList && addDataPlanList.epcProductSpec && addDataPlanList.epcProductSpec.epcAddOns.length === 0 && dataBoltOnPlansError === "" &&
        <>
            <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                <ResponseMessage
                    error
                    heading="Data not found"
                    id="pro-15"
                />
                <div className="d-grid gap-3 success-close-button" id="pro-16" >
                    <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                </div>
            </div>

        </>
    }

    {
        addDataPlanList && addDataPlanList.epcProductSpec && addDataPlanList.epcProductSpec.epcAddOns.length > 0 && dataBoltOnPlansError === "" &&
        <>
            <div className="upgrade_plan_body">
                {
                    addDataPlanList.epcProductSpec.epcAddOns.map((planData, i) => {
                        return (
                            <PlanCard key={`addDataPlanList${i}`} planData={planData} callBack={(data) => setSelectedPlan(data)} index={i}/>
                        )
                    })
                }
            </div>
            <GSTNote />
            <SimpleButton variant="primary" disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} className=" mb-4" width="100%" onClick={() => openBoltOnConfirmation("D")} >
                Confirm
            </SimpleButton>
        </>
    }

                       <ResponseModal
                            show={isBoltonConfirm}
                            handleClose={() => closeAddonConfirm()}
                            size="md"
                        >
                            <>
                                <h3 className='text-danger'>IMPORTANT NOTICE</h3>
                              <p className='changeplan_confirm_p mb-4 text-justify'>
                              Please be advised the product you are selecting will be added to your account immediately.
                              Please contact us 121 should you wish for this product to be removed at the end of the current billing period.
                              Alternatively, the product will continue to be charged in following bill periods.         
                                </p>
                               
                                <SimpleButton maxWidth disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} onClick={() => submitBoltOn("D")}>Continue</SimpleButton>
                            </>
                        </ResponseModal>

                    </div>,

                "ROMING":
                    <div className="upgrage_plan_main p-0">
                        <img src={BackIcon} alt="back" data-testid="Back_to_init" onClick={() => switchPage("BOLTON")} className="cursor" />
                        <h1 className="semiMediumText change_plan_header my-2">Roaming Bolt on</h1>
                        {
                            isEmpty(romaingBoltOnPlansList) && romaingBoltOnPlansError === "" &&
                            <div id="pro-12" className="loading-screen" data-testid="profile_preloader">
                                <PreLoader id="pro-13" />
                            </div>
                        }
                        {
                            romaingBoltOnPlansError !== "" && isEmpty(romaingBoltOnPlansList) &&
                            <>
                                <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                                    <ResponseMessage
                                        error
                                        heading={romaingBoltOnPlansError}
                                        id="pro-15"
                                    />
                                    <div className="d-grid gap-3 success-close-button" id="pro-16" >
                                        <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            romaingBoltOnPlansList && romaingBoltOnPlansList.epcProductSpec && romaingBoltOnPlansList.epcProductSpec.epcAddOns.length === 0 && romaingBoltOnPlansError === "" &&

                            <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                                <ResponseMessage
                                    error
                                    heading="Data not found"
                                    id="pro-15"
                                />
                                <div className="d-grid gap-3 success-close-button" id="pro-16" >
                                    <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                                </div>
                            </div>
                        }
                        {

                            romaingBoltOnPlansList && romaingBoltOnPlansList.epcProductSpec && romaingBoltOnPlansList.epcProductSpec.epcAddOns.length > 0 && romaingBoltOnPlansError === "" &&
                            <>
                                <div className="upgrade_plan_body">
                                    {
                                        romaingBoltOnPlansList.epcProductSpec.epcAddOns.map((data, i) => {
                                            return (
                                                <IRCardPostpaid key={i} planData={data} callBack={(plan) => setSelectedPlan(plan)} index={i} />
                                            )
                                        })
                                    }
                                </div>
                                <GSTNote />
                                <SimpleButton variant="primary" disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} className="mb-3" width="100%" onClick={() => openRoamingBolotOnConfirmation("R")} >
                                    Confirm
                                </SimpleButton>
                            </>
                        }
                        <ResponseModal
                            show={isBoltonConfirm}
                            handleClose={() => closeAddonConfirm()}
                            size="md"
                        >
                            <>
                                <h3 className='text-danger'>IMPORTANT NOTICE</h3>
                                {selectedPlan.productOfferingId === "S1-LLMDXF2" ? <p className='changeplan_confirm_p mb-4 text-justify'>
                                    Please be advised the product you are selecting will be added to your account immediately. This will remain active for the next 7 days.
                                     Please contact us 121 if you have further questions. Thank you.          
                                </p> :  <p className='changeplan_confirm_p mb-4 text-justify'>
                                    Please be advised by opting for this product it will remain active until the end of your current contract term.
                                    If you are not currently in contract, by adding this product you are agreeing to a contract extension of 24 months from the current date.
                                    This product is valid for use in the following destinations:  Aland Islands, Albania, Austria, Belarus, Belgium, Bosnia & Herzegovina, Bulgaria,
                                    Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Isle of Man, Iceland, Ireland, Italy, Latvia,
                                    Lithuania, Luxembourg, Macedonia, Malta, Netherlands, Poland, Portugal, Romania, Serbia, Slovakia, Slovenia, Spain, Sweden, Turkey, Switzerland, UK, Ukraine and USA.         
                                </p>}
                               
                                <SimpleButton maxWidth disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} onClick={() => submitBoltOn("R")}>Continue</SimpleButton>
                            </>
                        </ResponseModal>
                    </div>,
                "SUCCESS":
                    <div className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            success
                            heading="Request Submitted"
                            message={changeOrderSuccess}
                        />
                        <div className="success-close-button">
                            <SimpleButton variant="primary" maxWidth onClick={handleClose} >Close</SimpleButton>
                        </div>
                    </div>,
                "ERROR":
                    <div className="d-flex flex-column justify-content-between mt-5">
                        {
                            String(changeOrderError).includes("Please be advised the product you have selected is already active on your number. Please select an alternative product to continue. Thank you.") ?
                                <ResponseMessage
                                    info
                                    heading={changeOrderError}
                                />
                                :
                                String(changeOrderError).includes("Open Order Exist Please re-check.") ?
                                <ResponseMessage
                                    info
                                    heading={changeOrderError}
                                />
                                :
                                <ResponseMessage
                                    error
                                    heading={changeOrderError}
                                />
                        }

                        <div className="d-grid gap-3 success-close-button" id="pro-16" >
                            <SimpleButton variant="primary" maxWidth onClick={() => switchPage(flag === "L" ? "LOCAL" : flag === "R" ? "ROMING" : flag === "D" ? "ADDDATA" : "BOLTON")} id="pro-17" data-testid="try_again">Try Again</SimpleButton>
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={domain === "B2B" && isAccLevel ? handleClose : domain === "B2B" ? closeDrawer : handleClose} id="pro-18"  >Close</SimpleButton>
                        </div>
                    </div>,
            }[view]}

        </>

    )
}

export default BoltOn

