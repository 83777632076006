import { dasboard_type, B2BFooter_type } from '../helpers/types';
import { apiDashboard, lobSubLob, b2bFooter, apiErrorCode } from "../helpers/ApiConstant"
import axios from "./";
import { batch } from 'react-redux';


let {
    SUBSCRIBER_HIERARCHY_SUCCESS,
    SUBSCRIBER_HIERARCHY_ERROR,
    SUBSCRIBER_HIERARCHY_SUCCESS_B2B,
    SUBSCRIBER_HIERARCHY_ERROR_B2B,
    CUSTOMER_INFO_SUCCESS,
    CUSTOMER_INFO_ERROR,
} = dasboard_type;

let {
    subscriberHierarchy,
    subscriberHierarchyB2B,
    customerInfoApi
} = apiDashboard


const { NO_INTERNET, SOMETHING_WRONG } = apiErrorCode

const {
    FOOTER_B2B_PRODUCT_BUSSINESS_SUCCESS,
    FOOTER_B2B_PRODUCT_BUSSINESS_ERROR,
    FOOTER_B2B_WHY_AIRTEL_ERROR,
    FOOTER_B2B_WHY_AIRTEL_SUCCESS,
    FOOTER_B2B_CONTACT_US_SUCCESS,
    FOOTER_B2B_CONTACT_US_ERROR,
    FOOTER_B2B_ABOUT_STORE_LOCATION_SUCCESS,
    FOOTER_B2B_ABOUT_STORE_LOCATION_ERROR,
    FOOTER_B2B_ABOUT_AIRTEL_ERROR,
    FOOTER_B2B_ABOUT_AIRTEL_SUCCESS,

    //menu
    FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_SUCCESS,
    FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_ERROR,
    FOOTER_B2B_WHY_AIRTEL_TITLE_ERROR,
    FOOTER_B2B_WHY_AIRTEL_TITLE_SUCCESS,
    FOOTER_B2B_CONTACT_US_TITLE_SUCCESS,
    FOOTER_B2B_CONTACT_US_TITLE_ERROR,
    FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_SUCCESS,
    FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_ERROR,
    FOOTER_B2B_ABOUT_AIRTEL_TITLE_ERROR,
    FOOTER_B2B_ABOUT_AIRTEL_TITLE_SUCCESS

} = B2BFooter_type


const setError = (error, type) => {
    return {
        error,
        type
    }
}

const setSuccess = (data, type) => {
    return {
        data,
        type
    }
}
// fetch SI detail, SI is broadband or a mobile number
export function getCutomerInfo(number) {
    let config = {
        url: `${customerInfoApi}/${number}`,
        method: "GET",
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.data) {
                        dispatch(setSuccess(res.data.data, CUSTOMER_INFO_SUCCESS));
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, CUSTOMER_INFO_ERROR))
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, CUSTOMER_INFO_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, CUSTOMER_INFO_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, CUSTOMER_INFO_ERROR))
                }
            });
    }
}


// api for getting parent and child numbers
export function getSubscriberHierarchy(subLob, domain, serviceId) {
    let config = {
        url: subscriberHierarchy,
        method: "GET",
        params: {
            domain,
            subLob: lobSubLob[subLob],
            serviceId
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.data) {
                        dispatch(setSuccess(res.data.data, SUBSCRIBER_HIERARCHY_SUCCESS));
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, SUBSCRIBER_HIERARCHY_ERROR))
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, SUBSCRIBER_HIERARCHY_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, SUBSCRIBER_HIERARCHY_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, SUBSCRIBER_HIERARCHY_ERROR))
                }
            });
    }
}


export function getSubscriberHierarchyB2B(accountNumber) {
    let config = {
        url: subscriberHierarchyB2B,
        method: "GET",
        params: {
            accountNumber
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.data) {
                        dispatch(setSuccess(res.data, SUBSCRIBER_HIERARCHY_SUCCESS_B2B));
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, SUBSCRIBER_HIERARCHY_ERROR_B2B))
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, SUBSCRIBER_HIERARCHY_ERROR_B2B))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, SUBSCRIBER_HIERARCHY_ERROR_B2B));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, SUBSCRIBER_HIERARCHY_ERROR_B2B))
                }
            });
    }
}

// B2B footer API
function productSolutionBusines(url) {
    let config = {
        url,
        params: {
            _format: "hal_json"
        },
        method: "GET",
        headers: {
            Authorization: ' Basic YWRtaW46cGFzc3dvcmQ='
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.length > 0) {
                        dispatch(setSuccess(res.data, FOOTER_B2B_PRODUCT_BUSSINESS_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_PRODUCT_BUSSINESS_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_PRODUCT_BUSSINESS_ERROR));
                }
            })
            .catch(err => {
                dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_PRODUCT_BUSSINESS_ERROR))
            });
    }
}

function whyAirteVodafone(url) {
    let config = {
        url,
        method: "GET",
        headers: {
            Authorization: ' Basic YWRtaW46cGFzc3dvcmQ='
        },
        params: {
            _format: "hal_json"
        },
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.length > 0) {
                        dispatch(setSuccess(res.data, FOOTER_B2B_WHY_AIRTEL_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_WHY_AIRTEL_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_WHY_AIRTEL_ERROR));
                }
            })
            .catch(err => {
                dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_WHY_AIRTEL_ERROR))
            });
    }
}

function contactUs(url) {
    let config = {
        url,
        method: "GET",
        params: {
            _format: "hal_json"
        },
        headers: {
            Authorization: ' Basic YWRtaW46cGFzc3dvcmQ='
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.length > 0) {
                        dispatch(setSuccess(res.data, FOOTER_B2B_CONTACT_US_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_CONTACT_US_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_CONTACT_US_ERROR));
                }
            })
            .catch(err => {
                dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_CONTACT_US_ERROR))
            });
    }
}

function storeLocation() {
    let config = {
        url: b2bFooter.store_locations,
        method: "GET",
        params: {
            _format: "hal_json"
        },
        headers: {
            Authorization: ' Basic YWRtaW46cGFzc3dvcmQ='
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.length > 0) {
                        dispatch(setSuccess(res.data, FOOTER_B2B_ABOUT_STORE_LOCATION_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_ABOUT_STORE_LOCATION_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_ABOUT_STORE_LOCATION_ERROR));
                }
            })
            .catch(err => {
                dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_ABOUT_STORE_LOCATION_ERROR))
            });
    }
}

function aboutAirtelVoda() {
    let config = {
        url: b2bFooter.about_airtel_vodafone,
        method: "GET",
        params: {
            _format: "hal_json"
        },
        headers: {
            Authorization: ' Basic YWRtaW46cGFzc3dvcmQ='
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.length > 0) {
                        dispatch(setSuccess(res.data, FOOTER_B2B_ABOUT_AIRTEL_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_ABOUT_AIRTEL_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_ABOUT_AIRTEL_ERROR));
                }
            })
            .catch(err => {
                dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_PRODUCT_BUSSINESS_ERROR))
            });
    }
}

//footer menu titles

// B2B footer API
function productSolutionBusinesTitle(url) {
    let config = {
        url,
        params: {
            _format: "hal_json"
        },
        credentials: "include",
        method: "GET",
        headers: {
            Authorization: ' Basic YWRtaW46cGFzc3dvcmQ='
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        dispatch(setSuccess(res.data, FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_ERROR));
                }
            })
            .catch(err => {
                dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_ERROR))
            });
    }
}

function whyAirteVodafoneTitle(url) {
    let config = {
        url,
        method: "GET",
        headers: {
            Authorization: ' Basic YWRtaW46cGFzc3dvcmQ='
        },
        credentials: "include",
        params: {
            _format: "hal_json"
        },
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        dispatch(setSuccess(res.data, FOOTER_B2B_WHY_AIRTEL_TITLE_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_WHY_AIRTEL_TITLE_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_WHY_AIRTEL_TITLE_ERROR));
                }
            })
            .catch(err => {
                dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_WHY_AIRTEL_TITLE_ERROR))
            });
    }
}

function contactUsTitle(url) {
    let config = {
        url,
        method: "GET",
        params: {
            _format: "hal_json"
        },
        headers: {
            Authorization: ' Basic YWRtaW46cGFzc3dvcmQ='
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        dispatch(setSuccess(res.data, FOOTER_B2B_CONTACT_US_TITLE_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_CONTACT_US_TITLE_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_CONTACT_US_TITLE_ERROR));
                }
            })
            .catch(err => {
                dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_CONTACT_US_TITLE_ERROR))
            });
    }
}

function storeLocationTitle() {
    let config = {
        url: b2bFooter.store_locations_title,
        method: "GET",
        params: {
            _format: "hal_json"
        },
        headers: {
            Authorization: ' Basic YWRtaW46cGFzc3dvcmQ='
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        dispatch(setSuccess(res.data, FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_ERROR));
                }
            })
            .catch(err => {
                dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_ABOUT_STORE_LOCATION_TITLE_ERROR))
            });
    }
}

function aboutAirtelVodaTitle() {
    let config = {
        url: b2bFooter.about_airtel_vodafone_title,
        method: "GET",
        params: {
            _format: "hal_json"
        },
        headers: {
            Authorization: ' Basic YWRtaW46cGFzc3dvcmQ='
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data) {
                        dispatch(setSuccess(res.data, FOOTER_B2B_ABOUT_AIRTEL_TITLE_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_ABOUT_AIRTEL_TITLE_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_ABOUT_AIRTEL_TITLE_ERROR));
                }
            })
            .catch(err => {
                dispatch(setError(SOMETHING_WRONG, FOOTER_B2B_PRODUCT_BUSSINESS_TITLE_ERROR))
            });
    }
}


export function footerData(type) {
    return dispatch => {
        batch(() => {
            // menues
            dispatch(productSolutionBusinesTitle(type === "B2B" ? b2bFooter.product_solution_business_title : b2bFooter.devices_title))
            dispatch(whyAirteVodafoneTitle(type === "B2B" ? b2bFooter.why_airtel_vodafone_footer_title : b2bFooter.pay_monthly_plans_title))
            dispatch(contactUsTitle(type === "B2B" ? b2bFooter.contact_us_title : b2bFooter.help_and_support_title))
            dispatch(storeLocationTitle())
            dispatch(aboutAirtelVodaTitle())
            ///menu items
            dispatch(productSolutionBusines(type === "B2B" ? b2bFooter.product_solution_business : b2bFooter.devices))
            dispatch(whyAirteVodafone(type === "B2B" ? b2bFooter.why_airtel_vodafone_footer : b2bFooter.pay_monthly_plans))
            dispatch(contactUs(type === "B2B" ? b2bFooter.contact_us : b2bFooter.help_and_support))
            dispatch(storeLocation())
            dispatch(aboutAirtelVoda())
        })
    }
}
