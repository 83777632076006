import { login_types } from '../../redux/helpers/types'
import { decryptData } from "../common/utils/crypto";
import axios from "axios";
import applyAppTokenRefreshInterceptor from './responseInterceptor'


// axios main instance
const axiosPrivate = axios.create();


//global request interceptor
axiosPrivate.interceptors.request.use(config => {
    if (config.headers.Authorization) {
        return config
    }
    else {
        let authData
        try {
            authData = localStorage.getItem(login_types.TOKEN_INFO) ? decryptData(localStorage.getItem(login_types.TOKEN_INFO)) : undefined
        }
        catch (e) {
            localStorage.clear()
            window.location.reload()
        }

        const local_access = authData?.accessToken
        config.headers.Authorization = `Bearer ${local_access}`
        return config;
    }

}, error => Promise.reject(error)
);


//global response interceptor
applyAppTokenRefreshInterceptor(axiosPrivate)

export { axiosPrivate as axios };