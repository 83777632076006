import { useEffect, useState } from 'react'
import { SimpleButton, ResponseMessage, PreLoader, ResponseModal, } from '../../common';
import PlanCard from '../../common/components/PlanCard'
import { useDispatch, useSelector } from "react-redux"
import isEmpty from "../../common/utils/isEmpty"
import { changeOrder } from '../../../redux/actions/myPlanAction';
import useActivePlanId from '../hooks/useActivePlanId';
import { GSTNote } from '../../common/boltOn/boltonComponetns/GSTtext';


const AddData = ({ handleClose, isSelectAllownce = false, back }) => {

    const [selectedPlan, setSelectedPlan] = useState({})
    const [isDisabledChangeOrder, setIsDisabledChangeOrder] = useState(false)
    const [isDataBoltonConfirm, setIsDataBoltonConfirm] = useState(false)
    const { dataBoltOnPlans, dataBoltOnPlansError, changeOrderSuccess, changeOrderError, activePlanData, childNumber } = useSelector(state => state.planReducer)
    const { optimusDetails } = useSelector(state => state.auth)
    const { si, si_lob, domain, cust_class, circle_id } = optimusDetails
    const dispatch = useDispatch()
    const planId = useActivePlanId(activePlanData)
    const submitBoltOn = () => {
        setIsDisabledChangeOrder(true)
        if (!isEmpty(selectedPlan)) {
            if (!isEmpty(optimusDetails) && dataBoltOnPlansError === "") {
                //write submission code here
                dispatch(changeOrder(planId, childNumber ? childNumber : si, si_lob, domain, cust_class, selectedPlan.productOfferingId, selectedPlan.name, circle_id))
            }
        }
    }

    //unmount
    useEffect(() => {
        return () => dispatch({ type: "CHANGE_ORDER_RESET" })
    }, [])

    useEffect(() => {
        if (changeOrderError) {
            setIsDataBoltonConfirm(false)
        }
        if (changeOrderSuccess) {
            setIsDataBoltonConfirm(false)
        }

    }, [changeOrderError, changeOrderSuccess])

    // sorting the list price wise
    function compare(a, b) {
        try {
            return (a.productPriceDetails && a.productPriceDetails.summaryOfCharges && a.productPriceDetails.summaryOfCharges.attribute.length ? `${a.productPriceDetails.summaryOfCharges.attribute[0].attributeValue}` : "") - (b.productPriceDetails && b.productPriceDetails && b.productPriceDetails.summaryOfCharges && b.productPriceDetails.summaryOfCharges.attribute.length ? `${b.productPriceDetails.summaryOfCharges.attribute[0].attributeValue}` : "")
        }
        catch (e) {
            return 0
        }
    }
    let dataBoltOnPlansList = {}
    if (dataBoltOnPlans && !isEmpty(dataBoltOnPlans && dataBoltOnPlans.epcProductSpec && dataBoltOnPlans.epcProductSpec.epcAddOns)) {
        dataBoltOnPlans.epcProductSpec.epcAddOns.sort(compare)
        dataBoltOnPlansList = dataBoltOnPlans

    }

    const openBoltOnConfirmation = () => {
        if (!isEmpty(selectedPlan)) {
            //at some product need to show confirmation message
            setIsDataBoltonConfirm(true)
        }

    }
    
    const closeAddonConfirm = () => {
        setIsDataBoltonConfirm(false)
    }

    return (
        <>
            <h1 className="semiMediumText change_plan_header my-2">Data Bolt on</h1>
            {
                isEmpty(dataBoltOnPlans) && dataBoltOnPlansError === "" &&

                <div id="pro-12" className="loading-screen" data-testid="profile_preloader">
                    <PreLoader id="pro-13" />
                </div>
            }

            {dataBoltOnPlansError !== "" && isEmpty(dataBoltOnPlansList) &&
                <>
                    <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            error
                            heading={dataBoltOnPlansError}
                            id="pro-15"
                        />
                        <div className="d-grid gap-3 success-close-button" id="pro-16" >
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                        </div>
                    </div>
                </>
            }
            {
                dataBoltOnPlansList && dataBoltOnPlansList.epcProductSpec && dataBoltOnPlansList.epcProductSpec.length === 0 && dataBoltOnPlansError === "" &&
                <>
                    <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            error
                            heading="Data not found"
                            id="pro-15"
                        />
                        <div className="d-grid gap-3 success-close-button" id="pro-16" >
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                        </div>
                    </div>

                </>
            }

            {
                dataBoltOnPlansList && dataBoltOnPlansList.epcProductSpec && dataBoltOnPlansList.epcProductSpec.epcAddOns.length > 0 && dataBoltOnPlansError === "" && changeOrderSuccess === "" && changeOrderError === "" &&
                <>
                    <div className="upgrade_plan_body">
                        {
                            dataBoltOnPlansList.epcProductSpec.epcAddOns.map((planData, i) => {
                                return (
                                    <PlanCard key={`dataBoltOnPlansList${i}`} planData={planData} callBack={(data) => setSelectedPlan(data)} index={i}/>
                                )
                            })
                        }
                    </div>
                    <GSTNote />
                    {/* <SimpleButton disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} variant="primary" className="mb-4" width="100%" onClick={() => isSelectAllownce ? back(selectedPlan) : submitBoltOn()} >
                        {isSelectAllownce ? "Select" : "Confirm"}
                    </SimpleButton> */}
                    <SimpleButton disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} variant="primary" className="mb-4" width="100%" onClick={() => openBoltOnConfirmation()} >
                        Confirm
                    </SimpleButton>
                </>
            }
                        <ResponseModal
                            show={isDataBoltonConfirm}
                            handleClose={() => closeAddonConfirm()}
                            size="md"
                        >
                            <>
                                <h3 className='text-danger'>IMPORTANT NOTICE</h3>
                              <p className='changeplan_confirm_p mb-4 text-justify'>
                              Please be advised the product you are selecting will be added to your account immediately. 
                              Please contact us 121 should you wish for this product to be removed at the end of the current billing period. 
                              Alternatively, the product will continue to be charged in following bill periods.         
                                </p>
                               
                                <SimpleButton disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} variant="primary" className="mb-4" width="100%" onClick={() => submitBoltOn()} >
                                   Continue
                                </SimpleButton>
                            </>
                        </ResponseModal>
            {
                changeOrderSuccess &&
                <div className="d-flex flex-column justify-content-between mt-5">
                    <ResponseMessage
                        success
                        heading="Request Submitted"
                        message={changeOrderSuccess}
                    />
                    <div className="success-close-button">
                        <SimpleButton variant="primary" maxWidth onClick={handleClose} >Close</SimpleButton>
                    </div>
                </div>
            }
            {changeOrderError &&
                <div className="d-flex flex-column justify-content-between mt-5">
                    {
                        String(changeOrderError).includes("Please be advised the product you have selected is already active on your number. Please select an alternative product to continue. Thank you.") ?
                            <ResponseMessage
                                info
                                heading={changeOrderError}
                            />
                            :
                                String(changeOrderError).includes("Open Order Exist Please re-check.") ?
                                <ResponseMessage
                                    info
                                    heading={changeOrderError}
                                />
                            :
                            <ResponseMessage
                                error
                                heading={changeOrderError}
                            />
                    }
                    <div className="d-grid gap-3 success-close-button" id="pro-16" >
                        <SimpleButton variant="primary" maxWidth onClick={() => { dispatch({ type: "CHANGE_ORDER_RESET" }); setIsDisabledChangeOrder(false) }} id="pro-17" data-testid="try_again">Try Again</SimpleButton>
                        <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Close</SimpleButton>
                    </div>
                </div>
            }
        </>
    )
}

export default AddData
