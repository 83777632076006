import arrow_up from '../../../Assets/images/billing/arrow_up.svg'
import arrow_down from '../../../Assets/images/billing/arrow_down.svg'
import { useSelector } from 'react-redux';
import { PROD_TYPE_DATACARD } from '../../dashboard/dashboardConstant';

const AcoordianBreak = ({ children, title, active, setActive, parent }) => {

    const { optimusDetails } = useSelector((state) => state.auth);
    const {  domain, prod_type } = optimusDetails;

    //this function will open/close the drawer
    const openAccordian = (data) => {

        if (active === data) {
            setActive("")
        } else {
            setActive(data)
        }
    }

    return (
        <div data-testid="custom_accordian_test" 
        className={domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? "" : "cursor" } 
        onClick={domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? "" : () => openAccordian(title)}>
            <div className="d-flex justify-content-between p-0 gap-4">
                <div className='flex-grow-1'>
                    {parent}
                </div>
                {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? ""
                 :
                 <img
                 data-testid="accordian_button"
                 className="accordian_img cursor"
                 //arrow up and down image change after closing/opening the drawer
                 src={active === title ? arrow_up : arrow_down}
                 alt="arrow" />
                  } 
            </div>
            <div className={(active === title ? "accordian_content_show" : "accordian_content")}>
                <div className="content_padding_braek">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default AcoordianBreak
