import { useSelector } from 'react-redux'
import { isEmpty } from '../../common/utils'

function useCustomerInfo() {
    const { customerInfo } = useSelector(state => state.dashboard)
    const SIDetail = customerInfo && !isEmpty(customerInfo) && customerInfo[0] ? customerInfo[0] : {}
    const isBroadband = isEmpty(SIDetail) ? undefined : SIDetail.imsi && SIDetail.imsi !== "" ? false : true
    return { isBroadband }
}

export default useCustomerInfo