//B2B component for showing total Mobile number
import BorderedCard from '../../../common/components/BorderedCard';
import { Row, Col } from 'react-bootstrap';
import { ReportIcon } from '../../../Icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PreLoader, ResponseMessage } from '../../../common';
import isEmpty from '../../../common/utils/isEmpty';
import { b2b_prefix } from '../../../envConfig';


const TotalMobileNumber = (props) => {
    let history = useHistory();
    const { subscriberHierarchyB2B, subscriberHierarchyErrorB2B } = useSelector(state => state.dashboard);
    const prefix = `/${b2b_prefix}`
    return (
        <BorderedCard className={`cursor mb-3`} onClick={() => history.push(`${prefix}/MSISDN`)}>

            {
                subscriberHierarchyB2B.data && !isEmpty(subscriberHierarchyB2B.data) && subscriberHierarchyErrorB2B === "" &&
                < Row data-testid="nxt_bill_post" id="dashboard-mobile-number-7" className="mb-14 ">
                    <Col id="dashboard-mobile-number-8" sm={1} md={1}>
                        <img
                            src={ReportIcon}
                            id="dashboard-mobile-number-8"
                            alt="report icone"
                            className="d-inline-block align-top"
                        />
                    </Col>
                    <Col id="dashboard-mobile-number-9" sm={11} md={11}>
                        <h5 id="dashboard-mobile-number-10" className="dasboard-bill mb-4">Mobile number</h5>
                        <div data-testid="consumer_payment_test" id="dashboard-mobile-number-12" className="dasboard-pound m-b-12 d-flex align-items-center">
                            {subscriberHierarchyB2B && subscriberHierarchyB2B?.data?.totalConnection ? subscriberHierarchyB2B.data.totalConnection : 0}
                            <span className="totalMobileNoText ms-1">Total mobile numbers</span>
                        </div>
                        {/* <div className="totalMobileNoPending m-b-2 px-3">{`${subscriberHierarchyB2B?.data?.pendingActivation >= 0 ? subscriberHierarchyB2B.data.pendingActivation : 0} mobile numbers activation pending`}</div> */}
                    </Col>
                </Row>
            }
            {
                Object.keys(subscriberHierarchyB2B).length === 0 && subscriberHierarchyErrorB2B === "" &&
                <div className="m-t-13">
                    <PreLoader />
                </div>
            }
            {
                Object.keys(subscriberHierarchyB2B).length === 0 && subscriberHierarchyErrorB2B !== "" &&
                <ResponseMessage error heading={subscriberHierarchyErrorB2B} imageClass="productErrorImage" headingClass="productErrorHeading" messageClass="productErrorMessage" />

            }
        </BorderedCard >
    )
}

export default TotalMobileNumber;