import React from 'react'

function AlertBox({ success, error, heading, paragraph }) {
    return (
        <div className={`alert-box ${error ? 'alert-error-bg' : 'alert-success-bg '}`}>
            <div className="icon-container">
                <div className="_icon">
                    {error && <>&#10006;</>}
                    {success && <>&#10004;</>}


                </div>
            </div>
            <div>
                {heading && <h4>{heading}</h4>}
                {paragraph && <p>{paragraph}</p>}

            </div>
        </div>
    )
}

export default AlertBox