// main layout componets
import React, { useState } from 'react';
import NavBar from "./components/navBar";
import Footer from "./components/footer";
import { useSelector } from 'react-redux'
//import { Drawer } from "../common";
//import HelpSupport from "../help&support/helpSupportMain";
//import avaFloating from "../../Assets/images/helpSupport/avaFloating.svg";
import isEmpty from '../common/utils/isEmpty';
let Layout = (props) => {

  const { optimusDetails } = useSelector(state => state.auth);

  return (
    <>
      <NavBar optimusDetails={optimusDetails ? optimusDetails : {}} />
      <div id="layout-container" className="layout-container">
        {props.children}
        {!isEmpty(optimusDetails) && <Footer />}
      </div>
    </>
  )
}

export default Layout;