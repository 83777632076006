import { Col, Container, Row } from "react-bootstrap"
import Chart from "./Chart"
import React from "react";
import { useSelector } from "react-redux";
import isEmpty from "../../common/utils/isEmpty";
import { extractMonth, isValidDate, PreLoader, ResponseMessage } from '../../common'

const BillingChartCard = () => {
    let ChartData = []
    const { userInvoiceSummary, errorInvoiceSummary } = useSelector(state => state.myBillsAllReducers);
    if (!isEmpty(userInvoiceSummary && userInvoiceSummary.data && userInvoiceSummary.data.invoiceDetails)) {
        userInvoiceSummary.data.invoiceDetails.forEach((data, index) => {
            ChartData.push({
                "id": index,
                "month": isValidDate(data.customerBillingCycle.billingDate) ? extractMonth(data.customerBillingCycle.billingDate) : "",
                "usage": data && data.customerBillingCycle && data.customerBillingCycle.partyBill && data.customerBillingCycle.partyBill.dueAmount
            })
        })
    }

    return (
        <div id="ch-1" className="p-3 card chart-card" data-testid="billing-chart-card">
            <Container>
                <Row id="ch-2" >
                    {userInvoiceSummary.data
                        &&
                        userInvoiceSummary.data.invoiceDetails
                        &&
                        userInvoiceSummary.data.invoiceDetails.length === 0
                        &&
                        errorInvoiceSummary === ""
                        ?
                        //<PreLoader id="chp-1" />
                        <ResponseMessage error heading={"Data not found"} />
                        :
                        (
                            ChartData.length > 0 ?
                                <>
                                    <Col id="ch-3" md={4}><h1>Billing</h1></Col>
                                    <Col id="ch-4" md={8}>

                                        <Chart
                                            data-testid="chart-component"
                                            data={ChartData}
                                        />

                                    </Col>
                                </>
                                :
                                errorInvoiceSummary !== ""
                                    ?
                                    <ResponseMessage
                                        id="iuiuiu"
                                        error
                                        heading={errorInvoiceSummary}
                                    />

                                    :
                                    <PreLoader data-testid="biling chart-loader" />
                        )
                    }
                </Row>
            </Container>
        </div>
    )
}

export default BillingChartCard
