// Card component of my product 
import { Badge } from 'react-bootstrap';
import simIcone from '../../../Assets/images/dashboard/sim.svg';

const MyProductCard = (props) => {
    let logicalResource = props.logicalResource ? props.logicalResource : {};
    let id = props.id ? props.id : `myproduct-${Math.random()}`;
    return (
        <div id={`${id}-card-1`} className={props.title === "Broadband" ? `dashboard-fiberproduct-card ${props.className}` :`dashboard-product-card ${props.className}`}>
            {props.title && <div id={`${id}-card-2`} className={props.title === "Broadband" ? `dashboard-product-type font-style font-weight-normal f-s-3 text-color text-center` : `dashboard-product-type font-style font-weight-bold f-s-3 text-color text-center`}>{props.title}</div>}
            <div id={`${id}-card-3`} className="dashboard-product-text text-center">
                {props.siStatus && props.siStatus === "Suspended" && <Badge bg="danger" >{props.siStatus}</Badge>}
                {<div id={`${id}-card-4`} className={`font-style dasboard-spend text-center ${props.siStatus && props.siStatus === "Suspended" ? 'mt-1' : 'mt-4'} mb-2`}>{logicalResource.type === "MSISDN" ? logicalResource.identification.id : ""} {props.number ? props.number : ""}</div>}
                <div id={`${id}-card-5`} className="dashboard-product-image">
                    <img
                        src={simIcone}
                        id={`${id}-card-6`}
                        alt="sim"
                        className="img-fluid align-top"
                    />
                </div>
            </div>
        </div >
    )
}
export default MyProductCard