export const social = {
    twitter: "https://twitter.com/airtelvodafone",
    linkedin: "https://www.linkedin.com/company/914769/admin/",
    insta: "https://www.instagram.com/airtel_vodafone/",
    fb: "https://www.facebook.com/airtelvodafone",
    youtube: "https://www.youtube.com/user/airtelvodafoneuk"
}
export const applicationStore = {
    AppStore: "https://apps.apple.com/us/app/airtel-vodafone/id1483419178",
    GooglePlay: "https://play.google.com/store/apps/details?id=com.airtelvodafone.ci"
}