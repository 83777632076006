import axios from "./";
import { myBills_Type } from "../helpers/types";
import moment from 'moment'
import { b64_to_string } from '../../components/common'
import { apiMyBill, apiErrorCode, lobSubLob } from '../helpers/ApiConstant'
import isEmpty from "../../components/common/utils/isEmpty";

const {
  DUBLICATE_INVOICE_SUCCESS,
  DUBLICATE_INVOICE_ERROR,
  GET_USER_PAYMENT_HISTORY,
  FAIL_USER_PAYMENT_HISTORY,
  GET_CUSTOMER_INVOICE_SUMMARY,
  FAIL_CUSTOMER_INVOICE_SUMMARY,
  GET_BREAKDOWN_DATA,
  ERROR_BREAKDOWN_DATA,
  BILL_PAYMENT_SUCCESS,
  BILL_PAYMENT_ERROR,
  CURRENT_MONTH_BILL_AMOUNT_ERROR,
  CURRENT_MONTH_BILL_AMOUNT_SUCCESS
} = myBills_Type

const {
  SOMETHING_WRONG,
  NO_INTERNET
} = apiErrorCode
const AccNotFound = ""
const { ebillPassword, currentInvoiceAmmountApi, breakdownBill, paymentHistory, customerInvoices, dublicateInvoice, payBillAPI, currentmonthBill, validatePaymentApi } = apiMyBill

const setSuccess = (data, type) => {
  return { data, type }
}
const setError = (error, type) => {
  return { error, type }
}


//this action will show the amount and method of previous transactions in billing section
export const getAllUserPaymentHistory = (domain, subLob, accountId) => {

  let config = {
    url: paymentHistory,
    method: "GET",
    params: {
      domain,
      subLob: lobSubLob[subLob],
      accountId,
    }
  }
  return (dispatch) => {
    return axios(config)
      .then(res => {
        if (res.status === 200) {
          dispatch(setSuccess(res.data, GET_USER_PAYMENT_HISTORY));
        }
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError(NO_INTERNET, FAIL_USER_PAYMENT_HISTORY))
        }
        else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
          dispatch(setError(err.response.data.errors[0].description, FAIL_USER_PAYMENT_HISTORY));
        }
        else {
          dispatch(setError(SOMETHING_WRONG, FAIL_USER_PAYMENT_HISTORY))
        }
      });
  }
}


//This action will get all the bills of previous payments done by the user in billing section
export const getCustomerInvoiceSummary = (msisdn, domain, subLob) => {

  let config = {
    url: customerInvoices,
    method: "GET",
    params: {
      subLob: lobSubLob[subLob],
      domain,
      msisdn,
    }
  }
  return (dispatch) => {
    return axios(config)
      .then(res => {
        if (res.status === 200) {
          dispatch(setSuccess(res.data, GET_CUSTOMER_INVOICE_SUMMARY))
        }
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError(NO_INTERNET, FAIL_CUSTOMER_INVOICE_SUMMARY))
        }
        else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
          if (err.response.data.errors[0].code === "BM_CIS_007") {
            dispatch(setError(err.response.data.errors[0].description, FAIL_CUSTOMER_INVOICE_SUMMARY));
          }
          else {
            dispatch(setError(err.response.data.errors[0].description, FAIL_CUSTOMER_INVOICE_SUMMARY));
          }
        }
        else {
          dispatch(setError(SOMETHING_WRONG, FAIL_CUSTOMER_INVOICE_SUMMARY))
        }
      });
  }
}



export const eBillPassword = (email, invoiceNo, msisdn, accountNumber) => {
  let config = {
    url: ebillPassword,
    method: "POST",
    data: {
      "msisdn": msisdn,
      "accountNumber": accountNumber,
      "invoiceNumber": invoiceNo,
      "emailAddress": b64_to_string(email),
    }
  }

  return axios(config)
}



//Shows the breakdown of the bill
export const getBillBreakdown = (accNo, invoice) => {

  let config = {
    url: `${breakdownBill}/${invoice}`,
    method: "GET",
    params: {
      accountNumber: accNo
    },
  }

  return (dispatch) => {
    return axios(config)
      .then(res => {
        if (res.status === 200) {
          dispatch(setSuccess(res.data, GET_BREAKDOWN_DATA));
        }
      })
      .catch(err => {

        if (!err.response) {
          dispatch(setError(NO_INTERNET, ERROR_BREAKDOWN_DATA))
        }

        else {

          if (err.response && err.response.data
            && err.response.data.meta
            && err.response.data.meta.status
            && err.response.data.meta.status === "BM_BBD_005") {
            dispatch(setError("Bill not found", ERROR_BREAKDOWN_DATA))
          }

          else {
            if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
              dispatch(setError(err.response.data.errors[0].description, ERROR_BREAKDOWN_DATA));
            }
            else {
              dispatch(setError(SOMETHING_WRONG, ERROR_BREAKDOWN_DATA))
            }
          }
        }


      });
  }
}

export const dublicateInvoiceStatus = (data) => {
  return {
    type: DUBLICATE_INVOICE_SUCCESS,
    data
  }
}

export const getDublicateInvoice = (data) => {
  let config = {
    url: dublicateInvoice,
    method: "GET",
    params: {
      invoiceNumber: data.invoiceNumber,
      accountNumber: data.custid,
      interactionDate: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      billingDate: moment((data.billingDate)).format("YYYY-MM-DDTHH:mm:ss"),
      circleId: data.circleId,
      emailAddress: b64_to_string(data.emailAddress),
    }
  }
  return (dispatch) => {
    return axios(config)
      .then(res => {
        if (res.status === 200 && res.data) {
          dispatch(setSuccess(res.data, DUBLICATE_INVOICE_SUCCESS));
        } else {
          dispatch(setError(SOMETHING_WRONG, DUBLICATE_INVOICE_ERROR));
        }
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError(NO_INTERNET, DUBLICATE_INVOICE_ERROR))
        }
        else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
          dispatch(setError(err.response.data.errors[0].description, DUBLICATE_INVOICE_ERROR));
        }
        else {
          dispatch(setError(SOMETHING_WRONG, DUBLICATE_INVOICE_ERROR))
        }
      });
  }
}


export const getCurrentInovieAmount = (domain, subLob, msisdn) => {
  let config = {
    url: currentInvoiceAmmountApi,
    method: "GET",
    params: {
      domain,
      subLob,
      msisdn
    }
  }
  return (dispatch) => {
    return axios(config)
      .then(res => {
        if (res.status === 200 && res.data.data) {
          dispatch(setSuccess(res.data.data, CURRENT_MONTH_BILL_AMOUNT_SUCCESS));
        } else {
          dispatch(setError(SOMETHING_WRONG, CURRENT_MONTH_BILL_AMOUNT_ERROR));
        }
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError(NO_INTERNET, CURRENT_MONTH_BILL_AMOUNT_ERROR))
        }
        else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
          dispatch(setError(err.response.data.errors[0].description, CURRENT_MONTH_BILL_AMOUNT_ERROR));
        }
        else {
          dispatch(setError(SOMETHING_WRONG, CURRENT_MONTH_BILL_AMOUNT_ERROR))
        }
      });
  }
}




export const payBill = (msisdn, domain, accountNo, invoiceNumber, subLob) => {
  const config = {
    url: payBillAPI,
    method: "POST",
    params: {
      domain
    },
    data: {
      services: {
        msisdn,
        accountNo,
        domain,
        subLob,
        applicationType: "web"
      },
      "transaction": {
        invoiceNumber
      }
    }
  }
  return (dispatch) => {
    return axios(config)
      .then(res => {
        if (res.status === 200) {
          if (res.data && res.data.data) {
            dispatch(setSuccess(res.data.data, BILL_PAYMENT_SUCCESS))
          }
          else {
            dispatch(setError(SOMETHING_WRONG, BILL_PAYMENT_ERROR))
          }

        }
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError(NO_INTERNET, BILL_PAYMENT_ERROR))
        }
        else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
          dispatch(setError(err.response.data.errors[0].description, BILL_PAYMENT_ERROR));
        }
        else {
          dispatch(setError(SOMETHING_WRONG, BILL_PAYMENT_ERROR))
        }
      });


  }
}

export const validatePayment = (token) => {
  let config = {
    url: validatePaymentApi,
    method: "GET",
    params: {
      token
    },
  }

  return axios(config)
}




export const getCurrentMonthBill = (domain, msisdn, sublob, accNo) => {

  let config = {
    url: currentmonthBill,
    method: "GET",
    params: {
      msisdn,
      domain,
      subLob: lobSubLob[sublob],
      currentBill: true
    },
  }

  return (dispatch) => {
    return axios(config)
      .then(res => {
        if (
          res.status === 200
          &&
          res.data
          &&
          res.data.data
          &&
          !isEmpty(res.data.data.invoiceDetails)
          &&
          res.data.data.invoiceDetails[0].customerPayment
          &&
          res.data.data.invoiceDetails[0].customerPayment.partyPayment
          &&
          res.data.data.invoiceDetails[0].customerPayment.partyPayment.referenceNumber
        ) {

          const invoice = res.data.data.invoiceDetails[0].customerPayment.partyPayment.referenceNumber

          if (!isEmpty(invoice)) {
            dispatch(getBillBreakdown(accNo, invoice))
          }

        } else {
          dispatch(dispatch(setError("Bill not found", ERROR_BREAKDOWN_DATA)));
        }
      })
      .catch(err => {
        if (!err.response) {
          dispatch(setError(NO_INTERNET, ERROR_BREAKDOWN_DATA))
        }
        else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
          dispatch(setError(err.response.data.errors[0].description, ERROR_BREAKDOWN_DATA));
        }
        else {
          dispatch(setError(SOMETHING_WRONG, ERROR_BREAKDOWN_DATA))
        }
      });
  }
}



