import React, { useState } from 'react'
import { ResponseModal, PreLoader, ResponseMessage, Drawer, b64_to_string } from "../../common";
import UpdateProfile from "../../common/myProfile/UpdateProfile";
import { useDispatch, useSelector } from 'react-redux';
import { eBillPassword } from "../../../redux/actions/myBillsAction";
import { apiErrorCode } from '../../../redux/helpers/ApiConstant'
import { emailRegex } from '../../common/utils';
import { fetchUserProfileDetail } from '../../../redux/actions/commonAction';

const { SOMETHING_WRONG, NO_INTERNET } = apiErrorCode
function RequestEbillPassword({ customerEmail }) {
    const dispatch = useDispatch();
    const [ebillSuccessMessage, setEbillSuccessMessage] = useState("")
    const [ebillErrorMessage, setEbillErrorMessage] = useState("")
    const [ebillInfoMessage, setEbillInfoMessage] = useState(false)
    const [isShowProfileDrawer, setIsShowProfileDrawer] = useState(false)
    const [showResponseModal, setShowResponseModal] = useState(false)
    const { userInvoiceSummary } = useSelector(state => state.myBillsAllReducers);

    const { optimusDetails } = useSelector(state => state.auth)
    const msisdn = optimusDetails.si ? optimusDetails.si : ""
    const accountNumber = optimusDetails.cust_ac_no ? optimusDetails.cust_ac_no : ""
    const { si, si_lob, domain } = optimusDetails

    const handleOpenResponseModal = () => setShowResponseModal(true)
    const handleCloseResponseModal = () => {
        setShowResponseModal(false)
        setEbillSuccessMessage("")
        setEbillErrorMessage("")
    }

    const TryAgainRequestEbillPassword = () => {
        handleCloseResponseModal()
        requestEbillPassword()
    }

    //request e-bill password action
    let email = customerEmail
    const requestEbillPassword = () => {
        if (email && emailRegex.test(String(email).toLocaleLowerCase())) {
            let invoiceNumber = "";
            //get the valid invoice number from previous invocice bills.
            if (Object.keys(userInvoiceSummary).length > 0) {
                if (userInvoiceSummary.data.invoiceDetails.length > 0) {
                    invoiceNumber = userInvoiceSummary.data.invoiceDetails[0].customerPayment.partyPayment.referenceNumber ? userInvoiceSummary.data.invoiceDetails[0].customerPayment.partyPayment.referenceNumber : ""
                }
            }
            else {
                return setEbillErrorMessage("No invoce generated yet")
            }

            handleOpenResponseModal()
            //api call
            eBillPassword(email, invoiceNumber, msisdn, accountNumber)
                .then(res => {
                    if (res.status === 200) {
                        setEbillSuccessMessage(`${res.data.meta.description} Please check your email box `)
                    }
                })
                .catch(err => {
                    if (!err.response) {
                        setEbillErrorMessage(NO_INTERNET)
                    }
                    else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                        setEbillErrorMessage(err.response.data.errors[0].description);
                    }
                    else {
                        setEbillErrorMessage(SOMETHING_WRONG)
                    }
                })
        }
        else if (email === "invalid") {
            handleOpenResponseModal()
            setEbillInfoMessage(true)
            return true
        }

        else {
            handleOpenResponseModal()
            setEbillInfoMessage(true)
        }

    }

    const openProfileDrawer = (e) => {
        handleCloseResponseModal()
        setIsShowProfileDrawer(true)
    }

    const closeProfileDrawer = (e) => {
        if (si && si_lob && domain) {
            dispatch(fetchUserProfileDetail(si_lob, domain, si))
        }
        setIsShowProfileDrawer(false)
        setTimeout(() => {
            document.body.removeAttribute('style')
        },
            1000)

    }

    return (
        <>
            <p id="billing_section_main_6" className="mt-1 lightText cursor" style={{ color: "#E60000" }} onClick={requestEbillPassword} data-testid="requestEbillPass"><u>
                Request ebill password
            </u>
            </p>
            <ResponseModal id="rs-22" show={showResponseModal} handleClose={handleCloseResponseModal} data-testid="response-modal">
                {!ebillSuccessMessage && !ebillErrorMessage && !ebillInfoMessage && <PreLoader />}
                {ebillSuccessMessage &&
                    <ResponseMessage
                        id="rs-23"
                        success heading={"Success"}
                        message={<>E-bill password has been sent to your registered mobile number {msisdn ? <strong>+{msisdn}</strong> : ""}</>}
                        button={{ label: "Close", onClick: handleCloseResponseModal }}
                    />
                }
                {ebillErrorMessage &&
                    <ResponseMessage
                        id="rs-2se"
                        error
                        heading={"Warning"}
                        message={ebillErrorMessage}
                        button={{ label: "Try Again", onClick: TryAgainRequestEbillPassword }}
                    />}
                {ebillInfoMessage &&
                    <ResponseMessage
                        id="rs-22s32"
                        info
                        heading={"Warning"}
                        message={<>Please add email address to your profile</>}
                        button={{ label: "Update profile", onClick: openProfileDrawer }}

                    />
                }

            </ResponseModal>
            {/* profile side bar code */}
            <Drawer
                id="9s99s"
                body={<UpdateProfile closeDrawer={closeProfileDrawer} />}
                isOpen={isShowProfileDrawer}
                closeDrawer={closeProfileDrawer}
            />
        </>
    )
}

export default RequestEbillPassword
