import { WhiteDownArrowIcon, WhiteUpArrowIcon } from '../../Icons'

const AcoordianFooter = ({ children, title, active, setActive, name, ...rest }) => {

    //this function will open/close the drawer
    const openAccordian = (data) => {

        if (active === data) {
            setActive("")
        } else {
            setActive(data)
        }
    }

    return (
        <div data-testid="custom_accordian_test" className="custom_accordian_footer" {...rest}>
            <div className="accordingHeading">
                <div className="d-flex justify-content-between p-0">
                    <div>
                        <span >{name}</span>
                    </div>
                    <img onClick={() => openAccordian(title)}
                        data-testid="accordian_button"
                        className="accordian_img cursor"
                        //arrow up and down image change after closing/opening the drawer
                        src={active === title ? WhiteUpArrowIcon : WhiteDownArrowIcon}
                        alt="arrow" />
                </div>
            </div>
            <div className={(active === title ? "accordian_content_show" : "accordian_content")}>
                <div className="content_padding">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default AcoordianFooter
