
import { login_types, common_types } from '../helpers/types';

let {
    GENERATE_OTP_SUCCESS,
    GENERATE_OTP_ERROR,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_ERROR,
    RESET_LOGIN_META,
    SAVE_AUTH,
    LOGOUT_ERROR,
    LOGOUT_RESET
} = login_types;



let {
    GET_OPTIMUS_DETAILS,
} = common_types;

export const authInitState = {
    auth: { isAuthenticate: false, userType: "" },
    generateOTPSuccess: {},
    generateOTPError: {},
    verifyOTPSuccess: "",
    verifyOTPError: "",
    optimusDetails: {},
    logoutError: ""

};

const auth = (state = authInitState, action = {}) => {
    const { type, data, error } = action;
    switch (type) {
        case GET_OPTIMUS_DETAILS:
            return {
                ...state,
                optimusDetails: data,

            };
        case GENERATE_OTP_SUCCESS:
            return {
                ...state,
                generateOTPSuccess: data,

            };
        case GENERATE_OTP_ERROR:
            return {
                ...state,
                generateOTPError: error,

            };
        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                verifyOTPSuccess: data

            };
        case SAVE_AUTH:
            return {
                ...state,
                auth: data

            };
        case VERIFY_OTP_ERROR:
            return {
                ...state,
                verifyOTPError: error


            };
        case RESET_LOGIN_META:
            return {
                ...state,
                generateOTPSuccess: {},
                generateOTPError: {},
                verifyOTPSuccess: "",
                verifyOTPError: ""

            };

        case LOGOUT_ERROR:
            return {
                ...state,
                logoutError: error
            }
        case LOGOUT_RESET:
            return {
                ...state,
                logoutError: ""
            }
        default:
            return state;
    }
};

export { auth };
