
import moment from 'moment';
import isEmpty from './isEmpty'

//regex
export const nameRegex = /^[^-\s][A-Za-z\s]{2,50}$/
export const mobileRegex = /^[17]\d{9}$/
export const emailRegex = /^[a-zA-Z0-9._?{|}]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const getCurrency = (currencyAmt = 0) => <>{currencyAmt < 0 ? <>-&#163;</> : <>&#163;</>}{""}{currencyAmt ? Math.abs(parseFloat(currencyAmt).toFixed(2)) : 0}</>
export const divideByCurrencey = (currencyAmt = 0) => parseFloat(currencyAmt / 100).toFixed(2)  // amount is coming from the API is multipled by 100 so that is why we need to devide with 100 to show to correct ammonut
export const dateFormatter = (data = new Date()) => isValidDate(data) ? moment(data).format("Do MMM YY") : ""
export const transactionIdgenerator = () => Math.floor(100000 + Math.random() * 900000)
export const extractMonth = (data) => {
  const newDate = new Date(data)
  return newDate.toLocaleString("default", { month: "short" })
}
export const getScreenResolution = () => {
  if (window) {
    return {
      height: window.screen.availHeight,
      width: window.screen.availWidth
    }
  }
}


export const findByTestAttr = (wrapper, val) => {
  return wrapper.find(`[data-test="${val}"]`);
}


export function b64_to_string(str = "") {
  str = String(str)
  if (str && !emailRegex.test(String(str).toLocaleLowerCase())) {
    try {
      return decodeURIComponent(escape(window.atob(str)));
    }
    catch (e) {
      return "invalid"
    }

  }
  else {
    return str
  }

}

export const getMobileNumber = (number = "") => {
  number = String(number)
  let prefix = number.slice(0, 2)
  if (prefix === "44" && number.length === 12) {
    return String(number).substring(2);
  }
  else
    return number
}

export const isValidDate = (d = new Date()) => {
  d = Date.parse(d)
  if (!isNaN(d)) {
    return true
  }
  else {
    return false
  }
}

//write this formater beacuse in some cases date is recived in dd/mm/yyy which is invalid format for js
export const breakDownDateFormatter = (dt = new Date()) => {
  const [day, month, year] = dt.split('/');

  // 👇️ format Date string as `yyyy-mm-dd`
  const isoFormattedStr = `${year}-${month}-${day}`;
  return new Date(isoFormattedStr);
}

export const sum = (arr = [], key = "") => {
  let ans = arr.reduce((a, b) => a + (b[key] || 0), 0)
  return ans ? parseFloat(ans).toFixed(2) : 0
}

export { isEmpty }



