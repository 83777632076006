import CryptoJS from 'crypto-js';
import { encSalt } from '../../envConfig';

var encrypt = ''
var dcrypt = ""

export const encryptData = (data, salt) => {
    salt = salt || encSalt
    try {
        encrypt = CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
        return encrypt
    }
    catch (err) {
        return data
    }

}

export const decryptData = (ciphertext, salt) => {
    salt = salt || encSalt
    try {
        const bytes = CryptoJS.AES.decrypt(ciphertext, salt);
        dcrypt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return dcrypt
    }
    catch (err) {
        return ciphertext
    }

}
