import addonsIcone from '../../../Assets/images/dashboard/add-ons-hi.png';
import BorderedCard from '../../common/components/BorderedCard';

const Addons = (props) => {


    /**********************************POSTPAID COMPONENT**************************************************/
    /**********************************POSTPAID COMPONENT**************************************************/

    const postPaidComp = (
        <BorderedCard className="cardHeight cursor addons" onClick={props.onClick}>
            <div data-testid="addon_post_comp" id="dashboard-post-67" className="d-flex flex-row mb-32">
                <img
                    src={addonsIcone}
                    id="dashboard-post-68"
                    alt="report icone"
                    className="d-inline-block align-top"
                    onClick={props.onClick}
                />
                <div id="dashboard-post-69" className="dasboard-bill-details font-style font-weight-bold m-l-5">
                    Bolt on
                </div>

            </div>
            <div id="dashboard-post-70" className="dasboard-bill-details font-style ">
            Manage individual number
            </div>
        </BorderedCard>
    )


    /**********************************PREPAID COMPONENT**************************************************/
    /**********************************PREPAID COMPONENT**************************************************/

    const prepaidComp = (
        <BorderedCard className="cardHeight cursor addons" onClick={props.onClick}>
            <div data-testid="addon_prepaid_comp" id="dashboard-prep-67" className="d-flex flex-row mb-32">
                <img
                    src={addonsIcone}
                    id="dashboard-prep-68"
                    alt="report icone"
                    className="d-inline-block align-top"
                />
                <div id="dashboard-prep-69" className="dasboard-bill-details font-style font-weight-bold">
                    Manage bolt on
                </div>
            </div>
            <div id="dashboard-prep-70" className="dasboard-bill-details font-style noselect white_font">
                .
            </div>
        </BorderedCard>
    )

    return (
        //here the component will according to the props prepaid/postpaid
        <div>
            {props.type === "postpaid" ? postPaidComp : ""}
            {props.type === "prepaid" ? prepaidComp : ""}
        </div>
    )
}
export default Addons