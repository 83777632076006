import { useEffect, useRef, useState } from 'react';
import BorderedCard from '../../common/components/BorderedCard';
import { Row, Col } from 'react-bootstrap';
import reportIcone from '../../../Assets/images/dashboard/report.svg';
import PreLoader from "../../common/components/PreLoader"
import { ResponseMessage, dateFormatter, breakDownDateFormatter, getCurrency } from '../../common';
import NoBillScreen from '../../mybills/components/NoBillScreen';
import { divideByCurrencey, isEmpty } from '../../common/utils';
import { useSelector } from 'react-redux';

const NextBill = (props) => {
    const CardProperty = useRef(null);
    const [margin, setMargin] = useState(0);
    const { currentBillAmmount } = useSelector(state => state.myBillsAllReducers)
    let billData = props.nextBill && props.nextBill.data && props.nextBill.data ? props.nextBill.data : {};
    // let customerPayment = billData && billData.customerPayment ? billData.customerPayment : {}
    const updateHeight = () => {
        setMargin((CardProperty.current.offsetHeight - 16) / 4);
    };
    useEffect(() => {
        updateHeight();
    }, []);

    /**********************************PREPAID COMPONENT**************************************************/
    const prepaidComp = (
        <></>
        //     <BorderedCard className="cursor">
        //         <Row data-testid="nxt_bill_prep" id="dashboard-prep-7" className="mb-14">
        //             <Col id="dashboard-prep-8" sm={1}>
        //                 <img
        //                     src={reportIcone}
        //                     id="dashboard-prep-8"
        //                     alt="report icone"
        //                     className="d-inline-block align-top"
        //                 />
        //             </Col>
        //             <Col id="dashboard-prep-9" sm={11}>
        //                 <h5 id="dashboard-prep-10" className="dasboard-bill mb-0">Top-Up</h5>
        //             </Col>
        //         </Row>
        //         <div id="dashboard-prep-11" className="font-style dasboard-spend">Your credit is</div>
        //         <div id="dashboard-prep-12" className="font-style dasboard-pound mb-5">£16.00</div>
        //         <div id="dashboard-prep-13" className="d-flex justify-content-between py-3 border-bottom" >
        //             <span id="dashboard-prep-14" className="font-style dasboard-bill-details">Your spendings</span>
        //             <span id="dashboard-prep-15" className="font-style dasboard-bill-details">£16</span>
        //         </div>
        //         <div id="dashboard-prep-16" className="d-flex justify-content-between py-3 border-bottom" >
        //             <span id="dashboard-prep-17" className="font-style dasboard-bill-details">Vodafone special 50</span>
        //             <span id="dashboard-prep-18" className="font-style dasboard-bill-details">£30</span>
        //         </div>
        //         <div id="dashboard-prep-19" className="d-flex justify-content-between py-3 border-bottom" >
        //             <span id="dashboard-prep-20" className="font-style dasboard-bill-details">Top Up</span>
        //             <span id="dashboard-prep-21" className="font-style dasboard-bill-details">£8.00</span>
        //         </div>
        //         <div id="dashboard-prep-22" className="d-flex justify-content-between py-3 border-bottom" >
        //             <span id="dashboard-prep-23" className="font-style dasboard-bill-details">Out of bundle cost</span>
        //             <span id="dashboard-prep-24" className="font-style dasboard-bill-details">£30</span>
        //         </div>
        //         {/* <Button onClick={(e)=>e.stopPropagation()} className="mt-4 bg-white dashboard-addButton" size="lg" active>
        //         Add Data
        // </Button > */}
        //         <p id="dashboard-prep-25" className="mb-0 mt-5 dasboard-bill-details noselect white_font">.</p>
        //     </BorderedCard>
    )

    /**********************************POSTPAID COMPONENT**************************************************/
    /**********************************POSTPAID COMPONENT**************************************************/
    /**********************************POSTPAID COMPONENT**************************************************/

    const postpaidComp = (
        <BorderedCard className={`cursor ${(!billData.BREAKUP_ACCOUNT_DETAILS || props.errorBreakdown) ? `error-loader-height` : ""}`}>
            {!billData.BREAKUP_ACCOUNT_DETAILS && props.errorBreakdown === ""
                ?
                <div className="loader-position">
                    <PreLoader />
                </div>
                :
                props.errorBreakdown === "Bill not found"
                    ?
                    <div style={{ marginTop: margin }} >
                        <NoBillScreen />

                    </div>

                    :

                    props.errorBreakdown

                        ?
                        <div style={{ marginTop: margin }} >
                            <ResponseMessage
                                id="Bill-section-rs-55t534"
                                error
                                heading={props.errorBreakdown}
                            />
                        </div>
                        : billData.BREAKUP_ACCOUNT_DETAILS ?

                            <>
                                <Row data-testid="nxt_bill_post" id="dashboard-7" className="mb-15">
                                    <Col id="dashboard-8" sm={1}>
                                        <img
                                            src={reportIcone}
                                            id="dashboard-8"
                                            alt="report icone"
                                            className="d-inline-block align-top"
                                        />
                                    </Col>
                                    <Col id="dashboard-9" sm={11}>
                                        <h5 id="dashboard-10" className="dasboard-bill mb-3">Total Outstanding</h5>
                                    </Col>
                                </Row>
                                {/* <div id="dashboard-11" className="font-style dasboard-spend">Your current spend</div> */}
                                <div data-testid="consumer_payment_test" id="dashboard-12" className="font-style dasboard-pound mb-5">{getCurrency(!isEmpty(currentBillAmmount) && currentBillAmmount.customerBill && currentBillAmmount.customerBill.partyBill && divideByCurrencey(currentBillAmmount.customerBill.partyBill.dueAmount))}</div>
                                <div id="dashboard-13" className="d-flex justify-content-between py-3 m-b-3 border-bottom" >
                                    <span id="dashboard-14" className="font-style dasboard-bill-details">This month's charges</span>
                                    <span id="dashboard-15" className="font-style dasboard-bill-details">{billData.accountSummary && billData.accountSummary.thisMonthCharges && billData.accountSummary.thisMonthCharges.total ? getCurrency(billData.accountSummary.thisMonthCharges.total) : 0}</span>
                                </div>
                                <div id="dashboard-16" className="d-flex justify-content-between py-3 m-b-3 border-bottom" >
                                    <span id="dashboard-17" className="font-style dasboard-bill-details"> Payments</span>
                                    <span id="dashboard-18" className="font-style dasboard-bill-details">{billData.accountSummary && billData.accountSummary.payments ? getCurrency(billData.accountSummary.payments) : 0}</span>
                                </div>
                                <div id="dashboard-19" className="d-flex justify-content-between py-3 m-b-31 border-bottom" >
                                    <span id="dashboard-20" className="font-style dasboard-bill-details">Adjustments</span>
                                    <span id="dashboard-21" className="font-style dasboard-bill-details">{billData.accountSummary && billData.accountSummary.adjustments ? getCurrency(billData.accountSummary.adjustments) : 0}</span>
                                    {/* BREAKUP_ONE_TIME_CHARGES */}
                                </div>
                                <div id="dashboard-22" className="d-flex justify-content-between py-3 border-bottom" >
                                    <span id="dashboard-23" className="font-style dasboard-bill-details font-color"> Previous balance</span>
                                    <span id="dashboard-24" className="font-style dasboard-bill-details font-color">{billData.accountSummary && billData.accountSummary.previousBalance ? getCurrency(billData.accountSummary.previousBalance) : 0}</span>
                                </div>

                                <p id="dashboard-25" className="mb-0 m-t-12 font-style dasboard-bill-details">{`To be paid ${billData.BREAKUP_ACCOUNT_DETAILS && billData.BREAKUP_ACCOUNT_DETAILS.DUE_DATE ? dateFormatter(breakDownDateFormatter(billData.BREAKUP_ACCOUNT_DETAILS.DUE_DATE)) : ""}`}</p>
                            </>
                            :
                            <div style={{ marginTop: margin }} >
                                <ResponseMessage
                                    id="Bill-section-rs-55t534"
                                    error
                                    heading={props.errorBreakdown}
                                />
                            </div>
            }
        </BorderedCard>
    )

    return (
        //here the component will according to the props prepaid/postpaid
        <div ref={CardProperty}>
            {props.type === "postpaid" ? postpaidComp : ""}
            {props.type === "prepaid" ? prepaidComp : ""}
        </div>
    )
}
export default NextBill