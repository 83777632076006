import React from "react";

//this comp will used to wrap another component and to create shadowed borders around it

const BorderedCard = (props) => {
  return (
    <div id="border_card_1" className={`card BillCardCss ${props.className}`} onClick={props.onClick} title={props.title}>
      <div id="border_card_2" className="card-body">{props.children}</div>
    </div>
  );
};

export default BorderedCard;
