import React, { useEffect, useState } from 'react'
import { AlertMessage, PreLoader, ResponseMessage, Select, SimpleButton, TextField } from '../../common'
import backArrow from "../../../Assets/images/helpSupport/backArrow.svg"
import isEmpty from '../../common/utils/isEmpty'
import { fetchRaiseServiceMasterData, submitRaiseService, raiseSrviceRequestB2B } from '../../../redux/actions/commonAction'
import { useDispatch, useSelector } from 'react-redux'
import { helSupport } from '../../../redux/helpers/types'
import useValidator from '../../common/utils/useValidator';
import { useHistory } from 'react-router-dom'
import { b2b_prefix } from '../../envConfig'

const { RAISE_SERVICE_RESET } = helSupport
const ServiceRequest = (props) => {
    const history = useHistory()
    const prefix = `/${b2b_prefix}`
    const [validator, showValidationMessage] = useValidator();
    const { closeDrawer } = props
    const dispatch = useDispatch()
    const { raiseServiceMasterDetail, raiseServiceMasterError, raiseServiceSubmitSuccess, raiseServiceSubmitError, b2bRaiseServiceSubmitSuccess, b2bRaiseServiceSubmitError } = useSelector(state => state.common)
    const { optimusDetails } = useSelector(state => state.auth)
    const { si, si_lob, domain } = optimusDetails
    let initState = {
        requestType: "",
        subType: "",
        subSubType: "",
        subtypeOption: [],
        subSubtypeOption: [],
        caseType: ""
    }
    const [selectedData, setSelectedData] = useState(initState)
    const [description, setDescription] = useState("")
    const [masterError, setMasterError] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const onChange = (e, setFunc) => {

        const { value, name } = e.target
        if (name === "requestType") {
            let data = {}
            if (raiseServiceMasterDetail.data && raiseServiceMasterDetail.data.SRResponse && !isEmpty(value)) {
                data = raiseServiceMasterDetail.data.SRResponse.find(i => i.value === value);
            }
            selectedData.subType = "";
            selectedData.subSubType = "";
            selectedData.subtypeOption = data.subTypeList ? data.subTypeList : "";
            selectedData.subSubtypeOption = [];

            // setSelectedData({ ...selectedData })

        } else if (name === "subType" && !isEmpty(value)) {
            let data = {}
            if (selectedData.subtypeOption && selectedData.subtypeOption.length) {
                data = selectedData.subtypeOption.find(i => i.value === value);
            }
            selectedData.subSubType = "";
            selectedData.subSubtypeOption = data.subSubTypeList ? data.subSubTypeList : "";

        } else if (name === "subSubType" && !isEmpty(value)) {
            let data = {}
            if (selectedData.subSubtypeOption && selectedData.subSubtypeOption.length) {
                data = selectedData.subSubtypeOption.find(i => i.value === value);
            }
            selectedData.caseType = data.case ? data.case : ""
        }

        setSelectedData({ ...selectedData, [name]: value })
    }

    const onSubmitHandler = e => {
        e.preventDefault()
        if (validator.allValid()) {
            setIsLoading(true)
            selectedData.description = description
            if (props.isB2B) {
                let msisdnList = props.msisdnList.length > 0 ? props.msisdnList.map(item => item.msisdn) : []
                dispatch(raiseSrviceRequestB2B(si_lob, domain, si, selectedData, msisdnList))
            }
            else {
                dispatch(submitRaiseService(si_lob, domain, si, selectedData))
            }
            setMasterError("")
        } else {
            showValidationMessage(true)
            // setMasterError("All fields are required")
        }
    }

    useEffect(() => {
        setIsLoading(true)
        dispatch(fetchRaiseServiceMasterData())
    }, [dispatch])

    useEffect(() => {
        return () => dispatch({ type: RAISE_SERVICE_RESET })
    }, [])

    useEffect(() => {
        if (raiseServiceSubmitError) {
            setIsLoading(false)
        }
        if (raiseServiceSubmitSuccess) {
            setIsLoading(false)
        }
        if (raiseServiceMasterError) {
            setIsLoading(false)
        }
        if (raiseServiceMasterDetail) {
            setIsLoading(false)
        }
        if (b2bRaiseServiceSubmitError) {
            setIsLoading(false)
        }
        if (b2bRaiseServiceSubmitSuccess) {
            setIsLoading(false)
        }
    }, [raiseServiceSubmitError, raiseServiceMasterDetail, raiseServiceSubmitSuccess, raiseServiceMasterError, b2bRaiseServiceSubmitSuccess, b2bRaiseServiceSubmitError])




    const resetErrorClose = () => {
        // dispatch({ type: RAISE_SERVICE_RESET })
        closeDrawer()
    }

    // b2b response counts
    let successCount;
    let errorCount;
    if (b2bRaiseServiceSubmitSuccess && !isEmpty(b2bRaiseServiceSubmitSuccess)) {
        successCount = b2bRaiseServiceSubmitSuccess.length > 0 && b2bRaiseServiceSubmitSuccess.filter(item => item.status === "Success").length
        errorCount = b2bRaiseServiceSubmitSuccess.length > 0 && b2bRaiseServiceSubmitSuccess.filter(item => item.status === "Failed").length
    }

    return (
        <>
            {raiseServiceSubmitError === "" && raiseServiceSubmitSuccess === "" && raiseServiceMasterError === "" && isEmpty(b2bRaiseServiceSubmitSuccess) && b2bRaiseServiceSubmitError === "" && !isLoading &&
                <>
                    <div onClick={props.onStepChange} className="mb-3 cursor" >
                        <img
                            src={backArrow}
                            alt="backArrow"
                            className="d-inline-block align-top"
                        />
                    </div>
                    <div className="service_request_main help_Support_layout mt-2" data-testid={"serv_req_comp"}>
                        <h3 className="smallText mb-3">Raise a service request</h3>
                        <h1 className="semiMediumLight mb-2">Request details</h1>
                        {masterError && <AlertMessage variant="error" message={masterError} />}
                        <Select
                            value={selectedData.requestType}
                            name="requestType"
                            placeholder="Request type"
                            onChange={onChange}
                            options={!isEmpty(raiseServiceMasterDetail) ? raiseServiceMasterDetail.data.SRResponse : []}
                            keyName="value"
                            keyValue="value"
                            className="mb-4"
                            error={validator.message('requestType', selectedData.requestType, 'required')}
                        />
                        <Select
                            value={selectedData.subType}
                            name="subType"
                            placeholder="Sub Type"
                            onChange={onChange}
                            options={!isEmpty(selectedData.subtypeOption) ? selectedData.subtypeOption : []}
                            keyName="value"
                            keyValue="value"
                            className="mb-4"
                            error={validator.message('subType', selectedData.subType, 'required')}
                        />
                        <Select
                            value={selectedData.subSubType}
                            name="subSubType"
                            placeholder="Sub sub type"
                            onChange={onChange}
                            options={!isEmpty(selectedData.subSubtypeOption) ? selectedData.subSubtypeOption : []}
                            keyName="value"
                            keyValue="value"
                            className="mb-4"
                            error={validator.message('subSubType', selectedData.subSubType, 'required')}
                        />

                        <TextField
                            required
                            type="textArea"
                            name="description"
                            label="Description."
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            error={validator.message('description', description, 'required')}

                        />

                        <SimpleButton variant="primary" className="mt-4" maxWidth onClick={onSubmitHandler} >
                            Submit
                        </SimpleButton>
                    </div>
                </>
            }
            {isLoading &&
                <div className="loading-screen" data-testid>
                    <PreLoader />
                </div>}
            {
                raiseServiceSubmitError && <>
                    <div className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            error
                            heading={raiseServiceSubmitError}
                        />
                        <div className="d-grid gap-3 success-close-button">
                            <SimpleButton variant="primary" maxWidth onClick={() => dispatch({ type: RAISE_SERVICE_RESET })}>Try Again</SimpleButton>
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={resetErrorClose} >Cancel</SimpleButton>
                        </div>
                    </div>
                </>
            }
            {
                raiseServiceSubmitSuccess && <>
                    <div className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            success
                            heading={raiseServiceSubmitSuccess}
                        />
                        <div className="success-close-button">
                            <SimpleButton variant="primary" maxWidth onClick={resetErrorClose}>Close</SimpleButton>
                        </div>
                    </div>
                </>
            }
            {
                raiseServiceMasterError &&
                <div className="d-flex flex-column justify-content-between mt-5">
                    <ResponseMessage
                        error
                        heading={raiseServiceMasterError}
                    />
                    <div className="d-grid gap-3 success-close-button">
                        <SimpleButton variant="primary" maxWidth onClick={props.onStepChange}>Back</SimpleButton>
                        <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={resetErrorClose} >Cancel</SimpleButton>
                    </div>
                </div>
            }
            {
                b2bRaiseServiceSubmitError &&
                <div className="d-flex flex-column justify-content-between mt-5">
                    <ResponseMessage
                        error
                        heading={b2bRaiseServiceSubmitError}
                    />
                    <div className="d-grid gap-3 success-close-button">
                        <SimpleButton variant="primary" maxWidth onClick={props.onStepChange}>Back</SimpleButton>
                        <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={resetErrorClose} >Cancel</SimpleButton>
                    </div>
                </div>
            }
            {
                b2bRaiseServiceSubmitSuccess && b2bRaiseServiceSubmitSuccess.length > 0 &&

                <div className="d-flex flex-column justify-content-between mt-5">
                    <ResponseMessage
                        success={successCount > 0 ? true : false}
                        info={errorCount >= 1 && successCount === 0 ? true : false}
                        heading={errorCount >= 1 && successCount === 0 ? "Failed" : "Request processed successfully."}
                        message={<>{successCount > 0 ?
                            successCount === 1 && errorCount === 0 ? 'Request has been raised successfully' :
                                `${successCount >= 1 ? successCount : ""} Request has been raised successfully${errorCount > 0 ? " and " + errorCount + " has been failed" : ""}`
                            : errorCount > 0 ? `${errorCount > 1 ? errorCount : ""} Request has been failed` : ""}
                            {successCount > 1 || errorCount > 1 || (successCount === 1 && errorCount === 1) ? ` out of ${props.msisdnList.length} request` : ""}
                        </>}
                    />
                    <div className="success-close-button">
                        <SimpleButton variant="primary" maxWidth onClick={() => {
                            history.push({
                                pathname: `${prefix}/service_response`,
                                state: { data: { b2bRaiseServiceSubmitSuccess }, }
                            })
                        }}>View in detail</SimpleButton>
                    </div>
                </div>

            }
        </>
    )
}

export default ServiceRequest
