// Contain the reducer of Dashboard
import { myplanType } from '../helpers/types';

let {
  GET_LOCAL_BOLTON_SUCCESS,
  GET_LOCAL_BOLTON_ERROR,
  GET_ROAMING_BOLTON_SUCCESS,
  GET_ROAMING_BOLTON_ERROR,
  GET_DATA_BOLTON_SUCCESS,
  GET_DATA_BOLTON_ERROR,
  GET_ACTIVE_PLAN_SUCCESS,
  GET_ACTIVE_PLAN_ERROR,
  GET_ACC_LEVEL_BOLTON,
  GET_ACC_LEVEL_BOLTON_ERROR,
  GET_PROMOLIST_DATA,
  ERROR_PROMOLIST_DATA,
  MINUTES_SMS_USAGE_SUCCESS,
  DATA_USAGE_ERROR,
  DATA_USAGE_SUCCESS,
  MINUTES_SMS_USAGE_ERROR,
  CHANGE_ORDER_ERROR,
  CHANGE_ORDER_SUCCESS,
  CHANGE_ORDER_RESET,
  GET_IR_ADDONS,
  GET_DATA_ADDONS,
  GET_MSISDN_DETAILS,
  ERROR_MSISDN,
  ERROR_DATA_ADDONS
} = myplanType;


export const initState = {
  dataUsage: {},
  dataUsageError: "",
  childNumber: "",
  localBoltOnPlans: {},
  localBoltOnPlansError: "",
  accLevelBolton: {},
  accLevelBoltonError: "",
  romaingBoltOnPlans: {},
  romaingBoltOnPlansError: "",
  dataBoltOnPlans: {},
  dataBoltOnPlansError: "",
  activePlanData: {},
  activePlanError: "",
  promoList: {},
  errorPromoList: "",
  minutesAndSmsUsage: {},
  minutesAndSmsUsageError: "",
  changeOrderSuccess: "",
  changeOrderError: "",
  allgetDataBoltOn: {},
  errorDataBolton: "",
  detailsMsisdn: {},
  errorMsisdn: ""
};

const planReducer = (state = initState, action = {}) => {
  const { type, data, error } = action;
  switch (type) {
    case "SET_CHILD_NUMBER":
      return {
        ...state,
        childNumber: data
      }
    case "RESET_CHILD_NUMBER":
      return {
        ...state,
        childNumber: "",
        localBoltOnPlans: {},
        localBoltOnPlansError: "",
        romaingBoltOnPlans: {},
        romaingBoltOnPlansError: "",
        dataBoltOnPlans: {},
        dataBoltOnPlansError: "",
        activePlanData: {},
        activePlanError: "",
        promoList: {},
        errorPromoList: "",
        minutesAndSmsUsage: {},
        minutesAndSmsUsageError: "",
      }
    case GET_LOCAL_BOLTON_SUCCESS:
      return {
        ...state,
        localBoltOnPlans: data,
        localBoltOnPlansError: ""
      };
    case GET_LOCAL_BOLTON_ERROR:
      return {
        ...state,
        localBoltOnPlansError: error,
        localBoltOnPlans: {}
      };
    case GET_ACC_LEVEL_BOLTON:
      return {
        ...state,
        accLevelBolton: data,
        accLevelBoltonError: "",
      };
    case GET_ACC_LEVEL_BOLTON_ERROR:
      return {
        ...state,
        accLevelBolton: {},
        accLevelBoltonError: error
      };
    case GET_ROAMING_BOLTON_SUCCESS:
      return {
        ...state,
        romaingBoltOnPlans: data,
        romaingBoltOnPlansError: ""

      };
    case GET_ROAMING_BOLTON_ERROR:
      return {
        ...state,
        romaingBoltOnPlansError: error,
        romaingBoltOnPlans: {}

      };
    case GET_DATA_BOLTON_SUCCESS:
      return {
        ...state,
        dataBoltOnPlans: data,
        dataBoltOnPlansError: ""

      };
    case GET_DATA_BOLTON_ERROR:
      return {
        ...state,
        dataBoltOnPlansError: error,
        dataBoltOnPlans: {}

      };
    case GET_ACTIVE_PLAN_SUCCESS:
      return {
        ...state,
        activePlanData: data,
        activePlanError: ""

      };
    case GET_ACTIVE_PLAN_ERROR:
      return {
        ...state,
        activePlanError: error,
        activePlanData: {}
      };
    case 'BOLTON_PLAN_RESET':
      return {
        ...state,
        localBoltOnPlansError: "",
        localBoltOnPlans: {},
        romaingBoltOnPlansError: "",
        romaingBoltOnPlans: {},
        accLevelBolton: {},
        accLevelBoltonError: error
      };

    case GET_PROMOLIST_DATA:
      return {
        ...state,
        promoList: data,
        errorPromoList: ""
      };

    case ERROR_PROMOLIST_DATA:
      return {
        ...state,
        errorPromoList: error,
        promoList: {}
      };
    case MINUTES_SMS_USAGE_SUCCESS:
      return {
        ...state,
        minutesAndSmsUsage: data,
        minutesAndSmsUsageError: ""

      };
    case MINUTES_SMS_USAGE_ERROR:
      return {
        ...state,
        minutesAndSmsUsageError: error,
        minutesAndSmsUsage: {}

      };
    case DATA_USAGE_SUCCESS:
      return {
        ...state,
        dataUsage: data,
        dataUsageError: ""

      };
    case DATA_USAGE_ERROR:
      return {
        ...state,
        dataUsageError: error,
        dataUsage: {}

      };

    case CHANGE_ORDER_SUCCESS:
      return {
        ...state,
        changeOrderSuccess: data,
        changeOrderError: ""

      }
    case CHANGE_ORDER_ERROR:
      return {
        ...state,
        changeOrderSuccess: "",
        changeOrderError: error
      };
    case CHANGE_ORDER_RESET:
      return {
        ...state,
        changeOrderSuccess: "",
        changeOrderError: ""
      };
    case GET_IR_ADDONS:
      return {
        ...state,
        romaingBoltOnPlans: data,
      };
    case GET_DATA_ADDONS:
      return {
        ...state,
        allgetDataBoltOn: data,
        errorDataBolton: ""
      };

    case ERROR_DATA_ADDONS:
      return {
        ...state,
        errorDataBolton: "",
        allgetDataBoltOn: {}
      };

    case GET_MSISDN_DETAILS:
      return {
        ...state,
        detailsMsisdn: data,
        errorMsisdn: ""
      };

    case ERROR_MSISDN:
      return {
        ...state,
        errorMsisdn: error,
        detailsMsisdn: {}
      };

    default:
      return state;
  }
};

export default planReducer;
