import { Form } from "react-bootstrap"

const Radio = ({ label, name, value, checked, onChange, error, className, icon, ...rest }) => {
    return (
        <Form.Group data-testid="form-radio" >
            <Form.Check
                {...rest}
                type="radio"
                label={label}
                name={name}
                value={value}
                onChange={onChange}
                checked={checked}
            />

            {error && <Form.Control.Feedback type="invalid" data-testid="radio-error" >
                {error}
            </Form.Control.Feedback>
            }
        </Form.Group>
    )
}

export default Radio
