
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import './form.scss'

const TextField = ({ name, label, value, error, type, icon, labelProps, onBlur, id, required, readOnly, ...rest }) => {

    const [lable, setLable] = useState("")
    const [bgColor, setBgColor] = useState("")
    const [focus, setFocus] = useState(false)

    const onBlurField = e => {
        onBlur && onBlur()
        setFocus(false)
        if (value === undefined || value === "") {
            setLable("")
            setBgColor("");
        }

    }

    const onFocus = e => {
        let activeLable = 'active';
        if (value === undefined && value === "") {
            activeLable = '';
        }
        if (readOnly) {
            activeLable = '';
        }
        setLable(activeLable)
        if (!readOnly) {
            setFocus(true)
        }
    }
    const colors = {
        error: "#E60000",
        readOnly: "rgb(149 147 147)",
        focus: '#007C92'

    }



    return (
        <Form.Group className="form-group">
            <div className={`inputborderbox ${value ? 'white' : bgColor} ${type === "textArea" && 'text-area'} ${focus && 'inputFocus'} ${error && 'error-border'}`} style={{
                borderColor: readOnly && colors.readOnly
            }} data-testid="input-border" >
                <div className="gsc_mtl_field">
                    <div className="input-field">
                        {type === "textArea" ?
                            <textarea data-testid="input-textArea" rows="4" id={id ? id : name} {...rest} name={name} value={value} onFocus={onFocus} onBlur={onBlurField} readOnly={readOnly} style={{ width: '100%', resize: 'none' }} />
                            : <input data-testid="input-field" id={id ? id : name} {...rest} name={name} value={value} onFocus={onFocus} onBlur={onBlurField} readOnly={readOnly} style={{ color: readOnly ? colors.readOnly : "" }} />}
                        <label htmlFor={id ? id : name} style={{ color: readOnly && colors.readOnly }} className={`${value ? 'active' : lable} ${error && 'error-color'}`} {...labelProps} data-testid="input-label">{label}{required && <span className="error">*</span>}</label>
                        {icon && <span className="icon" data-testid="input-icon">{icon}</span>}
                    </div >
                </div >
            </div >
            {error && <Form.Control.Feedback type="invalid" data-testid={`input-error-${name}`} >
                {error}
            </Form.Control.Feedback>
            }
        </Form.Group >
    )
}

export default TextField