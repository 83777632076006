import React from 'react'
import TopUpCardPrep from ".././components/TopUpCardPrep";
import {SimpleButton, TextField } from "../../common";

const PrepaidTopUp = () => {
    return (
        <React.Fragment>
        <h1 className="semiMediumText cardContainer pt-5 mb-4">Top-up number</h1>
        <div className="cardContainer prepaid_topup_screen mb-5 pb-3">
          <div className="mobile-number-container">
            <div className="prefix" id="prefix1" >
              <TextField
                id="login5"
                name="prefix"
                value={`+${44} (0)`}
                className="mb-3"
                readOnly
              />
            </div>
            <div className="mobile-number" id="prefix2">
              <TextField
                type="text"
                name="mobileNo"
                label="Mobile No."
              />
            </div>
          </div>
        </div>
        <div className="top_ups_list cardContainer mb-5">
          <div className="mb-3">
            <TopUpCardPrep details="5GB,1000 Airtel-Airtel minutes & sms" validity="7 days" credit="£2.50" cost="20" />
            <TopUpCardPrep details="10GB, Unlimited Airtel-Airtel minutes & sms " validity="30 days" credit="£10" cost="40" />
            <TopUpCardPrep details="7GB, Unlimited Airtel-Airtel minutes & sms" validity="30 days" credit="£30" cost="60" />
          </div>
          <div style={{ textAlign: "center" }}>
            <SimpleButton variant="primary" className="mt-4" width="80%">
              Top-up now
            </SimpleButton>
          </div>
        </div>
      </React.Fragment>
    )
}

export default PrepaidTopUp
