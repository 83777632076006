
//  Component of help and support card
import { Row, Col } from 'react-bootstrap';
import rewardIcon from '../../../Assets/images/dashboard/reward.svg';
import { Drawer } from '../../common';
import HelpSupportMain from "../../help&support/helpSupportMain";
import { useState } from 'react';

const HelpSupport = () => {
    const [showHelpSupport, setShowHelpSupport] = useState(false);

    const OpenHelpSupport = () => {
        setShowHelpSupport(!showHelpSupport);
    }

    return (
        <>
            <div id="dashboard-60" className="dashbord-help-support cursor mb-3" onClick={OpenHelpSupport}>
                <Row id="dashboard-61">
                    <Col id="dashboard-62" xs={2} sm={2} md={2} className="p-0">
                        <img
                            src={rewardIcon}
                            id="dashboard-63"
                            alt="reward icone"
                            className="d-inline-block align-top dashbord-help-support-image"
                        />
                    </Col>
                    <Col id="dashboard-64" xs={10} sm={10} md={10}>
                        <div id="dashboard-65" className="dasboard-bill-details font-style text-color dashbord-help-support-text">
                            {'Help & support'}
                        </div>
                    </Col>
                </Row>
            </div>
            <Drawer data-testid="drawerw22" isOpen={showHelpSupport} closeDrawer={OpenHelpSupport} body={<HelpSupportMain closeDrawer={OpenHelpSupport} />} />
        </>
    )
}
export default HelpSupport