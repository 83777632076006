import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import BillBreakDown from "../components/mybills/views/BillBreakDown";
import MyBillsMain from "../components/mybills/MyBillsMain";
import Dashboard from '../components/dashboard'
import MyPlanAndUsagePostpaid from "../components/MyPlan&Usage/MyPlanAndUsagePostpaid";
import { postpaid_prefix } from '../components/envConfig';
import TermAndCondition from '../components/services/TermAndCondition';

function PostpaidRoute() {
    const prefix = `/${postpaid_prefix}`

    useEffect(() => {
        document.title = "Airtel-Vodafone Selfcare | Postpaid"
    }, [])
    return (
        <>
            <TermAndCondition />
            <Switch>
                <Route exact path={`${prefix}`}> <Dashboard type="postpaid" /></Route>
                <Route path={`${prefix}/myBill`}> <MyBillsMain type="postpaid" /></Route>
                <Route path={`${prefix}/breakDown`} component={BillBreakDown}></Route>
                <Route path={`${prefix}/myPlan`} component={MyPlanAndUsagePostpaid}></Route>
                <Redirect to={`${prefix}`} />
            </Switch>
        </>
    )
}

export default PostpaidRoute
