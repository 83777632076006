import React, { useEffect, useState } from 'react'
import { SimpleButton, TextField, AlertMessage, OTPField } from '../common'
import { MiniMobileIcon, BackIcon, RightRedArrowIcon, PersonsGroupIcon, prepaidicon } from '../Icons/'
import { emailRegex } from '../common/utils'
import NewConnection from './components/NewConnection'
import CountDownTimer from '../common/components/CountdownTimer'
import { useDispatch, useSelector } from 'react-redux'
import { generateOtp, verifyOTP } from '../../redux/actions/loginAction'
import { login_types } from '../../redux/helpers/types'
import { fiber_broadband_url, fixed_brodband_url} from '../envConfig'
import {loginbanner} from "../../Assets/imageData";

const { RESET_LOGIN_META } = login_types
const secondForOTP = 60
const numberPrefix = "44"
function MainLogin({ country, countryCode }) {
    const dispatch = useDispatch()
    const { generateOTPSuccess, generateOTPError, verifyOTPError, } = useSelector(state => state.auth)
    const [mobileNo, setMobileNo] = useState("")
    const [blocked, setBlocked] = useState(false)
    const [email, setEmail] = useState("")
    const [error, setError] = useState({})
    const [isDisableOTP, setDesableOTP] = useState(true)
    const [isDisableOtpButton, setIsDisableOtpButton] = useState(true)
    const [isDisableMobileButton, setIsDisableMobileButton] = useState(true)
    const [isDisableEmailButton, setIsDisableEmailButton] = useState(true)
    const [successMessage, setSuccessMessage] = useState({})
    const [doaminFlag, setdoaminFlag] = useState("")
    const [otp, setOtp] = useState("")
    const [view, setView] = useState("INIT")
    const [newConnectionvalue, setNewConnectionvalue] = useState("HELP_WITH_APP_SETUP")

    const ecommerce_login = `https://airtel-vodafone.com/${countryCode}/my-account/login`


    //mobile change
    const onMobileNoChange = e => {
        const { value } = e.target
        if (value.length > 9)
            setIsDisableMobileButton(false)
        if (value.length < 10)
            setIsDisableMobileButton(true)
        if (value <= 9999999999 && value.length <= 10) {
            setMobileNo(value.trim());
        }
    }

    const submitMobile = (e, resend = false) => {
        e.preventDefault()
        if (mobileNo) {
            setIsDisableMobileButton(true)
            dispatch(generateOtp(`${numberPrefix}${mobileNo}`, "", "B2C", resend))
            setdoaminFlag("B2C")
        }

        else
            return setError({ mobileNo: "Sorry we could not find your account. Please Register and try logging in again." })
    }

    const submitEmail = (e, resend = false) => {
        e.preventDefault()
        if (email)
            if (!emailRegex.test(email)) {
                return setError({ email: "This email is not valid" })
            }
            else {
                setIsDisableEmailButton(true)
                setdoaminFlag("B2B")
                dispatch(generateOtp("", email, "B2B", resend))
            }
        else
            return setError({ mobileNo: "This email is not valid" })
    }

    //handle OTP
    const handleChange = (value) => {
        setOtp(value);
        if (value.length > 3)
            setIsDisableOtpButton(false)
        if (value.length < 4)
            setIsDisableOtpButton(true)

    }

    //hnadle email
    const handleEmailChange = e => {
        let { value } = e.target
        if (emailRegex.test(String(value))) {
            setIsDisableEmailButton(false)
        }
        else {
            setIsDisableEmailButton(true)
        }
        setEmail(value)

    }

    const submitOTP = e => {
        e.preventDefault()
        if (otp === "") {
            setError({ otp: "Please Enter Correct OTP" })
            return true
        }
        if (doaminFlag === "B2C") {
            setIsDisableOtpButton(true)
            dispatch(verifyOTP(`${numberPrefix}${mobileNo}`, otp, "", "B2C"))
        }

        if (doaminFlag === "B2B") {
            setIsDisableOtpButton(true)
            dispatch(verifyOTP("", otp, email, "B2B"))
        }

    }

    useEffect(() => {
        //send to OTP screen
        if (generateOTPSuccess && generateOTPSuccess.message && !generateOTPSuccess.resend) {
            setView("OTP")
            setIsDisableMobileButton(false)
            setIsDisableEmailButton(false)
            dispatch({ type: RESET_LOGIN_META })
            setBlocked(false)
            setDesableOTP(true)
        }
        //show the otp resend message on OTP screen
        if (generateOTPSuccess && generateOTPSuccess.message && generateOTPSuccess.resend) {
            setSuccessMessage({ otp: generateOTPSuccess.message })
            setTimeout(() => setSuccessMessage({}), 3000)
            dispatch({ type: RESET_LOGIN_META })
        }
        //Error on OTP generation on mobile screen
        if (generateOTPError.error && !generateOTPError.resend) {
            setIsDisableMobileButton(false)
            setIsDisableEmailButton(false)
            setError({ mobileNo: generateOTPError.error })
            dispatch({ type: RESET_LOGIN_META })
        }
        //Error on OTP generation on OTP screen
        if (generateOTPError.error && generateOTPError.resend) {
            setDesableOTP(false)
            setError({ otp: generateOTPError.error })
            dispatch({ type: RESET_LOGIN_META })
        }
        //Error on OTP verification on OTP screen
        if (verifyOTPError) {
            setIsDisableOtpButton(false)
            setError({ otp: verifyOTPError })
            dispatch({ type: RESET_LOGIN_META })

        }
    }, [dispatch, generateOTPSuccess, generateOTPError, verifyOTPError])


    const back = (comp) => {
        setError({})
        setSuccessMessage({})
        setMobileNo("")
        setEmail("")
        setOtp("")
        setView(comp)
        setIsDisableOtpButton(true)
        setIsDisableEmailButton(true)
        setIsDisableMobileButton(true)
    }

    const resendB2C = (e) => { submitMobile(e, true); setDesableOTP(true); setError({}) }
    const resendB2B = (e) => { submitEmail(e, true); setDesableOTP(true); setError({}) }
    return (
        <div className="postpaid-login" >
            {{
                "INIT":
                    <div id="login1" data-testid="first-screen" >
                        <div id="login2" className="login-title">Login</div>
                        <div id="login3" className="mb-4">
                            <div className="bolts-local login_tiels cursor mb-4" data-testid="B2C_Login" onClick={() => back("PAY_MONTLY")} >
                                <img
                                    src={MiniMobileIcon}
                                    alt="login"
                                    className="bolts-roamings"
                                />
                                <div className="local-text" >
                                    <h5>Retail Customer</h5>
                                </div>
                                <img
                                    src={RightRedArrowIcon}
                                    alt="group"
                                    className="bolts-group"
                                />
                            </div >
                            <div className="bolts-local login_tiels cursor" data-testid="B2B_Login" onClick={() => back("B2B")} >
                                <img
                                    src={PersonsGroupIcon}
                                    alt="roaming"
                                    className="bolts-roamings"
                                />
                                <div className="local-text" >
                                    <h5>Business Customer</h5>
                                </div>
                                <img
                                    src={RightRedArrowIcon}
                                    alt="group"
                                    className="bolts-group"
                                />
                            </div >
                            <a href={ecommerce_login} target='blank'>
                            <div className="bolts-local login_tiels cursor" data-testid="Prepaid_Login" onClick={() => back("INIT")} >
                            
                                <img
                                    src={prepaidicon}
                                    alt="roaming"
                                    className="bolts-roamings"
                                />
                                <div className="local-text" >
                                    <h5>Pay As You Go Customer</h5>
                                </div>
                                <img
                                    src={RightRedArrowIcon}
                                    alt="group"
                                    className="bolts-group"
                                />
                            </div >
                            </a>
                        </div>
                    </div>,
                "PAY_MONTLY":
                    <div id="login1" data-testid="B2C_screen" >
                        <img src={BackIcon} alt="back" data-testid="Back_to_init" onClick={() => back("INIT")} className="cursor" />
                        <div id="login2" className="login-title">My Airtel-Vodafone Login</div>
                        <form id="login3" className="mb-4" onSubmit={(e) => submitMobile(e)}>
                            {error.mobileNo && <AlertMessage id="login4" data-testid="mobileNo_error" message={error.mobileNo} />}
                            <div className="mobile-number-container">
                                <div className="prefix" id="prefix1" >
                                    <TextField
                                        id="login5"
                                        name="prefix"
                                        value={`+${numberPrefix} (0)`}
                                        className="mb-3"
                                        readOnly
                                    />
                                </div>
                                <div className="mobile-number" id="prefix2">
                                    <TextField
                                        id="login6"
                                        label="Mobile Number"
                                        name="mobileNo"
                                        onChange={onMobileNoChange}
                                        value={mobileNo}
                                        // error={error.mobileNo}
                                        data-testid="numberField"
                                        className="mb-3"
                                    // required
                                    />
                                </div>

                            </div>

                            <SimpleButton
                                id="login6"
                                variant="primary"
                                maxWidth
                                className="mb-3"
                                //onClick={submitMobile}
                                data-testid="submit-mobile"
                                disabled={isDisableMobileButton}
                                type="submit"
                            >
                                Get OTP to proceed
                            </SimpleButton>
                            <p id="login7" className="new-request cursor text-center" onClick={() => back("NEWCON")}>Help with my Airtel-Vodafone Selfcare setup</p>
                        </form>

                    </div>
                ,
                "B2B":
                    <div id="login1" data-testid="B2B_screen" >
                        <img src={BackIcon} alt="back" onClick={() => back("INIT")} className="cursor" />
                        <div id="login2" className="login-title">My Airtel-Vodafone Business Login</div>
                        <form onSubmit={(e) => submitEmail(e)} id="login3" className="mb-4">
                            {error.mobileNo && <AlertMessage id="login4" data-testid="mobileNo-error" message={error.mobileNo} />}
                            <TextField
                                id="login895"
                                label="Email"
                                name="email"
                                onChange={handleEmailChange}
                                value={email}
                                // error={error.mobileNo}
                                data-testid="numberField"
                                className="mb-3"
                                required
                            />

                            <SimpleButton
                                id="login6"
                                variant="primary"
                                maxWidth
                                className="mb-3"
                                //onClick={() => submitEmail()}
                                data-testid="submit-mobile"
                                disabled={isDisableEmailButton}
                                type="submit"
                            >
                                Get OTP to proceed
                            </SimpleButton>
                            <p id="login7" className="new-request cursor text-center text-danger" onClick={() => back("PAY_MONTLY")}>Retail customer</p>
                        </form>
                    </div>,
                "OTP": <div id="otp1" data-testid="B2C_OTP_screen">
                    <div id="otp2" className="login-title text-center">Enter the OTP received on <span className="text_teal">{mobileNo && doaminFlag === "B2C" ? `0${mobileNo}` : ""} {email && doaminFlag === "B2B" ? `${email}` : ""}</span></div>
                    <form id="otp3" className="mb-4" onSubmit={(e) => submitOTP(e)}>
                        <div id="otp4" className="otpContainer">
                            <OTPField className="mb-3" data-testid="otp_field_b2c" onChange={handleChange} />
                            {successMessage.otp && <span id="otp5" className="text-success" data-testid="OTP-error">{successMessage.otp}</span>}
                            {error.otp && <span id="otp5" className="error otp-error" data-testid="OTP-error">{error.otp}</span>}
                            <div className="d-flex justify-content-between timer-container" id="f89">
                                {
                                    doaminFlag === "B2C" &&
                                    <>
                                        <div id="otp52" className="resend-otp cursor" data-testid="change_number" onClick={() => back("PAY_MONTLY")} >
                                            Change Number
                                        </div>
                                        {!isDisableOTP ? <div id="otp6" className={`resend-otp ${blocked ? 'disabled-text' : ''} cursor ml-1`} onClick={blocked ? null : resendB2C} >
                                            Resend OTP
                                        </div>
                                            : <div id="otp7" className="resend-otp-timer"> Resend OTP in <CountDownTimer seconds={secondForOTP} callback={() => setDesableOTP(false)} /></div>
                                        }
                                    </>

                                }
                                {
                                    doaminFlag === "B2B" &&
                                    <>
                                        <div id="otp52" className="resend-otp cursor" onClick={() => back("B2B")} >
                                            Change Email
                                        </div>
                                        {!isDisableOTP ? <div id="otp6" className={`resend-otp ${blocked ? 'disabled-text' : ''} cursor ml-1`} onClick={blocked ? null : resendB2B} >
                                            Resend OTP
                                        </div>
                                            : <div id="otp7" className="resend-otp-timer"> Resend OTP in <CountDownTimer hoursMinSecs={secondForOTP} callback={() => setDesableOTP(false)} /></div>
                                        }
                                    </>

                                }
                            </div>
                        </div>

                        <SimpleButton
                            id="otp8"
                            data-testid="submit-OTP"
                            variant="primary"
                            maxWidth
                            className="mb-2"
                            disabled={isDisableOtpButton}
                            type="submit"
                        >
                            Submit
                        </SimpleButton>
                    </form>
                </div>,
                "NEWCON": <NewConnection setView={setView} newConnectionvalue={newConnectionvalue} afterSucessCase="PAY_MONTLY" back="PAY_MONTLY" afterSuccess="Back to login" />,
            }[view]}
            {
                view === "NEWCON" ? "" :
                    <div className="bottom-area" id="login8">
                        <a target='blank' href={country === "Guernsey" ? fixed_brodband_url : fiber_broadband_url}><img id="login11" className='img-fluid' src={loginbanner} alt="Login-Banner" /></a>
                    </div>
            }
        </div>
    )
}

export default MainLogin
