import { decryptData, encryptData } from "../common/utils/crypto";
import { refreshToken } from "./refreshToken";
import { login_types } from '../../redux/helpers/types'


// It will check is there any 401 error or not 
const shouldIntercept = (error) => {
    try {
        return error.response.status === 401
    } catch (e) {
        return false;
    }
};

// it will set the token to localstorage 
// but not used  yet
const setTokenData = (tokenData = {}) => {
    localStorage.setItem(login_types.TOKEN_INFO, encryptData(tokenData))
};

// here refersh token API function is calling 
const handleTokenRefresh = () => {
    return new Promise((resolve, reject) => {
        refreshToken()
            .then(data => {
                const authData = localStorage.getItem(login_types.TOKEN_INFO) ? decryptData(localStorage.getItem(login_types.TOKEN_INFO)) : undefined
                authData.accessToken = data
                resolve(authData);
            })
            .catch((err) => {
                localStorage.clear()
                window.location.reload()
                reject(err);
            })
    });
};


const attachTokenToRequest = (request, token) => {
    request.headers['Authorization'] = 'Bearer ' + token;

};

export default (axiosClient, customOptions = {}) => {
    let isRefreshing = false;
    let failedQueue = [];

    const options = {
        attachTokenToRequest,
        handleTokenRefresh,
        setTokenData,
        shouldIntercept,
        ...customOptions,
    };
    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    // from here the main functionality began
    const interceptor = (error) => {
        if (!options.shouldIntercept(error)) {
            return Promise.reject(error);
        }

        if (error.config._retry || error.config._queued) {
            return Promise.reject(error);
        }

        const originalRequest = error.config;
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject })
            }).then(token => {
                originalRequest._queued = true;
                options.attachTokenToRequest(originalRequest, token);
                return axiosClient.request(originalRequest);
            }).catch(_err => {
                return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise((resolve, reject) => {
            options.handleTokenRefresh.call(options.handleTokenRefresh)
                .then((tokenData) => {
                    options.attachTokenToRequest(originalRequest, tokenData.accessToken);
                    processQueue(null, tokenData.accessToken);
                    resolve(axiosClient.request(originalRequest));
                })
                .catch((err) => {
                    processQueue(err, null);
                    reject(err);
                })
                .then(() => {
                    isRefreshing = false;
                })
        });
    };

    axiosClient.interceptors.response.use(undefined, interceptor);
};