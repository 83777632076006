import React, { useState } from "react";
import download from "../../../Assets/images/download.svg";
import MailIcon from "../../../Assets/images/billing/mail.svg";
import BorderedCard from "../../common/components/BorderedCard";
import { apiMyBill } from "../../../redux/helpers/ApiConstant";
import { useDispatch, useSelector } from "react-redux";
import { getDublicateInvoice } from "../../../redux/actions/myBillsAction"
import { axios } from "../../services/axiosService";
import { b64_to_string, divideByCurrencey, getCurrency, isValidDate, ResponseMessage, ResponseModal } from "../../common";
import { emailRegex } from "../../common/utils";

const { downloadPreviousBill } = apiMyBill

const PreviousBills = ({ amaount, date, id, invoiceNumber, billingDate, showInfoMessage, customerEmail }) => {
  const { optimusDetails } = useSelector(state => state.auth);
  let { cust_ac_no, circle_id } = optimusDetails;
  const dispatch = useDispatch();
  const [showDownloadErrorMoadal, setShowDownloadErrorMoadal] = useState(false)

  const extractMonth = (data) => {
    const newDate = new Date(data)
    return newDate.toLocaleString("default", { month: "long" })
  }
  // To convert the string to integer for dividing it by 100
  let finalAmount = amaount ? amaount : 0;

  const dublicateBill = () => {
    let email = ""
    email = customerEmail
    let data = {
      custid: cust_ac_no,
      invoiceNumber: invoiceNumber,
      billingDate: billingDate,
      emailAddress: b64_to_string(email),
      circleId: circle_id
    }
    if (email && emailRegex.test(String(email).toLocaleLowerCase())) {
      dispatch(getDublicateInvoice(data));
    }
    else {
      showInfoMessage(true);
    }
  }

  function downloadBill(referenceNo, accountNumber) {
    setShowDownloadErrorMoadal(false)

    axios({
      url: `${downloadPreviousBill}/${referenceNo}?accountNumber=${accountNumber}`,
      // url: `${downloadPreviousBill}/454990100?accountNumber=1-46875948520`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${accountNumber}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
      .catch(_error => {
        setShowDownloadErrorMoadal(true)
      })

  }

  return (
    <div id={id} data-testid="previous_bill_card" className="previous_bills_cards mb-4 cardContainer">
      <BorderedCard>
        <h5 id="previous_bill_1" className="lightText mb-2" style={{ color: "#666666" }}>{isValidDate(date) && new Date(date).getFullYear()}</h5>
        <div id="previous_bill_2" className="d-flex justify-content-between">
          <div id="previous_bill_3" className="month-billing-tab">
            <h2 id="previous_bill_4" className="bill-month">{isValidDate(date) && extractMonth(date)}</h2>
          </div>
          <div id="previous_bill_5" className="d-flex">
            <h2 id="previous_bill_6" className="mediumText">{finalAmount && getCurrency(divideByCurrencey(finalAmount))}</h2>
            {/* <img className="px-2" src={ok} alt="img" /> */}
          </div>
        </div>
        <hr id="previous_bill_7" />
        <div id="previous_bill_8" className="d-flex">
          <img
            id="previous_bill_9"
            data-testid="download_bill_button"
            src={download}
            className="cursor"
            onClick={() => downloadBill(invoiceNumber ? invoiceNumber : "", cust_ac_no ? cust_ac_no : "")}
            alt="img" />
          <img id="previous_bill_10" onClick={dublicateBill} src={MailIcon} className="cursor" alt="img" style={{ height: 33, marginLeft: 11, marginTop: 5 }} />
        </div>
      </BorderedCard >
      <ResponseModal show={showDownloadErrorMoadal} handleClose={() => setShowDownloadErrorMoadal(false)}>
        <ResponseMessage
          error
          heading="Bill is not generated"
          button={{ label: "Try Again", onClick: downloadBill }}
        />
      </ResponseModal>
    </div>
  );
};

export default PreviousBills;
