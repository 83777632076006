import React from 'react'
import WarningWhite from '../../../Assets/images/warning-white.svg'

const AlertMessage = ({ variant, message, className, ...rest }) => {

    return (
        <div className={`${className} alert-message`} {...rest} >
            <div className="icon-area">
                <img alt="logo" src={WarningWhite} />
            </div>
            <div className="message-area" data-testid="alert_message">
                {message}
            </div>
        </div>
    )
}

export default AlertMessage
