import { myBills_Type } from "../helpers/types";

const {
  DUBLICATE_INVOICE_SUCCESS,
  DUBLICATE_INVOICE_ERROR,
  GET_USER_PAYMENT_HISTORY,
  FAIL_USER_PAYMENT_HISTORY,
  GET_CUSTOMER_INVOICE_SUMMARY,
  FAIL_CUSTOMER_INVOICE_SUMMARY,
  RESET_CUSTOMER_INVOICE_SUMMARY,
  GET_BREAKDOWN_DATA,
  ERROR_BREAKDOWN_DATA,
  RESET_BREAKDOWN_DATA,
  BILL_PAYMENT_SUCCESS,
  BILL_PAYMENT_ERROR,
  BILL_PAYMENT_RESET,
  CURRENT_MONTH_BILL_AMOUNT_SUCCESS,
  CURRENT_MONTH_BILL_AMOUNT_ERROR
} = myBills_Type

const initialState = {
  userPaymentHistory: {},
  paymentHistoryError: "",
  userInvoiceSummary: {},
  errorInvoiceSummary: "",
  dublicateInvoiceSuccess: {},
  dublicateInvoiceError: "",
  breakDownData: {},
  errorBreakdown: "",
  billPaymentSuccess: {},
  billPaymentError: "",
  currentBillAmmount: {},
  currentBillAmmountError: ""

};

export default function myBillsAllReducers(state = initialState, action = {}) {
  const { type, data, error } = action;
  switch (type) {

    case GET_USER_PAYMENT_HISTORY:
      return {
        ...state,
        userPaymentHistory: data,
        paymentHistoryError: "",
      };
    case FAIL_USER_PAYMENT_HISTORY:
      return {
        ...state,
        paymentHistoryError: error,
        userPaymentHistory: {}
      };
    case GET_CUSTOMER_INVOICE_SUMMARY:
      return {
        ...state,
        userInvoiceSummary: data,
        errorInvoiceSummary: ""
      };
    case FAIL_CUSTOMER_INVOICE_SUMMARY:
      return {
        ...state,
        errorInvoiceSummary: error,
        userInvoiceSummary: {}
      };
    case RESET_CUSTOMER_INVOICE_SUMMARY:
      return {
        ...state,
        errorInvoiceSummary: "",
        userInvoiceSummary: {}
      };
    case DUBLICATE_INVOICE_SUCCESS:
      return {
        ...state,
        dublicateInvoiceSuccess: data,
        dublicateInvoiceError: ""
      };
    case DUBLICATE_INVOICE_ERROR:
      return {
        ...state,
        dublicateInvoiceError: error,
        dublicateInvoiceSuccess: {}
      };
    case GET_BREAKDOWN_DATA:
      return {
        ...state,
        breakDownData: data,
        errorBreakdown: ""
      };
    case ERROR_BREAKDOWN_DATA:
      return {
        ...state,
        errorBreakdown: error,
        breakDownData: {}
      };
    case RESET_BREAKDOWN_DATA:
      return {
        ...state,
        errorBreakdown: "",
        breakDownData: {}
      };
    case CURRENT_MONTH_BILL_AMOUNT_SUCCESS:
      return {
        ...state,
        currentBillAmmount: data,
        currentBillAmmountError: ""
      };
    case CURRENT_MONTH_BILL_AMOUNT_ERROR:
      return {
        ...state,
        currentBillAmmount: {},
        currentBillAmmountError: error
      };
    case BILL_PAYMENT_SUCCESS:
      return {
        ...state,
        billPaymentSuccess: data,
        billPaymentError: "",
        currentBillAmmount: {},
        currentBillAmmountError: ""
      };
    case BILL_PAYMENT_ERROR:
      return {
        ...state,
        billPaymentSuccess: {},
        billPaymentError: error
      };
    case BILL_PAYMENT_RESET:
      return {
        ...state,
        billPaymentSuccess: {},
        billPaymentError: ""
      };
    default:
      return state;
  }
}
