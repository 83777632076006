import { useEffect, useState } from "react";
import PlanCard from "../components/planCard"
import { Container, Row, Col } from "react-bootstrap";
import MyAccountCard from "../../dashboard/components/accountCard";
import profileIcone from "../../../Assets/images/dashboard/profileIcone.png"
import addContactIcone from "../../../Assets/images/dashboard/addContact.svg";
import { Drawer } from "../../common";
import UpgradePlan from "../components/UpgradePlan";
import UpdateProfile from "../../common/myProfile/UpdateProfile";
import BoltOn from "../../common/boltOn/BoltOn";
import AddData from "../components/AddData";
import { useDispatch, useSelector } from 'react-redux'
import { getDataBoltOn, fetchPromotionList, fetchMinutesSmsUsage, fetchActivePlan, getLocalBoltOn, getRomaingBoltOn } from "../../../redux/actions/myPlanAction";
import isEmpty from "../../common/utils/isEmpty";
import { Discovery } from "../../common/dashboard/Discovery";
import { getScreenResolution } from "../../common/utils";
import useCustomerInfo from "../hooks/useCustomerInfo";
import useActivePlanId from "../hooks/useActivePlanId";
import { PROD_TYPE_DATACARD } from "../../dashboard/dashboardConstant";


const MyPlanViewPostpaid = () => {

    const dispatch = useDispatch();
    const { optimusDetails } = useSelector(state => state.auth);
    const [open, setOpen] = useState(false);
    const [isOpenProfileDrawer, setIsOpenProfileDrawer] = useState(false)
    const [isOpenBoltOnDrawer, setIsOpenBoltOnDrawer] = useState(false)
    const [isOpenDataDrawer, setIsOpenDataDrawer] = useState(false)
    const openProfileDrawer = () => setIsOpenProfileDrawer(true)
    const closeProfileDrawer = () => setIsOpenProfileDrawer(false)
    const closeBoltOnDrawer = () => setIsOpenBoltOnDrawer(false)

    const closeDataDrawer = () => setIsOpenDataDrawer(false)
    const { activePlanData, childNumber } = useSelector(state => state.planReducer);
    const [screenResolution, setResolution] = useState({ height: "768", width: "1366" })
    const planId = useActivePlanId(activePlanData)


    const { cust_type, cust_class, circle_id, domain, si, si_lob, prod_type} = optimusDetails

    // usage and get active plan for another services
    useEffect(() => {
        if (childNumber) {
            dispatch(fetchActivePlan(si_lob, domain, childNumber, cust_class))
        }
        else {
            dispatch(fetchActivePlan(si_lob, domain, si, cust_class))
        }
    }, [childNumber])

    useEffect(() => {
        if (childNumber) {
            dispatch(fetchMinutesSmsUsage(domain, childNumber, optimusDetails.imsi, optimusDetails.circle, si_lob));
        }
        else {
            dispatch(fetchMinutesSmsUsage(domain, si, optimusDetails.imsi, optimusDetails.circle, si_lob));
        }
    }, [childNumber])


    // data addOn
    const OpenDataDrawer = () => {
        if (childNumber) {
            dispatch(fetchActivePlan(si_lob, domain, childNumber, cust_class))
        }
        else {
            dispatch(fetchActivePlan(si_lob, domain, si, cust_class))
        }
        if (planId !== "" && !isEmpty(planId)) {
            if (childNumber)
                dispatch(getDataBoltOn(planId, childNumber, domain, si_lob, circle_id, cust_class))
            else
                dispatch(getDataBoltOn(planId, si, domain, si_lob, circle_id, cust_class))
            setIsOpenDataDrawer(true)
        }
    }

    //open bolt on drawer
    const openBoltOnDrawer = () => {
        if (childNumber) {
            dispatch(fetchActivePlan(si_lob, domain, childNumber, cust_class))
        }
        else {
            dispatch(fetchActivePlan(si_lob, domain, si, cust_class))
        }

        if (planId !== "" && !isEmpty(planId)) {
            if (childNumber) {
                dispatch(getLocalBoltOn(planId, childNumber, domain, si_lob, circle_id, cust_class));
                dispatch(getRomaingBoltOn(planId, childNumber, domain, si_lob, circle_id, cust_class));
            }

            else {
                dispatch(getLocalBoltOn(planId, si, domain, si_lob, circle_id, cust_class));
                dispatch(getRomaingBoltOn(planId, si, domain, si_lob, circle_id, cust_class));
            }
            setIsOpenBoltOnDrawer(true)
        }

    }

    //open change plan drawer
    const handleShow = () => {
        if (childNumber) {
            dispatch(fetchActivePlan(si_lob, domain, childNumber, cust_class))
        }
        else {
            dispatch(fetchActivePlan(si_lob, domain, si, cust_class))
        }
        if (planId !== "" && !isEmpty(planId)) {
            if (childNumber)
                dispatch(fetchPromotionList(cust_type, cust_class, circle_id, childNumber, si_lob, domain, planId))
            else
                dispatch(fetchPromotionList(cust_type, cust_class, circle_id, si, si_lob, domain, planId))
            setOpen(true);
        }

    };
    //close plan Drawer
    const handleClose = () => {
        if (childNumber) {
            dispatch(fetchActivePlan(si_lob, domain, childNumber, cust_class))
        }
        else {
            dispatch(fetchActivePlan(si_lob, domain, si, cust_class))
        }
        setOpen(false);
    };

    let getResolution = () => {
        setResolution(getScreenResolution());
    }

    useEffect(() => {
        //get screen resolution
        getResolution();
        window.addEventListener('resize', getResolution);

        return () => {
            window.removeEventListener('resize', getResolution);
        };
    }, []);

    const myAccount = [
        {
            title: "My profile",
            content: "Update profile",
            icone: profileIcone,
            onClick: openProfileDrawer,
            className: 'mb-3'
        },
        {
            title: "My bolt on",
            content: "Manage individual number",
            icone: addContactIcone,
            onClick: openBoltOnDrawer
        },
    ];

    return (
        <Container className="pt-3">
            <Row>
                <h4 className="dashboard-account myPlan-title">Active Service</h4>
                <PlanCard handleShow={handleShow} OpenDataDrawer={OpenDataDrawer} />
            </Row>
            <Row className="dashboard-account">
                <Col sm={12}>
                    <h2 className="dashboard-typo font-style">My account</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="mb-3">
                    <MyAccountCard {...myAccount[0]} />
                </Col>
                {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? "" : 
                <Col sm={6}>
                    <MyAccountCard {...myAccount[1]} />
                </Col>
                }

            </Row>
            <Discovery screen={screenResolution.width} />

            <Drawer data-testid="drawer"
                isOpen={open}
                body={<UpgradePlan handleClose={handleClose} />}
                closeDrawer={handleClose} />
            <Drawer
                body={<UpdateProfile closeDrawer={closeProfileDrawer} />}
                isOpen={isOpenProfileDrawer}
                closeDrawer={closeProfileDrawer}
            />
            <Drawer
                body={<BoltOn handleClose={closeBoltOnDrawer} />}
                isOpen={isOpenBoltOnDrawer}
                closeDrawer={closeBoltOnDrawer}
            />
            <Drawer
                body={<AddData handleClose={closeDataDrawer} />}
                isOpen={isOpenDataDrawer}
                closeDrawer={closeDataDrawer}
            />
        </Container>
    )
}

export default MyPlanViewPostpaid;