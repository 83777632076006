import { useState } from 'react'
import UpdateProfile from '../../common/myProfile/UpdateProfile'
import ServiceRequest from '../../help&support/components/ServiceRequest'

function B2bProfile(props) {
    const [view, onStepChange] = useState("PROFILE")

    return (
        <>
            {{
                "PROFILE":
                    <UpdateProfile isB2b={true} raiseServiceRequestView={() => onStepChange("RAISE")} {...props} />,
                "RAISE":
                    <ServiceRequest isB2B={true} onStepChange={() => onStepChange("PROFILE")} {...props} />


            }[view]}
        </>
    )
}

export default B2bProfile
