import React, { useEffect, useState } from "react";
import { PreLoader, ResponseMessage, SimpleButton, ResponseModal, divideByCurrencey } from "../../common";
import { downloadIcon } from "../../Icons";
import BorderedCard from "../../common/components/BorderedCard";
import { useHistory } from "react-router-dom";
import { extractMonth, getCurrency } from "../../common/utils";
import isEmpty from "../../common/utils/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { payBill } from "../../../redux/actions/myBillsAction";
import { axios } from "../../services/axiosService";
import { apiMyBill } from "../../../redux/helpers/ApiConstant";
import NoBillScreen from "./NoBillScreen";
import moment from "moment";
import { postpaid_prefix } from "../../envConfig";

const CurrentBillCard = ({ currentMonthBill, errorBreakdown }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [payButtonDisabeld, setPayButtonDisabeld] = useState(false)
  const [showDownloadErrorMoadal, setShowDownloadErrorMoadal] = useState(false)
  const [showbillPayModal, setShowbillPayModal] = useState(false)

  const prefix = `/${postpaid_prefix}`
  const { optimusDetails } = useSelector(state => state.auth);
  const { billPaymentSuccess, billPaymentError, currentBillAmmount, currentBillAmmountError } = useSelector(state => state.myBillsAllReducers)
  const { si, domain, cust_ac_no, si_lob } = optimusDetails;

  const payBillAmount = (e, invoiceNo) => {
    e.stopPropagation()
    if (!isEmpty(currentBillAmmount) && currentBillAmmount.customerBill && currentBillAmmount.customerBill.partyBill && currentBillAmmount.customerBill.partyBill.dueAmount > 0 && currentBillAmmountError === "") {
      setPayButtonDisabeld(true)
      dispatch(payBill(si, domain, cust_ac_no, invoiceNo, si_lob))
    }
  }

  useEffect(() => {
    if (billPaymentSuccess && billPaymentSuccess.url) {
      window.location.replace(billPaymentSuccess.url)
    }
    if (billPaymentError) {
      setShowbillPayModal(true)
      setPayButtonDisabeld(false)
    }

  }, [billPaymentSuccess, billPaymentError, dispatch]);


  //function will route the component to bill breakdown screen 
  const routeToBreakDown = (e) => {
    e.stopPropagation()
    history.push(`${prefix}/breakDown`);
  };

  var dueAmount = ''
  if (currentMonthBill
    &&
    !isEmpty(currentMonthBill)
    &&
    currentMonthBill.data
    &&
    currentMonthBill.data.BREAKUP_ACCOUNT_DETAILS) {
    dueAmount = currentMonthBill.data.BREAKUP_ACCOUNT_DETAILS
  }
  const { downloadPreviousBill } = apiMyBill

  let invoiceNumber = ""

  if (currentMonthBill
    &&
    currentMonthBill.data
    &&
    currentMonthBill.data.BREAKUP_ACCOUNT_DETAILS.INVOICE_NO
  ) {
    invoiceNumber = currentMonthBill.data.BREAKUP_ACCOUNT_DETAILS.INVOICE_NO
  }



  function downloadBill(e, referenceNo, accountNumber) {
    e.stopPropagation()
    setShowDownloadErrorMoadal(false)

    axios({
      url: `${downloadPreviousBill}/${referenceNo}?accountNumber=${accountNumber}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${accountNumber}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
      .catch(_error => {
        setShowDownloadErrorMoadal(true)
      })

  }

  return (
    <>
      < div id="current_bill_card_main" className="current_bill_card_main_div cardContainer" >
        {
          (!isEmpty(dueAmount))
            &&
            dueAmount.BILL_DATE
            &&
            dueAmount.DUE_AMOUNT
            &&
            dueAmount.NO_OF_CONNECTION
            &&
            errorBreakdown === ''
            ?
            <React.Fragment>
              <BorderedCard onClick={routeToBreakDown} className="cursor" title="View bill breakdown">
                <div id="current_bill_card_main_1" className="d-flex justify-content-between" >
                  <div
                    className="content clickable_card cursor"
                    onClick={routeToBreakDown}
                    data-testid="click-bill-card"
                    id="current_bill_card_main_2"
                  >
                    <span className="lightText">
                      {`${moment(dueAmount.BILL_FROM_DT, "DD/MM/YYYY").format("DD")}/`}
                      {extractMonth(moment(dueAmount.BILL_FROM_DT, "DD/MM/YYYY").format("MM/DD/YYYY"))}
                    </span>
                    <span className="lightText"> - </span>
                    <span className="lightText">
                      {`${moment(dueAmount.BILL_TO_DT, "DD/MM/YYYY").format("DD")}/`}
                      {extractMonth(moment(dueAmount.BILL_TO_DT, "DD/MM/YYYY").format("MM/DD/YYYY"))}
                      {`/${moment(dueAmount.BILL_TO_DT, "DD/MM/YYYY").format("YY")}`}
                    </span>
                    <h2 id="current_bill_card_main_5" className="largeText">{getCurrency(dueAmount.DUE_AMOUNT)}</h2>
                  </div>
                  <div id="current_bill_card_main_6">
                    {!isEmpty(currentBillAmmount) && dueAmount.DUE_AMOUNT &&
                      <SimpleButton
                        size="sm"
                        variant="primary"
                        onClick={(e) => payBillAmount(e, invoiceNumber)}
                        className="pay_button"
                        data-testid="open-drawer"
                        disabled={payButtonDisabeld}
                      >
                        {!isEmpty(currentBillAmmount) && currentBillAmmount.customerBill && currentBillAmmount.customerBill.partyBill && divideByCurrencey(currentBillAmmount.customerBill.partyBill.dueAmount) > 0 && currentBillAmmountError === "" ? 'Pay now' : 'Paid'}
                      </SimpleButton>
                    }
                  </div>
                </div>
                <hr id="current_bill_card_main_8" />
                <div
                  id="current_bill_card_main_9"
                  data-testid="click-bill-card-2"
                >
                  <div id="current_bill_card_main_10" className="d-flex justify-content-between mb-4">
                    <p id="current_bill_card_main_11" className="smallText">Summary</p>
                  </div>
                  <div id="current_bill_card_main_12" className="d-flex justify-content-between">
                    <p id="current_bill_card_main_13" className="lightText">This month's charges
                    </p>
                    <p id="current_bill_card_main_14" className="lightText">{currentMonthBill && currentMonthBill.data && currentMonthBill.data.accountSummary && currentMonthBill.data.accountSummary.thisMonthCharges && currentMonthBill.data.accountSummary.thisMonthCharges.total ? getCurrency(currentMonthBill.data.accountSummary.thisMonthCharges.total) : 0}</p>
                  </div>
                  <hr id="current_bill_card_main_15" />
                  <div id="current_bill_card_main_16" className="d-flex justify-content-between">
                    <p id="current_bill_card_main_17" className="lightText">Previous balance</p>
                    <p id="current_bill_card_main_18" className="lightText">{currentMonthBill && currentMonthBill.data && currentMonthBill.data.accountSummary && currentMonthBill.data.accountSummary.previousBalance ? getCurrency(currentMonthBill.data.accountSummary.previousBalance) : 0}</p>
                  </div>
                  <hr id="current_bill_card_main_19" />
                </div>
                <img
                  className="cursor" id="current_bill_card_main_20"
                  src={downloadIcon}
                  alt="img"
                  onClick={e => downloadBill(e, invoiceNumber ? invoiceNumber : "", cust_ac_no ? cust_ac_no : "")}
                />
              </BorderedCard>
              {/* for download bill  */}
              <ResponseModal show={showDownloadErrorMoadal} handleClose={() => setShowDownloadErrorMoadal(false)}>
                <ResponseMessage
                  error
                  heading="Bill is not generated"
                  button={{ label: "Try Again", onClick: downloadBill }}
                />
              </ResponseModal>
              {/* for pay bill */}
              <ResponseModal show={showbillPayModal}
                handleClose={() => {
                  setPayButtonDisabeld(false)
                  dispatch({ type: "BILL_PAYMENT_RESET" })
                  setShowbillPayModal(false)
                }}>
                <ResponseMessage
                  error
                  heading={billPaymentError}
                  button={{ label: "Try Again", onClick: (e) => payBillAmount(e, invoiceNumber) }}
                />
              </ResponseModal>
            </React.Fragment>

            :

            errorBreakdown === "Bill not found"

              ?

              <BorderedCard>
                <NoBillScreen />
              </BorderedCard>

              :

              errorBreakdown !== "" ?
                <div style={{ backgroundColor: "#fff", borderRadius: "10px" }} className="cardContainer mb-5">
                  <ResponseMessage error heading={errorBreakdown} />
                </div>
                :
                <div style={{ backgroundColor: "#fff", padding: '80px' }} className="cardContainer mb-5">
                  <PreLoader />
                </div>
        }
      </div >
    </>
  );
};

export default CurrentBillCard;
