import { common_types, helSupport, login_types } from '../helpers/types';
import { commonApi, apiHelpSupport, apiErrorCode, lobSubLob, apiSrHistory } from "../helpers/ApiConstant"
import axios from "./";
import { decryptData, encryptData } from '../../components/common/utils/crypto';
import { b64_to_string } from '../../components/common';

const {
    srHistory
} = apiSrHistory


const { NO_INTERNET, SOMETHING_WRONG } = apiErrorCode
const { raiseServiceMasterData, apiSubmitServiceRequest, b2bApiSubmitServiceRequest, b2bCallbackApi } = apiHelpSupport
const {
    RAISE_SERVICE_MASTER_SUCCESS,
    RAISE_SERVICE_MASTER_ERROR,
    RAISE_SERVICE_SUBMIT_SUCCESS,
    RAISE_SERVICE_SUBMIT_ERROR,
    B2B_RAISE_SERVICE_SUBMIT_SUCCESS,
    B2B_RAISE_SERVICE_SUBMIT_ERROR,
    B2B_CALLBACK_SUCCESS,
    B2B_CALLBACK_ERROR,
} = helSupport
let {
    GET_PROFILE_DATA_SUCCESS,
    UPDATE_PROFILE_ERROR,
    UPDATE_PROFILE_SUCCESS,
    GET_PROFILE_DATA_ERROR,
    SR_HISTORY_ERROR,
    SR_HISTORY_SUCCESS
} = common_types;


let {
    getProfileDetail,
    updateProfile
} = commonApi

const {
    AUTH_DATA,
} = login_types;




const setSuccess = (data, type) => {
    return { data, type }
}
const setError = (error, type) => {
    return { error, type }
}

export const fetchUserProfileDetail = (subLob, domain, msisdn) => {
    return (dispatch) => {
        return axios.get(`${getProfileDetail}?subLob=${lobSubLob[subLob]}&domain=${domain}&msisdn=${msisdn}`)
            .then(res => {
                if (res.status === 200) {
                    dispatch({
                        data: {
                            name: res.data && res.data.data && res.data.data.customer && res.data.data.customer.partyRole && res.data.data.customer.partyRole.partyRoleId && res.data.data.customer.partyRole.partyRoleId.name && res.data.data.customer.partyRole.partyRoleId.name ? res.data.data.customer.partyRole.partyRoleId.name : "",
                            number: res.data && res.data.data && res.data.data.logicalResource && res.data.data.logicalResource.identification && res.data.data.logicalResource.identification.id && res.data.data.logicalResource.identification.id ? res.data.data.logicalResource.identification.id : "",
                            alterNumber: res.data && res.data.data && res.data.data.customer && res.data.data.customer.telephone && res.data.data.customer.telephone.number && res.data.data.customer.telephone.number ? res.data.data.customer.telephone.number : "",
                            email: res.data && res.data.data && res.data.data.customer && res.data.data.customer.email && res.data.data.customer.email.eMailAddress && res.data.data.customer.email.eMailAddress ? b64_to_string(res.data.data.customer.email.eMailAddress) : ""
                        }, type: GET_PROFILE_DATA_SUCCESS
                    })
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, GET_PROFILE_DATA_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, GET_PROFILE_DATA_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, GET_PROFILE_DATA_ERROR))
                }
            });
    }
}


export const updateProfileData = (values, subLob, domain, msisdn) => {
    let config = {
        url: `${updateProfile}?subLob=${lobSubLob[subLob]}&domain=${domain}`,
        method: "post",
        data: {
            msisdn,
            "alternateNumber": values.alterNumber,
            "emailAddress": values.email
        }
    }
    return (dispatch) => {
        axios(config)
            .then(res => {
                if (res.status === 200) {
                    let authData = localStorage.getItem(AUTH_DATA) ? decryptData(localStorage.getItem(AUTH_DATA)) : undefined
                    authData.userInfo.services[0].email = values.email
                    localStorage.setItem(AUTH_DATA, encryptData(authData))
                    dispatch({ data: authData.userInfo.services[0], type: "GET_OPTIMUS_DETAILS" })
                    dispatch({ data: res.data.data, type: UPDATE_PROFILE_SUCCESS })
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, UPDATE_PROFILE_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, UPDATE_PROFILE_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, UPDATE_PROFILE_ERROR))
                }
            })
    }
}

///////raise a service request
export const fetchRaiseServiceMasterData = () => {

    return (dispatch) => {
        return axios.get(raiseServiceMasterData)
            .then(res => {
                if (res.status === 200) {
                    if (res && res.data) {
                        dispatch(setSuccess(res.data, RAISE_SERVICE_MASTER_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, RAISE_SERVICE_MASTER_ERROR))
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, RAISE_SERVICE_MASTER_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, RAISE_SERVICE_MASTER_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, RAISE_SERVICE_MASTER_ERROR))
                }
            });
    }
}


export const submitRaiseService = (subLob, domain, msisdn, req) => {
    const {
        requestType,
        subType,
        subSubType,
        description,
        caseType
    } = req
    let config = {
        url: apiSubmitServiceRequest,
        method: "POST",
        params: {
            domain,
            subLob: lobSubLob[subLob],
            msisdn,
        },
        data: {
            "problem": {
                "type": requestType,
                "subType": subType,
                "subSubType": subSubType,
                "creationChannel": "Web",
                "caseType": caseType,
                "resolution": "",
                "category": "SR",
                "problemCode": "",
                "notes": {
                    "type": "General Notes",
                    "description": description
                }
            }
        }
    }

    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res && res.data && res.data.meta.description) {
                        dispatch(setSuccess(res.data.data, RAISE_SERVICE_SUBMIT_SUCCESS))
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, RAISE_SERVICE_SUBMIT_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    if (err.response.data.errors[0].code === "ACM_CPR_011") {
                        dispatch(setError("You are trying to raise duplicate request", RAISE_SERVICE_SUBMIT_ERROR));
                    }
                    else {
                        dispatch(setError(err.response.data.errors[0].description, RAISE_SERVICE_SUBMIT_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, RAISE_SERVICE_SUBMIT_ERROR))
                }
            });
    }
}

export const raiseSrviceRequestB2B = (subLob, domain, msisdn, req, msisdnList) => {
    const {
        requestType,
        subType,
        subSubType,
        description,
        caseType
    } = req
    let config = {
        url: b2bApiSubmitServiceRequest,
        method: "POST",
        params: {
            domain,
            subLob: lobSubLob[subLob],
        },
        data: {
            "problem": {
                "type": requestType,
                "subType": subType,
                "subSubType": subSubType,
                "creationChannel": "Web",
                "caseType": caseType,
                "resolution": "",
                "category": "SR",
                "problemCode": "",
                "notes": {
                    "type": "General Notes",
                    "description": description
                }
            },
            msisdnList,    //array format
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res && res.data && res.data.data && res.data.data.length > 0) {
                        dispatch(setSuccess(res.data.data, B2B_RAISE_SERVICE_SUBMIT_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, B2B_RAISE_SERVICE_SUBMIT_ERROR))
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, B2B_RAISE_SERVICE_SUBMIT_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, B2B_RAISE_SERVICE_SUBMIT_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, B2B_RAISE_SERVICE_SUBMIT_ERROR))
                }
            });
    }
}

export const submitB2bCallback = (req) => {
    const {
        companyName,
        alternateNumber,
        countryCode,
        emailAddress,
        circle,
        descriptionOfQuery
    } = req
    let config = {
        url: b2bCallbackApi,
        method: "POST",
        data: {
            companyName,
            contactInfo: {
                alternateNumber,
                countryCode,
                emailAddress,
                circle,
            },
            descriptionOfQuery
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res && res.data && res.data.data) {
                        dispatch(setSuccess(res.data.data, B2B_CALLBACK_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, B2B_CALLBACK_ERROR))
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, B2B_CALLBACK_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, B2B_CALLBACK_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, B2B_CALLBACK_ERROR))
                }
            });
    }
}


// api for getting Sr history b2b
export function getSrHistory(subLob, domain, si) {

    let config = {
        url: `${srHistory}/${si}/service-request`,
        method: "GET",
        params: {
            domain,
            subLob: lobSubLob[subLob],
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.data) {
                        dispatch(setSuccess(res.data.data, SR_HISTORY_SUCCESS));
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, SR_HISTORY_ERROR))
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, SR_HISTORY_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    if (err.response.data.errors[0].code === "ACM_UNKNOWN_001" || err.response.data.errors[0].code === 'ACM_FSR_023') {
                        dispatch(setError("Service request history not found", SR_HISTORY_ERROR));
                    }
                    else {
                        dispatch(setError(err.response.data.errors[0].description, SR_HISTORY_ERROR));
                    }

                }
                else {
                    dispatch(setError(SOMETHING_WRONG, SR_HISTORY_ERROR))
                }
            })
    }
}
