import React from 'react';
import { ResponseMessage, } from "../common";
import BorderedCard from "../common/components/BorderedCard";
import { Container } from "react-bootstrap";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // You can also log the error to an error reporting service by using this
  componentDidCatch(error, errorInfo) {
    console.info("errorBoundary", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container className="pt-5 d-flex justify-content-between align-items-center">
          <BorderedCard >
            <ResponseMessage error
              heading={"Somthing went wrong"}
              message="we will clean up and try again"
              button={{
                label: "Try Again",
                onClick: () => window.location.reload()
              }}
            />

          </BorderedCard>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;