import { useEffect, useState } from 'react'
import { fetchUserProfileDetail, updateProfileData } from '../../../redux/actions/commonAction'
import { AlertMessage, SimpleButton, TextField } from '../../common'
import PreLoader from '../components/PreLoader'
import ResponseMessage from '../components/ResponseMessage'
import { emailRegex, getMobileNumber } from '../utils'
import { useDispatch, useSelector } from 'react-redux'
import { common_types } from '../../../redux/helpers/types'
import useValidator from '../../common/utils/useValidator';
// My Account Componnet for get and update the profile detail
const { PROFILE_DATA_RESET, RESET_UPDATE_PROFILE } = common_types
const UpdateProfile = ({ closeDrawer, isB2b = false, raiseServiceRequestView }) => {
    const initvalues = {
        name: "",
        number: "",
        alterNumber: "",
        email: "",
    }
    const dispatch = useDispatch()
    const [values, setValues] = useState(initvalues)
    const [profileDetail, setProfileDetail] = useState({})
    const [errors, setErrors] = useState({})
    const [headerError, setHeaderError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const { optimusDetails } = useSelector(state => state.auth)
    const { profileData, getProfileError, updateProfileMessage, updateProfileError } = useSelector(state => state.common)
    const { si, si_lob, domain, emailAddress, frst_nm, lst_nm, mddl_nm, cnt } = optimusDetails

    const [validator, showValidationMessage] = useValidator({
        emailId: {  // name the rule
            message: 'Enter a valid :attribute.',
            rule: (val, params, validatorer) => {
                return validatorer.helpers.testRegex(val, emailRegex) && params.indexOf(val) === -1
            },
        }
    });

    //onChange function
    const onInputChnage = e => {
        let { value, name } = e.target
        setValues({ ...values, [name]: value })
    }

    //update profile detail
    const onValueSubmit = () => {
        if (validator.allValid()) {
            if (Object.keys(profileDetail).length !== 0) {
                if (values.email === profileDetail.email && values.alterNumber === profileDetail.alterNumber) {
                    setHeaderError("Nothing is changed")
                }
                else if (values.email === "" && values.alterNumber === "") {
                    setHeaderError("You can't submit blank value")
                }
                else {
                    setIsLoading(true)
                    dispatch(updateProfileData(values, si_lob, domain, si, emailAddress))
                    setHeaderError("")
                }
            }
        }
        else {
            showValidationMessage(true);
        }

    }


    useEffect(() => {
        //fetch customer profile detail
        if (getProfileError || updateProfileMessage || updateProfileError) {
            setIsLoading(false)

        }
        if (profileData && Object.values(profileData).length > 0) {
            setIsLoading(false)
            setProfileDetail(profileData)
            setValues(profileData)
        }

    }, [dispatch, si, profileData, getProfileError, updateProfileMessage, updateProfileError])

    useEffect(() => {
        return () => {
            dispatch({ type: PROFILE_DATA_RESET })
        }
    }, [dispatch])

    useEffect(() => {
        if (profileData && Object.values(profileData).length === 0 && getProfileError === "" && domain !== "B2B") {
            setIsLoading(true)
            dispatch(fetchUserProfileDetail(si_lob, domain, si))
        }
        if (domain === "B2B") {
            setValues({ name: `${frst_nm} ${mddl_nm} ${lst_nm}`, email: emailAddress, number: cnt })
        }

    }, [dispatch, si, profileData, getProfileError, si_lob, domain, frst_nm, mddl_nm, lst_nm, emailAddress, cnt])


    return (
        <div id="pro-1" data-testid="first-screen" className="new-connection" >
            {updateProfileError === "" && updateProfileMessage === "" && getProfileError === "" && !isLoading &&
                <>
                    <h3 id="pro-2" className="mb-4 text-bold">My profile</h3>
                    {headerError ? <AlertMessage id="pro-3" message={headerError} /> : ""}
                    <form className="mb-5" id="pro-4" data-testid="profile_form">
                        <div className="field-area mb-4" id="pro-6">
                            {isB2b && domain === "B2B" ? "" :
                                <>
                                    <TextField
                                        id="pro-7"
                                        label="Name"
                                        name="name"
                                        value={values.name}
                                        error={errors.name}
                                        data-testid="user_name"
                                        onChange={onInputChnage}
                                        readOnly
                                    />
                                    <div className="mobile-number-container">
                                        <div className="prefix" id="prefix1" >
                                            <TextField
                                                id="login5"
                                                name="prefix"
                                                value={`+${44} (0)`}
                                                className="mb-3"
                                                readOnly
                                            />
                                        </div>
                                        <div className="mobile-number" id="prefix2">
                                            <TextField
                                                id="pro-8"
                                                label="Number"
                                                name="number"
                                                value={getMobileNumber(values.number)}
                                                data-testid="user_number"
                                                readOnly
                                                onChange={onInputChnage}
                                            />
                                        </div>

                                    </div>

                                    <div className="mobile-number-container">
                                        <div className="prefix" id="prefix1" >
                                            <TextField
                                                id="login5"
                                                name="prefix"
                                                value={`+${44} (0)`}
                                                className="mb-3"
                                                readOnly
                                            />
                                        </div>


                                        <div className="mobile-number" id="prefix2">
                                            <TextField
                                                id="pro-999"
                                                label="Alternate Mobile"
                                                name="alterNumber"
                                                onChange={onInputChnage}
                                                value={values.alterNumber}
                                                error={validator.message('Mobile number', values.alterNumber, 'required|numeric|min:10|max:11')}
                                                data-testid="user_alt_number"
                                                readOnly={isB2b}
                                            />
                                        </div>


                                    </div>
                                </>
                            }
                            <TextField
                                id="pro-1010"
                                label="Email"
                                name="email"
                                onChange={onInputChnage}
                                value={values.email}
                                error={validator.message('Email', values.email, 'required|emailId')}
                                data-testid="user_email"
                                readOnly={isB2b}
                            />

                            {/* {isB2b && <p className="text-muted">
                                <span className="error">*</span> To amend your profile details please raise a service request.
                            </p>} */}

                        </div>
                        {isB2b ? ""
                            // <SimpleButton
                            //     id="pro-11"
                            //     variant="primary"
                            //     maxWidth
                            //     className="mb-3 mt-3"
                            //     onClick={raiseServiceRequestView}
                            //     data-testid="submit-mobile"
                            // >
                            //     Raise a service request
                            // </SimpleButton>
                            :

                            <SimpleButton
                                id="pro-11"
                                variant="primary"
                                maxWidth
                                className="mb-3"
                                onClick={onValueSubmit}
                                data-testid="submit_profile"
                            >
                                Save
                            </SimpleButton>
                        }
                    </form>
                </>}
            {isLoading &&
                //loader
                <div id="pro-12" className="loading-screen" data-testid="profile_preloader">
                    <PreLoader id="pro-13" />
                </div>}
            {
                updateProfileError && <>
                    <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            error
                            heading={updateProfileError}
                            id="pro-15"
                        />
                        <div className="d-grid gap-3 success-close-button" id="pro-16" >
                            <SimpleButton variant="primary" maxWidth onClick={() => dispatch({ type: RESET_UPDATE_PROFILE })} id="pro-17" data-testid="try_again">Try Again</SimpleButton>
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={closeDrawer} id="pro-18"  >Cancel</SimpleButton>
                        </div>
                    </div>
                </>
            }
            {
                updateProfileMessage && <>
                    <div className="d-flex flex-column justify-content-between mt-5" id="pro-119" >
                        <ResponseMessage
                            success
                            heading={"Profile updated"}
                            message={updateProfileMessage}
                            id="pro-1399"
                        />
                        <div className="success-close-button" id="pro-1993" >
                            <SimpleButton variant="primary" maxWidth onClick={closeDrawer} id="pro-1u83" >Close</SimpleButton>
                        </div>
                    </div>
                </>
            }

            {
                getProfileError && <>
                    <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            error
                            heading={getProfileError}
                            id="pro-15"
                        />
                        <div className="d-grid gap-3 success-close-button" id="pro-16" >
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={closeDrawer} id="pro-18"  >Cancel</SimpleButton>
                        </div>
                    </div>
                </>
            }

        </div>
    )
}

export default UpdateProfile
