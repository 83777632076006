import { useState } from 'react'
import { SimpleButton, TextField, PreLoader, ResponseMessage, Select, } from '../../common'
import { BackIcon } from '../../Icons'
import { requestNewConnection } from '../../../redux/actions/loginAction'
import { apiErrorCode } from "../../../redux/helpers/ApiConstant";
import useValidator from '../../common/utils/useValidator';
import { emailRegex } from '../../common/utils';

const { NO_INTERNET, SOMETHING_WRONG } = apiErrorCode
const NewConnection = (props) => {
    const initvalues = {
        countryCode: "",
        prefix: "+44",
        mobile: "",
        email: "",
        firstName: "",
        lastName: "",
        circle: ""

    }
    const [values, setValues] = useState(initvalues)
    const [validator, showValidationMessage] = useValidator({
        emailId: {  // name the rule
            message: 'Enter a valid :attribute.',
            rule: (val, params, validatorer) => {
                return validatorer.helpers.testRegex(val, emailRegex) && params.indexOf(val) === -1
            },
        }
    });

    const [success, setSuccess] = useState("")
    const [error, setError] = useState("")
    const [isLoading, setLoading] = useState(false)

    const onInputChnage = e => {
        let { value, name } = e.target
    if (name === "prefix") {
            value = String(value).substring(1)
            if (value <= 9999 && value.length <= 4) {
                value = '+' + value
                setValues({ ...values, [name]: value.trim() });
            }

        }
        else {
            setValues({ ...values, [name]: value });
        }
    }

    const onValueSubmit = e => {
        if (validator.allValid()) {
            setLoading(true)
            let payload = {
                ...values,
                name: `${values.firstName} ${values.lastName}`,
                number: values.mobile,
                prefix: `${String(values.prefix).substring(1)}`,
                customerQueryText: props.newConnectionvalue
            }
            requestNewConnection(payload)
                .then(res => {
                    if (res.status === 200) {
                        setLoading(false)
                        setSuccess(res.data.meta.description)
                    }
                })
                .catch(err => {
                    setLoading(false)
                    if (!err.response) {
                        setError(NO_INTERNET);
                    }
                    else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                        setError(err.response.data.errors[0].description);
                    } else {
                        setError(SOMETHING_WRONG);
                    }
                })

        } else {
            showValidationMessage(true);
        }

    }

    const locationData = [
        { key: "Jersey", values: "Jersey" },
        { key: "Guernsey", value: "Guernsey" },
        { key: "Other", value: "Other" }
    ]
    return (
        <div data-testid="first-screen" className="new-connection">
            {success === "" && error === "" && !isLoading &&
                <>
                    <img src={BackIcon} alt="back" onClick={() => props.setView(props.back)} className="cursor mb-3" />
                    <div className="title"> {props.newConnectionvalue === 'HELP_WITH_APP_SETUP' ? `Help with my Airtel-Vodafone Selfcare setup` : `Request a New Connection`}</div>
                    <form className="mb-4">
                        <div className="field-area mb-5">
                            <div className="mobile-number-container">
                                <div className="prefix" id="prefix1" >
                                    <TextField
                                        label="Code"
                                        id="login5"
                                        name="prefix"
                                        className="mb-3"
                                        // onBlur={onVBlurPrefix}
                                        onChange={onInputChnage}
                                        value={values.prefix}
                                        error={validator.message('Country Code', String(values.prefix).substring(1), 'required')}
                                        required
                                    />
                                </div>
                                <div className="mobile-number" id="prefix2">
                                    <TextField
                                        label="Your Number"
                                        name="mobile"
                                        onChange={onInputChnage}
                                        value={values.mobile}
                                        error={validator.message('Mobile number', values.mobile, 'required|numeric|min:10|max:11')}
                                        data-testid="number_field"
                                        required
                                    />
                                </div>

                            </div>

                            <TextField
                                label="Email"
                                name="email"
                                onChange={onInputChnage}
                                value={values.email}
                                error={validator.message('Email', values.email, 'required|emailId')}
                                data-testid="email_field"
                                required
                            />
                            <TextField
                                label="First Name"
                                name="firstName"
                                onChange={onInputChnage}
                                value={values.firstName}
                                error={validator.message('First name', values.firstName, 'required|alpha|max:25|min:2')}
                                data-testid="name_field"
                                required
                            />
                            <TextField
                                label="Last Name"
                                name="lastName"
                                onChange={onInputChnage}
                                value={values.lastName}
                                error={validator.message('Last name', values.lastName, 'required|alpha|max:25|min:2')}
                                data-testid="name_field"
                                required
                            />
                            <Select
                                value={values.circle}
                                name="circle"
                                placeholder="Location"
                                onChange={onInputChnage}
                                options={locationData}
                                keyName="key"
                                keyValue="value"
                                className="mb-4"
                                error={validator.message('Location', values.circle, 'required')}
                            />

                        </div>
                        <SimpleButton
                            variant="primary"
                            maxWidth
                            onClick={onValueSubmit}
                            data-testid="submit-button"
                        >
                            Submit
                        </SimpleButton>
                    </form>
                </>
            }
            {
                isLoading &&
                <div id="pro-12" className="loading-screen" >
                    <PreLoader id="pro-13" />
                </div>
            }
            {
                error &&
                <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                    <ResponseMessage
                        error
                        heading={error}
                        id="pro-15"
                    />
                    <div className="d-grid gap-3 success-close-button" id="pro-16" >
                        <SimpleButton variant="primary" maxWidth onClick={() => setError("")} id="pro-17" data-testid="try_again">Try Again</SimpleButton>
                    </div>
                </div>
            }
            {
                success &&
                <>
                    <div className="d-flex flex-column justify-content-between mt-4" id="pro-119" >
                        <ResponseMessage
                            success
                            heading={success}
                            id="pro-1399"
                        />
                        <div className="success-close-button" id="pro-1993" >
                            <SimpleButton variant="primary" maxWidth id="pro-1u83" onClick={() => props.setView(props.afterSucessCase)} >{props.afterSuccess}</SimpleButton>
                        </div>
                    </div>
                </>
            }
        </div >
    )
}

export default NewConnection
