import React from 'react'
import NavTab from '../../../components/common/components/NavTab';
import { Route, Switch, Redirect } from "react-router-dom";
import BillingSectionB2b from './views/BillingSectionB2b';
import TransactionsB2b from './views/TransactionsB2b';
import { b2b_prefix } from '../../envConfig'

const BillingMainB2b = () => {

    const url = `/${b2b_prefix}/myBill`

    const billRoute = [
        { path: `${url}`, eventKey: "link-3", name: "My Bill" },
        { path: `${url}/transactions`, eventKey: "link-4", name: "Transactions" },
    ];


    return (
        <div id="navtab_b2b">
            <NavTab tabRoutes={billRoute} />
            <Switch>
                <Route path={url} component={BillingSectionB2b} exact />
                <Route
                    path={`${url}/transactions`}
                    component={TransactionsB2b}
                />
                <Redirect to={url} />

            </Switch>
        </div>
    )
}

export default BillingMainB2b
