import React, { useEffect, useState } from 'react'
import { AlertMessage, PreLoader, ResponseMessage, Select, SimpleButton, TextField } from '../../common'
import { BackIcon } from '../../Icons'
import { raiseSrviceRequestB2B, submitB2bCallback, submitRaiseService } from '../../../redux/actions/commonAction'
import { useDispatch, useSelector } from 'react-redux'
import { helSupport } from '../../../redux/helpers/types'
import useValidator from '../../common/utils/useValidator';
import { getMobileNumber, emailRegex, isEmpty, } from '../../common/utils'
import { globalErrorMessage } from '../../envConfig'
// import { useHistory } from 'react-router-dom'
// import { b2b_prefix } from '../../envConfig'

const { RAISE_SERVICE_RESET, B2B_CALLBACK_RESET } = helSupport
const CallBackService = (props) => {
    // const history = useHistory()
    // const prefix = `/${b2b_prefix}`
    const initvalues = {
        companyName: "",
        circle: "",
        alternateNumber: "",
        emailAddress: "",
        countryCode: "+44",
        descriptionOfQuery: ""

    }
    const [values, setValues] = useState(initvalues)
    const [validator, showValidationMessage] = useValidator({
        emailId: {  // name the rule
            message: 'Enter a valid :attribute.',
            rule: (val, params, validatorer) => {
                return validatorer.helpers.testRegex(val, emailRegex) && params.indexOf(val) === -1
            },
        }
    });
    const { closeDrawer } = props
    const dispatch = useDispatch()
    const {
        raiseServiceSubmitSuccess,
        raiseServiceSubmitError,
        b2bCallSuccess,
        b2bCallError
    } = useSelector(state => state.common)
    const { optimusDetails } = useSelector(state => state.auth)
    const [description, setDescription] = useState("")
    const [masterError, setMasterError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const { si, si_lob, domain } = optimusDetails

    const onInputChnage = e => {
        let { value, name } = e.target
        if (name === "mobile") {

            if (value <= 9999999999 && value.length <= 10) {
                setValues({ ...values, [name]: value.trim() });
            }
        }
        else if (name === "countryCode") {
            value = String(value).substring(1)
            if (value <= 9999 && value.length <= 4) {
                value = '+' + value
                setValues({ ...values, [name]: value.trim() });
            }

        }
        else {
            setValues({ ...values, [name]: value });
        }
    }

    const onSubmitHandler = e => {
        //specif type we have to send for the call back
        e.preventDefault()
        let obj = {
            requestType: "Call Back",
            subType: "Request a call back",
            subSubType: "Please call me back",
            description: description ? description : "",
            caseType: "Query"
        }

        if (validator.allValid()) {
            setIsLoading(true)
            if (domain === "B2B") {
                values.descriptionOfQuery = description
                dispatch(submitB2bCallback(values))
            }
            else {
                setIsLoading(true)
                dispatch(submitRaiseService(si_lob, domain, si, obj))
            }
            setMasterError("")
        }
        else {
            showValidationMessage(true)
        }
    }
    useEffect(() => {
        return () => dispatch({ type: RAISE_SERVICE_RESET })
    }, [])

    useEffect(() => {
        if (raiseServiceSubmitError || raiseServiceSubmitSuccess || b2bCallError || b2bCallSuccess) {
            setIsLoading(false)
        }
    }, [raiseServiceSubmitError, raiseServiceSubmitSuccess, b2bCallError, b2bCallSuccess])


    const resetErrorClose = () => {
        dispatch({ type: B2B_CALLBACK_RESET })
        closeDrawer()
    }
    const locationData = [
        { key: "Jersey", values: "Jersey" },
        { key: "Guernsey", value: "Guernsey" },
        { key: "Other", value: "Other" }
    ]

    return (
        <>
            {raiseServiceSubmitError === "" && raiseServiceSubmitSuccess === "" && !isLoading && b2bCallError === "" && b2bCallSuccess === "" &&
                <>
                    <div onClick={() => props.onStepChange("helpSupport")}  >
                        <img
                            src={BackIcon}
                            alt="backArrow"
                            className="d-inline-block align-top cursor"
                        />
                    </div>
                    <div className="service_request_main help_Support_layout mt-2" data-testid={"serv_req_comp"}>
                        <h2 className="callBack-title">Request a call back</h2>
                        {masterError && <AlertMessage variant="error" message={masterError} />}
                        {domain === "B2B" &&
                            <>
                                <TextField
                                    label="Comapny Name"
                                    name="companyName"
                                    onChange={onInputChnage}
                                    value={values.companyName}
                                    error={validator.message('Company Name', values.companyName, 'required|min:2|max:60')}
                                    data-testid="email_field"
                                    required
                                />
                                <div className="mobile-number-container">
                                    <div className="prefix" id="prefix1" >
                                        <TextField
                                            label="Code"
                                            id="login5"
                                            name="countryCode"
                                            className="mb-3"
                                            // onBlur={onVBlurPrefix}
                                            onChange={onInputChnage}
                                            value={values.countryCode}
                                            error={validator.message('Country Code', String(values.countryCode).substring(1), 'required')}
                                            required
                                        />
                                    </div>
                                    <div className="mobile-number" id="prefix2">
                                        <TextField
                                            label="Contact Number"
                                            name="alternateNumber"
                                            onChange={onInputChnage}
                                            value={values.alternateNumber}
                                            error={validator.message('Contact number', values.alternateNumber, 'required|numeric|min:10|max:11')}
                                            data-testid="email_field"
                                            required
                                        />
                                    </div>
                                </div>
                                <TextField
                                    label="Email"
                                    name="emailAddress"
                                    onChange={onInputChnage}
                                    value={values.emailAddress}
                                    error={validator.message('Email', values.emailAddress, 'required|emailId')}
                                    data-testid="email_field"
                                    required
                                />
                                <Select
                                    value={values.circle}
                                    name="circle"
                                    placeholder="Location"
                                    onChange={onInputChnage}
                                    options={locationData}
                                    keyName="key"
                                    keyValue="value"
                                    className="mb-4"
                                    error={validator.message('Location', values.circle, 'required')}
                                />

                            </>
                        }
                        {domain === "B2C" &&
                            <div className="mobile-number-container">
                                <div className="prefix" id="prefix1" >
                                    <TextField
                                        id="login5"
                                        name="prefix"
                                        value={`+${44} (0)`}
                                        className="mb-3"
                                        readOnly
                                    />
                                </div>
                                <div className="mobile-number" id="prefix2">
                                    <TextField
                                        type="text"
                                        name="mobileNo"
                                        label="Mobile No."
                                        readOnly={true}
                                        value={getMobileNumber(optimusDetails.si)}
                                    />
                                </div>

                            </div>
                        }
                        <TextField
                            type="textArea"
                            name="description"
                            label="Description."
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            error={validator.message('description', description, 'required')}
                            data-testid="description"
                            required
                        />
                        {domain === "B2B" ? "" :
                            <p className="text-muted mb-2">
                                <span className="error">*</span>You can add alternate number in description.
                            </p>
                        }

                        <SimpleButton variant="primary" data-testid="submit_button" className="mt-2" maxWidth onClick={onSubmitHandler} >
                            Submit
                        </SimpleButton>
                    </div>
                </>
            }
            {isLoading &&
                <div className="loading-screen" data-testid="preloader">
                    <PreLoader />
                </div>}
            {
                raiseServiceSubmitError && <>
                    <div className="d-flex flex-column justify-content-between mt-5">
                        {
                            raiseServiceSubmitError === "You are trying to raise duplicate request" ?
                                <ResponseMessage
                                    info
                                    heading={raiseServiceSubmitError}
                                    message={globalErrorMessage()}
                                />
                                :

                                <ResponseMessage
                                    error
                                    heading={raiseServiceSubmitError}
                                />
                        }
                        <div className="d-grid gap-3 success-close-button">
                            <SimpleButton variant="primary" maxWidth onClick={() => dispatch({ type: RAISE_SERVICE_RESET })}>Try Again</SimpleButton>
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={resetErrorClose} >Cancel</SimpleButton>
                        </div>
                    </div>
                </>
            }
            {
                raiseServiceSubmitSuccess && <>
                    <div className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            success
                            heading={raiseServiceSubmitSuccess}
                        />
                        <div className="success-close-button">
                            <SimpleButton variant="primary" maxWidth onClick={resetErrorClose}>Close</SimpleButton>
                        </div>
                    </div>
                </>
            }
            {
                b2bCallSuccess && <>
                    <div className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            success
                            heading={b2bCallSuccess}
                        />
                        <div className="success-close-button">
                            <SimpleButton variant="primary" maxWidth onClick={resetErrorClose}>Close</SimpleButton>
                        </div>
                    </div>
                </>
            }
            {
                b2bCallError && <>
                    <div className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            error
                            heading={b2bCallError}
                        />
                        <div className="d-grid gap-3 success-close-button">
                            <SimpleButton variant="primary" maxWidth onClick={() => dispatch({ type: B2B_CALLBACK_RESET })}>Try Again</SimpleButton>
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={resetErrorClose} >Cancel</SimpleButton>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
export default CallBackService
