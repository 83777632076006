import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from "react-bootstrap-table2-paginator"
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { Row, Col } from 'react-bootstrap';


const TableComp = ({ keyField, tableData, columns, searchBar, paggination = false, heading, rowsData, download, check, handeleSelection, actions = false }) => {

    const { SearchBar } = Search;
    let selection = rowsData ? rowsData : []

const [tableDatas, setTableDatas] = useState([]);

  // Assuming you have an effect that fetches the data and updates the state
  useEffect(() => {
   
    // Sort the data based on the "status" property
    const sortedTableData = [...tableData].sort((rowA, rowB) => {
      if (rowA.status === 'Active') {
        return -1; // Move "Active" rows to the top
      }
      if (rowB.status === 'Active') {
        return 1; // Move "Active" rows to the top
      }
      return 0; 
    });

    setTableDatas(sortedTableData);
  }, []);

    const handleOnSelect = (row, isSelect) => {
        if (!check) {
            if (isSelect) {
                selection.push(row);
            } else {
                const keyIndex = selection.indexOf(row)
                if (keyIndex >= 0) {
                    // it does exist so we will remove it using destructing
                    selection = [
                        ...selection.slice(0, keyIndex),
                        ...selection.slice(keyIndex + 1),
                    ];
                }
            }
            handeleSelection([...selection])
        }

    };

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        hideSelectAll: true,
        hideSelectColumn: check,
        onSelect: handleOnSelect
    }
    // const customTotal = (from, to, size) => (
    //     <>
    //         <span className="react-bootstrap-table-pagination-total">
    //             Showing {from} to {to} of {size} Records
    //         </span>
    //     </>
    // );
    const paginationOption = {
        paginationSize: 4,
        pageStartIndex: 1,
        custom: false,
        sizePerPage: 5,
        hideSizePerPage: true,
        // paginationTotalRenderer: customTotal,
        showTotal: paggination,
    }

    return (
        <div className="table_all_content" data-testid="table-comp">
            <ToolkitProvider
                keyField={keyField}
                data={tableDatas}
                columns={columns}
                search
            >
                {
                    props => (
                        <div className="bootstrap_table_main">
                            <Row>
                                <Col md={actions ? 7 : 9} sm={9}>
                                    <h3 className="tableHeader">{heading}</h3>
                                </Col>
                                {

                                    download ?
                                        <Col md={3} sm={3}>
                                            {download}
                                        </Col>
                                        :
                                        ""
                                }
                                {

                                    searchBar ?
                                        <Col md={3} sm={3} className='mb-2'>
                                            <SearchBar
                                                {...props.searchProps} />
                                        </Col>
                                        :
                                        ''

                                }
                                {
                                    actions ?
                                        <Col sm={3} md={2}>
                                            {actions}
                                        </Col>
                                        : ""
                                }
                            </Row>

                            <hr />
                            <BootstrapTable
                                keyField={keyField}
                                {...props.baseProps}
                                bordered={false}
                                headerClasses="header-class"
                                selectRow={selectRow}
                                wrapperClasses="table-responsive"
                                pagination={paginationFactory(paginationOption)}
                            />
                        </div>
                    )
                }
            </ToolkitProvider >

        </div >
    )
}

export default TableComp
