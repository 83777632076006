const {
    REACT_APP_ENV,
    REACT_APP_POSTPAID_PREFIX,
    REACT_APP_IDLE_TIME,
    REACT_APP_B2B_PREFIX,
    REACT_APP_PREPAID_PREFIX,
    REACT_APP_DISCOVER_DESKTOP_GSY_IMG,
    REACT_APP_DISCOVER_MOBILE_GSY_IMG,
    REACT_APP_DISCOVER_DESKTOP_JSY_IMG,
    REACT_APP_LOGIN_JSY_IMG,
    REACT_APP_LOGIN_GSY_IMG,
    REACT_APP_DISCOVER_MOBILE_JSY_IMG,
    REACT_APP_ENC_DCP_SALT,
    REACT_APP_FIREBASE_API_KEY,
    REACT_APP_FIXED_BROADBAND_Guernsey_URL,
    REACT_APP_FIBRE_BROADBAND_JERSEY_URL,
    REACT_APP_FAQ_LINK_JERSEY_URL,
    REACT_APP_FAQ_LINK_GUERNSEY_URL,
    REACT_APP_BOOT_API_AUTH,
    REACT_APP_BOOT_API_BILL,
    REACT_APP_BOOT_API_ACCOUNT_MANAGER,
    REACT_APP_BOOT_API_PLAN_MANAGER,
    REACT_APP_FOOTER_API
} = process.env

// env
const ENV = REACT_APP_ENV

const postpaid_prefix = REACT_APP_POSTPAID_PREFIX // base path of postpaid
const idle_time_config = REACT_APP_IDLE_TIME            // in seconds
const b2b_prefix = REACT_APP_B2B_PREFIX
const prepaid_prefix = REACT_APP_PREPAID_PREFIX

// Images
const desktop_GSY = REACT_APP_DISCOVER_DESKTOP_GSY_IMG
const mobile_GSY = REACT_APP_DISCOVER_MOBILE_GSY_IMG
const dekstop_JSY = REACT_APP_DISCOVER_DESKTOP_JSY_IMG
const mobile_JSY = REACT_APP_DISCOVER_MOBILE_JSY_IMG
const login_JSY_img = REACT_APP_LOGIN_JSY_IMG
const login_GSY_img = REACT_APP_LOGIN_GSY_IMG

//salt
const encSalt = REACT_APP_ENC_DCP_SALT
const firebaseAPIKey = REACT_APP_FIREBASE_API_KEY

//urls
const fixed_brodband_url = REACT_APP_FIXED_BROADBAND_Guernsey_URL
const fiber_broadband_url = REACT_APP_FIBRE_BROADBAND_JERSEY_URL
const faq_url_jersey = REACT_APP_FAQ_LINK_JERSEY_URL
const faq_url_guernsey = REACT_APP_FAQ_LINK_GUERNSEY_URL

// api
const auth_base_url = REACT_APP_BOOT_API_AUTH  // auth service
const bill_base_url = REACT_APP_BOOT_API_BILL  // billiManagement service
const account_manager_url = REACT_APP_BOOT_API_ACCOUNT_MANAGER
const paln_manager_url = REACT_APP_BOOT_API_PLAN_MANAGER
const footer_base = REACT_APP_FOOTER_API
const footer_base_menu = REACT_APP_FOOTER_API

const globalErrorMessage = () => <span className="text-muted"> Please call our customer care team on <a href="tel:121">121</a> or email <a href="mailto:121@airtel-vodafone.com">121@airtel-vodafone.com</a> for further assistance. </span>


export {
    postpaid_prefix,
    idle_time_config,
    b2b_prefix,
    desktop_GSY,
    mobile_GSY,
    dekstop_JSY,
    mobile_JSY,
    login_JSY_img,
    login_GSY_img,
    encSalt,
    fixed_brodband_url,
    fiber_broadband_url,
    faq_url_jersey,
    faq_url_guernsey,
    prepaid_prefix,
    auth_base_url,
    bill_base_url,
    account_manager_url,
    paln_manager_url,
    footer_base,
    footer_base_menu,
    firebaseAPIKey,
    ENV,
    globalErrorMessage
}