
import { apiLogin, apiErrorCode } from '../helpers/ApiConstant'
import { login_types, common_types } from '../helpers/types'
import { encryptData } from '../../components/common/utils/crypto'
import { axiosService } from './'
import { b2b_prefix, postpaid_prefix, prepaid_prefix } from '../../components/envConfig'
import axios from 'axios'

const getSuccess = (data, type) => {
    return {
        data,
        type
    }
}

let { GET_OPTIMUS_DETAILS } = common_types;

let {
    GENERATE_OTP_SUCCESS,
    GENERATE_OTP_ERROR,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_ERROR,
    SAVE_AUTH,
    AUTH_DATA,
    TOKEN_INFO,
    LOGOUT,
    LOGOUT_ERROR
} = login_types;

const { NO_INTERNET, SOMETHING_WRONG } = apiErrorCode


const getError = (error, type) => {
    return {
        error,
        type
    }
}

function generateOtp(phoneNumber, emailAddress, domain, resend = false) {
    let config = {
        url: apiLogin.generateOtp,
        method: "POST",
        data: {
            domain,
            emailAddress,
            phoneNumber,
            applicationType: "web"
        }
    }
    return dispatch => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res && res.data) {
                        res.data.data.resend = resend // otp generated or resend
                        dispatch(getSuccess(res.data.data, GENERATE_OTP_SUCCESS));
                    }
                }
            })
            .catch(err => {
                let obj = { resend: resend }
                if (!err.response) {
                    obj.error = NO_INTERNET
                    dispatch(getError(obj, GENERATE_OTP_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    obj.error = err.response.data.errors[0].description
                    dispatch(getError(obj, GENERATE_OTP_ERROR))
                }
                else {
                    obj.error = SOMETHING_WRONG
                    dispatch(getError(obj, GENERATE_OTP_ERROR))
                }
            });
    }
}

function verifyOTP(phoneNumber, otp, emailAddress, domain) {
    let config = {
        url: apiLogin.verifyOtp,
        method: "POST",
        data: {
            phoneNumber,
            otp,
            domain,
            emailAddress,
            applicationType: "web"
        }
    }

    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res && res.data && res.data.data) {
                        let dataForSave = res.data.data
                        dataForSave.authDetails.isAuthenticate = true
                        //LOB condition for postpaid and save the data into the localstorage and dispatch into the auth reducer
                        if (res.data.data.userInfo && res.data.data.userInfo.services && res.data.data.userInfo.services[0].si_lob && dataForSave.domain === "B2C" && res.data.data.userInfo.services[0].si_lob.toUpperCase() === "POSTPAID") {
                            dataForSave.authDetails.userType = postpaid_prefix

                        }
                        //LOB condition for Prepaid and save the data into the localstorage and dispatch into the auth reducer
                        else if (res.data.data.userInfo && res.data.data.userInfo.services && res.data.data.userInfo.services[0].si_lob && res.data.data.userInfo.services[0].si_lob.toUpperCase() === "PREPAID") {
                            dataForSave.authDetails.userType = prepaid_prefix

                        }
                        //LOB condition for B2B and save the data into the localstorage and dispatch into the auth reducer
                        else if (res.data.data.userInfo && res.data.data.userInfo.b2bServices && res.data.data.userInfo.b2bServices[0].si_lob && dataForSave.domain === "B2B" && res.data.data.userInfo.b2bServices[0].si_lob.toUpperCase() === "POSTPAID") {
                            dataForSave.authDetails.userType = b2b_prefix
                        }
                        //nothing get from si_lob field
                        else {
                            dispatch(getError(SOMETHING_WRONG, VERIFY_OTP_ERROR))
                        }

                        //if you get your userType is B2B
                        if (dataForSave.authDetails.userType && dataForSave.authDetails.userType === "b2b") {
                            dataForSave.authDetails.domain = dataForSave.domain // to store the domain in auth detail
                            dataForSave.authDetails.firstTimeLogin = dataForSave.firstTimeLogin
                            dataForSave.authDetails.emailAddress = dataForSave.emailAddress
                            res.data.data.userInfo.b2bServices[0].domain = dataForSave.domain
                            res.data.data.userInfo.b2bServices[0].emailAddress = dataForSave.emailAddress
                            localStorage.setItem(AUTH_DATA, encryptData(dataForSave))
                            localStorage.setItem(TOKEN_INFO, encryptData(dataForSave?.authDetails))
                            dispatch(getSuccess(res.data.meta.description, VERIFY_OTP_SUCCESS));
                            dispatch(getSuccess({ ...res.data.data.userInfo.b2bServices[0], ...res.data.data.userInfo.contactDetails }, GET_OPTIMUS_DETAILS))
                            dispatch(getSuccess(dataForSave.authDetails, SAVE_AUTH))
                        }
                        //if you get your userType is B2C
                        if (dataForSave.authDetails.userType && dataForSave.authDetails.userType !== "b2b") {
                            dataForSave.authDetails.domain = dataForSave.domain // to store the domain in auth detail
                            dataForSave.authDetails.firstTimeLogin = dataForSave.firstTimeLogin
                            dataForSave.authDetails.phoneNumber = dataForSave.phoneNumber
                            res.data.data.userInfo.services[0].domain = dataForSave.domain
                            localStorage.setItem(AUTH_DATA, encryptData(dataForSave))
                            localStorage.setItem(TOKEN_INFO, encryptData(dataForSave?.authDetails))
                            dispatch(getSuccess(res.data.meta.description, VERIFY_OTP_SUCCESS));
                            dispatch(getSuccess(res.data.data.userInfo.services[0], GET_OPTIMUS_DETAILS))
                            dispatch(getSuccess(dataForSave.authDetails, SAVE_AUTH))
                        }
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(getError(NO_INTERNET, VERIFY_OTP_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(getError(err.response.data.errors[0].description, VERIFY_OTP_ERROR))
                }
                else {
                    dispatch(getError(SOMETHING_WRONG, VERIFY_OTP_ERROR))
                }
            });
    }
}

const requestNewConnection = (data) => {
    let config = {
        url: apiLogin.newConnection,
        method: "POST",
        data: {
            "customerName": data.name,
            "domain": "B2C", // beacuse request a new connection request is only in b2C
            "contactInfo": {
                "alternateNumber": data.number,
                "emailAddress": data.email,
                "countryCode": data.prefix,
                "circle": data.circle,

                "address": {
                    "addressLine1": "",
                    "addressLine2": "",
                    "addressLine3": "",
                    "addressLine4": "",
                    "state": "",
                    "city": "",
                    "pincode": "",
                    "country": ""
                }
            },
            "customerQuery": data.customerQueryText,
            "source": "web"
        }
    }

    return axiosService.post(config.url, config.data)

}

const logout = (phoneNumber, emailAddress, domain,) => {
    let config = {
        url: apiLogin.logoutAPI,
        method: "POST",
        data: {
            domain,
            emailAddress,
            phoneNumber,
            applicationType: "web",

        }
    }
    return dispatch => {
        return axiosService(config)
            .then(res => {
                if (res.status === 200) {
                    if (res && res.data) {
                        dispatch({ type: LOGOUT });
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(getError(NO_INTERNET, LOGOUT_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(getError(err.response.data.errors[0].description, LOGOUT_ERROR))
                }
                else {
                    dispatch(getError(SOMETHING_WRONG, LOGOUT_ERROR))
                }

            });
    }
}

export { generateOtp, verifyOTP, requestNewConnection, logout }
