//B2B dashboard component for showing total outstanding bills.
import BorderedCard from '../../../common/components/BorderedCard';
import { Row, Col } from 'react-bootstrap';
import { ReportIcon } from '../../../Icons';
import isEmpty from '../../../common/utils/isEmpty';
import { PreLoader, ResponseMessage, getScreenResolution, getCurrency, SimpleButton, divideByCurrencey, ResponseModal } from '../../../common';
import { useEffect, useState } from 'react';
import { getCurrentInovieAmount, getCurrentMonthBill, payBill, validatePayment } from '../../../../redux/actions/myBillsAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import { payment } from "../helpers/constant";
import {payment} from "../../../mybills//helpers/constant"
import { SuccessIcon, ErrorIcon } from "../../../Icons";
import PaymentResponse from '../../../mybills/components/PaymentResponse';


const TotalOutstanding = ({ breakDownData, erorBreak }) => {
    const [payButtonDisabeld, setPayButtonDisabeld] = useState(false);
    const dispatch = useDispatch();
    const { optimusDetails } = useSelector(state => state.auth);
    const { si, domain, cust_ac_no, si_lob } = optimusDetails;
    const {billPaymentSuccess, billPaymentError, currentBillAmmount, currentBillAmmountError } = useSelector(state => state.myBillsAllReducers);
    const [showbillPayModal, setShowbillPayModal] = useState(false)
    const [showPaymentModal, setShowPaymentModal] = useState(false)
    const [paymentValidationSuccess, setPaymentValidationSuccess] = useState({})
    const [paymentValidationError, setPaymentValidationError] = useState("")
    const { location } = useHistory()


    const [screenResolution, setResolution] = useState({ height: "768", width: "1366" })
    let billData = breakDownData && breakDownData.data ? breakDownData.data : {};
    let getResolution = () => {
        setResolution(getScreenResolution());
    }

    const payBillAmount = (e, invoiceNo) => {
        e.stopPropagation()
        if (!isEmpty(currentBillAmmount) && currentBillAmmount.customerBill && currentBillAmmount.customerBill.partyBill && currentBillAmmount.customerBill.partyBill.dueAmount > 0 && currentBillAmmountError === "") {
            setPayButtonDisabeld(true)
          dispatch(payBill(si, domain, cust_ac_no, invoiceNo, si_lob))
        }
      }
      var dueAmount = ''
  if (breakDownData
    &&
    !isEmpty(breakDownData)
    &&
    breakDownData.data
    &&
    breakDownData.data.BREAKUP_ACCOUNT_DETAILS) {
    dueAmount = breakDownData.data.BREAKUP_ACCOUNT_DETAILS
  }

      let invoiceNumber = ""

      if (breakDownData
        &&
        breakDownData.data
        &&
        breakDownData.data.BREAKUP_ACCOUNT_DETAILS.INVOICE_NO
      ) {
        invoiceNumber = breakDownData.data.BREAKUP_ACCOUNT_DETAILS.INVOICE_NO
      }

    useEffect(() => {
        if (billPaymentSuccess && billPaymentSuccess.url) {
            window.location.replace(billPaymentSuccess.url)
          }
          if (billPaymentError) {
            setShowbillPayModal(true)
            setPayButtonDisabeld(false)
          }
          if (isEmpty(currentBillAmmount) && currentBillAmmountError === "") {
            dispatch(getCurrentInovieAmount(domain, si_lob, si))
          }
        //get screenResolution resolution
        getResolution();
        window.addEventListener('resize', getResolution);

        return () => {
            window.removeEventListener('resize', getResolution);
        };
    }, [billPaymentSuccess, billPaymentError, dispatch]);

//payment functionality


///get the token for payment validation//

const paymentValidate = () => {
    setShowPaymentModal(true)
    validatePayment(location.state.token)
      .then(res => {
        setPaymentValidationError("")
        if (res && res.data && res.data.data) {
          setPaymentValidationSuccess(res.data.data)
        }
      })
      .catch(err => {
        setPaymentValidationSuccess({})
        if (!err.response) {
          setPaymentValidationError("Something went wrong")
        }
        else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
          setPaymentValidationError(err.response.data.errors[0].description);
        }
        else {
          setPaymentValidationError("Something went wrong")
        }
      })
  }

  useEffect(() => {
    if (location && location.state && location.state.token) {
      paymentValidate()
    }

  }, [location])

const closePaymentModal = () => {
    setShowPaymentModal(false)
    setPaymentValidationSuccess({})
    setPaymentValidationError("")
    dispatch(getCurrentInovieAmount(domain, si_lob, si))
    dispatch(getCurrentMonthBill(domain, si, si_lob, cust_ac_no))
  }

    return (
        <>
        <BorderedCard>
            {
                breakDownData && !isEmpty(breakDownData) && !isEmpty(billData) && erorBreak === "" &&
                    screenResolution.width >= 769 ?
                    breakDownData && !isEmpty(breakDownData) && !isEmpty(billData) && erorBreak === "" &&
                    <div style={{ minHeight: 275 }}>
                        <Row data-testid="nxt_bill_post" id="dashboard-71" className="mb-14">
                            <Col id="dashboard-8" sm={1}>
                                <img
                                    src={ReportIcon}
                                    id="dashboard-8"
                                    alt="report icone"
                                    className="d-inline-block align-top"
                                />
                            </Col>
                            <Col id="dashboard-9" sm={11}>
                                <h5 id="dashboard-10" className="dasboard-bill mb-0">Total outstanding</h5>
                            </Col>
                        </Row>
                        <div id="dashboard-13" className="d-flex justify-content-between py-3 border-bottom" >
                        <div data-testid="consumer_payment_test" id="dashboard-12" className="font-style dasboard-pound m-b-3">{billData.BREAKUP_ACCOUNT_DETAILS && billData.BREAKUP_ACCOUNT_DETAILS.DUE_AMOUNT ? getCurrency(billData.BREAKUP_ACCOUNT_DETAILS.DUE_AMOUNT) : 0}</div>
                        <div className='mt-21'>
                        {!isEmpty(currentBillAmmount) && dueAmount.DUE_AMOUNT &&
                        <SimpleButton
                        size="sm"
                        variant="primary"
                        onClick={(e) => payBillAmount(e, invoiceNumber)}
                        style={!isEmpty(currentBillAmmount) && currentBillAmmount.customerBill && currentBillAmmount.customerBill.partyBill && divideByCurrencey(currentBillAmmount.customerBill.partyBill.dueAmount) > 0 && currentBillAmmountError === "" ? {cursor: "pointer"} : {cursor: "default"}}
                        className="pay_button"
                        data-testid="open-drawer"
                        disabled={payButtonDisabeld}
                      >
                        {!isEmpty(currentBillAmmount) && currentBillAmmount.customerBill && currentBillAmmount.customerBill.partyBill && divideByCurrencey(currentBillAmmount.customerBill.partyBill.dueAmount) > 0 && currentBillAmmountError === "" ? 'Pay now' : 'Paid'}
                      </SimpleButton>
                      }
                      </div>
                      </div>
                        <div id="dashboard-13" className="d-flex justify-content-between py-3 border-bottom" >
                            <span id="dashboard-14" className="font-style dasboard-bill-details">This month’s charges</span>
                            <span id="dashboard-15" className="font-style dasboard-bill-details">{billData.accountSummary && billData.accountSummary.thisMonthCharges && billData.accountSummary.thisMonthCharges.total ? getCurrency(billData.accountSummary.thisMonthCharges.total) : 0}</span>
                        </div>
                        <div id="dashboard-16" className="d-flex justify-content-between py-3 border-bottom" >
                            <span id="dashboard-17" className="font-style dasboard-bill-details">Discount</span>
                            <span id="dashboard-18" className="font-style dasboard-bill-details">{billData && billData.accountSummary && billData.accountSummary.thisMonthCharges ? getCurrency(billData.accountSummary.thisMonthCharges.discounts) : 0}</span>
                        </div>
                        <div id="dashboard-19" className="d-flex justify-content-between py-3 border-bottom m-b-4" >
                            <span id="dashboard-20" className="font-style dasboard-bill-details">One time charges</span>
                            <span id="dashboard-21" className="font-style dasboard-bill-details">{billData && billData.accountSummary && billData.accountSummary.thisMonthCharges ? getCurrency(billData.accountSummary.thisMonthCharges.oneTimeCharges) : 0}</span>
                        </div>
                        {/* <p id="dashboard-25" className="mb-0 mt-5 font-style dasboard-bill-details">{`To be paid ${billData.BREAKUP_SUMMARY_CHARGES && billData.BREAKUP_SUMMARY_CHARGES.BREAKUP_ACCOUNT_DETAILS && billData.BREAKUP_SUMMARY_CHARGES.BREAKUP_ACCOUNT_DETAILS.BILL_DATE ? billData.BREAKUP_SUMMARY_CHARGES.BREAKUP_ACCOUNT_DETAILS.BILL_DATE : ""}`}</p> */}
                    </div>
                    :
                    breakDownData && !isEmpty(breakDownData) && !isEmpty(billData) && erorBreak === "" &&
                    <div style={{ minHeight: 171 }}>
                        <Row data-testid="nxt_bill_post" id="dashboard-7" className="mb-14">
                            <Col id="dashboard-8" sm={1}>
                                <img
                                    src={ReportIcon}
                                    id="dashboard-8"
                                    alt="report icone"
                                    className="d-inline-block align-top"
                                />
                            </Col>
                            <Col id="dashboard-9" sm={11}>
                                <div id="dashboard-10" className="dasboard-bill">Total outstanding</div>
                            </Col>
                        </Row>
                        <div data-testid="consumer_payment_test" id="dashboard-12" className="dashboard-mobile-bill-amount m-b-9">{billData.BREAKUP_ACCOUNT_DETAILS && billData.BREAKUP_ACCOUNT_DETAILS.DUE_AMOUNT ? getCurrency(billData.BREAKUP_ACCOUNT_DETAILS.DUE_AMOUNT) : 0}</div>
                    </div>
            }
            {
                breakDownData && !isEmpty(breakDownData) && isEmpty(billData) && erorBreak === "" &&

                <div style={{ minHeight: `${screenResolution.width >= 769 ? '311px' : '171px'}`, display: "flex", justifyContent: "center" }} >
                    <div style={{ margin: "auto" }}>
                        <ResponseMessage error heading={"No data found"} />
                    </div>
                </div>
            }
            {
                breakDownData && isEmpty(breakDownData) && erorBreak !== "" &&
                <div style={{ minHeight: `${screenResolution.width >= 769 ? '311px' : '171px'}`, display: "flex", justifyContent: "center" }} >
                    <div style={{ margin: "auto" }}>
                        <ResponseMessage error heading={erorBreak} />
                    </div>
                </div>
            }
            {
                breakDownData && isEmpty(breakDownData) && erorBreak === "" &&
                <div style={{ minHeight: `${screenResolution.width >= 769 ? '275px' : '171px'}`, display: "flex", justifyContent: "center" }} >
                    <div style={{ margin: "auto" }}>
                        <PreLoader />
                    </div>
                </div>
            }
        </BorderedCard>

          {/* for pay bill */}
           <ResponseModal show={showbillPayModal}
                handleClose={() => {
                  setPayButtonDisabeld(false)
                  dispatch({ type: "BILL_PAYMENT_RESET" })
                  setShowbillPayModal(false)
                }}>
                <ResponseMessage
                  error
                  heading={billPaymentError}
                  button={{ label: "Try Again", onClick: (e) => payBillAmount(e, invoiceNumber) }}
                />
              </ResponseModal>

              {/* payment Drwaer */}
        <ResponseModal id="Bill-section-9988999" show={showPaymentModal} handleClose={closePaymentModal} data-testid="response-modal">
          {
            // payment success
            !isEmpty(paymentValidationSuccess) &&
            paymentValidationError === "" &&
            paymentValidationSuccess.status === payment.TRANSACTION_COMPLETED &&
            < PaymentResponse
              icon={SuccessIcon}
              paymentValidationSuccess={paymentValidationSuccess}
              closePaymentModal={closePaymentModal}
              heading="Payment successfull!"
              message="Transaction may take 24 hours to be reflected"
            />
          }
          {
            // payment failed
            !isEmpty(paymentValidationSuccess) &&
            paymentValidationError === "" &&
            (paymentValidationSuccess.status === payment.TOKEN_GENERATED ||
              paymentValidationSuccess.status === payment.TRANSACTION_FAILURE ||
              paymentValidationSuccess.status === payment.TOKEN_GENERATION_FAILED
            ) &&
            < PaymentResponse
              icon={ErrorIcon}
              paymentValidationSuccess={paymentValidationSuccess}
              closePaymentModal={closePaymentModal}
              heading="Failed!"
              message="Your transaction has been failed"
            />
          }
          {
            // payment failed at posting
            !isEmpty(paymentValidationSuccess) && paymentValidationError === "" &&
            paymentValidationSuccess.status !== payment.TOKEN_GENERATED &&
            paymentValidationSuccess.status !== payment.TRANSACTION_FAILURE &&
            paymentValidationSuccess.status !== payment.TOKEN_GENERATION_FAILED &&
            paymentValidationSuccess.status !== payment.TRANSACTION_COMPLETED &&

            < PaymentResponse
              icon={SuccessIcon}
              paymentValidationSuccess={paymentValidationSuccess}
              closePaymentModal={closePaymentModal}
              heading="Payment successfull!"
              message="Transaction may take 24 hours to be reflected"
            />
          }
          {
            isEmpty(paymentValidationSuccess) && paymentValidationError === "" &&
            <PreLoader />
          }
        </ResponseModal>
        </>
    )
}

export default TotalOutstanding;