import { myplanType } from '../helpers/types';
import axios from "./";
import { apiMyPlan, apiErrorCode, lobSubLob } from '../helpers/ApiConstant'
import { isEmpty } from '../../components/common/utils';

const { getActivePlan, localBoltOnAPI, roamingBoltOnAPI, accountLevelBoltonApi, fetchPromoChangePlan, changeOrderAPI, minutesAndSmsUsageApi, dataUsageApi, msisdnDetails } = apiMyPlan
const { SOMETHING_WRONG, NO_INTERNET } = apiErrorCode



export const setSuccess = (data, type) => {
    return {
        type,
        data
    }
}

const setError = (error, type) => {
    return {
        type,
        error
    }
}

let {
    GET_LOCAL_BOLTON_SUCCESS,
    GET_LOCAL_BOLTON_ERROR,
    GET_ROAMING_BOLTON_SUCCESS,
    GET_ROAMING_BOLTON_ERROR,
    GET_DATA_BOLTON_SUCCESS,
    GET_DATA_BOLTON_ERROR,
    GET_ACC_LEVEL_BOLTON,
    GET_ACC_LEVEL_BOLTON_ERROR,
    GET_ACTIVE_PLAN_SUCCESS,
    GET_ACTIVE_PLAN_ERROR,
    GET_PROMOLIST_DATA,
    ERROR_PROMOLIST_DATA,
    MINUTES_SMS_USAGE_SUCCESS,
    MINUTES_SMS_USAGE_ERROR,
    DATA_USAGE_ERROR,
    DATA_USAGE_SUCCESS,
    CHANGE_ORDER_SUCCESS,
    CHANGE_ORDER_ERROR,
    GET_MSISDN_DETAILS,
    ERROR_MSISDN
} = myplanType;






// api for getting list of local bolt on
export function getLocalBoltOn(promotionID, msisdn, domain, subLob, circle, customerClass) {
    let config = {
        url: localBoltOnAPI,
        method: "GET",
        params: {
            promotionID,
            domain,
            subLob,
            msisdn,
            customerClass,
            circle
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.data) {
                        dispatch(setSuccess(res.data.data, GET_LOCAL_BOLTON_SUCCESS));
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, GET_LOCAL_BOLTON_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, GET_LOCAL_BOLTON_ERROR));
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, GET_LOCAL_BOLTON_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, GET_LOCAL_BOLTON_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, GET_LOCAL_BOLTON_ERROR))
                }
            });
    }
}

// api for getting  Roamning bolt on list
export function getRomaingBoltOn(planId, msisdn, domain, subLob, circle, customerClass) {
    let config = {
        url: roamingBoltOnAPI,
        method: "GET",
        params: {
            promotionID: planId,
            isIRBolton: true,
            domain,
            subLob,
            msisdn,
            circle,
            customerClass
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.data) {
                        dispatch(setSuccess(res.data.data, GET_ROAMING_BOLTON_SUCCESS));
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, GET_ROAMING_BOLTON_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, GET_ROAMING_BOLTON_ERROR));
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, GET_ROAMING_BOLTON_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, GET_ROAMING_BOLTON_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, GET_ROAMING_BOLTON_ERROR))
                }
            });
    }
}


//get bolton list for data
export function getDataBoltOn(promotionID, msisdn, domain, subLob, circle, customerClass) {
    let config = {
        url: localBoltOnAPI,
        method: "GET",
        params: {
            promotionID,
            isDataBolton: true,
            domain,
            customerClass,
            subLob,
            msisdn,
            circle
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.data) {
                        dispatch(setSuccess(res.data.data, GET_DATA_BOLTON_SUCCESS));
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, GET_DATA_BOLTON_ERROR))
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, GET_DATA_BOLTON_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, GET_DATA_BOLTON_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, GET_DATA_BOLTON_ERROR))
                }
            });
    }
}

//get bolton list for data
export function getAccLevelBolton(promotionID, msisdn, domain, subLob, circle, customerClass) {
    let config = {
        url: accountLevelBoltonApi,
        method: "GET",
        params: {
            promotionID,
            isDataBolton: true,
            domain,
            customerClass,
            subLob,
            msisdn,
            circle
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.data) {
                        dispatch(setSuccess(res.data.data, GET_ACC_LEVEL_BOLTON));
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, GET_ACC_LEVEL_BOLTON_ERROR))
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, GET_ACC_LEVEL_BOLTON_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, GET_ACC_LEVEL_BOLTON_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, GET_ACC_LEVEL_BOLTON_ERROR))
                }
            });
    }
}


//This action will get active plan for a SI
export const fetchActivePlan = (subLob, domain, msisdn, cust_class) => {

    let config = {
        url: `${getActivePlan}`,
        method: "GET",
        params: {
            subLob,
            domain,
            msisdn,
            accountType: cust_class
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {

                if (res.status === 200) {
                    if (res.data && res.data.data && res.data.data.getCustomerCurrentPlanDetailsResponse) {
                        dispatch(setSuccess(res.data.data.getCustomerCurrentPlanDetailsResponse, GET_ACTIVE_PLAN_SUCCESS))
                    }

                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, GET_ACTIVE_PLAN_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, GET_ACTIVE_PLAN_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, GET_ACTIVE_PLAN_ERROR))
                }
            });
    }
}

//Shows the breakdown of the bill
export const fetchPromotionList = (customerType, cust_class, circle, si, si_lob, domain, promotionID) => {
    let config = {
        url: fetchPromoChangePlan,
        method: "GET",
        params: {
            customerType,
            circle,
            orderType: "New",
            brand: "Voice+Data",
            planType: "Voice+Data",
            salesChannel: "web",
            domain: domain,
            subLob: si_lob,
            msisdn: si,
            // ownershipType: cust_class,
            customerClass: cust_class,
            promotionID


        },
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200 && res.data) {
                    dispatch(setSuccess(res.data, GET_PROMOLIST_DATA));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, ERROR_PROMOLIST_DATA))
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, ERROR_PROMOLIST_DATA))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, ERROR_PROMOLIST_DATA));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, ERROR_PROMOLIST_DATA))
                }
            });
    }
}


export const changeOrder = (promoId, msisdn, subLob, domain, customerClass, productId, productName, circle, pcrfSpec, isPlanChange = false, addon = {}) => {

    let boltonBody = {
        msisdn,
        subLob: lobSubLob[subLob],
        domain,
        customerClass,
        productId,
        productName,
        circle,
        applicationType: "web",
    }
    const config = {
        url: `${changeOrderAPI}/${promoId}/${isPlanChange ? 'change-plan' : 'add-ons'}`,
        method: "POST",
        data: isPlanChange ? {
            msisdn,
            subLob: lobSubLob[subLob],
            domain,
            customerClass,
            productId,
            productName,
            circle,
            applicationType: "web",
            additionalInfo: isEmpty(addon) ? null : [
                {
                    productId: addon.planId ? addon.planId : "",
                    productName: addon.name ? addon.name : "",
                    pcrfId: addon.pcrfId ? addon.pcrfId : "",
                    pcrfName: addon.pcrfName ? addon.pcrfName : "",
                }
            ],
            pcrfSpec
        } : boltonBody
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.data) {
                        dispatch(setSuccess(res.data.data.status.statusDescription, CHANGE_ORDER_SUCCESS))
                    }
                    else {
                        dispatch(setError(SOMETHING_WRONG, CHANGE_ORDER_ERROR))
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, CHANGE_ORDER_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    if (err.response.data.errors && err.response.data.errors[0].code === "PM_CCO_012") {
                        dispatch(setError("Please be advised the product you have selected is already active on your number. Please select an alternative product to continue. Thank you.", CHANGE_ORDER_ERROR));
                    }
                    else if (err.response.data.errors && err.response.data.errors[0].code === "PM_CCO_006") {
                        dispatch(setError("Open Order Exist Please re-check.", CHANGE_ORDER_ERROR));
                    }
                    else {
                        dispatch(setError(err.response.data.errors[0].description, CHANGE_ORDER_ERROR));
                    }
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, CHANGE_ORDER_ERROR))
                }
            });
    }
}

//api for getting usage
export function fetchMinutesSmsUsage(domain, msisdn, imsi, circle, subLob) {
    let config = {
        url: minutesAndSmsUsageApi,
        method: "GET",
        params: {
            domain,
            msisdn,
        }
    }
    return (dispatch) => {
        dispatch(fetchDataUsage(domain, msisdn, subLob, imsi, circle))
        return axios(config)
            .then(res => {
                if (res.status === 200 && res.data.data) {
                    dispatch(setSuccess(res.data.data, MINUTES_SMS_USAGE_SUCCESS));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, MINUTES_SMS_USAGE_ERROR))
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, MINUTES_SMS_USAGE_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, MINUTES_SMS_USAGE_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, MINUTES_SMS_USAGE_ERROR))
                }
            });
    }
}

export function fetchDataUsage(domain, msisdn, subLob, imsi, circle) {
    let config = {
        url: dataUsageApi,
        method: "GET",
        params: {
            domain,
            msisdn,
            subLob,
            imsi,
            circle
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200 && res.data.data) {
                    dispatch(setSuccess(res.data.data, DATA_USAGE_SUCCESS));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, DATA_USAGE_ERROR))
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, DATA_USAGE_ERROR))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, DATA_USAGE_ERROR));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, DATA_USAGE_ERROR))
                }
            });
    }
}







/****************************************MSISDN B2B**************************************************/


const successMsisdn = data => {
    return {
        type: GET_MSISDN_DETAILS,
        data
    }
}


export function getMsisdnDetails(id) {

    let config = {
        url: `${msisdnDetails}/${id.number}`,
        //`${breakdownBill}/${invoice}`
        method: "GET",
        params: {
            domain: id.domain,
            subLob: id.subLob,
            customerId: id.customerId,
            circleId: id.circleId
        }
    }
    return (dispatch) => {
        return axios(config)
            .then(res => {
                if (res.status === 200) {
                    if (res.data && res.data.data) {
                        dispatch(successMsisdn(res.data.data));
                    }
                }
            })
            .catch(err => {
                if (!err.response) {
                    dispatch(setError(NO_INTERNET, ERROR_MSISDN))
                }
                else if (err && err.response && err.response.data && err.response.data.errors && err.response.data.errors[0] && err.response.data.errors[0].description) {
                    dispatch(setError(err.response.data.errors[0].description, ERROR_MSISDN));
                }
                else {
                    dispatch(setError(SOMETHING_WRONG, ERROR_MSISDN))
                }
            });
    }
}







