
import BorderedCard from '../../common/components/BorderedCard';
import { SimpleButton, PreLoader, ResponseMessage } from '../../common'
import { Button, Row, Col } from 'react-bootstrap';
import { DataIcon, CallIcon, SMSIcon, ReportIcon, MobileIcon } from '../../Icons/'
import isEmpty from '../../common/utils/isEmpty';
import useUsage from '../hooks/useUsage';
import { useSelector } from 'react-redux';
import { PROD_TYPE_DATACARD, PROD_TYPE_BROADBAND } from '../dashboardConstant';

const UsageCard = (props) => {
    const { minutes, sms, minutesAndSmsUsageError, minutesAndSmsUsage, sumOfQuotaAllocated, sumOfQuotaAllocatedUnit, quotaLeft, quotaLeftUnit } = useUsage()

    const { optimusDetails } = useSelector((state) => state.auth);
    const {  domain, prod_type } = optimusDetails;


    /**********************************POSTPAID COMPONENT**************************************************/
    /**********************************POSTPAID COMPONENT**************************************************/
    /**********************************POSTPAID COMPONENT**************************************************/

    // const postpaidComp = (
    //     <div id="dashboard-27" className="usageCard" onClick={() => !true && props.gotoMyplan}>
    //         <BorderedCard>
    //             {
    //                 // temp code till not get api for usage with data to work properly remove code with condition True
    //                 true ? <div id="pro-12" style={{ minHeight: 218, marginTop: "170px" }} data-testid="profile_preloader"> </div>
    //                     :
    //                     props.usage && props.usage.loader && props.usage.msg === "" ?
    //                         <div id="pro-12" style={{ minHeight: 218, marginTop: "170px" }} data-testid="profile_preloader">
    //                             <PreLoader id="pro-13" />
    //                         </div>
    //                         :
    //                         props.usage && !props.usage.loader && props.usage.msg ?
    //                             <>
    //                                 <div id="pro-14" style={{ minHeight: 337 }} className="d-flex flex-column justify-content-between mt-5">
    //                                     <ResponseMessage
    //                                         error
    //                                         heading={props.usage.msg}
    //                                         id="pro-15"
    //                                     />
    //                                 </div>
    //                             </>
    //                             :
    //                             <>
    //                                 <Row data-testid="usage_postpaid_comp" id="dashboard-28">
    //                                     <Col id="dashboard-29" sm={1}>
    //                                         <img
    //                                             id="dashboard-30"
    //                                             src={ReportIcon}
    //                                             alt="report icone"
    //                                             className="d-inline-block align-top"
    //                                         />
    //                                     </Col>
    //                                     <Col id="dashboard-31" sm={11} >
    //                                         <div id="dashboard-32" className="d-flex justify-content-between">
    //                                             <span id="dashboard-33" className="dasboard-bill">My plan</span>
    //                                             {/* <span id="dashboard-34" className="font-style dasboard-bill-details">{`+${props.optimusDetails.si ? props.optimusDetails.si : ""}`}</span> */}
    //                                         </div>
    //                                     </Col>
    //                                 </Row>


    //                                 <Row id="dashboard-35" className="mt-5" style={{ minHeight: 222 }}>
    //                                     <Col id="dashboard-36" sm={8}>
    //                                         {
    //                                             conditional.data &&
    //                                             <Row id="dashboard-37" className="mb-3">
    //                                                 <Col id="dashboard-38" sm={2} lg={2}>
    //                                                     <img
    //                                                         src={DataIcon}
    //                                                         id="dashboard-39"
    //                                                         alt="data icone"
    //                                                         className="d-inline-block align-top"
    //                                                     />
    //                                                 </Col>
    //                                                 <Col id="dashboard-40" sm={10} lg={10} className="p-0">
    //                                                     <div id="dashboard-41" className=" d-flex justify-content-between font-style dasboard-bill-details"><span>Data</span> <span><span className="dashboard-usage-text-color">{`${props.usage && props.usage.dataUsage ? props.usage.dataUsage.remainedAmount : 0} left`}</span> of {props.usage && props.usage.dataUsage ? props.usage.dataUsage.totalAmount : 0}</span></div>
    //                                                     <ProgressBar id="dashboard-42" now={data} />
    //                                                 </Col>
    //                                                 <div className="d-flex justify-content-end mt-2">
    //                                                     <SimpleButton
    //                                                         //onClick={props.OpenDataDrawer} //removing because not in scope
    //                                                         onClick={(e) => e.stopPropagation()}
    //                                                         variant="primary" width="101px">
    //                                                         Add data
    //                                                     </SimpleButton>
    //                                                 </div>
    //                                             </Row>
    //                                         }
    //                                         {
    //                                             conditional.minutes &&
    //                                             <Row id="dashboard-43" className="mb-32">
    //                                                 <Col id="dashboard-44" sm={2} lg={2}>
    //                                                     <img
    //                                                         src={CallIcon}
    //                                                         id="dashboard-45"
    //                                                         alt="call icone"
    //                                                         className="d-inline-block align-top"
    //                                                     />
    //                                                 </Col>
    //                                                 <Col id="dashboard-46" sm={10} lg={10} className="p-0">
    //                                                     <div id="dashboard-47" className="d-flex justify-content-between font-style dasboard-bill-details"><span>Calls</span> <span><span className="dashboard-usage-text-color">{`${props.usage && props.usage.voiceUsage ? props.usage.voiceUsage.remainedAmount : 0} left`}</span> of {props.usage && props.usage.voiceUsage ? props.usage.voiceUsage.totalAmount : 0}</span> </div>     {/* <span><span className="dashboard-usage-text-color">Unlimited</span></span> */}
    //                                                     <ProgressBar id="dashboard-48" now={voiceMin} />
    //                                                 </Col>
    //                                             </Row>
    //                                         }
    //                                         {
    //                                             conditional.message &&
    //                                             <Row id="dashboard-49" className="mb-32">
    //                                                 <Col sm={2} lg={2}>
    //                                                     <img
    //                                                         src={SMSIcon}
    //                                                         id="dashboard-50"
    //                                                         alt="message icone"
    //                                                         className="d-inline-block align-top"
    //                                                     />
    //                                                 </Col>
    //                                                 <Col id="dashboard-51" sm={10} lg={10} className="p-0">
    //                                                     <div id="dashboard-52" className="d-flex justify-content-between font-style dasboard-bill-details"><span>SMS</span> <span><span className="dashboard-usage-text-color">{`${props.usage && props.usage.smsUsage ? props.usage.smsUsage.remainedAmount : 0} left`}</span> of {props.usage && props.usage.smsUsage ? props.usage.smsUsage.totalAmount : 0}</span></div>
    //                                                     <ProgressBar id="dashboard-53" now={sms} />
    //                                                 </Col>
    //                                             </Row>
    //                                         }

    //                                     </Col>
    //                                     <Col id="dashboard-54" sm={4}>
    //                                         <img
    //                                             src={MobileIcon}
    //                                             id="dashboard-55"
    //                                             alt="mobile icone"
    //                                             className="img-fluid align-top"
    //                                         />
    //                                     </Col>
    //                                 </Row>
    //                                 <Button id="dashboard-56" className="bg-white dashboard-addButton" onClick={props.gotoUsage} size="lg" active>
    //                                     View all usage
    //                                 </Button>
    //                                 <p id="dashboard-57" className="mb-0 mt-3 font-style dasboard-bill-details">{`Resets in ${props.reset} days`}</p>
    //                             </>
    //             }
    //         </BorderedCard >
    //     </div >
    // )

    // /**********************************PREPAID COMPONENT**************************************************/
    // /**********************************PREPAID COMPONENT**************************************************/
    // /**********************************PREPAID COMPONENT**************************************************/

    // const prepaidComp = (
    //     <div id="dashboard-prep-27" className="usageCard" onClick={props.gotoMyplan}>
    //         <BorderedCard>
    //             <Row data-testid="usage_prepaid_comp" id="dashboard-prep-28">
    //                 <Col id="dashboard-prep-29" sm={2}>
    //                     <img
    //                         id="dashboard-prep-30"
    //                         src={ReportIcon}
    //                         alt="report icone"
    //                         className="d-inline-block align-top"
    //                     />
    //                 </Col>
    //                 <Col id="dashboard-prep-31" sm={10} className="p-0">
    //                     <div id="dashboard-prep-32" className="d-flex justify-content-between">
    //                         <span id="dashboard-prep-33" className="dasboard-bill">My plan</span>
    //                     </div>
    //                 </Col>
    //             </Row>
    //             <Row id="dashboard-prep-35" className="mt-5">
    //                 <Col id="dashboard-prep-36" sm={8}>
    //                     <Row id="dashboard-prep-37" className="mb-32">
    //                         <Col id="dashboard-prep-38" sm={2}>
    //                             <img
    //                                 src={DataIcon}
    //                                 id="dashboard-prep-39"
    //                                 alt="data icone"
    //                                 className="d-inline-block align-top"
    //                             />
    //                         </Col>
    //                         <Col id="dashboard-prep-40" sm={10} className="p-0">
    //                             <div id="dashboard-prep-41" className=" d-flex justify-content-between font-style dasboard-bill-details"><span>Data</span> <span><span className="dashboard-usage-text-color">1.2 left</span> of 50GB</span></div>
    //                             <ProgressBar id="dashboard-prep-42" now={props.data} />
    //                         </Col>
    //                     </Row>
    //                     <Row id="dashboard-prep-43" className="mb-32">
    //                         <Col id="dashboard-prep-44" sm={2}>
    //                             <img
    //                                 src={CallIcon}
    //                                 id="dashboard-prep-45"
    //                                 alt="call icone"
    //                                 className="d-inline-block align-top"
    //                             />
    //                         </Col>
    //                         <Col id="dashboard-prep-46" sm={10} className="p-0">
    //                             <div id="dashboard-prep-47" className="d-flex justify-content-between font-style dasboard-bill-details"><span>Calls</span> <span><span className="dashboard-usage-text-color">Unlimited</span></span></div>
    //                             <ProgressBar id="dashboard-prep-48" now={props.minutes} />
    //                         </Col>
    //                     </Row>
    //                     <Row id="dashboard-prep-49" className="mb-32">
    //                         <Col sm={2}>
    //                             <img
    //                                 src={SMSIcon}
    //                                 id="dashboard-prep-50"
    //                                 alt="message icone"
    //                                 className="d-inline-block align-top"
    //                             />
    //                         </Col>
    //                         <Col id="dashboard-prep-51" sm={10} className="p-0">
    //                             <div id="dashboard-prep-52" className="d-flex justify-content-between font-style dasboard-bill-details"><span>SMS</span> <span><span className="dashboard-usage-text-color">5 left</span> of 100</span></div>
    //                             <ProgressBar id="dashboard-prep-53" now={props.message} />
    //                         </Col>
    //                     </Row>
    //                 </Col>
    //                 <Col id="dashboard-prep-54" sm={4}>
    //                     <img
    //                         src={MobileIcon}
    //                         id="dashboard-prep-55"
    //                         alt="mobile icone"
    //                         className="d-inline-block align-top"
    //                     />
    //                 </Col>
    //             </Row>
    //             <Button id="dashboard-prep-56" className="bg-white dashboard-addButton" onClick={props.gotoUsage} size="lg" active>
    //                 View all Usage
    //             </Button>
    //             <p id="dashboard-prep-57" className="mb-0 mt-3 font-style dasboard-bill-details">Ends in 14 days</p>
    //         </BorderedCard>
    //     </div>
    // )

    // this code will be usefull in future
    // return (
    //     //here the component will according to the props prepaid/postpaid
    //     <div>
    //         {props.type === "prepaid" ? prepaidComp : ""}
    //         {props.type === "postpaid" ? postpaidComp : ""}
    //     </div>
    // )

    return (
        <BorderedCard>
            < div id="dashboard-27" style={{ minHeight: 372 }} className={`usageCard ${minutesAndSmsUsageError !== "" && isEmpty(minutesAndSmsUsage) ? 'text-center' : ""}`} onClick={() => props.gotoMyplan}>
                {
                    !isEmpty(minutesAndSmsUsage) && Object.keys(minutesAndSmsUsage).length > 0 && minutesAndSmsUsageError === "" &&

                    // temp code till not get api for usage with data to work properly remove code with condition True
                    //     <div id="pro-12" style={{ minHeight: 218, marginTop: "170px" }} data-testid="profile_preloader"> </div>
                    //         :
                    // props.usage && props.usage.loader && props.usage.msg === "" ?
                    // <div id="pro-12" style={{ minHeight: 218, marginTop: "170px" }} data-testid="profile_preloader">
                    //     <PreLoader id="pro-13" />
                    // </div>
                    // :
                    // props.usage && !props.usage.loader && props.usage.msg ?
                    // <>
                    //     <div id="pro-14" style={{ minHeight: 337 }} className="d-flex flex-column justify-content-between mt-5">
                    //         <ResponseMessage
                    //             error
                    //             heading={props.usage.msg}
                    //             id="pro-15"
                    //         />
                    //     </div>
                    // </>
                    // :
                    <>
                        <Row data-testid="usage_postpaid_comp" id="dashboard-28">
                            <Col id="dashboard-29" sm={1}>
                                <img
                                    id="dashboard-30"
                                    src={ReportIcon}
                                    alt="report icone"
                                    className="d-inline-block align-top"
                                />
                            </Col>
                            <Col id="dashboard-31" sm={11} >
                                <div id="dashboard-32" className="d-flex justify-content-between">
                                    <span id="dashboard-33" className="dasboard-bill">Usage</span>
                                </div>
                            </Col>
                        </Row>
                        <Row id="dashboard-35" className="mt-5" style={{ minHeight: 222 }}>
                            <Col id="dashboard-36" md={12}>
                                <Row id="dashboard-37" className="mb-3 border-bottom pb-4">
                                    <Col id="dashboard-38" sm={2} lg={2} md={2}>
                                        <img
                                            src={DataIcon}
                                            id="dashboard-39"
                                            alt="data icone"
                                            className="d-inline-block align-top"
                                        />
                                    </Col>
                                    <Col id="dashboard-40" lg={6} md={6} className="p-0">
                                        <div id="dashboard-41" className="dasboard-bill-details"><span>Data</span><span className="data-unit-used ms-2">{`${quotaLeft} ${quotaLeftUnit} `}</span> <span className='light-font'> consumed
                                            {/* {`${sumOfQuotaAllocated} ${sumOfQuotaAllocatedUnit}`} */}
                                        </span>
                                            {/* {
                                                        (quotaLeft <= 5 && quotaLeftUnit === 'MB') || (quotaLeft <= 100 && quotaLeftUnit === 'KB') ? */}
                                            {/* <SimpleButton
                                                        onClick={props.OpenDataDrawer} //removing because not in scope
                                                        className="ms-4"
                                                        size="sm"
                                                        // onClick={(e) => e.stopPropagation()}
                                                        variant="primary">
                                                        Add data
                                                    </SimpleButton> */}
                                            {/* : ""
                                                    } */}

                                        </div >
                                        {/* <hr /> */}
                                    </Col >
                                    <Col>
                                        <SimpleButton
                                            onClick={props.OpenDataDrawer} //removing because not in scope
                                            size="sm"
                                            variant="primary">
                                            Add data
                                        </SimpleButton>
                                    </Col>
                                </Row >
                                {
                                    minutes !== undefined && minutes !== null &&
                                    <Row id="dashboard-43" className="mb-3 border-bottom pb-4">
                                        <Col id="dashboard-44" sm={2} lg={2}>
                                            <img
                                                src={CallIcon}
                                                id="dashboard-45"
                                                alt="call icone"
                                                className="d-inline-block align-top"
                                            />
                                        </Col>
                                        <Col id="dashboard-46" sm={10} lg={10} className="p-0">
                                            <div id="dashboard-47" className="dasboard-bill-details"><span>Calls</span>
                                            {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? <span className="ms-2 bold-font">NA</span>
                                             :
                                             <>
                                             <span className="ms-2 data-unit-used">{`${minutes} Minutes`}</span><span className='light-font'> consumed</span> 
                                             </>
                                             }
                                             </div>     {/* <span><span className="dashboard-usage-text-color">Unlimited</span></span> */}
                                            {/* <hr /> */}
                                        </Col>
                                    </Row>
                                }
                                {
                                    sms !== undefined && sms !== null &&
                                    <Row id="dashboard-49" className="mb-3 border-bottom pb-4">
                                        <Col sm={2} lg={2}>
                                            <img
                                                src={SMSIcon}
                                                id="dashboard-50"
                                                alt="message icone"
                                                className="d-inline-block align-top"
                                            />
                                        </Col>
                                        <Col id="dashboard-51" sm={10} lg={10} className="p-0">
                                            <div id="dashboard-52" className="dasboard-bill-details"><span>Texts</span>
                                            {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_DATACARD ? <span className="ms-2 bold-font">NA</span>
                                             :
                                             <>
                                             <span className="ms-2 data-unit-used">{`${sms} SMS`}</span><span className='light-font'> consumed</span> 
                                             </>
                                             }
                                            </div>
                                            {/* <ProgressBar id="dashboard-53" now={sms} /> */}
                                            {/* <hr /> */}
                                        </Col>

                                    </Row>
                                }

                            </Col >
                            {/* <Col id="dashboard-54" className='d-md-none d-lg-block' sm={4}>
                                <img
                                    src={MobileIcon}
                                    id="dashboard-55"
                                    alt="mobile icone"
                                    className="img-fluid align-top"
                                />
                            </Col> */}
                        </Row >
                        <Button id="dashboard-56" className="bg-white dashboard-addButton mb-2" onClick={props.gotoUsage} size="lg" active>
                            View all usage
                        </Button>
                        <p id="dashboard-57" className="mb-0 mt-3 font-style dasboard-bill-details d-none">{`Resets in ${props.reset} days`}</p>
                    </>
                }
                {
                    minutesAndSmsUsageError !== "" && isEmpty(minutesAndSmsUsage) &&
                    <div className="loader-position-response">
                        <ResponseMessage error heading={minutesAndSmsUsageError} />
                    </div>
                }

                {
                    minutesAndSmsUsageError === "" && isEmpty(minutesAndSmsUsage) &&
                    <>
                   {domain === 'B2C' && prod_type.toLowerCase() === PROD_TYPE_BROADBAND ? 
                   <>
                   <Row data-testid="usage_postpaid_comp" id="dashboard-28">
                       <Col id="dashboard-29" sm={1}>
                           <img
                               id="dashboard-30"
                               src={ReportIcon}
                               alt="report icone"
                               className="d-inline-block align-top"
                           />
                       </Col>
                       <Col id="dashboard-31" sm={11} >
                           <div id="dashboard-32" className="d-flex justify-content-between">
                               <span id="dashboard-33" className="dasboard-bill">Usage</span>
                           </div>
                       </Col>
                   </Row>
                   <Row id="dashboard-35" className="mt-5" style={{ minHeight: 222 }}>
                       <Col id="dashboard-36" md={12}>
                           <Row id="dashboard-37" className="mb-3 border-bottom pb-4">
                               <Col id="dashboard-38" sm={2} lg={2} md={2}>
                                   <img
                                       src={DataIcon}
                                       id="dashboard-39"
                                       alt="data icone"
                                       className="d-inline-block align-top"
                                   />
                               </Col>
                               <Col id="dashboard-40" lg={6} md={6} className="p-0">
                                   <div id="dashboard-41" className="dasboard-bill-details"><span>Data</span>
                                   <span className="ms-2 bold-font">NA</span>
                                   </div >
                                   {/* <hr /> */}
                               </Col >
                           </Row >
                               <Row id="dashboard-43" className="mb-3 border-bottom pb-4">
                                   <Col id="dashboard-44" sm={2} lg={2}>
                                       <img
                                           src={CallIcon}
                                           id="dashboard-45"
                                           alt="call icone"
                                           className="d-inline-block align-top"
                                       />
                                   </Col>
                                   <Col id="dashboard-46" sm={10} lg={10} className="p-0">
                                       <div id="dashboard-47" className="dasboard-bill-details"><span>Calls</span>
                                        <span className="ms-2 bold-font">NA</span>
                                        </div> 
                                       {/* <hr /> */}
                                   </Col>
                               </Row>
                               <Row id="dashboard-49" className="mb-3 border-bottom pb-4">
                                   <Col sm={2} lg={2}>
                                       <img
                                           src={SMSIcon}
                                           id="dashboard-50"
                                           alt="message icone"
                                           className="d-inline-block align-top"
                                       />
                                   </Col>
                                   <Col id="dashboard-51" sm={10} lg={10} className="p-0">
                                       <div id="dashboard-52" className="dasboard-bill-details"><span>Texts</span>
                                       <span className="ms-2 bold-font">NA</span>
                                       </div>
                                       {/* <hr /> */}
                                   </Col>
                               </Row>
                       </Col >
                   </Row >
               </>
                   :
                    <PreLoader />
                   }
                   </>

                }
            </div >
        </BorderedCard >
    )
}
export default UsageCard