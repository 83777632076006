// my plan main component
import React, { useEffect } from "react";
import NavTab from "../common/components/NavTab";
import { Route, Redirect } from "react-router-dom";
import MyPlanViewPostpaid from "./views/MyPlanViewPostpaid";
import UsagePostpaid from "./views/UsagePostpaid"
import mobile from "../../Assets/images/dashboard/mobile.svg"
import HeaderBar from "../common/components/HeaderBar";
import { useDispatch, useSelector } from "react-redux";
import { getMobileNumber, getCurrency } from "../common/utils";
import { postpaid_prefix } from "../envConfig";
import useCustomerInfo from "./hooks/useCustomerInfo";

const MyPlanAndUsagePostpaid = () => {
  const prefix = `/${postpaid_prefix}`
  const { activePlanData } = useSelector(state => state.planReducer)
  const { optimusDetails } = useSelector(state => state.auth)
  const { childNumber } = useSelector(state => state.planReducer);
  const { isBroadband } = useCustomerInfo()
  const dispatch = useDispatch()
  //routes props
  const planandusageroutes = [
    { path: `${prefix}/myPlan`, eventKey: "link-1", name: "My plan" },
    { path: `${prefix}/myPlan/usage`, eventKey: "link-2", name: "Usage" }
  ];

  const [isDesktop, setDesktop] = React.useState(window.innerWidth > 1080);
  const { fname, lname, mname, si } = optimusDetails
  React.useEffect(() => {
    //it will update the state on screen size change of screen
    const updateMedia = () => {
      setDesktop(window.innerWidth > 480);
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_CHILD_NUMBER" })
      dispatch({ type: "CUSTOMER_INFO_RESET" })
    }
  }, [])

  const desktopView = (
    <HeaderBar>
      <div id="header_desktop_pu" className="plan_usage-header container-fluid p-3" data-test="usage_desktop_header">
        <div id="header_desktop_pu_1" className="row">
          <div id="header_desktop_pu_2" className="col-6">
            <div id="header_desktop_pu_3" className="row">
              <div id="header_desktop_pu_4" className="col-2 p-0">
                <img id="header_desktop_pu_10" className="img-fluid" src={mobile} alt="dwadwa" />
              </div>
              <div id="header_desktop_pu_5" className="col-10 my-auto p-0">
                <h1 id="header_desktop_pu_11" className="mediumText">{fname && mname && lname ? `${fname} ${lname}` : fname && lname && `${fname} ${lname} `}</h1>
                <p id="header_desktop_pu_12" className="smallLightText">+44(0){getMobileNumber(childNumber ? childNumber : si ? si : "")}</p>
              </div>
            </div>
          </div>
          <div id="header_desktop_pu_6" className="col-6 text-end my-auto">
            <h1 id="header_desktop_pu_13" className="mediumText">{getCurrency(activePlanData && activePlanData.billPlanRental && activePlanData.billPlanRental.productSpecification && activePlanData.billPlanRental.componentProdOfferPrice.price)}</h1>
            <p id="header_desktop_pu_14" className="smallLightText">monthly cost</p>
          </div>
        </div>
      </div>
    </HeaderBar>
  )


  const mobileView = (
    <HeaderBar>
      <div id="header_mobile_pu" className="plan_usage-header container-fluid p-3" data-test="usage_mobile_header">
        <div id="header_mobile_pu_1" className="row">
          <div id="header_mobile_pu_2" className="col-6 p-2">
            <div id="header_mobile_pu_3" className="my-auto">
              <h1 id="header_mobile_pu_6" className="mediumText">{fname && mname && lname ? `${fname} ${lname}` : fname && lname && `${fname} ${lname} `}</h1>
              <p id="header_mobile_pu_7" className="smallLightText">+44(0){getMobileNumber(si)}</p>
            </div>
          </div>
          <div id="header_mobile_pu_4" className="col-6 text-end my-auto">
            <h1 id="header_mobile_pu_8" className="mediumText">{getCurrency(activePlanData && activePlanData.billPlanRental && activePlanData.billPlanRental.productSpecification && activePlanData.billPlanRental.componentProdOfferPrice.price)}</h1>
            <p id="header_mobile_pu_9" className="smallLightText">monthly cost</p>
          </div>
        </div>
      </div>
    </HeaderBar>
  )

  return (
    <div id="myplan_and_usage_1" className="myplan_and_usage_main_css" data-testid="planAndUsagemain">

      {isDesktop
        //load the mobile or desktop component according to the state
        ? desktopView
        : mobileView
      }
      <div id="myplan_and_usage_2" className="nav-tab-plan-usage">
        {isBroadband ? "" : <NavTab tabRoutes={planandusageroutes} />}
      </div>
      <Route
        path={`${prefix}/myPlan`}
        render={({ location, match: { url } }) => (
          <>
            <Route path={url + "/"} component={MyPlanViewPostpaid} exact />
            {isBroadband ? "" : <Route
              path={url + "/usage"}
              component={UsagePostpaid}
              exact
            />
            }
            {!(location.pathname === `${prefix}/myPlan`) && !(location.pathname === `${prefix}/myPlan/usage`) ?
              <Redirect to={url} />
              : null
            }
          </>
        )}
      />
    </div>
  );
};

export default MyPlanAndUsagePostpaid;
