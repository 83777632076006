import React from 'react'
import Radio from '../../common/form/Radio'
import gift from '../../../Assets/images/prepaid/topup/gift.svg'
import clock from '../../../Assets/images/prepaid/topup/clock.svg'
import MoneyIcon from '../../../Assets/images/plan/money.svg'
import { getCurrency} from "../../common";

const TopUpCardPrep = (props) => {
    return (
        <div>
              <div className="top_up_card_prepaid">
        <div id="p12" className="position-relative p-2 mb-3 d-flex data-planCard justify-content-between align-items-center">
            <ul id="p123">
                <li id="p124">< img src={gift} alt="data" /><span id="p125">{props.details}</span></li>
                <li id="p126"><img src={clock} alt="complete" id="p127" /><span id="p128">{props.validity}</span></li>
                <li id="p129"><img src={MoneyIcon} alt="money" id="p120" /><span id="p1256">{props.credit}</span></li>
            </ul>
            <div id="p1772" className="d-flex align-items-center">
                <span id="p1276">{getCurrency(props.cost)}</span>
                <Radio id="p1762" name="local" className="m-0" />
            </div>
        </div>
        </div>
        </div>
    )
}

export default TopUpCardPrep
