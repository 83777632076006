import axios from "axios";
import { b64_to_string } from "../common";
import { decryptData, encryptData } from "../common/utils/crypto";
import { apiLogin } from "../../redux/helpers/ApiConstant";
import { login_types } from '../../redux/helpers/types'

const refresInstance = axios.create()

export const refreshToken = async () => {
    let authData
    try {
        authData = await localStorage.getItem(login_types.TOKEN_INFO) ? decryptData(localStorage.getItem(login_types.TOKEN_INFO)) : undefined
    }
    catch (e) {
        localStorage.clear()
        window.location.reload()
    }

    const local_domain = authData.domain
    const local_mobile = authData.phoneNumber
    const local_email = authData.emailAddress
    const local_refereshToken = authData.refreshToken
    return new Promise((resolve) => {
        let config = {
            url: apiLogin.refreshToken,
            method: 'POST',
            data: {
                "phoneNumber": local_domain === 'B2C' ? local_mobile : "",
                "emailAddress": local_domain === 'B2B' ? b64_to_string(local_email) : "",
                "domain": local_domain,
                "authDetails": {
                    "refreshToken": local_refereshToken
                },
                applicationType: "web"
            }
        }
        refresInstance(config)
            .then((res) => {
                if (res.data.data) {
                    authData.accessToken = res.data.data.authDetails.accessToken
                    localStorage.setItem(login_types.TOKEN_INFO, encryptData(authData))
                    resolve(res.data.data.authDetails.accessToken)
                }
                else {
                    resolve(false);
                }
            })
            .catch(() => {
                localStorage.clear()
                window.location.reload()
            })
    });
};
