// help and support main components
import React, { useState } from 'react';
import HelpSupport from "./components/helpSupport";
import ServiceRequest from "./components/ServiceRequest"
import NewConnection from '../login/components/NewConnection';
import CallBackService from "./components/CallBackService";

let HelpSupportMain = (props) => {
    const [newConnectionvalue, setNewConnectionvalue] = useState("REQUEST_A_NEW_CONNECTION")
    const [step, setStep] = useState(props.view ? props.view : 'helpSupport');

    const onStepChange = (step) => {
        setStep(step);
    }
    return (
        <>
            <>
                {
                    {
                        'helpSupport': <HelpSupport onStepChange={onStepChange} {...props} />,
                        "serviceRequest": <ServiceRequest msisdnList={props.msisdnList} isB2B={props.view ? true : false} onStepChange={() => props.view ? props.closeDrawer() : onStepChange("helpSupport")} {...props} />,
                        "NEWCON": <NewConnection setView={onStepChange} newConnectionvalue={newConnectionvalue} afterSucessCase="helpSupport" back="helpSupport" afterSuccess="Back" />,
                        "callBack": <CallBackService msisdnList={props.msisdnList} isB2B={props.view ? true : false} onStepChange={props.view ? props.closeDrawer : onStepChange} {...props} />

                    }[step]
                }

            </>
        </>
    )
}

export default HelpSupportMain