import React, { useState } from "react";
import { divideByCurrencey, getCurrency } from "../../common";

var ROUND_PRECISION = 1000;
// Chart componnet 
export default function Chart({ data, ...rest }) {

    const [activeItem, setActiveItem] = useState('');
    let newData = []
    // get percentage for each value
    function getPercentage(now, min, max) {
        now = divideByCurrencey(now)
        var percentage = (now / max) * 100;
        return Math.round(percentage * ROUND_PRECISION) / ROUND_PRECISION;
    }

    //string cutter for month name
    function stringCutter(str) {
        str = str.toString().trim();
        return str.substring(0, 3);
    }

    // convert the API data into field data
    const convertData = () => {
        const numbers = data.map((item) => divideByCurrencey(item.usage)); // put less than condtion to ignore the -12 values // devided by 100 according to the ticket raised by airtel team
        const max = Math.max.apply(null, numbers);
        const min = Math.min.apply(null, numbers);

        data.map((d) => {
            d.height = getPercentage(d.usage, min, max);
            d.top = 100 - d.height;
            d.label = stringCutter(d.month);
            return newData.push(d)
        });
    };


    if (data && data.length > 0) {
        convertData();
    }

    //toggle the data block in chart bar
    const setVisable = (index, height) => {
        if (activeItem === index) {
            setActiveItem('');
        } else {
            setActiveItem(index);
        }
    };


    return (
        <div id="ccha-12" className="chart-area p-4" data-testid="billing-chart" {...rest}>
            {newData.map((bar, index) => (
                <div key={index} className="bar-wrapper" role="bar-wrapper" id={`ccha-12${index}`} >
                    {activeItem === '' ?
                        <div className="chart-bar" id={`ccha-132${index}`} >
                            <div className="chart-track" id={`ccha-1232${index}`} style={{ opacity: 1 }} >
                                <div
                                    id={`ccha-12w${index}`}
                                    onClick={() => setVisable(index)}
                                    role="chart-bar"
                                    className="chart-fill cursor"
                                    style={{ height: `${bar.height < 0 ? 0 : bar.height}%`, top: `${bar.top}%` }}
                                />
                                <span id={`ccha-1s2${index}`} onClick={() => setVisable(index)} className="bar-text cursor">{bar.label}</span>
                            </div>
                        </div>
                        :
                        <div className="chart-bar" id={`ccha-1e2${index}`}>
                            <div className="chart-track" id={`ccha-1y32${index}`} role="chart-bar" style={{ opacity: activeItem === index ? 1 : 0.4 }}>
                                <div
                                    id={`cchs3a-12${index}`}
                                    onClick={() => setVisable(index)}
                                    className="chart-fill cursor"
                                    style={{ height: `${bar.height < 0 ? 0 : bar.height}%`, top: `${bar.top}%` }}
                                />
                                <span id={`ccha-1l2${index}`} onClick={() => setVisable(index)} className="bar-text cursor">{bar.label}</span>
                            </div>
                        </div>
                    }
                    {activeItem === index ?
                        <div className="bar-display" id={`ccha-12id${index}`} data-testid="bar-display">
                            <p className="font-weight-bold" id={`ccha-1m2${index}`}>{bar.month} Bill</p>
                            <p id={`ccha-1c2${index}`} className="tab text-muted">{getCurrency(divideByCurrencey(bar.usage))}</p>  {/*  devided by 100 according to the ticket raised by airtel team */}
                        </div>
                        : null}
                </div>
            ))}
        </div>
    );
}
