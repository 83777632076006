// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { ENV, firebaseAPIKey } from "../envConfig";

/////////// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: firebaseAPIKey,
    authDomain: "airtelvodafoneselfcare.firebaseapp.com",
    projectId: "airtelvodafoneselfcare",
    storageBucket: "airtelvodafoneselfcare.appspot.com",
    messagingSenderId: "301059311168",
    appId: "1:301059311168:web:0b08b12d33adea5b1d41a5",
    measurementId: "G-GT1QVMP7S0"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

ENV === "prod" && getAnalytics(app);