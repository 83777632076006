import React, { useEffect } from 'react'
import HeaderBar from '../../../../components/common/components/HeaderBar'
import { useHistory } from 'react-router-dom';
import AlertBox from '../../../common/components/AlertBox';
import { b2b_prefix } from '../../../envConfig';

const ResponseDetail = () => {

    let { location } = useHistory()
    const history = useHistory()
    const prefix = `/${b2b_prefix}`

    useEffect(() => {
        if (location && location.state && location.state.data) {
            if (location && location.state && location.state.data && location.state.data.b2bRaiseServiceSubmitSuccess.length === 0) {
                history.push(`${prefix}/MSISDN`)
            }
        } else {
            history.push(`${prefix}/MSISDN`)
        }



    }, [history, location, prefix])


    return (
        <React.Fragment>
            <div id="mssidnb2b_7" className="response-detail cardContainer">
                <HeaderBar>
                    <div id="mssidnb2b_8" className="container-fluid p-4">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <div onClick={() => history.goBack()} className="back-button" >
                                &larr;{" "} Back

                            </div>
                            <h1 id="mssidnb2b_9" className="semiMediumText m-0">Response Detail</h1>
                        </div>
                        {
                            location.state && location.state.data && location.state.data.b2bRaiseServiceSubmitSuccess.length > 0 && location.state.data.b2bRaiseServiceSubmitSuccess.map(item => (
                                <AlertBox
                                    success={item.status === "Success" ? true : false}
                                    error={item.status === "Failed" ? true : false}
                                    heading={'+' + item.msisdn}
                                    paragraph={<>{item && item.responseMsg ? item.responseMsg : ""}{item.status === "Success" && "Request processed successfully."}</>}
                                />
                            ))
                        }

                    </div>
                </HeaderBar>
            </div >
        </React.Fragment >
    )
}

export default ResponseDetail