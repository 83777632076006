// usage components 
import React, { useEffect, useState } from 'react'
import { SimpleButton, ResponseMessage, PreLoader, ResponseModal, } from '../../common';
import AllPromoLists from './AllPromoLists'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from '../../common/utils/isEmpty'
import { changeOrder, getDataBoltOn } from '../../../redux/actions/myPlanAction';
import { Form } from 'react-bootstrap';
import useActivePlanId from '../hooks/useActivePlanId';
import useAllownces from '../hooks/useAllownces';
import AddData from './AddData';
import backArrow from "../../../Assets/images/helpSupport/backArrow.svg"

const UpgradePlan = ({ handleClose}) => {

    const [selectedPlan, setSelectedPlan] = useState({})
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { optimusDetails } = useSelector(state => state.auth)
    const { promoList, errorPromoList, changeOrderSuccess, changeOrderError, activePlanData, childNumber } = useSelector(state => state.planReducer);
    const [isDisabledChangeOrder, setIsDisabledChangeOrder] = useState(false)
    const { si, si_lob, domain, cust_class, circle_id } = optimusDetails
    const [orderConfirm, setOrderConfirm] = useState(false)
    const [agree, setAgree] = useState(false)
    const planId = useActivePlanId(activePlanData)
    const { data } = useAllownces(selectedPlan)
    const [addonModal, setAddonModal] = useState(false)
    const [addOnView, setaddOnView] = useState("CONFIRM")
    const [addOnViewPrevState, setaddOnViewPrevState] = useState("")
    const [addonSelectedPlan, setAddonSelectedPlan] = useState({})
    let date = new Date()
    let day = date.getDate()

    const clickedUpgradePlan = () => {
        setIsDisabledChangeOrder(true)
        // if data alownce is not there then need to show the bolton 
        if (activePlanData && data === 0) {
            setAddonModal(true)
            if (childNumber)
                dispatch(getDataBoltOn(planId, childNumber, domain, si_lob, circle_id, cust_class))
            else
                dispatch(getDataBoltOn(planId, si, domain, si_lob, circle_id, cust_class))
        }
        else {
            openConfirmModal()
        }
    }

    const submitChangeOrder = () => {
        if (!isEmpty(selectedPlan)) {
            if (!isEmpty(optimusDetails) && errorPromoList === "") {
                //write submission code here
                let addonPlan = {}
                if (addonSelectedPlan && !isEmpty(addonSelectedPlan) && addonSelectedPlan.name && addonSelectedPlan.productOfferingId) {
                    addonPlan = {
                        name: addonSelectedPlan.name,
                        planId: addonSelectedPlan.productOfferingId,
                        pcrfId: addonSelectedPlan.pcrfId,
                        pcrfName: addonSelectedPlan.pcrfName
                    }
                }
                setLoading(true)
                dispatch(changeOrder(planId, childNumber ? childNumber : si, si_lob, domain, cust_class, selectedPlan.promotionId, selectedPlan.name, circle_id, selectedPlan.pcrfSpec, true, addonPlan))
                closeConfirmOrder()
                closeAddonConfirm()
            }
        }
    }


    useEffect(() => {
        if (changeOrderError) {
            setLoading(false)

        }
        if (changeOrderSuccess) {
            setLoading(false)
        }

    }, [changeOrderSuccess, changeOrderError])


    useEffect(() => {
        return () => dispatch({ type: "CHANGE_ORDER_RESET" })
    }, [])

    // sorting the list price wise
    function compare(a, b) {
        try {
            return (
                a.bundlePriceDetails
                    && a.bundlePriceDetails.summaryOfCharges
                    && a.bundlePriceDetails.summaryOfCharges.attribute
                    && a.bundlePriceDetails.summaryOfCharges.attribute[0].attributeName
                    && a.bundlePriceDetails.summaryOfCharges.attribute[0].attributeName === "RC" ? a.bundlePriceDetails.summaryOfCharges.attribute[0].attributeValue : ""
            ) - (
                    b.bundlePriceDetails
                        && b.bundlePriceDetails.summaryOfCharges
                        && b.bundlePriceDetails.summaryOfCharges.attribute
                        && b.bundlePriceDetails.summaryOfCharges.attribute[0].attributeName
                        && b.bundlePriceDetails.summaryOfCharges.attribute[0].attributeName === "RC" ? b.bundlePriceDetails.summaryOfCharges.attribute[0].attributeValue : ""

                )
        }
        catch (e) {
            return 0
        }
    }
    let newPromoList = {}
    if (promoList && !isEmpty(promoList)) {
        promoList.data.sort(compare)
        newPromoList = promoList

    }
    const openConfirmModal = (fromAddOn = false) => {
        if (fromAddOn) changeView("IFNOT19")
        else setOrderConfirm(true)
    }
    // close confirmation order box if user is trying to change the plan before or after 19th
    const closeConfirmOrder = () => {
        setIsDisabledChangeOrder(false)
        setOrderConfirm(false)

    }

    //close Add data bolton confirmation modal
    const closeAddonConfirm = () => {
        setAddonModal(false)
        changeView("CONFIRM")
        setIsDisabledChangeOrder(false)
        setAgree(false)
        setAddonSelectedPlan({})

    }
    const changeView = (view) => {
        const prevSate = addOnView
        setaddOnViewPrevState(prevSate)
        setaddOnView(view)
    }


    const backFunction = () => {
        setaddOnView(addOnViewPrevState)
    }

    const confirmContent = (
        <>
            <h3 className='text-danger'>IMPORTANT NOTICE</h3>
            <p className='text-justify' >By selecting to change your plan today, you are agreeing to extend your contract term for 24 months from the current date. This will be updated automatically & you do not have to take any further action. T&Cs will apply.</p>
            <p>Please select confirm & continue.</p>
            <div className='changeplan_confirm my-4'>
                <Form.Check
                    type="checkbox"
                    label="Confirm"
                    checked={agree}
                    onChange={() => setAgree(!agree)}
                />

            </div>
            <SimpleButton disabled={!agree} maxWidth onClick={() => agree && submitChangeOrder()}>Continue</SimpleButton>
        </>
    )

    return (
        <>
            {
                isEmpty(newPromoList) && errorPromoList === "" &&
                <div id="pro-12" className="loading-screen" data-testid="profile_preloader">
                    <PreLoader id="pro-13" />
                </div>
            }
            {
                !isEmpty(newPromoList) && newPromoList.data.length === 0 && errorPromoList === "" &&
                <>
                    <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            error
                            heading={"Data not found"}
                            id="pro-15"
                        />
                        <div className="d-grid gap-3 success-close-button" id="pro-16" >
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                        </div>
                    </div>
                </>
            }
            {

                !isEmpty(newPromoList) && newPromoList.data.length > 0 && changeOrderSuccess === "" && changeOrderError === "" && !loading &&
                <>
                    <h1 className="mediumText mb-4">Change Plan</h1>
                    {
                        newPromoList.data.map((promData, i) => {
                            return (
                                <AllPromoLists key={promData.promotionId} promData={promData} callBack={(data) => setSelectedPlan(data)} index={i} />
                            )
                        })
                    }

                    <SimpleButton id="change-plan-button" maxWidth disabled={isEmpty(selectedPlan) || (!isEmpty(selectedPlan) && isDisabledChangeOrder)} variant="primary" onClick={clickedUpgradePlan} className="mt-4">
                        Upgrade to new plan
                    </SimpleButton>
                </>

            }
            {
                loading &&
                <div id="pro-12" className="loading-screen" data-testid="profile_preloader">
                    <PreLoader id="pro-13" />
                </div>
            }
            {changeOrderSuccess &&
                <>
                    <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            success
                            heading={'Your plan has been changed successfully & your contract term has been updated. Thank you for being with Airtel-Vodafone.'}
                            id="pro-15"
                        />
                        <div className="d-grid gap-3 success-close-button" id="pro-16" >
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Cancel</SimpleButton>
                        </div>
                    </div>
                </>

            }
            {changeOrderError &&
                <>
                    <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            error
                            heading={changeOrderError}
                            id="pro-15"
                        />
                        <div className="d-grid gap-3 success-close-button" id="pro-16" >
                            <SimpleButton variant="primary" maxWidth onClick={() => {
                                dispatch({ type: "CHANGE_ORDER_RESET" });
                                setIsDisabledChangeOrder(false)
                                setSelectedPlan({})
                            }} id="pro-17" data-testid="try_again">Try Again</SimpleButton>
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18"  >Close</SimpleButton>
                        </div>
                    </div>
                </>

            }
            {errorPromoList &&
                <>
                    <div id="pro-14" className="d-flex flex-column justify-content-between mt-5">
                        <ResponseMessage
                            error
                            heading={errorPromoList}
                            id="pro-15"
                        />
                        <div className="d-grid gap-3 success-close-button" id="pro-16" >
                            <SimpleButton variant="outline-secondary" className="btn-outline" maxWidth onClick={handleClose} id="pro-18">Cancel</SimpleButton>
                        </div>
                    </div>
                </>

            }
            <ResponseModal
                show={orderConfirm}
                handleClose={() => closeConfirmOrder()}
                size="md"
            >
                {confirmContent}
            </ResponseModal>

            {/* Add on list */}

            <ResponseModal
                show={addonModal}
                handleClose={() => closeAddonConfirm()}
                size="md"
                isBack={addOnViewPrevState !== "" ? true : false}
                back={<div onClick={backFunction} className="mb-3 cursor" >
                    <img
                        src={backArrow}
                        alt="backArrow"
                        className="d-inline-block align-top"
                    />
                </div>}
            >
                {{
                    "CONFIRM":
                        <>
                            <p className='changeplan_confirm_p mb-4 text-justify'>
                                The selected plan does not include any local data allowance. Please select a data bolt on, or continue without opting for a data bolt on. Please note that without opting for a data bolt on, you will be charged for local data usage at our standard out of bundle rate.
                            </p>
                            <div className="d-flex gap-3">
                                <SimpleButton variant="outline-primary" maxWidth onClick={() => openConfirmModal(true)}>Continue without data allowance</SimpleButton>
                                <SimpleButton maxWidth onClick={() => changeView("ADDON")}>Add data allowance</SimpleButton>
                            </div>

                        </>,
                    "IFNOT19": confirmContent,
                    "ADDON":
                        <>
                            <AddData isSelectAllownce  handleClose = {backFunction} back={(addOnPlan) => {
                                changeView("FINAL")
                                setAddonSelectedPlan(addOnPlan)
                               
                            }
                            } />
                        </>,
                    "FINAL":
                        <>
                            <h3 className='text-danger'>{selectedPlan && selectedPlan.name && selectedPlan.name}</h3>
                            <p className='text-muted'>{addonSelectedPlan && addonSelectedPlan.name && addonSelectedPlan.name}</p>
                            <hr />
                            {day === '19' || day === 19 ? "" : confirmContent}
                        </>,

                }[addOnView]}
            </ResponseModal>
        </>
    )
}

export default UpgradePlan
